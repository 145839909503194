<div class="gtmt-container" >
    <mat-grid-list [cols]="1" [rowHeight]="'3rem'" *ngIf="_GlobalDataService.interactionIdSF">

        <mat-grid-tile rowspan="2">
            <mat-toolbar>
                Module Cap mailing plateforme
            </mat-toolbar>
        </mat-grid-tile>


        <mat-grid-tile rowspan="2">
            <button mat-raised-button color="blue" (click)="openPopOut()">Ouvrir Cap Mailing Plateforme
                <mat-icon  svgIcon="Ouvrir"></mat-icon>
            </button>
        </mat-grid-tile>

        <mat-grid-tile rowspan="2">
            <button mat-raised-button color="blue" (click)="closePopOut()">Fermer Cap Mailing Plateforme
                <mat-icon  svgIcon="Croix"></mat-icon>
            </button>
        </mat-grid-tile>

        <mat-grid-tile rowspan="2">
            <button mat-raised-button color="blue" (click)="ReloadPopOut()">Actualiser Cap Mailing Plateforme
                <mat-icon  svgIcon="Refresh"></mat-icon>
            </button>
        </mat-grid-tile>

        <mat-grid-tile rowspan="6">
            <div class="logo">
            </div>
        </mat-grid-tile>

    </mat-grid-list>


    <mat-grid-list [cols]="1" [rowHeight]="'3rem'" *ngIf="!_GlobalDataService.interactionIdSF">

        <mat-grid-tile rowspan="2">
            <mat-toolbar>
                Module Cap mailing plateforme
            </mat-toolbar>
        </mat-grid-tile>

        <mat-grid-tile rowspan="2">
            <p>
                Votre n'êtes pas autorisé à acceder à Cap Mailing Plafeforme. 
                Veuillez vous connecter à SalesCloud pour continuer.
            </p>
        </mat-grid-tile>

        <mat-grid-tile rowspan="6">
            <div class="logo">
            </div>
        </mat-grid-tile>

    </mat-grid-list>
</div>
