<mat-card>
    <mat-card-title>Mes informations personnelles</mat-card-title>
    <mat-card-content>
        <form [formGroup]="userForm">
            <mat-grid-list cols="2" rowHeight="1.2rem">
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Login</mat-label>
                        <input type="text" matInput formControlName="login" readonly required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Email de contact</mat-label>
                        <input type="text" matInput formControlName="email" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Nom</mat-label>
                        <input type="text" matInput formControlName="lastName" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Prénom</mat-label>
                        <input type="text" matInput formControlName="firstName" required>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button [disabled]="!userForm.valid" (click)="saveUserChanges()" mat-flat-button
            color="primary">Enregistrer modifications</button>
    </mat-card-actions>
</mat-card>