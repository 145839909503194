<mat-card [formGroup]="_CampagnePlanificationService.form">
  <mat-card-header>
    <mat-card-title>
      <h2>Récurrence de la campagne courrier</h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-grid-list cols="2" rowHeight="3.5rem" [gutterSize]="'7rem'">
      <mat-radio-group
        formControlName="FrequenceEnvoi"
        aria-label="Choisir une vitesse"
      >
        <mat-grid-tile
          *ngFor="let recurrence of recurrences"
          class="gtmt-checkbox-container"
          colspan="1"
          rowspan="0.5"
          (click)="
            updateFrequenceEnvoi(recurrence)
          "
          [disabled]="true"
        >
          <span>{{ recurrence }}</span>
          <mat-radio-button [value]="recurrence"> </mat-radio-button>
        </mat-grid-tile>
      </mat-radio-group>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
