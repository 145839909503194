import { Annonceur } from './annonceur.model'

export class AdresseRetour {
  IdAnnonceur: string | undefined;
  EloquaInstallId: string | undefined;
  EloquaInstanceId: string | undefined;
  EloquaSiteId: string | undefined;
  EloquaSiteName: string | undefined;
  EloquaAppId: string | undefined;

  oauth_consumer_key: string | undefined;
  oauth_nonce: string | undefined;
  oauth_signature: string | undefined;
  oauth_signature_method: string | undefined;
  oauth_timestamp: string | undefined;
  oauth_version: string | undefined;
  user: string | undefined;
  username: string | undefined;

  IdAdresse: string | undefined;

  NomAdresse: string;
  RaisonSociale: string;
  Service: string;
  EntreeBatimentZI: string;
  Numero: string;
  MentionSpeciale: string;
  CodePostal: string;
  Ville: string;
  Favori: string;

  constructor(Annonceur: Annonceur = undefined) {
      if (Annonceur) {
          this.IdAnnonceur = Annonceur.IdAnnonceur;
          this.EloquaInstallId = Annonceur.EloquaInstallId;
          this.EloquaInstanceId = Annonceur.EloquaInstanceId;
          this.EloquaSiteId = Annonceur.EloquaSiteId;
          this.EloquaSiteName = Annonceur.EloquaSiteName;
          this.EloquaAppId = Annonceur.EloquaAppId;

          this.oauth_consumer_key = Annonceur.oauth_consumer_key;
          this.oauth_nonce = Annonceur.oauth_nonce;
          this.oauth_signature = Annonceur.oauth_signature;
          this.oauth_signature_method = Annonceur.oauth_signature_method;
          this.oauth_timestamp = Annonceur.oauth_timestamp;
          this.oauth_version = Annonceur.oauth_version;
          this.user = Annonceur.user;
          this.username = Annonceur.username;
      }
      this.IdAdresse = "0";
      this.NomAdresse = "";
      this.RaisonSociale = "";
      this.Service = "";
      this.EntreeBatimentZI = "";
      this.Numero = "";
      this.MentionSpeciale = "";
      this.CodePostal = "";
      this.Ville = "";
      this.Favori = "0";
  }
}