<mat-card [formGroup]="_CampagnePlanificationService.form">
    <mat-card-content>

        <mat-grid-list cols="2" rowHeight="3.5rem" [gutterSize]="'7rem'">
            <!-- Date d’activation -->
            <mat-grid-tile colspan="1" rowspan="0.5">
                <mat-label>Date d’activation
                    <mat-icon [mdePopoverTriggerFor]="appPopover1" mdePopoverTriggerOn="click"
                        #popoverTrigger="mdePopoverTrigger" style="cursor: pointer;">
                        info</mat-icon>
                </mat-label>
            </mat-grid-tile>

            <!-- Date de retour du bon à tirer -->
            <mat-grid-tile colspan="1" rowspan="0.5">
                <mat-label>Date de retour du bon à tirer
                </mat-label>
            </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="2" rowHeight="15rem" [gutterSize]="'7rem'">

            <!-- Date d’activation -->
            <mat-grid-tile colspan="1" rowspan="1">
                <mat-form-field floatLabel="never">
                    <input matInput [matDatepickerFilter]="disablePastDatesFilter" [matDatepicker]="picker"
                        formControlName="DateRemiseImprimeur" [errorStateMatcher]="errorMatcher">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="_CampagnePlanificationService.form.hasError('isBeforeToday') && !_CampagnePlanificationService.form.get('DateRemiseImprimeur').hasError('required')">
                        La date d’activation <strong>ne doit pas être dans le passé</strong>
                    </mat-error>
                    <mat-error
                        *ngIf="(!_CampagnePlanificationService.form.get('DateRemiseImprimeur').valid || _CampagnePlanificationService.form.hasError('isInvalid')) && !_CampagnePlanificationService.form.hasError('isBeforeToday')">
                        Date d’activation <strong>n'est pas valide</strong>
                    </mat-error>
                    <mat-error
                        *ngIf="_CampagnePlanificationService.form.get('DateRemiseImprimeur').hasError('required') && !_CampagnePlanificationService.form.hasError('isInvalid')">
                        Merci de saisir votre date d’activation.
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <!-- Date de retour du bon à tirer -->
            <mat-grid-tile colspan="1" rowspan="1">
                <!-- <mat-label matPrefix>Prévue le
                </mat-label> -->

                <mat-form-field floatLabel="never">
                    <span matPrefix>Prévue le &nbsp;</span>
                    <input matInput formControlName="DateTheoriqueDepotLaPoste" readonly>
                </mat-form-field>

            </mat-grid-tile>

        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="4.5rem" [gutterSize]="'7rem'">

            <!-- Date estimée de début de distribution -->
            <mat-grid-tile colspan="1" rowspan="1">

                <mat-label>Date estimée de début de distribution
                    <mat-icon [mdePopoverTriggerFor]="appPopover2" mdePopoverTriggerOn="click"
                        #popoverTrigger="mdePopoverTrigger" style="cursor: pointer;">
                        info</mat-icon>
                </mat-label>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="4.5rem" [gutterSize]="'7rem'">
            <mat-grid-tile colspan="1" rowspan="1">

                <!-- Date estimée de début de distribution -->
                <mat-form-field floatLabel="never">
                    <span matPrefix>Prévue le &nbsp;</span>
                    <input matInput formControlName="DateEstimeeDistribution" readonly>
                </mat-form-field>
            </mat-grid-tile>

        </mat-grid-list>

    </mat-card-content>
</mat-card>


<mde-popover #appPopover1="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="gtmt-popover-card">
        <mat-card-header> Point d’information
            <mat-icon svgIcon="Infos"></mat-icon>
        </mat-card-header>
        <mat-card-content>
            La date d'activation représente la date d'envoie de votre commande au Groupe La Poste pour traitement.
        </mat-card-content>
    </mat-card>
</mde-popover>


<mde-popover #appPopover2="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="gtmt-popover-card">
        <mat-card-header> Point d’information
            <mat-icon svgIcon="Infos"></mat-icon>
        </mat-card-header>
        <mat-card-content>
            La date estimée de début de distribution est soumise à la condition de validation du bon à tirer par vos
            soins.
        </mat-card-content>
    </mat-card>
</mde-popover>