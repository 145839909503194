import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthenticationService } from './authentication.service';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  _url: string = environment.url_api + '/eventLog';

  constructor(
    private _http: HttpClient,
    private _GlobalDataService: GlobalDataService
  ) { }

  add(idCampagne: string, idCommande: string, event: string) {
    
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    if(!this._GlobalDataService.objectParams?.["UserName"]){
      this._GlobalDataService.objectParams = Object.assign({}, this._GlobalDataService.objectParams, { "UserName": this._GlobalDataService.Annonceur['CreeParLogin']})
    }
    console.log("CreeParLogin = ", this._GlobalDataService.objectParams["UserName"]);
    return this._http.post<any>(this._url, {
      request: {
        "CreeParLogin": this._GlobalDataService.objectParams["UserName"],
        "Event": event,
        "IdCampagne": idCampagne,
        "IdCommande": idCommande
      }
    }, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}`}) });
  }

}
