<mat-card>
    <mat-card-header>
        <mat-card-title>Rythme d’envoi</mat-card-title>
    </mat-card-header>
    <img mat-card-image *ngIf=" _GlobalDataService.Campagne.CodeVitesse == ''" src="../../../../../assets/images/schema-rythme-envoi.png" alt="Photo of a Shiba Inu">
    <img mat-card-image *ngIf=" _GlobalDataService.Campagne.CodeVitesse == 'Classique'" src="../../../../../assets/images/schema-rythme-envoi-classique.png" alt="Photo of a Shiba Inu">
    <img mat-card-image *ngIf=" _GlobalDataService.Campagne.CodeVitesse == 'Rapide'" src="../../../../../assets/images/schema-rythme-envoi-rapide.png" alt="Photo of a Shiba Inu">

    <mat-card-content>
        <p>Attention toute commande validée après 17h00 sera transmise aux équipes de production le jour ouvré suivant à
            partir
            de
            8h00. Date estimative sur la base des jours ouvrés et sans prise en compte de jours fériés.
        </p>
    </mat-card-content>
</mat-card>