import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { AccumulationAnnotationService, AccumulationDataLabelService, AccumulationLegendService, AccumulationTooltipService, LegendService, PieSeriesService } from '@syncfusion/ej2-angular-charts';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CampagneModule } from './campagne/campagne.module';
import { CoreModule } from './core//core.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ErreurComponent } from './erreur/erreur.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SharedModule } from '@shared/shared.module';
import { StandaloneModule } from './standalone/standalone.module';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { PopoutService } from '@core/services';
import { AdministrationModule } from './administration/administration.module';
import { EloquaModule } from './eloqua/eloqua.module';
import { SalesforceModule } from './salesforce/salesforce.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DATE_FORMAT_FRA } from '@core/conf/date-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { StylePaginatorDirective } from '@core/directives/style-paginator.directive';
import { CookieService } from 'ngx-cookie-service';
import { httpInterceptorProviders } from '@core/services/http.interceptor';
import { FileRequestLimitService } from '@core/services/file-request-limit.service';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    ErreurComponent,
  ],
  imports: [
    NgxExtendedPdfViewerModule,
    PdfViewerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    CampagneModule,
    StandaloneModule,
    AdministrationModule,
    EloquaModule,
    SalesforceModule,
  ],
  providers: [
    CookieService,PopoutService, PieSeriesService, AccumulationLegendService, AccumulationTooltipService, AccumulationDataLabelService,
    AccumulationAnnotationService, LegendService, DatePipe, httpInterceptorProviders,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT_FRA },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
