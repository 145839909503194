<mat-toolbar>
    <mat-toolbar-row (click)="gotoLogin()" style="cursor:pointer">
        <mat-icon>
            replay
        </mat-icon>
        Retour
    </mat-toolbar-row>
</mat-toolbar>


<mat-card [formGroup]="form">
    <mat-card-header>
        <mat-card-title>
            Vous avez oublié votre mot de passe Cap Mailing Plateforme ? 
        </mat-card-title>
        <mat-card-subtitle>
            Entrez l’adresse mail liée à votre compte et nous vous enverrons un lien de réinitialisation
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Adresse mail</mat-label>
            <input matInput placeholder="dupont@gmail.com" formControlName="email">
        </mat-form-field>      
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="requestReset()">Envoyer</button>
    </mat-card-actions>
</mat-card>


