import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { GlobalDataService } from '@core/services/global-data.service';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, NavigationBehaviorOptions, Params, Router } from '@angular/router';
import { CampagneTypologieService } from '@core/services';
import { navPaths } from '@core/models/support.model';
import { Subscription } from 'rxjs';
import { stepperStandaloneIndexes } from '@core/constants/stepperIndexes';

@Component({
  selector: 'app-campagne-typologie',
  templateUrl: './campagne-typologie.component.html',
  styleUrls: ['./campagne-typologie.component.scss']
})
export class CampagneTypologieComponent implements OnInit, OnDestroy {

  private navigationOption: NavigationBehaviorOptions = { state: {} };
  
  private activatedRouteSub: Subscription;
  private stepperSub: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private _GlobalDataService: GlobalDataService,
    private _CampagneTypologieService: CampagneTypologieService,
    private _stepper: MatStepper,
  ) { 
    this.activatedRouteSub = this.activatedRoute.queryParams.subscribe(() => {
      let queryParams: Params = {
        AppId: this._GlobalDataService.objectParams['AppId'],
        AssetId: this._GlobalDataService.objectParams['AssetId'],
        InstallId: this._GlobalDataService.objectParams['InstallId'], // environment.InstallIdv10,
        InstanceId: this._GlobalDataService.objectParams['InstanceId'],
        SiteId: this._GlobalDataService.objectParams['SiteId'],
        SiteName: this._GlobalDataService.objectParams['SiteName'],
        UserId: this._GlobalDataService.objectParams['UserId'],
        UserName: this._GlobalDataService.objectParams['UserName'],
        oauth_consumer_key: this._GlobalDataService.objectParams['oauth_consumer_key'],
        oauth_nonce: this._GlobalDataService.objectParams['oauth_nonce'],
        oauth_signature: this._GlobalDataService.objectParams['oauth_signature'],
        oauth_signature_method: this._GlobalDataService.objectParams['oauth_signature_method'],
        oauth_timestamp: this._GlobalDataService.objectParams['oauth_timestamp'],
        oauth_version: this._GlobalDataService.objectParams['oauth_version'],
      };
      this._GlobalDataService.objectParams = queryParams;
    });
  }

  ngOnInit(): void {
    this._CampagneTypologieService.SupportFormGroup = this._formBuilder.group({
      CodeTypologie: new FormControl(this._GlobalDataService.Campagne.CodeTypologie),
      CodeSupport: new FormControl(this._GlobalDataService.Campagne.CodeSupport),
      CodeDimensionsSupport: new FormControl(this._GlobalDataService.Campagne.CodeDimensionsSupport),
      CodeGrammageSupport: new FormControl(this._GlobalDataService.Campagne.CodeGrammageSupport),
      CodeFinitionSupport: new FormControl(this._GlobalDataService.Campagne.CodeFinitionSupport),
      CodeEncreSupport: new FormControl(this._GlobalDataService.Campagne.CodeEncreSupport)
    });

    this._CampagneTypologieService.EnveloppeFormGroup = this._formBuilder.group({
      CodeTypologie: new FormControl(this._GlobalDataService.Campagne.CodeTypologie),
      CodeEnveloppe: new FormControl(this._GlobalDataService.Campagne.CodeEnveloppe),
      CodeDimensionsEnveloppe: new FormControl(this._GlobalDataService.Campagne.CodeDimensionsEnveloppe),
      CodeGrammageEnveloppe: new FormControl(this._GlobalDataService.Campagne.CodeGrammageEnveloppe),
      CodeFinitionEnveloppe: new FormControl(this._GlobalDataService.Campagne.CodeFinitionEnveloppe),
      CodeEncreEnveloppe: new FormControl(this._GlobalDataService.Campagne.CodeEncreEnveloppe)
    });

    this.Init();
    this.alertChiliTypologyChanges();
  }

  Init() {
    // Typologie (Type de support)
    this._CampagneTypologieService.dataSupportTypologies = this._GlobalDataService.distinct(this._GlobalDataService.produits, 'CodeTypologie');

    // Support
    this._CampagneTypologieService.dataSupports = this._GlobalDataService.produits
      .filter((element: {
        CodeTypologie: string | undefined;
      }) => element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie);
    this._CampagneTypologieService.dataSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataSupports, 'CodeSupport');

    // Dimension
    this._CampagneTypologieService.dataDimensionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeSupport: string | undefined; CodeTypologie: string | undefined; }) => element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataDimensionsSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataDimensionsSupports, 'CodeDimensionsSupport');

    // Grammage
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.produits
      .filter((element: { CodeDimensionsSupport: string | undefined; CodeSupport: string | undefined; CodeTypologie: any; }) => element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataGrammagesSupports, 'CodeGrammageSupport');

    // Finition
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataFinitionsSupports, 'CodeFinitionSupport');

    // Encre
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.produits
      .filter((element: { CodeFinitionSupport: any; CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeFinitionSupport == this._GlobalDataService.Campagne.CodeFinitionSupport
        && element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEncresSupports, 'CodeFinitionSupport');

    this._CampagneTypologieService.dataEnveloppes= this._GlobalDataService.produits
      .filter((element: { CodeTypologie: string | undefined; CodeSupport: string | undefined; CodeDimensionsSupport: string | undefined; CodeGrammageSupport: string | undefined; CodeFinitionSupport: string | undefined; CodeEncreSupport: string | undefined; }) => element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeFinitionSupport == this._GlobalDataService.Campagne.CodeFinitionSupport
        && element.CodeEncreSupport == this._GlobalDataService.Campagne.CodeEncreSupport);
    this._CampagneTypologieService.dataEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEnveloppes, 'CodeEnveloppe');

    this.navigate();
  }

  navigate() {
    if (this._GlobalDataService.Campagne.CodeTypologie != "") {
      this._CampagneTypologieService.navigate(navPaths.selection, this.activatedRoute, this.navigationOption);
    }
    else {
      this._CampagneTypologieService.navigate(navPaths.format, this.activatedRoute, this.navigationOption);
    }
  }

  ngOnDestroy() {
    this.activatedRouteSub?.unsubscribe();
    this.stepperSub?.unsubscribe();
  }

  private alertChiliTypologyChanges(): void {
    this.stepperSub = this._stepper.selectionChange.subscribe((event) => {
      if (this._GlobalDataService.Campagne.creationType === 'chili' && event.selectedIndex === stepperStandaloneIndexes.Typologie) {
        this._GlobalDataService.alert("Attention, si vous modifiez votre produit, votre configuration réalisée dans le parcours Studio de création sera perdue.")
      }
    })
  }
}
