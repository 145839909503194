import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AccountService,
  AuthenticationService,
  GlobalDataService,
} from '@core/services';
import { Subject } from 'rxjs';
import { PasswordControlData } from '@shared/components/password-control/password-control.component';
import { User } from '@core/models/user.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  login: string;

  user: User;

  public eventsSubject: Subject<string> = new Subject<string>();
  public errorSubject: Subject<string> = new Subject<string>();

  public isSendingPassword: boolean = false;

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _AccountService: AccountService,
    private _authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    // Load user informations
    this._authenticationService.getUserInfo().subscribe({
      next: (res: HttpResponse<User>) => {
        this.user = res.body;
      },
      error: (err) => {
        console.log('err', err);
      },
    });
  }

  ngOnDestroy() {
    this.eventsSubject?.unsubscribe();
  }

  /**
   * Call API
   */
  public changePassword(passwordForm: PasswordControlData): void {
    this.isSendingPassword = true;
    this._AccountService
      .changePassword(passwordForm.oldPassword, passwordForm.newPassword)
      .subscribe({
        next: (_) => {
          this.eventsSubject.next('Changement de mot de passe réussi.');
          this.isSendingPassword = false;
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          if(error.status >=400 && error.status <=499 && error.error) {
            const body : {title?: string} = error.error;
            if(body.title === 'Incorrect password') {
              this.errorSubject.next('Merci de renseigner votre ancien mot de passe.');
            } else if(body.title === 'Vous ne pouvez pas utilisez un ancien mot de passe.') {
              this.errorSubject.next('Entrez un nouveau mot de passe. Il doit être différent de vos 9 derniers mot de passe.');
            } else {
              this.errorSubject.next('Changement de mot de passe a echoué, Veuillez réessayer.');
            }
          } else if(error.status >=500 && error.status <=599) {
            this._GlobalDataService.alert(
              'Une erreur est survenue.'
            );
          } else {
            this._GlobalDataService.alert(
              'Changement de mot de passe a echoué, Veuillez réessayer.'
            );
          }
          this.isSendingPassword = false;
        },
      });
  }
}
