import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalDataService } from '@core/services';
import { EstimationService } from '@core/services/estimation.service';
import { Subscription, pairwise, startWith } from 'rxjs';

@Component({
  selector: 'app-estimation-card',
  templateUrl: './estimation-card.component.html',
  styleUrls: ['./estimation-card.component.scss'],
})
export class EstimationCardComponent implements OnInit, OnDestroy {
  @Input() showInfo?: boolean = false;
  @Input() showRefresh?: boolean = false;
  @Input() showunitAmountWithoutTax?: boolean = false;
  @Input() showamountWithoutTax?: boolean = false;

  private controls: { [key: string]: FormControl };

  private volumeSub: Subscription;

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _EstimationService: EstimationService
  ) {
    this.controls = {
      volume: new FormControl(this._GlobalDataService.Campagne.volume, [
        Validators.maxLength(100),
        Validators.pattern('^[0-9]*$'),
      ]),
    };

    this._GlobalDataService.formEstimate = new FormGroup(this.controls);

    // Force only numbers on volume input
    const volumeCtrl = this._GlobalDataService.formEstimate.get('volume');
    this.volumeSub = volumeCtrl.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [null | string, null | string]) => {
        if(next == 'NaN') {
          volumeCtrl.setValue('0');
          return;
        }
        if(prev == next) {
          return;
        }
        if (typeof next === 'string' && !!next && !next.match(/^([0-9]*)$/)
        ) {
          volumeCtrl.setValue(prev);
          return;
        }
        volumeCtrl.setValue('' + parseInt(next));
      });
  }

  public ngOnInit(): void {
    this.controls['volume'].valueChanges.subscribe((value: string) => {
      // if valid
      if (this.controls['volume'].valid) {
        // update model
        this._GlobalDataService.Campagne.volume = value;
      }
    });
  }

  public ngOnDestroy(): void {
    this.volumeSub?.unsubscribe();
  }

  public estimate() {
    let request = this._GlobalDataService.getEstimationRequest();
    if (request) {
      this._EstimationService.put(request).subscribe({
        next: (res) => this.estimateResponseHandler(res),
        error: (err) => this.estimateErrorHandler(err),
      });
    }
  }

  private estimateResponseHandler(response: any) {
    console.log(response);
    if (
      typeof response.response === 'object' &&
      response.response.status === 'SUCCESS'
    ) {
      this._GlobalDataService.Campagne.unitAmountWithoutTax =
        response.chargeList.length > 0
          ? response.chargeList[0].unitAmountWithoutTax
          : '';
      this._GlobalDataService.Campagne.amountWithoutTax =
        response.chargeList.length > 0
          ? response.chargeList[0].amountWithoutTax
          : '';
    } else if (response.status === 500) {
      this._GlobalDataService.alert(
        "Le serveur d'estimation tarifaire est indisponible."
      );
    } else {
      this._GlobalDataService.Campagne.unitAmountWithoutTax = '';
      this._GlobalDataService.Campagne.amountWithoutTax = '';
      this._GlobalDataService.alert(
        "Une erreur est survenue . Merci de relancer l'estimation tarifaire.",
        response.response.errorMessage
      );
    }
  }

  private estimateErrorHandler(error: any) {
    console.log('errorHandler :' + error);
  }
}
