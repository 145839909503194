import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { Campagne } from '../models/campagne.model';
import { GlobalDataService } from './global-data.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { clearScreenDown } from 'readline';

@Injectable({
  providedIn: 'root'
})
export class CampagneService {

  campagneDataSub: Subject<Object[]> = new Subject<Object[]>();
  campagneDataRaw: any[] = [];
  campagneData: any[] = [];
  campagneYearsSub: Subject<Object[]> = new Subject<Object[]>();
  campagneYears: any[] = [];
  campagneUsersSub: Subject<Object[]> = new Subject<Object[]>();
  campagneUsers: any[] = [];
  campagneOrdersSub: Subject<Object[]> = new Subject<Object[]>();
  campagneOrders: any[] = [
    {name: 'De la plus ancienne à la plus récente', sort: "croisant", checked: false},
    {name: 'De la plus récente à la plus ancienne', sort: "decroisant", checked: false}
  ];

  _url = environment.url_api + '/campagne';
  _standaloneUrl = environment.url_api + '/standalone/campagne';
  _salesforceUrl = environment.url_api + '/salesforce/campagne';

  public campagne$: Observable<Campagne>;

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,
  ) { }

  /**
   * Création d'une campagne
   * @param Campagne
   * POST /campagne/add
   */
  add(Campagne: Campagne) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    var RequestCampagne = Object.assign({}, Campagne);
    //supprimer les proprietes en plus
    delete RequestCampagne.FichiersCreation;
    delete RequestCampagne.ChampsFusion;
    delete RequestCampagne.Produit;
    delete RequestCampagne.Adresse;

    let url = this._url + '/add'
    return this._http.post<any>(url, { request: RequestCampagne }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });

  }
  /**
   * récupération d'une campagne par Id
   * @param id
   * GET /campagne/:id
   */
  get(id: string) {
    
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/' + id;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/get/by-id/' + id;
        break;
      }
      default: {
        url = this._standaloneUrl + '/get/by-id/' + id;
        break;
      }
    }

    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe(response => {
          if (response && response.campagne && response.campagne["CodeStatut"]) {
            let c = response.campagne;
            response.campagne["StatutCampagneLibelle"] = this._GlobalDataService.mappingStatut[c["CodeStatut"]];
          }
          subscriber.next(response);
          subscriber.complete();
        },
          err => { subscriber.error(err); });
    });
    return observable;
  }

  getList(annonceurId: string): any {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/list/' + annonceurId;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/list/' + annonceurId;
        break;
      }
      default: {
        url = this._standaloneUrl + '/list/' + annonceurId;
        break;
      }
    }

    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe({
          next: () => response => {
            if (response && response.campagne && response.campagne["CodeStatut"]) {
              let c = response.campagne;
              response.campagne["StatutCampagneLibelle"] = this._GlobalDataService.mappingStatut[c["CodeStatut"]];
            }
            subscriber.next(response);
            subscriber.complete();
          },
          error: (err) => {
            subscriber.error(err);
          }
        });
      });
    return observable;
  }

  getListAvailable(annonceurId: string): any {  
    let url: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/listAvailable/' + annonceurId;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/listAvailable/' + annonceurId;
        break;
      }
      default: {
        url = this._standaloneUrl + '/listAvailable/' + annonceurId;
        break;
      }
    }

    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe({
          next: (response) => {
            if (response && response.campagne && response.campagne["CodeStatut"]) {
              let c = response.campagne;
              response.campagne["StatutCampagneLibelle"] = this._GlobalDataService.mappingStatut[c["CodeStatut"]];
            }
            subscriber.next(response);
            subscriber.complete();
          },
          error: (err) => {
            subscriber.error(err);
          }
        });
      });
    return observable;
  }

  /**
   * mettre à jour d'une camapgne
   * @param Campagne
   * PUT /campagne/:id
   */
  put(Campagne: Campagne) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    

    // Check if campaign is purged
    if(Campagne.isPurged) { 
      console.log("Can not update: the campaign has been purged.");
      return null;
    }

    var RequestCampagne = Object.assign({}, Campagne);;
    //supprimer les proprietes en plus
    delete RequestCampagne.FichiersCreation;
    delete RequestCampagne.ChampsFusion;
    delete RequestCampagne.Produit;
    delete RequestCampagne.Adresse;

    ////////////////////////////// ATA 26/04/22
    // let queryParams = this._GlobalDataService.objectParams;
    // queryParams['AppId'] = environment.AppIdv10;
    // queryParams['InstallIdv10'] = environment.InstallIdv10;      
    // this._GlobalDataService.objectParams = queryParams; 
    //////////////////////////////

    this._GlobalDataService.objectParams = Object.assign({}, this._GlobalDataService.objectParams);

    // let url = this._url + '/' + RequestCampagne.IdCampagne;
    let url: string = "";

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/' + RequestCampagne.IdCampagne;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/' + RequestCampagne.IdCampagne
        break;
      }
      case "SALESFORCE": {
        if (this._GlobalDataService.getinteractionIdSF())
          this._GlobalDataService.objectParams = Object.assign({}, this._GlobalDataService.objectParams, { "InteractionId": this._GlobalDataService.getinteractionIdSF() });

        url = this._salesforceUrl + '/' + RequestCampagne.IdCampagne
        break;
      }
    }

    return this._http.put<Campagne[]>(url, { request: RequestCampagne }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

    /**
    * Activer une camapgne
    * @param idCampagne
    * POST /campagne/:id/activate
    */
    activate(idCampagne: string) {
      this._GlobalDataService.objectParams = Object.assign({}, this._GlobalDataService.objectParams);
  
      let url: string = "";
  
      switch (this._GlobalDataService.CONTEXT) {
        case "ELOQUA": {
          url = this._url + '/' + idCampagne + "/activate";
          break;
        }
        case "STANDALONE": {
          url = this._standaloneUrl + '/' + idCampagne + "/activate";
          break;
        }
        case "SALESFORCE": {
          if (this._GlobalDataService.getinteractionIdSF())
            this._GlobalDataService.objectParams = Object.assign({}, this._GlobalDataService.objectParams, { "InteractionId": this._GlobalDataService.getinteractionIdSF() });
          url = this._salesforceUrl + '/' + idCampagne + "/activate";
          break;
        }
      }
  
      return this._http.post<void>(url, {}, {
        params: this._GlobalDataService.objectParams,
        headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
      });
    }

  /**
   *
   * @param id
   * DELETE /campagne/:id
   */
  delete(id: string) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/' + id
    return this._http.delete<Campagne[]>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });

  }
  /**
   * dupliquer une campagne
   * @param id
   * POST /campagne/:id/duplicate
   */
  duplicate(id: string, request: any) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/' + id + '/duplicate'
    return this._http.post<any>(url, request, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });

  }

  /**
   * advance dupliquer une campagne
   * @param id
   * POST /campagne/:id/duplicate
   */
  advanceDuplicate(id: string, request: {
    CampaignCopyId: string,
    Adresse?: boolean,
    FichierCreation?: boolean,
    ChampFusion?: boolean,
    PaveAdresse?: boolean, 
    CodeVitesse?: boolean,
    FrequenceEnvoi?: boolean,
    Alliage?: boolean,
    Support?: boolean,
    Enveloppe?: boolean,
    ChiliCreation?: boolean,
    ImportCiblage?: boolean
  }) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url: string;
    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/' + id + "/advanceDuplicate";
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + "/" + id + "/advanceDuplicate";
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + "/" + id + "/advanceDuplicate";
        break;
      }
    }
    return this._http.post<any>(url, request, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }


  getCurrentCampagne() {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/';
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/'
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/' + this._GlobalDataService.getinteractionIdSF();
        break;
      }
    }

    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe(
          response => {
            if (response && response.campagne && response.campagne["CodeStatut"]) {
              let c = response.campagne;
              response.campagne["StatutCampagneLibelle"] = this._GlobalDataService.mappingStatut[c["CodeStatut"]];
            }
            subscriber.next(response);
            subscriber.complete();
          },
          err => { subscriber.error(err); });
    });
    return observable;
  }

  getListAdminCampagneByIdAnnonceur(idAnnonceur?: string) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/admin/list/' + idAnnonceur;
    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe(
          response => {
            if (response && response.campagne && response.campagne["CodeStatut"]) {
              let c = response.campagne;
              response.campagne["StatutCampagneLibelle"] = this._GlobalDataService.mappingStatut[c["CodeStatut"]];
            }
            subscriber.next(response);
            subscriber.complete();


            this.clearNull(response.campagne);
            if (this.campagneDataRaw.length === 0) {
              this.campagneDataRaw = response.campagne;
              this.getYears();
              this.getUsers();
              this.getOrders();
            }
            this.campagneData = response.campagne;
            this.updateData(response.campagne);
          },
          err => { subscriber.error(err); });
    });
    return observable;
  }

  updateData(data: any[] = []) {
    if (data.length === 0) data = this.campagneData;

    this.filterCampagneByYear(0, data);
    this.filterCampagneByUser(0, this.campagneData);
  }

  clearNull(arr: any[]) {
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      const keys = Object.keys(element);

      keys.forEach((key) => {
        if (element[key] === "null") element[key] = "";
      });

    }
  }

  getAdminCampagneById(id: any) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/admin-get/' + id
    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe(
          response => {
            if (response && response.campagne && response.campagne["CodeStatut"]) {
              let c = response.campagne;
              response.campagne["StatutCampagneLibelle"] = this._GlobalDataService.mappingStatut[c["CodeStatut"]];
            }
            subscriber.next(response);
            subscriber.complete();
          },
          err => { subscriber.error(err); });
    });
    return observable;
  }

  getAdminDetailsCampagneById(id: any) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/admin/details/' + id
    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe(
          response => {
            if (response && response.campagne && response.campagne["CodeStatut"]) {
              let c = response.campagne;
              response.campagne["StatutCampagneLibelle"] = this._GlobalDataService.mappingStatut[c["CodeStatut"]];
            }
            subscriber.next(response);
            subscriber.complete();
          },
          err => { subscriber.error(err); });
    });
    return observable;
  }


  /**
   * Vérifier si le nom de la campagne existe ou pas
   * @param idAnnonceur
   * @param request {NomCamapgne, IdCampagne}
   */
  verifNomCampagne(idAnnonceur: string, request: any) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/verif-campagne/' + idAnnonceur
    return this._http.post<any>(url, { request: request }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });

  }

  getCampagnesByStatus(status: string) {
    const selectedCampagneData = this.campagneData.filter(c => c.StatutCampagne == status);
    this.campagneDataSub.next(selectedCampagneData);
  }

  clearCampagnes() {
    this.campagneDataSub.next([]);
  }

  selectAllCampagnes() {
    this.campagneDataSub.next(this.campagneDataRaw);
  }

  filterCampagneByStatus(status: string) {
    this.campagneData = this.campagneData.filter(c => c.StatutCampagne !== status);
    this.updateData(this.campagneData);
  }

  addCampagneByStatus(status: string) {
    const filtered = this.campagneDataRaw.filter(c => c.StatutCampagne === status);
    this.campagneData = this.campagneData.concat(filtered);
    this.updateData(this.campagneData);
  }

  getOrders(){
    this.campagneOrdersSub.next(this.campagneOrders);
  }

  getYears() {
    this.campagneDataRaw.forEach(c => {
      const y = c['DateCreation'].substring(0, 4);
      if (this.campagneYears.findIndex(el => el.value === y) === -1) {
        this.campagneYears.push({ value: y, filtered: false });
        this.campagneYears.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        this.campagneYearsSub.next(this.campagneYears);
      }
    });
  }

  getUsers() {
    this.campagneDataRaw.forEach(c => {
      if (this.campagneUsers.findIndex(el => el.value === c['CreeParLogin']) === -1) {
        this.campagneUsers.push({ value: c['CreeParLogin'], filtered: false });
        this.campagneUsers.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        this.campagneUsersSub.next(this.campagneUsers);
      }
    });
  }

    /**
   * Trier par la date de création
   */
     sortCampagneByOrder(order: any) {
      order.checked = true;
      let sortOrder = 1;
      if (order.sort == "croisant") sortOrder = -1;
      this.campagneData.sort((a, b) => {
        if (a.DateCreation < b.DateCreation) return 1*sortOrder;
        if (a.DateCreation > b.DateCreation) return -1*sortOrder;
        return 0;
      })
      this.campagneDataSub.next(this.campagneData);
    }
  
     /**
     * réinitialiser l'ordre des campagnes par le nom de campagne
     */
    resetSortByOrder() {
      this.campagneOrders.forEach(el => el.checked = false)
      this.campagneData.sort((a, b) => {
        if (a.NomCampagne < b.NomCampagne) return -1;
        if (a.NomCampagne > b.NomCampagne) return 1;
        return 0;
      })
      this.campagneDataSub.next(this.campagneData);
      this.campagneOrdersSub.next(this.campagneOrders);
    }
  
  filterCampagneByYear(year: any, data: any[] = []) {
    if (data.length === 0) data = this.campagneData;

    const indexYear = this.campagneYears.indexOf(year);
    if (indexYear > -1) {
      this.campagneYears[indexYear].filtered = !this.campagneYears[indexYear].filtered;
      this.campagneYearsSub.next(this.campagneYears);
    }
    let flagNoneFiltered = true;
    let campagneData: any[] = [];
    this.campagneYears.forEach((cYear: any) => {
      if (cYear.filtered) {
        flagNoneFiltered = false;
        campagneData = campagneData.concat(data.filter(el => el.DateCreation.substring(0, 4) === cYear.value));
      }
    });
    if (flagNoneFiltered) campagneData = [...this.campagneData];

    this.campagneData = campagneData;
    this.campagneDataSub.next(campagneData);
  }

  resetFilterByYears() {
    this.campagneYears.forEach(el => el.filtered = false);
    this.updateData();
    this.campagneYearsSub.next(this.campagneYears);
  }

  filterCampagneByUser(user: any, data: any[] = []) {
    if (data.length === 0) data = this.campagneData;

    const indexUser = this.campagneUsers.indexOf(user);
    if (indexUser > -1) {
      this.campagneUsers[indexUser].filtered = !this.campagneUsers[indexUser].filtered;
      this.campagneUsersSub.next(this.campagneUsers);
    }
    let flagNoneFiltered = true;
    let campagneData: any[] = [];
    this.campagneUsers.forEach((cUser: any) => {
      if (cUser.filtered) {
        flagNoneFiltered = false;
        campagneData = campagneData.concat(data.filter(el => el['EmailNotification'] === cUser.value));
      }
    });
    if (flagNoneFiltered) campagneData = [...this.campagneData];

    this.campagneDataSub.next(campagneData);
  }

  resetFilterByUsers() {
    this.campagneUsers.forEach(el => el.filtered = false);
    this.updateData();
    this.campagneUsersSub.next(this.campagneUsers);
  }

  getStatutConfigList() {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    const url = environment.url_entrypoint + '/service/statutConfig/list';

    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe(response => {
          subscriber.next(response);
          subscriber.complete();
        },
          err => { subscriber.error(err); });
    });
    return observable;
  }

  onComponentDestroy() {
    this.campagneUsersSub.next([]);
    this.campagneDataSub.next([]);
    this.campagneYearsSub.next([]);
    this.campagneOrdersSub.next([]);
  }
}
