<mat-card>
  <mat-card-content>
    <mat-grid-list [cols]="2" rowHeight="5rem" [gutterSize]="'2rem'">
      <mat-grid-tile
        class="gtmt-ai-start gtmt-jc-space-between"
        colspan="1"
        rowspan="2"
      >
        <span style="font-weight: bold"> Bon à tirer</span>
        <span>
          {{
            _GlobalDataService.CampagneExt.CurrentStatutCommande?.StatutLibelle
          }}</span
        >
        <span>
          Date
          {{
            _GlobalDataService.CampagneExt.CurrentStatutCommande
              ?.dateModification | date : "dd/MM/yyyy"
          }}
        </span>
      </mat-grid-tile>

      <mat-grid-tile class="gtmt-ai-end gtmt-jc-end" colspan="1" rowspan="2">
        <span *ngIf="!_GlobalDataService.Campagne.isPurged && _GlobalDataService.Campagne.creationType !=='chili'">
          <button mat-raised-button color="blue" (click)="VisualiserBAT()" [disabled]="['', undefined].indexOf(_GlobalDataService.CampagneExt.CurrentCommande?.UrlBat) != -1">
              Visualiser mon Bon à Tirer
          </button>
        </span>

        <span *ngIf="_GlobalDataService.Campagne.isPurged && _GlobalDataService.Campagne.creationType !=='chili'" [mdePopoverTriggerFor]="purgePopover" (mouseenter)="$event.stopPropagation()" style="cursor:pointer">
          <button mat-raised-button color="blue" [disabled]="true">
              Visualiser mon Bon à Tirer
          </button>
        </span>

        <app-chili-bat-thumbnail
          *ngIf="this._GlobalDataService.Campagne.creationType === 'chili'"
        ></app-chili-bat-thumbnail>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>

</mat-card>
<mde-popover #purgePopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapAutoCaptureEnabled]="false" mdePopoverPositionY="above">
  <mat-card class="gtmt-popover-card">
  Le BAT a été purgé
  </mat-card>
</mde-popover>
