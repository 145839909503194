<mat-card [formGroup]="_CampagnePlanificationService.form">
    <mat-card-header>
        <mat-card-title>
            <h2>
                Type de récurrence
            </h2>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>



        <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'" style="width: 47%">
            <mat-radio-group formControlName="Recurrence" aria-label="Choisir une vitesse">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="0.5"
                    (click)="_CampagnePlanificationService.form.get('Recurrence').setValue(typeRecurrence)"
                    *ngFor="let typeRecurrence of typeRecurrences">
                    <span> {{typeRecurrence}}</span>
                    <mat-radio-button [value]="typeRecurrence">
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
            <mat-grid-tile colspan="1" rowspan="0.5">
            </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="2" rowHeight="3.5rem" [gutterSize]="'7rem'">
            <!-- Date d’activation -->
            <mat-grid-tile colspan="1" rowspan="1">
                <mat-label>Date d’activation
                    <mat-icon>info</mat-icon>
                </mat-label>
            </mat-grid-tile>

            <!-- Date de retour du bon à tirer -->
            <mat-grid-tile colspan="1" rowspan="1">
                <mat-label>Date de retour du bon à tirer
                </mat-label>
            </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="2" rowHeight="15rem" [gutterSize]="'7rem'">

            <!-- Date d’activation -->
            <mat-grid-tile colspan="1" rowspan="1">
                <mat-form-field floatLabel="never">
                    <input matInput [matDatepickerFilter]="disablePastDatesFilter" [matDatepicker]="picker"
                        formControlName="DateRemiseImprimeur" [errorStateMatcher]="errorMatcher">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="_CampagnePlanificationService.form.hasError('isBeforeToday') && !_CampagnePlanificationService.form.get('DateRemiseImprimeur').hasError('required')">
                        La date d’activation <strong>ne doit pas être dans le passé</strong>
                    </mat-error>
                    <mat-error
                        *ngIf="(!_CampagnePlanificationService.form.get('DateRemiseImprimeur').valid || _CampagnePlanificationService.form.hasError('isInvalid')) && !_CampagnePlanificationService.form.hasError('isBeforeToday')">
                        Date d’activation <strong>n'est pas valide</strong>
                    </mat-error>
                    <mat-error
                        *ngIf="_CampagnePlanificationService.form.get('DateRemiseImprimeur').hasError('required') && !_CampagnePlanificationService.form.hasError('isInvalid')">
                        Merci de saisir votre date d’activation.
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <!-- Date de retour du bon à tirer -->
            <mat-grid-tile colspan="1" rowspan="1">
                <!-- <mat-label matPrefix>Prévue le
                </mat-label> -->

                <mat-form-field floatLabel="never">
                    <span matPrefix>Prévue le &nbsp;</span>
                    <input matInput formControlName="DateTheoriqueDepotLaPoste">
                </mat-form-field>

            </mat-grid-tile>

        </mat-grid-list>


        <!-- Si Frequence envoi est Hebdomadaire -->

        <ng-container *ngIf="_GlobalDataService.Campagne.Recurrence == 'Hebdomadaire'">

            <!-- Détail de la récurrence -->

            <mat-grid-list cols="2" rowHeight="4rem" [gutterSize]="'7rem'">
                <mat-grid-tile colspan="1" rowspan="1">
                    <mat-label>Détail de la récurrence
                    </mat-label>
                </mat-grid-tile>
            </mat-grid-list>


            <mat-grid-list cols="2" rowHeight="5rem" [gutterSize]="'7rem'">
                <mat-grid-tile class="gtmt-radio-group-container" colspan="1" rowspan="1">
                    <mat-radio-group formControlName="DetailRecurrence" aria-label="Choisir un jour">
                        <mat-radio-button *ngFor="let day of days" value="{{day}}">
                            {{day}}
                        </mat-radio-button>
                    </mat-radio-group>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="1" rowHeight="5rem" [gutterSize]="'1rem'">
                <mat-grid-tile colspan="1" rowspan="1">
                </mat-grid-tile>
            </mat-grid-list>

        </ng-container>

        <!-- Si Frequence envoi est Mensuelle -->

        <ng-container  *ngIf="_GlobalDataService.Campagne.Recurrence == 'Mensuelle'">

            <!-- Détail de la récurrence -->

            <mat-grid-list cols="1" rowHeight="5rem" [gutterSize]="'1rem'">
                <mat-grid-tile colspan="1" rowspan="1">
                    <mat-label>Détail de la récurrence
                    </mat-label>
                </mat-grid-tile>
            </mat-grid-list>


            <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'">
                <mat-radio-group formControlName="DetailRecurrenceMois">
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-radio-button value="Le premier Lundi du mois">
                            Le premier Lundi du mois
                        </mat-radio-button>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-radio-button value="Le dernier Lundi du mois">
                            Le dernier Lundi du mois
                        </mat-radio-button>
                    </mat-grid-tile>
                </mat-radio-group>
            </mat-grid-list>

            <mat-grid-list cols="1" rowHeight="5rem" [gutterSize]="'1rem'">
                <mat-grid-tile colspan="1" rowspan="1">
                </mat-grid-tile>
            </mat-grid-list>

        </ng-container>





        <mat-grid-list cols="2" rowHeight="3.5rem" [gutterSize]="'7rem'">
            <!-- Date de début de la récurrence -->
            <mat-grid-tile colspan="1" rowspan="0.5">
                <mat-label>Date de début de la récurrence
                </mat-label>
            </mat-grid-tile>

            <!-- Date de fin de la récurrence -->
            <mat-grid-tile colspan="1" rowspan="0.5">
                <mat-label>Date de fin de la récurrence
                </mat-label>
            </mat-grid-tile>
        </mat-grid-list>



        <mat-grid-list cols="2" rowHeight="15rem" [gutterSize]="'7rem'">

            <!-- Date de début de la récurrence -->
            <mat-grid-tile colspan="1" rowspan="1">
                <mat-form-field floatLabel="never">
                    <input matInput [matDatepickerFilter]="disablePastDatesFilter" [matDatepicker]="DateDeDebutPicker"
                        formControlName="DateDeDebut" [errorStateMatcher]="errorMatcher">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matPrefix [for]="DateDeDebutPicker"></mat-datepicker-toggle>
                    <mat-datepicker #DateDeDebutPicker></mat-datepicker>
                    <mat-error *ngIf="_CampagnePlanificationService.form.hasError('isBeforeToday') && !_CampagnePlanificationService.form.get('DateDeDebut').hasError('required')">
                        La date de début de la récurrence <strong>ne doit pas être dans le passé</strong>
                    </mat-error>                     
                    <mat-error *ngIf="(!_CampagnePlanificationService.form.get('DateDeDebut').valid ||  _CampagnePlanificationService.form.hasError('isInvalid') )&& !_CampagnePlanificationService.form.hasError('isBeforeToday')">
                        Date début de la récurrence <strong>n'est pas valide</strong>
                    </mat-error>                                                           
                    <mat-error *ngIf="_CampagnePlanificationService.form.get('DateDeDebut').hasError('required') && ! _CampagnePlanificationService.form.hasError('isInvalid') ">
                        Merci de saisir votre date de début de la récurrence.
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>

            <!-- Date de fin de la récurrence -->
            <mat-grid-tile colspan="1" rowspan="1">
                <mat-form-field floatLabel="never">
                    <input matInput [matDatepickerFilter]="disablePastDatesFilterForDatedeFin"
                        [matDatepicker]="DateDeFinPicker" formControlName="DateDeFin"
                        [errorStateMatcher]="errorMatcher">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matPrefix [for]="DateDeFinPicker"></mat-datepicker-toggle>
                    <mat-datepicker #DateDeFinPicker></mat-datepicker>
                    <!-- <mat-error *ngIf="_CampagnePlanificationService.form.hasError('isBeforeToday') && _CampagnePlanificationService.form.get('DateDeFin').hasError('required')">
                        La date de fin de la récurrence <strong>ne doit pas être dans le passé</strong>
                    </mat-error>       -->
                    <mat-error *ngIf="(!_CampagnePlanificationService.form.get('DateDeFin').valid ||  _CampagnePlanificationService.form.hasError('isInvalid') ) && !_CampagnePlanificationService.form.hasError('isBeforeStartDate')">
                        Date de fin de la récurrence <strong>n'est pas valide</strong>
                    </mat-error>                    
                    <mat-error *ngIf="_CampagnePlanificationService.form.get('DateDeFin').hasError('required') && _CampagnePlanificationService.form.get('DateDeFin').valid">
                        Merci de saisir votre date de fin de la récurrence.
                    </mat-error>                  
                    <mat-error *ngIf="_CampagnePlanificationService.form.hasError('isBeforeStartDate')">
                        La date de fin de la récurrence <strong>ne doit pas être avant la date de début</strong>
                    </mat-error>

                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>



    </mat-card-content>

</mat-card>



