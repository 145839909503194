<p>
    Le volume requis pour cette commande est de <B class="gtmt-chips"> {{
        _GlobalDataService.CampagneExt.Produit.MinFabrication}} PLIS
        MINIMUM </B> et
    <B class="gtmt-chips">{{_GlobalDataService.CampagneExt.Produit.MaxFabrication === '0' ? 'PAS DE ' : 'de ' +
        _GlobalDataService.CampagneExt.Produit.MaxFabrication}} PLIS MAXIMUM
    </B>
</p>

<mat-grid-list cols="3" rowHeight="8.5rem" [gutterSize]="'3rem'">

    <!-- Statut de la campagne courrier -->
    <mat-grid-tile class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly" colspan="1">
        <span>
            Statut de la campagne courrier
        </span>
        <span>
            {{ _GlobalDataService.Campagne.StatutCampagne }}
        </span>
    </mat-grid-tile>

    <!-- Date de remise imprimeur -->
    <mat-grid-tile class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly" colspan="1"
        *ngIf="_GlobalDataService.Campagne.FrequenceEnvoi === 'Une seule fois'">
        <span>
            Date de remise imprimeur
        </span>
        <span>
            {{ _GlobalDataService.Campagne.DateRemiseImprimeur | date :"dd/MM/yyyy"}}
        </span>
    </mat-grid-tile>

    <!-- Nom de la campagne courrier -->
    <mat-grid-tile class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly" colspan="1"
        [mdePopoverTriggerFor]="appPopover" (mouseenter)="$event.stopPropagation()" style="cursor:pointer">
        <span>
            Nom de la campagne courrier
        </span>
        <span class="gtmt-overflow-ellipsis">
            {{ _GlobalDataService.Campagne.NomCampagne }}
        </span>
    </mat-grid-tile>

    <!-- Bon de commande -->
    <mat-grid-tile class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly" colspan="1">
        <span>
            Bon de commande
        </span>
        <span>
            {{ _GlobalDataService.Campagne.BonDeCommande ? _GlobalDataService.Campagne.BonDeCommande : 'Non renseigné'}}
        </span>
    </mat-grid-tile>

    <!-- Description -->
    <mat-grid-tile class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly" colspan="1">
        <span>
            Description
        </span>
        <span class="gtmt-overflow-ellipsis">
            {{ _GlobalDataService.Campagne.Description ? _GlobalDataService.Campagne.Description : 'Non renseignée'}}
        </span>
    </mat-grid-tile>

    <!-- Adresse de notification -->
    <mat-grid-tile class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly" colspan="1">
        <span>
            Adresse de notification
        </span>
        <span class="gtmt-chips-flex gtmt-overflow-ellipsis">
            {{ _GlobalDataService.Campagne.EmailNotification }}
        </span>
    </mat-grid-tile>

    <!-- Fréquence -->
    <mat-grid-tile class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly" colspan="1">
        <span>
            Fréquence
        </span>
        <span>
            {{ _GlobalDataService.Campagne.FrequenceEnvoi }}
        </span>
    </mat-grid-tile>

    <!-- Vitesse -->
    <mat-grid-tile class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly" colspan="1">
        <span>
            Vitesse
        </span>
        <span>
            {{ _GlobalDataService.Campagne.CodeVitesse }}
        </span>
    </mat-grid-tile>

    <!-- Support -->
    <mat-grid-tile colspan="1" rowspan="3" class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly">
        <span> Support </span>
        <mat-divider></mat-divider>
        <app-support-info-card [support]="_GlobalDataService.CampagneExt.Support"></app-support-info-card>
    </mat-grid-tile>

    <!-- Enveloppe -->
    <mat-grid-tile colspan="1" rowspan="3" class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
        *ngIf="_GlobalDataService.Campagne.CodeEnveloppe != ''">
        <span> Enveloppe </span>
        <mat-divider></mat-divider>
        <app-support-info-card [support]="_GlobalDataService.CampagneExt.Enveloppe"></app-support-info-card>
    </mat-grid-tile>

    <!-- Champ de personnalisation -->
    <mat-grid-tile colspan="1" rowspan="4" class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
        *ngIf="!(_GlobalDataService.isStandaloneContext() && _GlobalDataService.Campagne.importOption === 'sftp')">
        <span> Champ de personnalisation </span>
        <mat-divider></mat-divider>
        <div *ngIf="isLoadingChiliDetails" class="spinner-container">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
        <app-champ-fusion-card [cfs]="personalisationChampsFusion"></app-champ-fusion-card>
    </mat-grid-tile>

    <!--  Fichiers de créations  -->
    <ng-container *ngIf="this._GlobalDataService.Campagne.creationType !== 'chili'">
        <mat-grid-tile colspan="1" rowspan="3" class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly">
            <span> Fichiers de créations </span>
            <mat-divider></mat-divider>
            <app-fichiers-crea-card [IdCampagne]="_GlobalDataService.Campagne.IdCampagne"
                [FichiersCreation]="_GlobalDataService.CampagneExt.FichiersCreation">
            </app-fichiers-crea-card>
        </mat-grid-tile>
    </ng-container>

    <!-- Pavé adresse -->
    <mat-grid-tile colspan="1" rowspan="4" class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
        *ngIf="!(_GlobalDataService.isStandaloneContext() && _GlobalDataService.Campagne.importOption === 'sftp')">
        <span> Pavé adresse </span>
        <mat-divider></mat-divider>
        <div *ngIf="isLoadingChiliDetails" class="spinner-container">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
        <app-champ-fusion-card [cfs]="adressesDestinatairesChampsFusion"></app-champ-fusion-card>
    </mat-grid-tile>

  <!-- Adresse de retour -->
  <ng-container *ngIf="_GlobalDataService.Campagne.creationType !== 'chili'">
    <mat-grid-tile
      colspan="1"
      rowspan="3"
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      *ngIf="!_GlobalDataService.Campagne.Alliage"
    >
      <span> Adresse de retour </span>
      <mat-divider></mat-divider>
      <app-adresse-retour
        *ngIf="_GlobalDataService.CampagneExt.Adresse"
        [adresse]="_GlobalDataService.CampagneExt.Adresse"
      ></app-adresse-retour>
    </mat-grid-tile>
  </ng-container>

  <!-- PAO : Chili Adresse de retour -->
  <ng-container *ngIf="_GlobalDataService.Campagne.creationType === 'chili'">
    <mat-grid-tile
      colspan="1"
      rowspan="3"
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      *ngIf="!_GlobalDataService.Campagne.Alliage"
    >
      <span> Adresse de retour </span>
      <mat-divider></mat-divider>
      <div *ngIf="isLoadingChiliDetails" class="spinner-container">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <app-adresse-retour [adresse]="adresseRetour"></app-adresse-retour>
    </mat-grid-tile>
  </ng-container>

  <!-- Alliage -->
  <mat-grid-tile
    class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
    colspan="1"
    *ngIf="
      _GlobalDataService.Campagne.Alliage
    "
  >
    <span> Alliage </span>
    <span>
      {{ _GlobalDataService.Campagne.Alliage ? "Oui" : "Non" }}
    </span>
  </mat-grid-tile>

  <!-- Chili Thumbnails -->
  <mat-grid-tile
    [colspan]="
      this._GlobalDataService.Campagne.CodeTypologie === 'ITE001' ? 3 : 1
    "
    rowspan="4"
    class="thumbnail gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
    *ngIf="this._GlobalDataService.Campagne.creationType === 'chili'"
  >
    <span class="header">
      <span>Studio de création</span>
    </span>
    <mat-divider></mat-divider>
    <app-thumbnail-chili-recap
      [idCampaign]="this._GlobalDataService.Campagne.IdCampagne"
      [selected]="
        this._GlobalDataService.Campagne.CodeTypologie === 'ITE001'
          ? 'letterEnvelop'
          : 'classic'
      "
    ></app-thumbnail-chili-recap>
  </mat-grid-tile>
</mat-grid-list>

<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapAutoCaptureEnabled]="false">
    <mat-card class="gtmt-popover-card">
        <mat-card-header> Nom de la campagne courrier
        </mat-card-header>
        <mat-card-content>
            <p>
                {{ _GlobalDataService.Campagne.NomCampagne }}
            </p>
        </mat-card-content>
    </mat-card>
</mde-popover>
