import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class CampagneIdentificationService {

  // public form = this._formBuilder.group({
  //   NomCampagne: new FormControl(this._GlobalDataService.Campagne.NomCampagne, [
  //     Validators.required,
  //     Validators.maxLength(100),
  //   ]),
  //   NomCampagneParente: new FormControl(this._GlobalDataService.Campagne.NomCampagneParente),
  //   Description: new FormControl(this._GlobalDataService.Campagne.Description),
  //   BonDeCommande: new FormControl(this._GlobalDataService.Campagne.BonDeCommande),
  // });


  public form = this._formBuilder.group({
    NomCampagne: new FormControl([Validators.required,Validators.maxLength(100)]),
    NomCampagneParente: new FormControl(),
    Description: new FormControl(),
    BonDeCommande: new FormControl(),
  });


  constructor(
    private _formBuilder: FormBuilder,
    public _GlobalDataService: GlobalDataService) {

    // if (typeof this._GlobalDataService.Campagne === 'object') {
    //   this.form.get('NomCampagne').patchValue(this._GlobalDataService.Campagne.NomCampagne, { emeitEvent: false });
    //   this.form.get('NomCampagneParente').patchValue(this._GlobalDataService.Campagne.NomCampagneParente, { emeitEvent: false });
    //   this.form.get('Description').patchValue(this._GlobalDataService.Campagne.Description, { emeitEvent: false });
    //   this.form.get('BonDeCommande').patchValue(this._GlobalDataService.Campagne.BonDeCommande, { emeitEvent: false });
    // }
  }

  reset() {
    this.form.get('NomCampagne').patchValue(this._GlobalDataService.Campagne.NomCampagne, { emitEvent: false });
    this.form.get('NomCampagneParente').patchValue(this._GlobalDataService.Campagne.NomCampagneParente, { emitEvent: false });
    this.form.get('Description').patchValue(this._GlobalDataService.Campagne.Description, { emitEvent: false });
    this.form.get('BonDeCommande').patchValue(this._GlobalDataService.Campagne.BonDeCommande, { emitEvent: false });
  }
}
