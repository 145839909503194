import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Adresse } from '@core/models/adresse.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { GlobalDataService } from '@core/services';
import { AdresseService } from '@core/services/adresse.service';
import { Subscription } from 'rxjs';

export class FormErrorStateMatcher implements ErrorStateMatcher {
  constructor() { }
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid && control.dirty && control.touched);
  }
}

export interface DialogData {
  editmode: boolean;
  adresse?: AdresseRetour;
}


@Component({
  selector: 'app-adresse-retour-dialog',
  templateUrl: './adresse-retour-dialog.component.html',
  styleUrls: ['./adresse-retour-dialog.component.scss']
})
export class AdresseRetourDialogComponent implements OnInit {

  title: string = 'Création d’une adresse de retour';
  form: FormGroup;
  errorMatcher = new FormErrorStateMatcher();

  formSub: Subscription;
  upsertAddressSub: Subscription;

  adresse: AdresseRetour;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AdresseRetourDialogComponent>,
    public _AdresseService: AdresseService,
    public _GlobalDataService: GlobalDataService,
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {

    if (this.dialogData.editmode) {
      this.adresse = this.dialogData.adresse;
    }
    else {
      this.adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
    }

    this.form = this.fb.group({
      IdAnnonceur: [this.adresse.IdAnnonceur, Validators.required],
      IdAdresse: [this.adresse.IdAdresse],
      NomAdresse: [this.adresse.NomAdresse, Validators.required],
      RaisonSociale: [this.adresse.RaisonSociale, Validators.required],
      Service: [this.adresse.Service],
      EntreeBatimentZI: [this.adresse.EntreeBatimentZI],
      Numero: [this.adresse.Numero, Validators.required],
      MentionSpeciale: [this.adresse.MentionSpeciale],
      Ville: [this.adresse.Ville, Validators.required],
      CodePostal: [this.adresse.CodePostal, Validators.required],
      Favori: [this.adresse.Favori],
    });

    Object.keys(this.form.controls).forEach(c => {
      if (this.form.get(c).hasValidator(Validators.required) && !this.form.get(c).value) {
        this.form.get(c).patchValue(c, { emitEvent: false });
        this.form.get(c).patchValue('', { emitEvent: false });
        this.form.get(c).markAsDirty();
        this.form.get(c).markAsTouched({ onlySelf: true });
        this.form.get(c).updateValueAndValidity({ onlySelf: true });
      }
    });

    this.formSub = this.form.valueChanges.subscribe(data => {
      console.log('Form changes', data)
      this.adresse = data;
      console.log(' this.adresse changes', this.adresse)
    })

  }

  closeDialog() {
    this.dialogRef.close();
  }

  valider() {
    console.log(this.adresse);
    this.upsertAddressSub = this._AdresseService.add(this.adresse)
      .subscribe(
          response => this.SuccessHandler(response),
          error => this.errorHandler(error)
      );

  }

  SuccessHandler(response: any) {
    console.log(response);
    if (response.status === 'success') {
      if (this.dialogData.editmode) {
          this._GlobalDataService.alert("L'adresse de retour '" + this.adresse.NomAdresse + "' a été mise à jour avec succès");
          this._GlobalDataService.CampagneExt.Adresse = this.adresse;
      }   
      else {
        this._GlobalDataService.alert("L'adresse de retour '" + this.adresse.NomAdresse + "' a été créée avec succès");
        this.adresse.IdAdresse = response.IdAdresse;
        //this._GlobalDataService.availableAdresses.push(this.adresse);
        this._GlobalDataService.Campagne.IdAdresse = this.adresse.IdAdresse;
        this._GlobalDataService.CampagneExt.Adresse = this.adresse;
        this._GlobalDataService.Campagne.isDirty = true;
      }
      
      this.closeDialog();
    }
  }

  errorHandler(error: any) {
    this._GlobalDataService.alert("Une erreur s'est produite lors de la mise à jour");
  }

  ngOnDestroy() {
    if (this.formSub) this.formSub.unsubscribe();
  }
}
