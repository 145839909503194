<app-password-control
  [type]="'change'"
  [lastName]="user?.lastName"
  [firstName]="user?.firstName"
  [successEvent]="eventsSubject.asObservable()"
  [errorEvent]="errorSubject.asObservable()"
  [isSending]="isSendingPassword"
  (passwordSend)="changePassword($event)"
>
  <h2 header>Changement de mot de passe</h2>
</app-password-control>
