import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public _backend_url: string = environment.url_api;
  public _gateway_url: string = environment.url_gateway;

  constructor(
    private _http: HttpClient,
    private _GlobalDataService: GlobalDataService,
  ) { }

  authenticate(username: string, password: string) {
    console.debug(`authenticate ${username}:${password}`);
    return this._http.post<any>(this._gateway_url + '/authenticate', {
      "username": username,
      "password": password,
      "rememberMe": false
    });
  }

  authenticateStandalone(username: string, password: string) {
    console.debug(`authenticateStandalone ${username}`);
      return this._http.post<any>(this._backend_url + '/standalone/authenticate', {
      "username": username,
      "password": password,
      "rememberMe": false
    });
  }

  authenticateEloqua(path: string, method: string) {
    let eloquaParams = {
      "ELOQUA_URI_PATH": path,
      "ELOQUA_HTTP_METHOD": method
    };
    return this._http.post<any>(this._gateway_url + '/eloqua/authenticate', {}, { params: Object.assign(eloquaParams, this._GlobalDataService.objectParams) });
  }

  isAuthenticated(): Observable<boolean> {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    console.log(`isAuthenticated ; token = ${this._GlobalDataService.getJwtoken()}`);

    let observable = new Observable<boolean>(sub => {
      this._http.get<any>(this._gateway_url + '/account', {
        observe: 'response',
        headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}`})
      })
        .subscribe(
          response => { console.debug("isAuth succ"); sub.next(true); sub.complete(); },
          err => { console.debug("isAuth fail"); sub.next(false); sub.complete(); }
        );
    });
    return observable;
  }
  getUserInfo(): Observable<any> {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    console.log(`getUserInfo ; token = ${this._GlobalDataService.getJwtoken()}`);

    let observable = new Observable<any>(sub => {
      this._http.get<any>(this._gateway_url + '/account', {
        observe: 'response',
        headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}`})
      })
        .subscribe(
          response => { console.debug("getUserInfo success"); sub.next(response); sub.complete(); },
          err => { console.debug("isAuth fail"); sub.next({}); sub.complete(); }
        );
    });
    return observable;
  }

}
