import { Component, Input, OnInit } from '@angular/core';

import { StatutCommandeService } from '@core/services/statut-commande.service';

@Component({
  selector: 'app-timeline-commande-admin',
  templateUrl: './timeline-commande-admin.component.html',
  styleUrls: ['./timeline-commande-admin.component.scss']
})
export class TimelineCommandeAdminComponent implements OnInit {

  @Input() IdCommande: any;
  ListeStatutCommande!: any;
  constructor(private _StatutCommandeService: StatutCommandeService) { }

  ngOnInit(): void {
    this._StatutCommandeService.getStatutsByIdCommandeAdmin(this.IdCommande)
      .subscribe(
        response => this.getStatutsResponse(response),
        error => { }
      );
  }
  getStatutsResponse(response: { StatutCommande: never[]; }) {
    console.log("statuts commandes = ", response);
    if (Array.isArray(response.StatutCommande)) {
      this.ListeStatutCommande = response.StatutCommande;
    }
    else if (response.StatutCommande && typeof response.StatutCommande === "object") {
      console.log("fix wrong server response");
      this.ListeStatutCommande = [response.StatutCommande];
    }

  }
}
