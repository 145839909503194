import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Adresse } from '@core/models/adresse.model';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { AdresseService } from '@core/services/adresse.service';
import { ChampsFusionService } from '@core/services/champs-fusion.service';

@Component({
  selector: 'app-pave-adresse-card',
  templateUrl: './pave-adresse-card.component.html',
  styleUrls: ['./pave-adresse-card.component.scss']
})
export class PaveAdresseCardComponent implements OnInit {

  @Input() cfs?: Array<ChampsFusion>;
  @Input() IdCampagne?: string;


  @Output('ChampsAdresse')
  requestChampsAdresse: EventEmitter<any> = new EventEmitter<any>();

  constructor(public _ChampsFusionService: ChampsFusionService,
  ) {

    this.cfs = new Array<ChampsFusion>();

  }
  ngOnInit(): void {
    if (this.IdCampagne) {
      this._ChampsFusionService.list(<string>this.IdCampagne)
      .subscribe({
        next: (response: any) => {
          if (response.ChampsFusion.length > 0) {
            for (var i = 0; i < response.ChampsFusion.length; i++) {
              // CF = result.champsFusion.ChampsFusion[i];
              var CF = {
                Id: response.ChampsFusion[i].Id,
                ChampFusion: response.ChampsFusion[i].ChampFusion,
                internalName: response.ChampsFusion[i].InternalName,
                Sequence: response.ChampsFusion[i].Sequence,
                IsPaveAdresse: response.ChampsFusion[i].IsPaveAdresse == '1' ? 'true' : 'false',
              }
              this.cfs .push(CF);
            }            
            // this.cfs = response.ChampsFusion;            
            this.cfs = this.cfs.filter( (cf: ChampsFusion) => cf.IsPaveAdresse == '1' || cf.IsPaveAdresse == 'true' ).sort((a: ChampsFusion, b: ChampsFusion) => (a.Sequence - b.Sequence));
            this.requestChampsAdresse.emit({
              ChampsAdresse: this.cfs
            });             
            console.log(this.cfs);            
          }
        },
        error: (e) => console.error(e)

      });
    }
  }
}
