import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { GlobalDataService } from './global-data.service';
import { ChampsFusionService } from './champs-fusion.service';
import { ChampsFusion } from '@core/models/champsFusion.model';

@Injectable({
  providedIn: 'root'
})
export class AdressesDestinatairesService {

  public form!: FormGroup;

  public controls: any;

  champFusion: string;

  availableChampsFusion: Array<any> = [];
  filteredAvailableChampsFusion: Array<any> = [];
  selectedChampsFusion: Array<any> = [];
  availableStandaloneChampsFusion: Array<any> = [];

  constructor(
    private _formBuilder: FormBuilder,
    public _GlobalDataService: GlobalDataService,
    public champsFusionService: ChampsFusionService
  ) {
    this.controls = {
      champFusion: new FormControl(this.champFusion, [
      ]),
    };
    this.form = this._formBuilder.group(this.controls);
    this.initialise();
  }

  public initialise() {
    this.selectedChampsFusion = this._GlobalDataService.CampagneExt.ChampsFusion.filter(cf => cf.IsPaveAdresse == '1' || cf.IsPaveAdresse == 'true');
    // Standalone
    if (this._GlobalDataService.isStandaloneContext()) {
      this.updateChampFusion();
    } 
    else {
      this.availableChampsFusion = this._GlobalDataService.availableEloquaChampsFusion;
      this.filteredAvailableChampsFusion = this.availableChampsFusion.filter(acf => !this._GlobalDataService.CampagneExt.ChampsFusion.map(scf => scf.Id).includes(acf.Id));
    }
  }

  public setChampFusionType() {
    this.selectedChampsFusion.forEach((value, index) => {
      /** (?) Pass by reference Typescript.
       * Problème:
       * Lorsqu'un champ fusion est présent côté personnalisation et pavé adresse, cette méthode va également modifier les attributs côté personnalisation.
       * Après appel upsert en DB, un seul champ fusion sera crée, celui côté perso. est perdu.
       * 
       * Modifier la valeur d'un attribut du champ fusion le déréférence côté personnalisation.
      */
      let copyFusion = JSON.parse(JSON.stringify(value));
      this.selectedChampsFusion[index] = copyFusion;
      this.selectedChampsFusion[index].IsPaveAdresse = "true";
      this.selectedChampsFusion[index].Sequence = index;
    });
  }

  private updateChampFusion() {
    this.availableStandaloneChampsFusion = [];
    this.champsFusionService.listChampsDisponibleStandalone().subscribe({
      next: (response) => {
        console.log(response);
        for (var i = 0; i < response.ContactFields.length; i++) {
          let CF: ChampsFusion = {
            Id: response.ContactFields[i].Id,
            ChampFusion: response.ContactFields[i].FieldName,
            internalName: response.ContactFields[i].internalName,
          }
          this.availableStandaloneChampsFusion.push(CF);
        }
        this.availableChampsFusion = this.availableStandaloneChampsFusion;
        this.filteredAvailableChampsFusion = this.availableChampsFusion.filter(acf => !this.selectedChampsFusion.map(scf => scf.ChampFusion).includes(acf.ChampFusion));
      },
      error: (e) => {
        console.log('No contact fields is available.')
      }
    });
  }

  public deletePaveAdresse() {
    // Vider selectedChampsFusion sur pavé adresse
    this.selectedChampsFusion = [];

    // Vider filteredAvailableChampsFusion
    this.filteredAvailableChampsFusion = [];

    // Vider availableChampsFusion
    this.availableChampsFusion = [];

    // MAJ BD
    this.champsFusionService.upsert({
      IdCampagne: this._GlobalDataService.Campagne.IdCampagne,
      ChampsFusion: []
    }).subscribe({
      next: (response) => {
        console.log('Success on deleting Pavé adresse');
      },
      error: (e) => {
        console.log('Une erreur technique s\'est produite.');
      }
    })
  }

  public empty() {
    // empty selectedChampsFusion
    this.selectedChampsFusion = [];

    // reset filteredAvailableChampsFusion
    this.filteredAvailableChampsFusion = this.availableStandaloneChampsFusion.filter(cf => !this.selectedChampsFusion.includes(cf));
    console.log("Available ChampsFusion Pavé Adresse: " + this.filteredAvailableChampsFusion);

    // update champs fusion
    this._GlobalDataService.CampagneExt.ChampsFusion = this._GlobalDataService.CampagneExt.ChampsFusion.filter(
      cf => cf.IsPaveAdresse == "0" || cf.IsPaveAdresse == 'false'
    );
    console.log("Champs fusion: " + this._GlobalDataService.CampagneExt.ChampsFusion);
  }

}
