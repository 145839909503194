<mat-card class="gtmt-ar-container" [formGroup]="_campagneAdressesService.form">

    <mat-card-title>Adresse de retour expéditeur</mat-card-title>

    <mat-card-content>

        <mat-grid-list cols="1" rowHeight="2rem" [gutterSize]="'2.5rem'" style="width:100%">

            <!-- Adresse de retour expéditeur -->

            <mat-grid-tile class="gtmt-ai-end" colspan="1" rowspan="3">
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Sélectionner une adresse </mat-label>
                    <input #adresseRetourRef ma matInput formControlName="nomAdresseRetour"
                        placeholder="Rechercher une adresse de retour" type="text">
                    <button matSuffix mat-icon-button aria-label="Clear">
                        <mat-icon svgIcon="Loupe"></mat-icon>
                    </button>
                </mat-form-field>
            </mat-grid-tile>


            <mat-grid-tile colspan="1" rowspan="10">
                    <cdk-virtual-scroll-viewport appendOnly itemSize="60">
                        <div class="gtmt-drop-box" *ngFor="let record of filteredAvailableRecords"
                        (click)="updateAdresse(record)"
                        >

                            <li [ngClass]="{
                                'gtmt-favorite': record.Favori === '1' }"
                            >
                                {{record.NomAdresse}}
                            </li>
                        </div>
                    </cdk-virtual-scroll-viewport>
            </mat-grid-tile>


        </mat-grid-list>
    </mat-card-content>

</mat-card>