import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-thumbnail-chili-dialog',
  templateUrl: './thumbnail-chili-dialog.component.html',
  styleUrls: ['./thumbnail-chili-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailChiliDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ThumbnailChiliDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { url: SafeResourceUrl }
  ) {}

  public ngOnInit() {
    this.data.url;
  }

  public close() {
    this.dialogRef.close();
  }
}
