<mat-card class="gtmt-card-container" [formGroup]="_CampagneTypologieService.EnveloppeFormGroup">
    <mat-card-title>Grammage</mat-card-title>
    <mat-card-content>

        <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'">
            <mat-radio-group formControlName="CodeGrammageEnveloppe"
                aria-labelledby="dimension-radio-group-label" class="dimension-radio-group">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="0.5"
                    (click)="_CampagneTypologieService.EnveloppeFormGroup.get('CodeGrammageEnveloppe').setValue(dataGrammagesEnveloppe.CodeGrammageEnveloppe)"
                    *ngFor="let dataGrammagesEnveloppe of _CampagneTypologieService.dataGrammagesEnveloppes">
                    <span> {{dataGrammagesEnveloppe.GrammageEnveloppe}}</span>
                    <mat-radio-button [value]="dataGrammagesEnveloppe.CodeGrammageEnveloppe">
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list>

        <!-- <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'">
            <mat-radio-group formControlName="campagne_EnveloppeGrammage" (change)="grammageChanged()"
                aria-labelledby="dimension-radio-group-label" class="dimension-radio-group"
                [(ngModel)]="_GlobalDataService.Campagne.CodeGrammageEnveloppe">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="0.5"
                    *ngFor="let dataGrammagesEnveloppe of _CampagneTypologieService.dataGrammagesEnveloppes">
                    <span>  {{dataGrammagesEnveloppe.GrammageEnveloppe}}</span>
                    <mat-radio-button [value]="dataGrammagesEnveloppe.CodeGrammageEnveloppe">                       
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list> -->

    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="Valider()">
            Valider la sélection
        </button>
    </mat-card-actions>

</mat-card>