import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormControlName } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Annonceur } from '@core/models/annonceur.model';
import { AnnonceurService, AuthenticationService, GlobalDataService } from '@core/services';
import { AuditLogService } from '@core/services/audit-log.service';
import { HelpComponent } from 'app/campagne/components';



@Component({
  selector: 'app-parametrage',
  templateUrl: './parametrage.component.html',
  styleUrls: ['./parametrage.component.scss']
})
export class ParametrageComponent implements OnInit {
  logo!: any;

  titre = "Administration par annonceur";
  ParametresForm!: FormGroup;

  NumeroClient!: FormControl;
  NumeroContrat!: FormControl;

  isLoaded = false;
  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private _AnnonceurService: AnnonceurService,
    public _GlobalDataService: GlobalDataService,
    private activeRoute: ActivatedRoute,
    public _HelpComponent: HelpComponent,
    private _sanitizer: DomSanitizer,
    private _AuditLogService: AuditLogService,
    private _AuthenticationService: AuthenticationService
  ) {
    _GlobalDataService.referenceDocHelp = "#ID_annonceur";
    this.activeRoute.queryParams.subscribe(params => {
      this._GlobalDataService.objectParams = params; // Print the parameter to the console. 
    });

    this.activeRoute.data.subscribe(data => {
      console.log("ActivatedRoute.data = ", data);
      if (data['context'])
        this._GlobalDataService.CONTEXT = data['context'];
    })
  }


  ngOnInit(): void {
    this._AnnonceurService.get()
      .subscribe(
        response => this.InitForm(response),
        error => this.InitForm()
      );
  }

  submit(): any {

    this._AuditLogService.add('0', '0', `Validation paramètres annonceur`).subscribe();

    if (this.NumeroClient.invalid || this.NumeroContrat.invalid) {
      this._GlobalDataService.alert("Les champs numéro client et numéro de contrat sont obligatoires."
        + "<br>Le champ numéro client doit être numérique."
        + "<br>Merci de compléter les champs concernés");
      return false;
    }

    console.log(this._GlobalDataService.Annonceur);
    if (this._GlobalDataService.Annonceur.IdAnnonceur === undefined) {// création
      this._GlobalDataService.Annonceur = new Annonceur();
    }

    this._GlobalDataService.Annonceur.NomContact = this.ParametresForm.value.NomContact;
    this._GlobalDataService.Annonceur.PrenomContact = this.ParametresForm.value.PrenomContact;
    this._GlobalDataService.Annonceur.EmailContact = this.ParametresForm.value.EmailContact;
    this._GlobalDataService.Annonceur.RoleContact = this.ParametresForm.value.RoleContact;
    this._GlobalDataService.Annonceur.TelephoneContact = this.ParametresForm.value.TelephoneContact;
    this._GlobalDataService.Annonceur.Siret = this.ParametresForm.value.Siret;
    this._GlobalDataService.Annonceur.RaisonSociale = this.ParametresForm.value.RaisonSociale;
    this._GlobalDataService.Annonceur.NumeroClient = this.ParametresForm.value.NumeroClient;
    this._GlobalDataService.Annonceur.NumeroContrat = this.ParametresForm.value.NumeroContrat;
    this._GlobalDataService.Annonceur.NumeroContratAlliage = this.ParametresForm.value.NumeroContratAlliage;
    this._GlobalDataService.Annonceur.IdentifiantAlliageGE = this.ParametresForm.value.IdentifiantAlliageGE;

    //call API
    if (this._GlobalDataService.Annonceur.IdAnnonceur === undefined) {// création
      this._AnnonceurService.add(this._GlobalDataService.Annonceur)
        .subscribe(
          response => this.successResponse(response),
          error => this.errorResponse(error)
        );
    } else {//mise à jour de l'annonceur
      this._AnnonceurService.put(this._GlobalDataService.Annonceur)
        .subscribe(
          response => this.successResponsePUT(response),
          error => this.errorResponse(error)
        );
    }

  }

  errorResponse(HttpErrorResponse: any) {
    console.error("Error!", HttpErrorResponse)
    if (HttpErrorResponse.error.message && HttpErrorResponse.error.message != "") {
      this._GlobalDataService.openSnackBarInfo("Une erreur s'est produite : " + HttpErrorResponse.error.message);
    }
  }

  successResponse(response: any) {
    this._GlobalDataService.Annonceur.IdAnnonceur = response.IdAnnonceur;
    this._GlobalDataService.alert("La création de l'annonceur s'est passée avec succès (Id Annonceur = " + response.IdAnnounceur + ")");
    this.goToHome();
  }
  successResponsePUT(response: any) {
    this.goToHome();
  }


  InitForm(response?: any) {
    this._AnnonceurService.downloadLogo()
      .subscribe(response => {
        var urlCreator = window.URL;
        this.logo = this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response))
      });

    if (response && typeof response.annonceur === 'object')
      this._GlobalDataService.Annonceur = response.annonceur;

    this.NumeroClient = new FormControl(this._GlobalDataService.Annonceur.NumeroClient, [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
    ]);
    this.NumeroContrat = new FormControl(this._GlobalDataService.Annonceur.NumeroContrat, [
      Validators.required,
    ]);

    console.log('this._GlobalDataService.Annonceur', this._GlobalDataService.Annonceur)
    this.ParametresForm = this._formBuilder.group({
      NumeroClient: this.NumeroClient,
      NumeroContrat: this.NumeroContrat,
      NumeroContratAlliage: new FormControl(this._GlobalDataService.Annonceur.NumeroContratAlliage, [
        Validators.minLength(2),
      ]),
      IdentifiantAlliageGE: new FormControl(this._GlobalDataService.Annonceur.IdentifiantAlliageGE, [
      ]),

      NomContact: new FormControl(this._GlobalDataService.Annonceur.NomContact, [
      ]),
      PrenomContact: new FormControl(this._GlobalDataService.Annonceur.PrenomContact, [
      ]),
      EmailContact: new FormControl(this._GlobalDataService.Annonceur.EmailContact, [
      ]),
      TelephoneContact: new FormControl(this._GlobalDataService.Annonceur.TelephoneContact, [
      ]),
      RoleContact: new FormControl(this._GlobalDataService.Annonceur.RoleContact, [
      ]),
      Siret: new FormControl(this._GlobalDataService.Annonceur.Siret, [
      ]),
      RaisonSociale: new FormControl(this._GlobalDataService.Annonceur.RaisonSociale, [
      ]),
    });

    this.isLoaded = true;

  }

  goToHome() {
    this.router.navigate(['/list-campagne'], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
    });
  }

  openHelp(signet: any) {
    this._GlobalDataService.referenceDocHelp = "#" + signet;
    this._HelpComponent.OpenHelpFile();
  }

  uploadLogo($event: any) {
    console.log("uploadLogo", $event);
    let file = $event.target.files[0];
    this._AnnonceurService.uploadLogo(file)
      .subscribe(() => {
        this._AnnonceurService.downloadLogo()
          .subscribe(response => {
            var urlCreator = window.URL;
            this.logo = this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response)) as SafeUrl
          });
      });
  }
}