<div
  class="container"
  [class.has-radio]="type === 'radio'"
  [class.disabled]="disabled"
  (click)="toggleRadio()"
>
  <!-- Select type -->
  <div *ngIf="type === 'radio'" class="type">
    <ng-container *ngIf="radioCheck">
      <mat-icon svgIcon="radio_check"></mat-icon>
    </ng-container>
    <ng-container *ngIf="!radioCheck">
      <mat-icon svgIcon="radio_uncheck"></mat-icon>
    </ng-container>
  </div>

  <!-- Title -->
  <div class="title">{{ title }}</div>

  <!-- Icon -->
  <div
    *ngIf="icon"
    class="icon"
    [mdePopoverTriggerFor]="iconHoverInfo"
    mdePopoverTriggerOn="hover"
    #popoverTrigger="mdePopoverTrigger"
  >
    <ng-container *ngIf="icon == 'information'">
      <mat-icon svgIcon="information"></mat-icon>
    </ng-container>
    <ng-container *ngIf="icon == 'question'">
      <mat-icon svgIcon="question"></mat-icon>
    </ng-container>
  </div>

  <mde-popover #iconHoverInfo="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="gtmt-popover-card">
      <mat-card-content>
        <ng-content tooltip></ng-content>
      </mat-card-content>
    </mat-card>
  </mde-popover>
</div>
