import {Component, Input, OnInit} from '@angular/core';
import {GlobalDataService} from '@core/services';
import {FichierPersoStandaloneService} from "@core/services/fichier-perso-standalone.service";


@Component({
  selector: 'app-suivi-campagne-telechargement',
  templateUrl: './suivi-campagne-telechargement.component.html',
  styleUrls: ['./suivi-campagne-telechargement.component.scss']
})
export class SuiviCampagneTelechargementComponent implements OnInit {

  constructor(public _GlobalDataService: GlobalDataService, private _FichierPersoStandalone: FichierPersoStandaloneService) {}

  ngOnInit(): void {}

  public downloadFile(): void {
    this._FichierPersoStandalone.download(this._GlobalDataService.Campagne['IdCampagne'])
      .subscribe(response => {
        const blob = new Blob([response], {type: response.type});
        let a = document.createElement("a");
        a.style.display = "none";
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.download = this._GlobalDataService.Campagne.importFile;
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
      })
  }
}
