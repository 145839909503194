<div class="iframe-address">
  <h3>Aperçu : pavé adresses</h3>

  <div class="chili-content" [class.is-loading]="isLoadingChili">
    <ng-container *ngIf="
                    !isCreationCreated || 
                    iframeHasError || 
                    isLoadingChili || 
                    !_GlobalDataService.Campagne.importFile
                  ">
      <!-- spinner -->
      <div *ngIf="isLoadingChili" class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>

      <!-- Background iframe and error message -->
      <div *ngIf="!isLoadingChili && iframeHasError" class="spinner-container">
        <div class="error-message">
          <div class="icon">
            <mat-icon svgIcon="attention"></mat-icon>
          </div>

          <!-- PostMessage Error -->
          <div>
            {{ iframeErrorMsg }}
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Iframe Addresses -->
    <ng-container *ngIf="isCreationCreated && !iframeHasError">
      <iframe
        *ngIf="isVisible"
        [class.iframe-fullpage]="_GlobalDataService.isChiliFullpageMode"
        [src]="getIframeUrl()"
        frameborder="0"
        allowfullscreen
        sandbox="allow-downloads allow-modals allow-popups allow-popups-to-escape-sandbox allow-presentation allow-scripts allow-same-origin allow-top-navigation-by-user-activation"
      >
      </iframe>
    </ng-container>
  </div>
</div>
