<!-- Header  -->
<app-header [titre]="titre" [showButtonRetour]="true"></app-header>
<mat-sidenav-container class="gtmt-sidenav-container">
    <mat-sidenav-content>

        <div class="content" role="main">
            <div class="example-container mat-elevation-z8">
                <div class="titre-h2">
                    <h2>Mon compte client</h2>
                </div>
                <form [formGroup]="ParametresForm" (ngSubmit)="submit()" *ngIf="isLoaded">
                    <div>
                        <mat-grid-list cols="10" rowHeight="7rem">

                            <mat-grid-tile colspan="10" rowspan="1" class="gtmt-flex-start">
                                <a class="mat-typography" href="https://www.assistant-courrier.laposte.fr/"
                                    target="_blank" rel="noopener noreferrer">
                                    Pour accéder à votre contrat et à vos factures, connectez-vous à l’Assistant
                                    Courrier La Poste

                                </a>
                            </mat-grid-tile>


                            <mat-grid-tile colspan="5" rowspan="1">

                                <mat-form-field appearance="outline">
                                    <mat-label>N° client</mat-label>
                                    <input matInput placeholder="N° client" formControlName="NumeroClient"
                                        maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">
                                <a style="color: #e91e63; cursor: pointer;" (click)="openHelp('parametres_client')">
                                    <mat-icon>help_outline</mat-icon>
                                </a>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1">
                                <mat-form-field appearance="outline">
                                    <mat-label>N° Contrat </mat-label>
                                    <input matInput placeholder="N° Contrat" formControlName="NumeroContrat"
                                        maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">
                                <a style="color: #e91e63; cursor: pointer;" (click)="openHelp('parametres_contrat')">
                                    <mat-icon>help_outline</mat-icon>
                                </a>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1">
                                <mat-form-field appearance="outline">
                                    <mat-label>N° Contrat Alliage </mat-label>
                                    <input matInput placeholder="N° Contrat Alliage"
                                        formControlName="NumeroContratAlliage" maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1" rowspan="1" class="gtmt-flex-start">
                                <a style="color: #e91e63; cursor: pointer;" (click)="openHelp('parametres_alliage')">
                                    <mat-icon>help_outline</mat-icon>
                                </a>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="4" rowspan="1" style="overflow: visible">
                                <p style="margin-right: 10px; font-size: 16px">Logo</p>
                                <input style="display: none" type="file" (change)="uploadLogo($event)" #fileInput>
                                <img id="logo" (click)="fileInput.click()" [src]="this.logo">
                            </mat-grid-tile>

                            <mat-grid-tile colspan="5" rowspan="1">
                                <mat-form-field appearance="outline">
                                    <mat-label>Identifiant Alliage </mat-label>
                                    <input matInput placeholder="Identifiant Alliage"
                                        formControlName="IdentifiantAlliageGE" maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">
                                <a style="color: #e91e63; cursor: pointer;" (click)="openHelp('parametres_ge')">
                                    <mat-icon>help_outline</mat-icon>
                                </a>
                            </mat-grid-tile>

                            <mat-grid-tile colspan="5" rowspan="1">
                                <mat-form-field appearance="outline">
                                    <mat-label>Nom du contact</mat-label>
                                    <input matInput placeholder="Nom" formControlName="NomContact" maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">
                            </mat-grid-tile>

                            <mat-grid-tile colspan="5" rowspan="1">

                                <mat-form-field appearance="outline">
                                    <mat-label>Prénom</mat-label>
                                    <input matInput placeholder="Prénom" formControlName="PrenomContact" maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">

                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1">

                                <mat-form-field appearance="outline">
                                    <mat-label>Adresse e-mail</mat-label>
                                    <input matInput placeholder="Adresse e-mail" formControlName="EmailContact"
                                        maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">

                            </mat-grid-tile>
                            <!-- Tél -->

                            <mat-grid-tile colspan="5" rowspan="1">

                                <mat-form-field appearance="outline">
                                    <mat-label>Téléphone </mat-label>
                                    <input matInput placeholder="Téléphone" formControlName="TelephoneContact"
                                        maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">


                            </mat-grid-tile>


                            <mat-grid-tile colspan="5" rowspan="1">

                                <mat-form-field appearance="outline">
                                    <mat-label>Rôle</mat-label>
                                    <input matInput placeholder="Rôle" formControlName="RoleContact" maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">


                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1">

                                <mat-form-field appearance="outline">
                                    <mat-label>SIRET</mat-label>
                                    <input matInput placeholder="SIRET" formControlName="Siret" maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">


                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1">
                                <mat-form-field appearance="outline">
                                    <mat-label>Raison sociale</mat-label>
                                    <input matInput placeholder="Raison sociale" formControlName="RaisonSociale"
                                        maxLength="50">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1" class="gtmt-flex-start">

                            </mat-grid-tile>


                            <mat-grid-tile colspan="5" rowspan="1">

                                <button mat-raised-button (click)="goToHome()">Annuler</button>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5" rowspan="1">
                                <button mat-raised-button type="submit">Valider /
                                    Enregistrer</button>

                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </form>

            </div>
        </div>


    </mat-sidenav-content>
</mat-sidenav-container>
<!-- Footer -->
<app-footer>
</app-footer>