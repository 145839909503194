import { Component, ElementRef, OnInit, Sanitizer, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { GlobalDataService } from '@core/services';
import { FichierCreaService } from '@core/services/fichier-crea.service';

@Component({
  selector: 'app-campagne-gestion-logo',
  templateUrl: './campagne-gestion-logo.component.html',
  styleUrls: ['./campagne-gestion-logo.component.scss']
})
export class CampagneGestionLogoComponent implements OnInit {

  logo: any;
  showSpinner: boolean = false;
  public fichiers_crea: string;

  FichiersCreationsURL = {};
  @ViewChild('hiddenfileinput') hiddenfileinput: ElementRef;

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _FichierCreaService: FichierCreaService,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this._GlobalDataService.CampagneExt.FichiersCreation.forEach(f => {
      if (f.FichierCreation === '2') {
        this.downloadFile(f.NomFichier);
      }
    })

  }

  downloadFile(fileName: string) {
    // console.log("download file " + fileName);
    return this._FichierCreaService.download(fileName, this._GlobalDataService.Campagne.IdCampagne)
      .subscribe(response => {
        var blob = new Blob([response], { type: response.type });
        //Create a link element, hide it, direct 
        //it towards the blob, and then 'click' it programatically

        //Create a DOMString representing the blob 
        //and point the link element towards it
        let url = window.URL.createObjectURL(blob);
  
        if (blob.type.match('image/png')) {
          let objectURL = URL.createObjectURL(blob);
          this.FichiersCreationsURL[fileName] = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
        }
      });
  }


  __deleteFile(index: any, Fichier: FichiersCreation) {

    delete this.FichiersCreationsURL[Fichier.NomFichier];    
    //API DELETE Fichier
    this.showSpinner = true;
    this._FichierCreaService.delete(Fichier.IdfichierCreation)
      .subscribe(
        response => {
          console.log(response)
          this.showSpinner = false;
          this._GlobalDataService.CampagneExt.FichiersCreation.splice(index, 1);
          this._GlobalDataService.CampagneExt.NbFichiersLogo =  0;          
        },
        error => this.errorResonse(error),
      );

  }

  deleteFile(index: any, Fichier: FichiersCreation) {
    const onSuccess = (file: FichiersCreation) => {
      this.showSpinner = true;
      this._FichierCreaService.delete(file.IdfichierCreation)
        .subscribe(
          response => {
            console.log('Response : ' + response);
            this._GlobalDataService.CampagneExt.FichiersCreation.splice(index, 1);
            delete this._GlobalDataService.CampagneExt.FichiersCreationsURL[Fichier.NomFichier];
            this._GlobalDataService.CampagneExt.NbFichiersLogo = 0;
            this.showSpinner = false;
          },
          error => this.errorResonse(error),
        );
    }

    // VLN03 : Escape HTML char to prevent XSS
    let fileNameClean = this.domSanitizer.sanitize(SecurityContext.HTML, Fichier.NomFichier);
    fileNameClean = fileNameClean.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");

    this._GlobalDataService.confirm({
      "titre": "Supprimer?",
      "message": "Voulez-vous vraiment supprimer le fichier portant le nom " + fileNameClean + "?",
      "BouttonConfirmation": true,
      "onConfirm": onSuccess.bind(this, Fichier),
      "onCancel": console.log('onCancel')
    });
  }



  getFileDetailsV2(e: any) {
    console.log("GET FILE DETAILS V2");
    for (var i = 0; i < e.target.files.length; i++) {

      const file = e.target.files[i];

      console.log("processing file", file);

      const filename = this.domSanitizer.sanitize(SecurityContext.HTML,file.name);

      if (!file.type.startsWith('image')) {
        this._GlobalDataService.alert("Le fichier " + filename + " n'est pas de type image.")
        continue;
      }

      let objectURL = URL.createObjectURL(file);
      this.FichiersCreationsURL[file.name] = this.domSanitizer.bypassSecurityTrustUrl(objectURL);


      const onSuccess = (file: any, idCampagne: string) => {
        this.showSpinner = true;
        this._FichierCreaService.uploadFile(file, idCampagne)
          .subscribe(
            response => {
              console.log(response);
              this._FichierCreaService.add({
                NomFichier: filename,
                TypeFichier: 'Autre',
                IdCampagne: idCampagne,
                FichierLogo: true
              })
                .subscribe(
                  (result) => {
                    console.log(result);
                    if (!fileExists) {
                      this._GlobalDataService.CampagneExt.FichiersCreation.push(
                        {
                          NomFichier: filename,
                          TypeFichier: 'Autre',
                          IdCampagne: idCampagne,
                          IdfichierCreation: result.idfichier,
                          FichierCreation: '2'
                        }
                      )
                      this._GlobalDataService.CampagneExt.NbFichiersLogo =  1;
                    }
                    this.showSpinner = false;
                  },

                  (error) => this.errorResonse(error)
                );
            },
            error => this.errorResonse(error),
          );
      }


      // Check if there is already a file with the same name
      let fileExists = false;
      for (let i = 0; i < this._GlobalDataService.CampagneExt.FichiersCreation.length; i++) {
        const fichier = this._GlobalDataService.CampagneExt.FichiersCreation[i];

        // If there is a file with same name, ask if overwrite or not
        if (fichier.NomFichier === file.name && fichier.FichierCreation == '2' ) {
          this._GlobalDataService.alert("Un fichier logo est déjà chargé. Veuillez le supprimer d'abord")
          // this._GlobalDataService.confirm({
          //   "titre": "Remplacer?",
          //   "message": "Un fichier portant le même nom " + file.name + " est déjà chargé. Souhaitez-vous le remplacer ?",
          //   "BouttonConfirmation": true,
          //   "onConfirm": onSuccess.bind(this, file, this._GlobalDataService.Campagne.IdCampagne),
          //   "onCancel": console.log('onCancel')
          // });
          fileExists = true;
          break;
        }
      }

      if (!fileExists && this._GlobalDataService.CampagneExt.NbFichiersLogo == 1 ) {
        this._GlobalDataService.alert("Un fichier logo est déjà chargé. Veuillez le supprimer d'abord")
        continue;
      }

      // Call on success if no files with the same name were found
      onSuccess.call(this, file, this._GlobalDataService.Campagne.IdCampagne);



      //permet de vider le champ et autoriser l'uload d'un nouveau fichier avec le même nom!
      if (i == e.target.files.length - 1) {
        //this.CreationForm.get('fichiers_crea')!.reset();
        this.fichiers_crea = "";
      }
    }
    this.hiddenfileinput.nativeElement.value = '';

  }

  errorResonse(HttpErrorResponse: { error: { message: string; }; }) {
    this.showSpinner = false;
    console.error("Error!", HttpErrorResponse);
    if (HttpErrorResponse.error.message && HttpErrorResponse.error.message != "") {
      this._GlobalDataService.alert("Une erreur est survenue . Merci de relancer l'estimation tarifaire.", HttpErrorResponse.error.message);
    }
  }


}
