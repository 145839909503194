<form [formGroup]="_AccountService.userForm">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Nouveau compte client Standalone</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <mat-toolbar>
                <h2>
                    Créer un nouveau client :
                </h2>
            </mat-toolbar>

            <mat-grid-list cols="2" rowHeight="2rem">
                <!-- Login -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Login</mat-label>
                        <input type="text" matInput formControlName="login" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- Nom du client* ANNONCEUR -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Nom du client</mat-label>
                        <input type="text" matInput formControlName="annonceurName" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- N° du client* ANNONCEUR -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° du client</mat-label>
                        <input type="text" matInput formControlName="NumeroClient" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- N° du contrat* -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° contrat</mat-label>
                        <input type="text" matInput formControlName="NumeroContrat" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- N° Contrat Alliage -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° Contrat Alliage</mat-label>
                        <input type="text" matInput formControlName="NumeroContratAlliage">
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- Identifiant Alliage -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Identifiant Alliage</mat-label>
                        <input type="text" matInput formControlName="IdentifiantAlliageGE">
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- SIRET* -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>SIRET</mat-label>
                        <input type="text" matInput formControlName="Siret" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- Raison Sociale* -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Raison sociale</mat-label>
                        <input type="text" matInput formControlName="RaisonSociale" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- Nom de l’utilisateur* -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Nom de l’utilisateur</mat-label>
                        <input type="text" matInput formControlName="lastname" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- Prénom de l’utilisateur* -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Prénom de l’utilisateur</mat-label>
                        <input type="text" matInput formControlName="firstname" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- Adresse e-mail* -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Adresse e-mail</mat-label>
                        <input type="email" matInput formControlName="email" required>
                    </mat-form-field>
                </mat-grid-tile>
            
                <!-- Téléphone -->
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Téléphone</mat-label>
                        <input type="text" matInput formControlName="TelephoneContact">
                    </mat-form-field>
                </mat-grid-tile>
            
            </mat-grid-list>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button color="primary" [disabled]="!_AccountService.userForm.valid" (click)="onSubmit()">Enregistrer</button>
        </mat-card-actions>
    </mat-card>
</form>

