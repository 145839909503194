import { LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import * as fromComponents from './components';

import { StandaloneRoutingModule } from './standalone-routing.module';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DATE_FORMAT_FRA } from '@core/conf/date-adapter';

@NgModule({
  declarations: [fromComponents.components],
  exports: [fromComponents.components],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    StandaloneRoutingModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT_FRA },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
})
export class StandaloneModule {}
