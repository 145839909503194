<mat-grid-list [cols]="3" rowHeight="8rem" [gutterSize]="'2rem'">

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Date de début de récurrence </h2>
        <span>{{_GlobalDataService.Campagne.DateDeDebut | date: 'dd/MM/yyyy' }}</span>
    </mat-grid-tile>

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Date de fin de récurrence</h2>
        <span>{{_GlobalDataService.Campagne.DateDeFin | date: 'dd/MM/yyyy' }}</span>

    </mat-grid-tile>

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Date de validation du BAT</h2>
        <span>{{_GlobalDataService.Campagne.DateValidationBAT | date: 'dd/MM/yyyy' }}</span>
    </mat-grid-tile>


</mat-grid-list>


<mat-grid-list [cols]="1" rowHeight="8rem" [gutterSize]="'2rem'">

    <mat-grid-tile class="gtmt-ai-end" colspan="1" rowspan="1">
        <button mat-raised-button color="blue"
            [disabled]="statutsAutoriseAnnulationCampagne.indexOf(_GlobalDataService.Campagne.CodeStatut) > -1"
            (click)="demandeAnnulationCampagne()">
            Annuler cette campagne courrier
        </button>
    </mat-grid-tile>
</mat-grid-list>



<!-- <button *ngIf="campagne?.FrequenceEnvoi == 'Récurrente'" mat-button class="btn-grey"
[disabled]="statutsAutoriseAnnulationCampagne.indexOf(campagne.CodeStatut) > -1"
(click)="demandeAnnulation()">
Annuler la campagne récurrente
</button>

<button *ngIf="campagne?.FrequenceEnvoi != 'Récurrente'" mat-button class="btn-grey"
[disabled]="statutsAutoriseAnnulationCommande.indexOf(currentcommande?.CodeStatut) > -1"
(click)="demandeAnnulationCommande(currentcommande)">
Annuler cette campagne courrier
</button> -->