import { Component, Input, OnInit } from '@angular/core';
import { GlobalDataService } from '@core/services/global-data.service';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { CampagneTypologieService } from '@core/services';
import { navPaths } from '@core/models/support.model';


@Component({
  selector: 'app-campagne-typologie-dimension',
  templateUrl: './campagne-typologie-dimension.component.html',
  styleUrls: ['./campagne-typologie-dimension.component.scss']
})
export class CampagneTypologieDimensionComponent implements OnInit {

  typologie: string = "autre";

  navigationOption: NavigationBehaviorOptions = { state: {} };

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,    
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {

    if (this.router.getCurrentNavigation()!.extras.state) {
      const routeState = this.router.getCurrentNavigation()!.extras.state;
      if (routeState) {
        this.typologie = routeState['typologie'];
        console.log(this.typologie);
      }
    }
  }

  ngOnInit(): void {
  }

  gotoTypologieSelection(): void {
    console.log('gotoTypologieSelection');
    this._CampagneTypologieService.navigate(navPaths.selection, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }  

}
