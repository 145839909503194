import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Annonceur } from '@core/models/annonceur.model';
import {
  AccountService,
  AnnonceurService,
  AuthenticationService,
  GlobalDataService,
} from '@core/services';
import { HelpComponent } from 'app/campagne/components';
import { Subject, Subscription } from 'rxjs';
import { User } from '@core/models/user.model';
import { HttpResponse } from '@angular/common/http';
import { StandaloneService } from '@core/services/standalone.service';

@Component({
  selector: 'app-compte-client',
  templateUrl: './compte-client.component.html',
  styleUrls: ['./compte-client.component.scss'],
})
export class CompteClientComponent implements OnInit {
  logo = null;
  hidePasswordSFTP: boolean = true;

  titre = 'Administration par annonceur';
  form: FormGroup;
  formSub: Subscription;

  login: string;

  user: User;

  public eventsSubject: Subject<string> = new Subject<string>();

  public isSendingPassword: boolean = false;

    // Generate password
    public generatePasswordLoading = false;
    private subGeneratePassword: Subscription;

  constructor(
    public router: Router,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private _AnnonceurService: AnnonceurService,
    public _GlobalDataService: GlobalDataService,
    public _HelpComponent: HelpComponent,
    private _sanitizer: DomSanitizer,
    private _AccountService: AccountService,
    private _authenticationService: AuthenticationService,
    private _standaloneService: StandaloneService
  ) {
    _GlobalDataService.referenceDocHelp = '#ID_annonceur';

    this.form = this._formBuilder.group({
      annonceurName: new FormControl(
        this._GlobalDataService.Annonceur.annonceurName,
        [Validators.required]
      ),
      NumeroClient: new FormControl(
        this._GlobalDataService.Annonceur.NumeroClient,
        [Validators.required]
      ),
      NumeroContrat: new FormControl(
        this._GlobalDataService.Annonceur.NumeroContrat,
        [Validators.required]
      ),
      NumeroContratAlliage: new FormControl(
        this._GlobalDataService.Annonceur.NumeroContratAlliage,
        [Validators.minLength(2)]
      ),
      IdentifiantAlliageGE: new FormControl(
        this._GlobalDataService.Annonceur.IdentifiantAlliageGE,
        []
      ),
      NomContact: new FormControl(
        this._GlobalDataService.Annonceur.NomContact,
        []
      ),
      PrenomContact: new FormControl(
        this._GlobalDataService.Annonceur.PrenomContact,
        []
      ),
      EmailContact: new FormControl(
        this._GlobalDataService.Annonceur.EmailContact,
        []
      ),
      TelephoneContact: new FormControl(
        this._GlobalDataService.Annonceur.TelephoneContact,
        []
      ),
      RoleContact: new FormControl(
        this._GlobalDataService.Annonceur.RoleContact,
        []
      ),
      Siret: new FormControl(this._GlobalDataService.Annonceur.Siret, []),
      RaisonSociale: new FormControl(
        this._GlobalDataService.Annonceur.RaisonSociale,
        []
      ),
      LoginSFTP: new FormControl(
        this._GlobalDataService.Annonceur.loginSFTP,
        []
      ),
      PasswordSFTP: new FormControl(
        this._GlobalDataService.Annonceur.passwordSFTP,
        []
      ),
    });

    this.login = this._GlobalDataService.getJWTLoginName();
  }

  ngOnInit(): void {
    Object.keys(this.form.controls).forEach((c) => {
      if (
        this.form.get(c).hasValidator(Validators.required) &&
        !this.form.get(c).value
      ) {
        // this.form.get(c).patchValue(c, { emitEvent: false });
        // this.form.get(c).patchValue('', { emitEvent: false });
        //this.form.get(c).markAsDirty();
        this.form.get(c).markAsTouched({ onlySelf: true });
        this.form.get(c).updateValueAndValidity({ onlySelf: true });
      }
    });

    // Load user informations
    this._authenticationService.getUserInfo().subscribe({
      next: (res: HttpResponse<User>) => {
        this.user = res.body;
      },
      error: (err) => {
        console.log('err', err);
      },
    });
  }

  submit() {
    console.log('validate the annonceur');

    // A revoir pour le cas Standalone @Ata
    // this._AuditLogService.add(`0`, `0`, `Validation paramètres annonceur`).subscribe();

    if (
      this.form.get('NumeroClient').invalid ||
      this.form.get('NumeroContrat').invalid
    ) {
      this._GlobalDataService.alert(
        'Les champs numéro client et numéro de contrat sont obligatoires.' +
          '<br>Le champ numéro client doit être numérique.' +
          '<br>Merci de compléter les champs concernés'
      );
      return false;
    }

    console.debug(this._GlobalDataService.Annonceur);
    if (this._GlobalDataService.Annonceur.IdAnnonceur === undefined) {
      // création
      this._GlobalDataService.Annonceur = new Annonceur();
    }

    this._GlobalDataService.Annonceur.login = this.login;
    this._GlobalDataService.Annonceur.email = this.form.value.EmailContact;
    this._GlobalDataService.Annonceur.annonceurName =
      this.form.value.annonceurName;
    this._GlobalDataService.Annonceur.NumeroClient =
      this.form.value.NumeroClient;
    this._GlobalDataService.Annonceur.NumeroContrat =
      this.form.value.NumeroContrat;
    this._GlobalDataService.Annonceur.NumeroContratAlliage =
      this.form.value.NumeroContratAlliage;
    this._GlobalDataService.Annonceur.IdentifiantAlliageGE =
      this.form.value.IdentifiantAlliageGE;
    this._GlobalDataService.Annonceur.EmailContact =
      this.form.value.EmailContact;
    this._GlobalDataService.Annonceur.TelephoneContact =
      this.form.value.TelephoneContact;
    this._GlobalDataService.Annonceur.NomContact = this.form.value.NomContact;
    this._GlobalDataService.Annonceur.PrenomContact =
      this.form.value.PrenomContact;
    this._GlobalDataService.Annonceur.RoleContact = this.form.value.RoleContact;
    this._GlobalDataService.Annonceur.Siret = this.form.value.Siret;
    this._GlobalDataService.Annonceur.RaisonSociale =
      this.form.value.RaisonSociale;

    this._AccountService
      .saveAnnonceurDetails(this._GlobalDataService.Annonceur)
      .subscribe(
        (response) => {
          this._GlobalDataService.alert(
            "La mise à jour du client s'est passée avec succès"
          );
        },
        (err) => {
          this._GlobalDataService.alert(
            'Une erreur est survenue lors de la mise à jour de vos informations personnelles : ' +
              JSON.stringify(err)
          );
        }
      );

    return true;
  }

  openHelp(signet) {
    this._GlobalDataService.referenceDocHelp = '#' + signet;
    this._HelpComponent.OpenHelpFile();
  }

  uploadLogo($event) {
    console.log('uploadLogo', $event);
    let file = $event.target.files[0];
    this._AnnonceurService.uploadLogo(file).subscribe(() => {
      this._AnnonceurService.downloadLogo().subscribe((response) => {
        var urlCreator = window.URL;
        this._GlobalDataService.logo = this._sanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(response)
        );
      });
    });
  }

  gotoMentionsLegales() {
    this.router.navigate([
      '/standalone/home',
      { outlets: { navview: 'mentions-legales' } },
    ]);
    return false;
  }

  ngDestroy() {
    this.formSub?.unsubscribe();
    this.subGeneratePassword?.unsubscribe();
  }

  public generatePassword() {
    const IdAnnonceur = this._GlobalDataService.Annonceur.IdAnnonceur;
    this.generatePasswordLoading = true;
    this.subGeneratePassword = this._standaloneService
      .generatePassword(IdAnnonceur)
      .subscribe({
        next: () => {
          this.generatePasswordLoading = false;
          this._GlobalDataService.alert(
            'Un nouveau mot de passe vous a été envoyé par email.'
          );
        },
        error: () => {
          this.generatePasswordLoading = false;
          this._GlobalDataService.alert('Une erreur est survenu.');
        },
      });
  }

  public isGeneratePasswordDisable() {
    return (
      !this.form.valid ||
      this.generatePasswordLoading ||
      !this.form.controls['LoginSFTP'].value
    );
  }
}
