import { EventEmitter, Injectable } from '@angular/core';
import { Annonceur } from '../models/annonceur.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DialogMessageComponent, DialogAnnulationCampagneComponent, DialogCreationOptionChangeComponent, DialogShortcutComponent } from 'app/campagne/components';
import { Campagne } from '@core/models/campagne.model';
import { SafeUrl } from '@angular/platform-browser';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup } from '@angular/forms';
import { CampagneExt } from '@core/models/campagne-ext.model';
import {
  Criteria,
  EstimationRequest,
} from '@core/models/estimationRequest.model';
import { Produit } from '@core/models/produit.model';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdresseRetour } from '@core/models/adresseRetour.model';



@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  Annonceur: Annonceur = new Annonceur();
  version: string = environment.version;

  public localAnnonceur: Annonceur = new Annonceur();
  public objectParams: any;
  public produits = new Array<Produit>();
  listEnveloppeProduits$ = new BehaviorSubject([]);
  public listChampsFusionDisponible = [];
  public availableChampsFusion = [];
  public availableEloquaChampsFusion = [];
  public availableAdresses = [];
  public sharedListEloqua = [];
  public sharedListNames = [];
  public mappingStatut: { [key: string]: string } = {};
  public mappingPercentage: { [key: string]: string } = {};

  public showStepEnveloppeNC = true; //afficher ou non l'étape Enveloppe de la création de la campagne

  public stepCompleted_IdentifCamp = false;
  public stepCompleted_Support = false;
  public stepCompleted_Enveloppe = false;
  public stepCompleted_AdresseR = false;
  public stepCompleted_FichieCrea = false;
  public stepCompleted_Freq = false;
  public stepCompleted_ServiceAdd = false;
  public stepCompleted_Confirmation = false;

  public stepIndex: number;

  public referenceDocHelp = ''; // exemple : referenceDoc = '#_Toc49435597';

  public nomCampagneIsValid = true; //permet de vérifier dans l'écran récap si le nom de la campagne a été corrigé.
  public descriptionCampagneIsValid = true;
  public refCampagneIsValid = true;

  public Campagne!: Campagne;
  public CampagneExt: CampagneExt;
  public campagneBeforeChanges!: Campagne;
  public logo!: SafeUrl;
  public EmailNotification: string;

  public listChampsFusionSF = [];
  public interactionIdSF = null;

  // C'est pour garder les tokcens en memoire

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  public ADMIN_APP_JWT: string = null;
  public STANDALONE_APP_JWT: string = null;
  public ELOQUA_APP_JWT: string = null;
  public SALESFORCE_APP_JWT: string = null;
  public SF_FUEL_TOKEN: string = null;
  public SF_FUEL_API_REST_HOST: string = null;

  public tabWindowId: any;

  public stepper!: MatStepper;

  public formEstimate!: FormGroup;

  public isLoaded: boolean = false;
  public isIframeChiliLoaded: boolean = false;
  public isChiliFullpageMode: boolean = false;

  public sharedLists: Array<any>;
  public availableCamapgnes = new Array<Campagne>();

  public showSpinner: boolean = false;

  public standalone_username: string = null;
  public standalone_password: string = null;

  public completedStepsEvt: EventEmitter<number[]> = new EventEmitter<number[]>();    

  // public APP_JWT = null;

  // CONTEXT is either "STANDALONE", "ELOQUA" or "SALESFORCE"
  public CONTEXT: string = '';

  private changedCampagne$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public changedCampagneObs$: Observable<boolean>;

  public emails = new Array<string>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
  ) {
    console.log('version', this.version);
    this.changedCampagneObs$ = this.changedCampagne$.asObservable();
  }

  public campagneChanged() {
    this.changedCampagne$.next(true);
  }

  /**
   * permer d'afficher une alerte
   * @param message message à afficher
   */
  alert(message: string, errorMessage?: string) {
    this.dialog.open(DialogMessageComponent, {
      panelClass: 'laposte-msg-panel',
      data: {
        message: message,
        errorMessage: errorMessage,
        titre: 'Cap Mailing Plateforme',
        BouttonConfirmation: false,
      },
    });
  }

  dialogAnnulation(data: any) {
    return this.dialog.open(DialogAnnulationCampagneComponent, {
      panelClass: 'laposte-msg-panel',
      data: data,
    });
  }

  /**
   * 
   * @param data   {
    message: '';
    titre: 'Cap Mailing Plateforme';
    BouttonConfirmation: false;
    }
   */
  confirm(data: any) {
    return this.dialog.open(DialogMessageComponent, {
      panelClass: 'laposte-msg-panel',
      data: data
    });
  }

  confirmCreationOption(data: any) {
    return this.dialog.open(DialogCreationOptionChangeComponent, {
      panelClass: 'laposte-msg-panel',
      data: data
    });
  }

  confirmShortCutOption(data: any) {
    return this.dialog.open(DialogShortcutComponent, {
      panelClass: 'laposte-msg-panel',
      data: data
    });
  }

  openSnackBarInfo(message: string) {
    console.log('openSnackBarInfo : ' + message);
    this.alert(
      "Erreur technique.<br> Si le problème persiste, veuillez rouvrir l'application CapMailing Plateforme."
    );
    // this.alert("Erreur serveur. <br>" +
    // "Veuillez vérifier votre connexion internet. <br>" +
    // CTBEB-446 (CFZ @ 2020-10-21) : Remove sentence about fields.
    //"Veuillez vérifier les champs renseignés. <br>"+
    // "Si le problème persiste, veuillez rouvrir l'application Cap Mailing Plateforme");

    /** Pas daffichage de notif si y a une erreur dans l'api
     * */
    // this.snackBar.open(message, 'OK', {
    //       duration: 5000,
    //       horizontalPosition: 'center',
    //       verticalPosition: 'top',

    //     });
  }

  /**
   *
   * @param key : filtrer sur ce champ
   */
  distinct(array: any, key: any) {
    return [
      ...new Map(
        array.map((item: { [x: string]: any }) => [item[key], item])
      ).values(),
    ];
  }

  /**
   * 
   * @param key : filtrer sur ce champ
   
  distinct(array: { map: (arg0: (item: any) => any[]) => Iterable<readonly [unknown, unknown]>; }, key: string | number) {
    return [...new Map(array.map((item: { [x: string]: any; }) => [item[key], item])).values()];
  }*/

  didCampagneProductChange(campagne: any) {
    const fields: string[] = [
      'CodeSupport',
      'CodeTypologie',
      'CodeDimensionsSupport',
      'CodeGrammageSupport',
      'CodeFinitionSupport',
      'CodeEncreSupport',
      'CodeEnveloppe',
      'CodeDimensionsEnveloppe',
      'CodeGrammageEnveloppe',
      'CodeFinitionEnveloppe',
      'CodeEncreEnveloppe',
    ];
    let oldCampagne: any = this.campagneBeforeChanges || null;
    let newCampagne: any = campagne || null;

    console.log(oldCampagne, newCampagne);

    if (oldCampagne) {
      for (const field of fields) {
        if (oldCampagne[field] == 'null') oldCampagne[field] = null;
        if (oldCampagne[field] != newCampagne[field] && oldCampagne[field]) {
          console.log('Field ' + field + ' differ.');
          // this.alert("Vous avez opté pour un autre produit, merci de remplacer les fichiers à envoyer en production.");
          return true;
        }
      }
    }
    return false;
  }

  getLibelleProduit(
    codeName: string | number,
    codeValue: any,
    libelleName: string | number
  ) {
    let produit;
    if (this.produits !== undefined) {
      produit = this.produits.find(
        (x: { [x: string]: any }) => x[codeName] === codeValue
      )!;
      if (produit !== undefined && produit[libelleName])
        return produit[libelleName];
      else return null;
    } else return null;
  }

  getStatutMapping() {
    // ATA@20230403 - Security enhancements
    // if (this.isTokenExpired(this.getJwtoken())) {
    //   this.refreshToken();
    // }

    let statusUrl = environment.url_api + '/statutConfig/list';

    return new Promise<void>((resolve) => {
      this.http
        .get<any>(statusUrl, {
          params: this.objectParams,
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.getJwtoken()}`,
          }),
        })
        // this.http.get<any>(statusUrl, { params: this.objectParams })
        .subscribe((response) => {
          console.log('getStatutConfig result', response);
          if (response && response.statutConfig) {
            this.mappingStatut = {};
            response.statutConfig.forEach((statutConfig: { codeStatut: string; libelle: string; percentage: string; chiliPercentage: string }) => {
              this.mappingStatut[statutConfig.codeStatut] = statutConfig.libelle;

              // Bar de progression : Chili and Classic paths
              if (this.Campagne?.creationType === 'chili') {
                this.mappingPercentage[statutConfig.codeStatut] = statutConfig.chiliPercentage;
              } else {
                this.mappingPercentage[statutConfig.codeStatut] = statutConfig.percentage;
              }
           
            })
          }
          resolve();
        });
    });
  }

  isEloquaContext() {
    return this.CONTEXT === 'ELOQUA';
  }

  isStandaloneContext() {
    return this.CONTEXT === 'STANDALONE';
  }

  isSalesforceContext() {
    return this.CONTEXT === 'SALESFORCE';
  }

  getJWTAnnonceurId() {
    const token = this.getJwtoken();

    if (!token) return -1;
    const jsonToken = JSON.parse(atob(token.split('.')[1]));

    return jsonToken.idAnnonceur;
  }

  getJWTLoginName() {
    const token = this.getJwtoken();

    if (!token) return null;
    const jsonToken = JSON.parse(atob(token.split('.')[1]));

    return jsonToken.sub;
  }

  isAdminAnnonceur() {
    const token = this.getJwtoken();

    if (!token) return false;
    const jsonToken = JSON.parse(atob(token.split('.')[1]));

    return jsonToken.auth.indexOf('ADMIN_ANNONCEUR_STANDALONE') !== -1;
  }

  isAdminLaposte() {
    const token = this.getJwtoken();

    if (!token) return false;
    const jsonToken = JSON.parse(atob(token.split('.')[1]));

    return (
      jsonToken.auth.indexOf('ADMIN_LAPOSTE') !== -1 ||
      jsonToken.auth.indexOf('ROLE_ADMIN') !== -1
    );
  }

  _getJwtoken() {
    return localStorage.getItem(this.CONTEXT + '_APP_JWT');
  }

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  getJwtoken() {
    switch (this.CONTEXT) {
      case 'ADMIN':
        return this.ADMIN_APP_JWT;
      case 'ELOQUA':
        return this.ELOQUA_APP_JWT;
      case 'STANDALONE':
        return this.STANDALONE_APP_JWT;
      case 'SALESFORCE':
        return this.SALESFORCE_APP_JWT;
      default:
        return null;
    }
  }

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  setJwtoken(jwtToken: string) {
    switch (this.CONTEXT) {
      case 'ADMIN':
        this.ADMIN_APP_JWT = jwtToken;
        break;
      case 'ELOQUA':
        this.ELOQUA_APP_JWT = jwtToken;
        break;
      case 'STANDALONE':
        this.STANDALONE_APP_JWT = jwtToken;
        break;
      case 'SALESFORCE':
        this.SALESFORCE_APP_JWT = jwtToken;
        break;
      default:
        break;
    }
  }

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  getinteractionIdSF() {
    // return this.cookieService.get('interactionIdSF');
    return this.interactionIdSF;
  }

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  setinteractionIdSF(_interactionIdSF: string) {
    this.interactionIdSF = _interactionIdSF;
    this.cookieService.set('interactionIdSF', _interactionIdSF, {
      sameSite: 'None',
      secure: true,
    });
  }

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  getSF_FUEL_TOKEN() {
    // return this.cookieService.get('SF_FUEL_TOKEN');
    return this.SF_FUEL_TOKEN;
  }

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  setSF_FUEL_TOKEN(token: string) {
    // this.cookieService.set('SF_FUEL_TOKEN', token,{sameSite: 'None',secure: true });     
    this.SF_FUEL_TOKEN = token;
  }

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  getSF_FUEL_API_REST_HOST() {
    // return this.cookieService.get('SF_FUEL_API_REST_HOST');
    return this.SF_FUEL_API_REST_HOST;
  }

  setSF_FUEL_API_REST_HOST(token: string) {
    // this.cookieService.set('SF_FUEL_API_REST_HOST',token,{sameSite: 'None',secure: true });
    this.SF_FUEL_API_REST_HOST = token;
  }

  // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
  getSF_FUEL_API_REST_HOSTEN() {
    // return this.cookieService.get('SF_FUEL_API_REST_HOST');
    return this.SF_FUEL_API_REST_HOST;
  }

  setSF_FUEL_API_REST_HOSTEN(token: string) {
    // this.cookieService.set('SF_FUEL_API_REST_HOST', token,{sameSite: 'None',secure: true });
    this.SF_FUEL_API_REST_HOST = token;
  }

  // ATA@09-03-23 -Security enhancements
  isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return expiry * 1000 > Date.now();
  }

  _refreshSFToken() {
    let baseUrl: string = environment.url_api + '/salesforce';
    let url = baseUrl + '/checkToken';

    let authTokens = { fuel2token: this.getSF_FUEL_TOKEN() };
    let fuelapiRestHost = this.getSF_FUEL_API_REST_HOST();

    this.http
      .post<any>(
        url,
        { request: { tokens: authTokens, fuelapiRestHost: fuelapiRestHost } },
        {}
      )
      .subscribe(
        async (result) => {
          console.log('result is ', result);
          if (result) {
            console.log('id token ', result.id_token);
            this.setJwtoken(result.id_token);
          } else {
            this.router.navigate(['/erreur'], {
              queryParams: this.objectParams,
              queryParamsHandling: 'merge',
            });
          }
        },
        (error) => {
          console.debug('isAuth fail', error);
        }
      );
  }

  refreshSFToken(interactionIdSF: string) {
    let baseUrl: string = environment.url_api + '/salesforce';
    let url = baseUrl + '/refreshToken';

    return this.http.post<any>(
      url,
      { request: { interactionIdSF: interactionIdSF } },
      {}
    );
  }

  _refreshEloquaToken() {
    let eloquaParams = {
      ELOQUA_URI_PATH: 'eloqua/bootstrap',
      ELOQUA_HTTP_METHOD: 'GET',
    };

    let baseUrl: string = environment.url_gateway + '/eloqua';
    let url = baseUrl + '/refreshToken';

    this.http.post<any>(url, {}, { params: Object.assign(eloquaParams, this.objectParams) })
      .subscribe(async (result) => {
        console.log("result is ", result);
        if (result) {
          console.log('id token ', result.id_token);
          this.setJwtoken(result.id_token);
        } else {
          this.router.navigate(['/erreur'], {
            queryParams: this.objectParams, queryParamsHandling: "merge"
          });
        }
      },
        (error) => {
          console.debug("isAuth fail", error);
        }
      );
  }

  refreshEloquaToken(): Observable<any> {
    let eloquaParams = {
      ELOQUA_URI_PATH: 'eloqua/bootstrap',
      ELOQUA_HTTP_METHOD: 'GET',
    };

    let baseUrl: string = environment.url_gateway + '/eloqua';
    let url = baseUrl + '/refreshToken';

    return this.http.post<any>(url, {}, {
      params: Object.assign(eloquaParams, this.objectParams)
    });
  }

  refreshStandaloneToken() {
    let baseUrl: string = environment.url_api + '/standalone';
    let url = baseUrl + '/authenticate';
    this.http.post<any>(url, {
      "username": this.standalone_username,
      "password": this.standalone_password,
      "rememberMe": false
    })
      .subscribe(async (result) => {
        console.log("result is ", result);
        if (result) {
          console.log('id token ', result.id_token);
          this.setJwtoken(result.id_token);
        } else {
          this.router.navigate(['/erreur'], {
            queryParams: this.objectParams, queryParamsHandling: "merge"
          });
        }
      },
        (error) => {
          console.debug("isAuth fail", error);
        }
      );
  }

  refreshToken() {
    switch (this.CONTEXT) {
      case 'ADMIN':
        this.refreshStandaloneToken();
        break;
      case 'STANDALONE':
        this.refreshStandaloneToken();
        break;
      case 'ELOQUA':
        this.refreshEloquaToken();
        break;
      case 'SALESFORCE':
        this.refreshSFToken(this.getinteractionIdSF());
        break;
      default:
        break;
    }
  }

  isValidDate(date: string) {
    //console.log('isValidDate : ' + date);
    if (date == null) return false;
    let dateWrapper = new Date(date);
    return !isNaN(dateWrapper.getDate());
  }

  setOptionRetour() {
    this.Campagne.OptionRetour = '';
    if (
      !this.Annonceur.NumeroContratAlliage ||
      (this.Campagne.IdAdresse != '0' && this.Campagne.IdAdresse)
    ) {
      this.Campagne.OptionRetour = 'AdresseRetour';
    } else if (this.Campagne.Alliage) this.Campagne.OptionRetour = 'Alliage';
  }

  checkStepCompletion() {
    const completedSteps = [];

    // Step 1 - Identification
    if (this.Campagne.NomCampagne && this.Campagne.NomCampagne.length <= 100)
      completedSteps.push(1);

    // Step 2 - Typologie
    if (!(this.Campagne.IdProduit === undefined || this.Campagne.IdProduit == "" || this.Campagne.IdProduit == "0")) {
      completedSteps.push(2);
    }
    // Step 3 - Créations
    if(this.Campagne.creationType !== 'chili') {
      if (this.CampagneExt.NbFichiersPersonnalisation > 0 || this.CampagneExt.NbFichiersCreation > 0) {
        completedSteps.push(3);
      }
    } else {
      if(this.CampagneExt.chiliCreationStatus === 'VALIDEE') {
        completedSteps.push(3);
      }
    }

    // Step 4 - Personalisation
    // Pas d'obligation sur la personnalisation
    if(this.Campagne.creationType !== 'chili') {
      let hasChampsFusionPersonnalisation = (this.CampagneExt.ChampsFusion.filter(cf => cf.IsPaveAdresse == '0' || cf.IsPaveAdresse == 'false')).length > 0;
      if (hasChampsFusionPersonnalisation || this.Campagne.importOption == 'sftp') {
        completedSteps.push(4);
      }
    } else {
      if(this.CampagneExt.chiliCreationStatus === 'VALIDEE') {
        completedSteps.push(4);
      }
    }

    // Step 5 - Planification
    // contrôle de la fréquence d'envoi
      if (this.Campagne.FrequenceEnvoi == "Récurrente") {
        if (this.Campagne.DateDeDebut && this.Campagne.DateDeFin) {
          completedSteps.push(5);
        }
      } else if (this.Campagne.FrequenceEnvoi == "Une seule fois") {
        if (this.Campagne.DateRemiseImprimeur && this.Campagne.DateRemiseImprimeur !== "null") {
          completedSteps.push(5);
        }
    } 

    // Step 6 - Adresses
    if(this.Campagne.creationType !== 'chili') {
      if (this.isStandaloneContext() && this.Campagne.importOption === 'sftp') {
        // Option Alliage 
        if (this.Campagne.Alliage && this.logo) {
          completedSteps.push(6);
        }
        //  Option Adresse de Retour 
        if (!this.Campagne.Alliage && !(!this.Campagne.IdAdresse || this.Campagne.IdAdresse == "0")) {
          completedSteps.push(6);
        }
      } else if (this.CampagneExt.ChampsFusion.filter(cf => cf.IsPaveAdresse == '1' || cf.IsPaveAdresse == 'true').length > 0) {
        // Option Alliage 
        if (this.Campagne.Alliage && this.logo) {
          completedSteps.push(6);
        }
        // Option Adresse de Retour 
        if (!this.Campagne.Alliage && !(!this.Campagne.IdAdresse || this.Campagne.IdAdresse == "0")) {
          completedSteps.push(6);
        }
      }
    } else {
      if(this.CampagneExt.chiliCreationStatus === 'VALIDEE') {
        completedSteps.push(6);
      }
    }

    // Update completed steps
    this.CampagneExt.completedSteps = completedSteps;
    this.completedStepsEvt.emit(this.CampagneExt.completedSteps)
  }

  getNbFichiersCreation() {
    return this.CampagneExt.FichiersCreation.filter(
      (fichier) => fichier.FichierCreation === '1'
    ).length;
  }

  getNbFichiersPersonnalisation() {
    return this.CampagneExt.FichiersCreation.filter(
      (fichier) => fichier.FichierCreation === '0'
    ).length;
  }

  getNbFichiersLogo() {
    return this.CampagneExt.FichiersCreation.filter(
      (fichier) => fichier.FichierCreation === '2'
    ).length;
  }

  onComponentDestroy() {}

  getEstimationRequest(): EstimationRequest {
    let request: EstimationRequest = new EstimationRequest();
    if (this.CampagneExt.Produit) {
      request.custAccNumber = this.Annonceur.NumeroClient;
      request.contractNumber = this.Annonceur.NumeroContrat;
      request.quantity = this.Campagne.volume;

      request.criteria = new Criteria();
      request.criteria.item_type = this.CampagneExt.Produit
        .CodeTypologie as string;
      request.criteria.folding = this.CampagneExt.Produit.CodeSupport as string;
      request.criteria.printFormatValue = this.CampagneExt.Produit
        .CodeDimensionsSupport as string;
      request.criteria.paperGrammageCode = this.CampagneExt.Produit
        .CodeGrammageSupport as string;
      request.criteria.glossLevel = this.CampagneExt.Produit
        .CodeFinitionSupport as string;
      request.criteria.printingInkType = this.CampagneExt.Produit
        .CodeEncreSupport as string;
      request.criteria.windowEnvelope = this.CampagneExt.Produit
        .CodeEnveloppe as string;
      request.criteria.envelopType = this.CampagneExt.Produit
        .CodeDimensionsEnveloppe as string;
      request.criteria.envelope_paperGrammage = this.CampagneExt.Produit
        .CodeGrammageEnveloppe as string;
      request.criteria.envelope_glossLevel = this.CampagneExt.Produit
        .CodeFinitionEnveloppe as string;
      request.criteria.envelope_printingInkType = this.CampagneExt.Produit
        .CodeEncreEnveloppe as string;
      request.criteria.deliveryPriority = this.getLibelleProduit(
        'Vitesse',
        this.Campagne.CodeVitesse,
        'CodeVitesse'
      );
      request.criteria.campaignProductionMode = this.CampagneExt.Produit
        .CodeModeDeProduction as string;

      return request;
    } else return null;
  }

  getVitessesProduit(): Array<string> {
    let listeProduits: any;
    let VitesssProduit = new Array<string>();
    if (this.Campagne.CodeEnveloppe) {
      listeProduits = this.produits.filter(
        (element: Produit) =>
          element.CodeTypologie == this.Campagne.CodeTypologie &&
          element.CodeSupport == this.Campagne.CodeSupport &&
          element.CodeDimensionsSupport ==
            this.Campagne.CodeDimensionsSupport &&
          element.CodeGrammageSupport == this.Campagne.CodeGrammageSupport &&
          element.CodeFinitionSupport == this.Campagne.CodeFinitionSupport &&
          element.CodeEncreSupport == this.Campagne.CodeEncreSupport &&
          element.CodeEnveloppe == this.Campagne.CodeEnveloppe &&
          element.CodeDimensionsEnveloppe ==
            this.Campagne.CodeDimensionsEnveloppe &&
          element.CodeGrammageEnveloppe ==
            this.Campagne.CodeGrammageEnveloppe &&
          element.CodeFinitionEnveloppe ==
            this.Campagne.CodeFinitionEnveloppe &&
          element.CodeEncreEnveloppe == this.Campagne.CodeEncreEnveloppe
      );
    } else {
      listeProduits = this.produits.filter(
        (element: Produit) =>
          element.CodeTypologie == this.Campagne.CodeTypologie &&
          element.CodeSupport == this.Campagne.CodeSupport &&
          element.CodeDimensionsSupport ==
            this.Campagne.CodeDimensionsSupport &&
          element.CodeGrammageSupport == this.Campagne.CodeGrammageSupport &&
          element.CodeFinitionSupport == this.Campagne.CodeFinitionSupport &&
          element.CodeEncreSupport == this.Campagne.CodeEncreSupport
      );
    }

    listeProduits.forEach((p: any) => {
      VitesssProduit.push(p.Vitesse);
    });
    return VitesssProduit;
  }

  // Vérifie si le produit de la campagne à dupliquer existe dans le nouveau catalogue
  checkCampagneProduit(campagne: Campagne): boolean {
    let listeProduits: Produit[];

    const productFound = !!this.produits.filter(
      (element: Produit) => element.IdProduit == campagne.IdProduit
    ).length;
    if (!productFound) {
      return false;
    }

    if (campagne.CodeEnveloppe) {
      listeProduits = this.produits.filter(
        (element: Produit) =>
          element.CodeTypologie == campagne.CodeTypologie &&
          element.CodeSupport == campagne.CodeSupport &&
          element.CodeDimensionsSupport == campagne.CodeDimensionsSupport &&
          element.CodeGrammageSupport == campagne.CodeGrammageSupport &&
          element.CodeFinitionSupport == campagne.CodeFinitionSupport &&
          element.CodeEncreSupport == campagne.CodeEncreSupport &&
          element.CodeEnveloppe == campagne.CodeEnveloppe &&
          element.CodeDimensionsEnveloppe == campagne.CodeDimensionsEnveloppe &&
          element.CodeGrammageEnveloppe == campagne.CodeGrammageEnveloppe &&
          element.CodeFinitionEnveloppe == campagne.CodeFinitionEnveloppe &&
          element.CodeEncreEnveloppe == campagne.CodeEncreEnveloppe
      );
    } else {
      listeProduits = this.produits.filter(
        (element: Produit) =>
          element.CodeTypologie == campagne.CodeTypologie &&
          element.CodeSupport == campagne.CodeSupport &&
          element.CodeDimensionsSupport == campagne.CodeDimensionsSupport &&
          element.CodeGrammageSupport == campagne.CodeGrammageSupport &&
          element.CodeFinitionSupport == campagne.CodeFinitionSupport &&
          element.CodeEncreSupport == campagne.CodeEncreSupport
      );
    }
    if (listeProduits.length >= 1) return true;

    return false;
  }

  // Récupérer le produit du nouveau catalogue
  getCampagneProduit(campagne: Campagne): Produit {
    let listeProduits: Array<Produit>;

    if (campagne.CodeEnveloppe) {
      listeProduits = this.produits.filter(
        (element: Produit) =>
          element.CodeTypologie == campagne.CodeTypologie &&
          element.CodeSupport == campagne.CodeSupport &&
          element.CodeDimensionsSupport == campagne.CodeDimensionsSupport &&
          element.CodeGrammageSupport == campagne.CodeGrammageSupport &&
          element.CodeFinitionSupport == campagne.CodeFinitionSupport &&
          element.CodeEncreSupport == campagne.CodeEncreSupport &&
          element.CodeEnveloppe == campagne.CodeEnveloppe &&
          element.CodeDimensionsEnveloppe == campagne.CodeDimensionsEnveloppe &&
          element.CodeGrammageEnveloppe == campagne.CodeGrammageEnveloppe &&
          element.CodeFinitionEnveloppe == campagne.CodeFinitionEnveloppe &&
          element.CodeEncreEnveloppe == campagne.CodeEncreEnveloppe
      );
    } else {
      listeProduits = this.produits.filter(
        (element: Produit) =>
          element.CodeTypologie == campagne.CodeTypologie &&
          element.CodeSupport == campagne.CodeSupport &&
          element.CodeDimensionsSupport == campagne.CodeDimensionsSupport &&
          element.CodeGrammageSupport == campagne.CodeGrammageSupport &&
          element.CodeFinitionSupport == campagne.CodeFinitionSupport &&
          element.CodeEncreSupport == campagne.CodeEncreSupport
      );
    }
    if (listeProduits.length >= 1) return listeProduits[0];

    return <Produit>{};
  }

  isEmpty(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0;
  }

  saveFusionFields() {
    // Fusion field save
    let url!: string;
    switch (this.CONTEXT) {
      case 'ELOQUA': {
        url = environment.url_api + '/champ-fusion';
        break;
      }
      case 'SALESFORCE': {
        url = environment.url_api + '/salesforce/champ-fusion';
        break;
      }
      case 'STANDALONE': {
        url = environment.url_api + '/standalone/champ-fusion';
        break;
      }
    }

    return this.http.post<any>(
      url + "/upsert",
      {
        request: {
          IdCampagne: this.Campagne.IdCampagne,
          ChampsFusion: this.CampagneExt.ChampsFusion,
        },
      },
      {
        params: this.objectParams,
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.getJwtoken()}`,
        }),
      }
    )
  }

  saveCampaign(campagne: Campagne) {
    // Save campaign
    var RequestCampagne = Object.assign({}, campagne);
    delete RequestCampagne.FichiersCreation;
    delete RequestCampagne.ChampsFusion;
    delete RequestCampagne.Produit;
    delete RequestCampagne.Adresse;

    if (!this.objectParams?.['AssetId'])
      this.objectParams = Object.assign({}, this.objectParams, {
        AssetId: '0000',
      });

    let url: string = environment.url_api;
    switch (this.CONTEXT) {
      case 'ELOQUA': {
        url += '/campagne/' + RequestCampagne.IdCampagne;
        break;
      }
      case 'STANDALONE': {
        url += '/standalone/campagne/' + RequestCampagne.IdCampagne;
        break;
      }
      case 'SALESFORCE': {
        if (this.getinteractionIdSF())
          this.objectParams = Object.assign({}, this.objectParams, {
            InteractionId: this.getinteractionIdSF(),
          });

        url += '/salesforce/campagne/' + RequestCampagne.IdCampagne;
        break;
      }
    }

    return this.http.put<Campagne[]>(
      url,
      { request: RequestCampagne },
      {
        params: this.objectParams,
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.getJwtoken()}`,
        }),
      }
    );
  }

  saveAll(campagne: Campagne, saveFusion: Boolean) {
    if (saveFusion) return this.saveFusionFields();
    return this.saveCampaign(campagne);
  }

  public initialise() {
    this.Campagne = new Campagne();
    if (typeof this.Campagne.EmailNotification == 'undefined') {
      this.Campagne.EmailNotification = '';
    }

    this.CampagneExt = new CampagneExt();
    this.CampagneExt.Produit = {};
    this.CampagneExt.VitessesProduit = new Array<string>();
    this.CampagneExt.Adresse = new AdresseRetour(this.Annonceur);
    this.CampagneExt.FichiersCreation = [];
    this.CampagneExt.ChampsFusion = [];
    this.CampagneExt.FichiersCreationsURL = {};
    this.CampagneExt.FichiersCreationsType = {};
    this.CampagneExt.NbFichiersCreation = 0;
    this.CampagneExt.NbFichiersPersonnalisation = 0;
    this.CampagneExt.NbFichiersLogo = 0;

    this.CampagneExt.VitessesProduit = ['Classique', 'Rapide'];

    this.Campagne.volume = '';
    this.Campagne.unitAmountWithoutTax = '';
    this.Campagne.amountWithoutTax = '';

    this.Campagne.showInfo = false;
    this.Campagne.showRefresh = false;
    this.Campagne.showunitAmountWithoutTax = false;
    this.Campagne.showamountWithoutTax = false;
    this.Campagne.OptionRetour = '';
    this.checkStepCompletion();
  }
}
