import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import {
  ActivatedRoute,
  NavigationBehaviorOptions,
  Router,
} from '@angular/router';
import { Campagne } from '@core/models/campagne.model';
import { navPaths } from '@core/models/support.model';
import {
  AdressesDestinatairesService,
  AnnonceurService,
  CampagneTypologieService,
  GlobalDataService,
  PaoService,
  PersonalisationService,
} from '@core/services';
import { CampagneService } from '@core/services/campagne.service';
import { EstimationService } from '@core/services/estimation.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { environment as env } from 'environments/environment';
import { NavigateUtil } from '@shared/utils/navigate.util';

@Component({
  selector: 'app-campagne-details',
  templateUrl: './campagne-details.component.html',
  styleUrls: ['./campagne-details.component.scss'],
})
export class CampagneDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  titre = 'Conception de la campagne courrier';
  Campagne!: Campagne;
  isLoaded = false;
  isLinear: boolean = true;
  isEditable = true;
  MAX_STEP = 7;
  showButtonRetour = false;

  selectedIndex: number = 0;

  step: number = 0;
  page: number = 0;

  @ViewChild(MatStepper) stepper!: MatStepper;

  navigationSubscription: Subscription;
  activeRouteSubscription: Subscription;

  navigationOption: NavigationBehaviorOptions = { state: {} };

  campaignName: string;

  // Chili
  private SAVE_MESSAGE = { save: true };
  private targetOrigin = env.targetOriginChili;

  private destroy$ = new Subject<void>();

  constructor(
    public _GlobalDataService: GlobalDataService,
    private router: Router,
    public _AnnonceurService: AnnonceurService,
    private activeRoute: ActivatedRoute,
    private _CampagneService: CampagneService,
    private readonly activatedRoute: ActivatedRoute,
    private _CampagneTypologieService: CampagneTypologieService,
    private _EstimationService: EstimationService,
    private _PaoService: PaoService,
    private elementRef: ElementRef,
    private _PersonalisationService: PersonalisationService,
    private _AdressesDestinatairesService: AdressesDestinatairesService
  ) {
    this.activeRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this._GlobalDataService.objectParams = params; // Print the parameter to the console.
        console.debug(this._GlobalDataService.objectParams);
        console.debug('CONTEXT ', this._GlobalDataService.CONTEXT);
      });
  }

  public ngOnInit() {
    //init pas de change!
    //identification campagne
    this._GlobalDataService.referenceDocHelp = '#new_campaign_step1';
    this._GlobalDataService.stepper = this.stepper;
    this.loadChiliCreationStatus();

    // Update stepper completion
    this._GlobalDataService.completedStepsEvt
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.changeStepperStyle();
      });

    // Go to top
    NavigateUtil.goToTopSideNavContent(this.elementRef);

    this.isLoaded = true;
  }

  public ngAfterViewInit() {
    if (this.stepper) {
      this.stepper._getIndicatorType = () => 'number';
      this._GlobalDataService.checkStepCompletion();
    }
  }

  public ngOnDestroy() {
    this._GlobalDataService.isIframeChiliLoaded = false;

    // Unsubscribe
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigatetoErrorPage(error: any) {
    //une erreur est survenue => redirection page erreur
    console.log('CampagneDetailsComponent : ErrorCurrentCampagne', error);
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams,
      queryParamsHandling: 'merge',
    });
    this.isLoaded = true;
  }

  showTooltip(event: any) {
    console.log(event);
  }

  SuccessCurrentCampagne(campagne) {
    if (typeof campagne === 'object') {
      const NomCampagne = this._GlobalDataService.Campagne.NomCampagne;
      const Description = this._GlobalDataService.Campagne.Description;
      const BonDeCommande = this._GlobalDataService.Campagne.BonDeCommande;

      //affecter la campagne reçu

      this._GlobalDataService.Campagne = campagne;

      this._GlobalDataService.Campagne.NomCampagne = NomCampagne;
      this._GlobalDataService.Campagne.Description = Description;
      this._GlobalDataService.Campagne.BonDeCommande = BonDeCommande;

      if (
        typeof this._GlobalDataService.Campagne.EmailNotification == 'undefined'
      )
        this._GlobalDataService.Campagne.EmailNotification = '';

      console.log('current Campagne : ', this._GlobalDataService.Campagne);

      console.log(
        'Id Campagne : ',
        this._GlobalDataService.Campagne.IdCampagne
      );

      console.log('campagne.StatutCampagne', campagne.StatutCampagne);

      console.log('campagne.CodeStatut', campagne.CodeStatut);
    }
  }

  public changeStepperStyle() {
    this.elementRef.nativeElement
      .querySelectorAll('mat-step-header')
      .forEach((item: { classList: { remove: (arg0: string) => void } }) => {
        item.classList.remove('step-completed');
      });

    this._GlobalDataService.CampagneExt.completedSteps.forEach((stepIndex) => {
      let elem = this.elementRef.nativeElement.querySelector(
        `mat-step-header[aria-posinset="${stepIndex}"]`
      );
      if (!elem) return;
      elem.classList.add('step-completed');
      elem.nextSibling.nextSibling.classList.add('step-completed');
    });
  }

  
  onClickMatStepper(evt: MouseEvent) {
    if (!this._GlobalDataService.isIframeChiliLoaded) return; 

    let index = null;

    if (evt?.target) {
      const target = evt.target as HTMLElement;

      // Find parent mat-stepper
      const clickPosX = evt.offsetX;

      // Find children
      index = this.findMatHorizontalStepIndex(target, clickPosX);   
    }

    if (index == 2 || index == null) return;

    // Check close chili and save creation by send postMessage
    console.log("Request on quitting Chili Creation")
    
    this._PaoService.quitChili((isSaved: boolean) => {
      this._GlobalDataService.isIframeChiliLoaded = false;
      if (isSaved) {
        this._GlobalDataService.isIframeChiliLoaded = false;
        console.log('Confirm on saving Chili Creation.');

        // Send postMessage
        try {
          const iframeChili = this.elementRef.nativeElement.querySelector('#iframe');
          console.log(iframeChili);
        
          if (iframeChili) {
            iframeChili.contentWindow.postMessage(
              this.SAVE_MESSAGE,
              this.targetOrigin
            );
          } else {
            console.error("iframe not found");
          }
        } catch (error) {
          console.error("Error on sending Saving Chili Creation postMessage", error);
        }

        // Go to step
        this.stepper.selectedIndex = index;
      }
    });
  }

  private findMatHorizontalStepIndex(
    target: HTMLElement, 
    clickPosX: number
  ): number | null {
    let incX = 0;
    let matStepCount = 0;
    for (let i = 0; i < target.children.length; i++) {
      const child: HTMLElement = target.children[i] as HTMLElement;
      // Count Mat-Step
      if (child.tagName === 'MAT-STEP-HEADER') {
        matStepCount++;
      }

      const from = incX;
      const computedStyle = window.getComputedStyle(child);
      const marginX =
        parseInt(computedStyle.marginLeft) +
        parseInt(computedStyle.marginRight);
      const to = incX + child.offsetWidth + marginX;
      // console.log('  (' + i + ') From : ' + from + ' | To : ' + to);

      if (clickPosX >= from && clickPosX <= to) {
        if (child.tagName !== 'MAT-STEP-HEADER') {
          // Exit without action
          return null;
        }
        return matStepCount - 1;
      }

      // Next element
      incX = to;
    }
    return null;
  }

  selectionChange(event: any) {
    console.log(event);

    // Load Chili
    if (event.previouslySelectedIndex !== 3) {
      this.loadChiliCreationStatus();
    }



    console.debug(event);

    // ATA@20230403 - Security enhancements
    if (
      this._GlobalDataService.isTokenExpired(
        this._GlobalDataService.getJwtoken()
      )
    ) {
      this._GlobalDataService.refreshToken();
    }

    if (this._GlobalDataService.Campagne.NomCampagne === '') {
      this._GlobalDataService.alert(
        'Le nom de la campagne est obligatoire et doit-être unique. Merci de renseigner un nouveau nom de campagne'
      );
      return;
    } else {
      if (
        !this._GlobalDataService.Campagne.IdCampagne &&
        this._GlobalDataService.isStandaloneContext()
      ) {
        this._CampagneService
          .getCurrentCampagne()
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res) => this.SuccessCurrentCampagne(res.campagne),
            error: (c) => console.log((error) => console.log(error)),
          });
      }
    }

    // TODO: Useless?
    this.selectedIndex = event.selectedIndex;

    //call API
    if (this._GlobalDataService.Campagne.isDirty) {
      this._CampagneService
        .put(this._GlobalDataService.Campagne)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this._GlobalDataService.checkStepCompletion();
            this._GlobalDataService.Campagne.isDirty = false;
          },
          (error) => {
            console.log(error.error.message);
            this._GlobalDataService.openSnackBarInfo(error.error.message);
          }
        );
    }

    // Personalisation Champs Fusion automatic save in stepper
    if (
      this._GlobalDataService.CONTEXT === 'ELOQUA' &&
      this._GlobalDataService.stepIndex === 3
    ) {
      this._PersonalisationService.setChampFusionType();
      this._GlobalDataService.CampagneExt.ChampsFusion =
        this._PersonalisationService.selectedChampsFusion.concat(
          this._AdressesDestinatairesService.selectedChampsFusion
        );
      this._GlobalDataService
        .saveAll(this._GlobalDataService.Campagne, true)
        .subscribe({
          complete: () => {},
          error: (e) => {
            console.log(e.error.message);
            this._GlobalDataService.openSnackBarInfo(e.error.message);
          },
        });
    }

    this._GlobalDataService.stepIndex = event.selectedIndex;

    if (this._GlobalDataService.Campagne.volume) {
      this._GlobalDataService.formEstimate
        .get('volume')
        .setValue(this._GlobalDataService.Campagne.volume, {
          emitEvent: false,
        });
    }

    // Fil d'Ariane
    this._GlobalDataService.checkStepCompletion();

    switch (event.selectedIndex) {
      case 0:
        //identification campagne
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step1';
        break;
      case 1:
        // Typologie
        this._GlobalDataService.Campagne.VisualisationStep2 = true;
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step3';

        if (this._GlobalDataService.Campagne.CodeTypologie != '') {
          this._CampagneTypologieService.initDataSupport(
            this._GlobalDataService.Campagne
          );
          this._CampagneTypologieService.navigate(
            navPaths.selection,
            this.activatedRoute,
            this.navigationOption
          );
        } else {
          this._CampagneTypologieService.navigate(
            navPaths.format,
            this.activatedRoute,
            this.navigationOption
          );
        }

        break;
      case 2:
        // Créations
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step6';
        break;
      case 3:
        // Personnalisation
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step6';
        break;
      case 4:
        // Planification
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step7';
        break;
      case 5:
        // Adresses
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step8';
        break;
      case 6:
        //Récapitulatif
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step10';
        if (this._GlobalDataService.Campagne.volume) {
          this.estimate();
        }
        break;
      default:
        //identification campagne
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step1';
        break;
    }
  }

  /**
   * Estimate price.
   * Product, delivery priority and volume are necessary to estimate.
   */
  estimate() {
    if (!this._GlobalDataService.CampagneExt.Produit) {
      this._GlobalDataService.alert(
        "Veuillez renseigner la typologie souhaitée afin d'utiliser l'estimation tarifaire."
      );
      return;
    }
    if (!this._GlobalDataService.Campagne.CodeVitesse) {
      this._GlobalDataService.alert(
        "Veuillez renseigner le type de vitesse souhaitée afin d'utiliser l'estimation tarifaire."
      );
      return;
    }
    if (!this._GlobalDataService.Campagne.volume) {
      this._GlobalDataService.alert(
        "Veuillez renseigner le volume souhaitée afin d'utiliser l'estimation tarifaire."
      );
      return;
    }
    let request = this._GlobalDataService.getEstimationRequest();
    if (request) {
      this._EstimationService
        .put(request)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => this.estimateRresponseHandler(res),
          error: (err) => this.estimateErrorHandler(err),
        });
    }
  }

  estimateRresponseHandler(response: any) {
    console.log(response);
    if (
      typeof response.response === 'object' &&
      response.response.status === 'SUCCESS'
    ) {
      this._GlobalDataService.Campagne.unitAmountWithoutTax =
        response.chargeList.length > 0
          ? response.chargeList[0].unitAmountWithoutTax
          : '';
      this._GlobalDataService.Campagne.amountWithoutTax =
        response.chargeList.length > 0
          ? response.chargeList[0].amountWithoutTax
          : '';
      this._GlobalDataService.Campagne.showInfo = true;
      this._GlobalDataService.Campagne.showRefresh = true;
      this._GlobalDataService.Campagne.showunitAmountWithoutTax = true;
      this._GlobalDataService.Campagne.showamountWithoutTax = true;
    } else if (response.status === 500) {
      this._GlobalDataService.alert(
        "Le serveur d'estimation tarifaire est indisponible."
      );
    } else {
      this._GlobalDataService.Campagne.unitAmountWithoutTax = '';
      this._GlobalDataService.Campagne.amountWithoutTax = '';
      this._GlobalDataService.alert(
        "Une erreur est survenue . Merci de relancer l'estimation tarifaire.",
        response.response.errorMessage
      );
    }
  }

  estimateErrorHandler(error: any) {
    console.log('errorHandler :' + error);
  }

  public campaignNameChange(name: string): void {
    this.campaignName = name;
  }

  private loadChiliCreationStatus() {
    if (this._GlobalDataService.Campagne.creationType !== 'chili') {
      return;
    }
    const idCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._PaoService
      .getStatus(idCampagne)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this._GlobalDataService.CampagneExt.chiliCreationStatus = res.status;
          this._GlobalDataService.checkStepCompletion();
        },
      });
  }
}
