import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-enveloppe-finition-card',
  templateUrl: './enveloppe-finition-card.component.html',
  styleUrls: ['./enveloppe-finition-card.component.scss']
})
export class EnveloppeFinitionCardComponent implements OnInit {

  navigationOption = {};

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {
    this.navigationOption = { typologie: 'enveloppe' };
  }

  ngOnInit(): void {
    // Finition
    this._CampagneTypologieService.dataFinitionsEnveloppes = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );
    this._CampagneTypologieService.dataFinitionsEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataFinitionsEnveloppes, 'CodeFinitionEnveloppe');

    if (this._CampagneTypologieService.dataFinitionsEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeFinitionEnveloppe = this._CampagneTypologieService.dataFinitionsEnveloppes[0].CodeFinitionEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeFinitionEnveloppe = "";
    }    
  }

  ngAfterViewInit() {
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeFinitionEnveloppe')
        .valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneTypologieService.EnveloppeFormGroup.get('CodeFinitionEnveloppe').valid) {

        console.log('CodeFinitionEnveloppe value changed')
        console.log(this._CampagneTypologieService.EnveloppeFormGroup.get('CodeFinitionEnveloppe').value);
       
        setTimeout(() => {
          console.log(this._CampagneTypologieService.EnveloppeFormGroup.value)
        })

        // update model
        this._GlobalDataService.Campagne.CodeFinitionEnveloppe = value;
      }
    });
  }


  Valider() {
    if (!this._GlobalDataService.Campagne.CodeFinitionEnveloppe) {
      this._GlobalDataService.alert("Veuillez sélectionner une Finition");
      return;
    }    
    this.finitionChanged();
    this._CampagneTypologieService.navigate(navPaths.encre, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  finitionChanged(): void {
    // Encre
    this._CampagneTypologieService.dataEncresEnveloppes = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );

    if (this._CampagneTypologieService.dataEncresEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeEncreEnveloppe = this._CampagneTypologieService.dataEncresEnveloppes[0].CodeEncreEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeEncreEnveloppe = "";
    }

    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEncreEnveloppe').patchValue(this._GlobalDataService.Campagne.CodeEncreEnveloppe, { emitEvent: false });

  }
}
