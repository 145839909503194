import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdressesDestinatairesService, GlobalDataService, PaoService, PersonalisationService } from '@core/services';
import { MatStepper } from '@angular/material/stepper';
import { CampagneService } from '@core/services/campagne.service';
import { FichierPersoStandaloneService } from '@core/services/fichier-perso-standalone.service';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { error } from 'console';

@Component({
  selector: 'app-campagne-standalone-ciblage',
  templateUrl: './campagne-standalone-ciblage.component.html',
  styleUrls: ['./campagne-standalone-ciblage.component.scss']
})
export class CampagneStandaloneCiblageComponent implements OnInit {

  isUploading: boolean = false;
  isDeleted: boolean = false;
  isDeleting: boolean = false;

  csvFileName: string;
  csvHeaders: string[] = undefined;
  csvRows: any[];

  constructor(
    private _CampagneService: CampagneService,
    public _GlobalDataService: GlobalDataService,
    private _FichierPersoStandalone: FichierPersoStandaloneService,
    private _ChampsFusionService: ChampsFusionService,
    public personalisationService: PersonalisationService,
    public adressesDestinatairesService: AdressesDestinatairesService,
    public _paoService: PaoService,
  ) { }

  ngOnInit(): void {
    if (this._GlobalDataService.Campagne.importFile) {
      this.getFile(true);
    }
  }

  public uploadFile(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.isUploading = true;
    this.isDeleted = false;
    const segmentFileCSV = target.files[0];
    const IdCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._FichierPersoStandalone.upload(segmentFileCSV, IdCampagne).subscribe({
      next: (_) => {
        this.isUploading = false;
        this.csvFileName = segmentFileCSV.name;
        this.getFile(false);

        // Update campagne
        this._GlobalDataService.Campagne.importFile = segmentFileCSV.name;
        this.updateCampagne()
      },
      error: (e) => {
        this.isUploading = false;
        console.log(e);
        this._GlobalDataService.alert("Une erreur technique s'est produite.");
      },
    });
  }

  public getFile(isInit: boolean) {
    const IdCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._FichierPersoStandalone.readContact(IdCampagne).subscribe({
      next: (response) => {
        console.log('    ***', response);
        this.csvFileName = response['fileName'];
        console.log('csvFileName', this.csvFileName);
        console.log('data', response['data']);
        this.readCsv(response['data']);
      },
      error: (e) => {
        console.log(e);
        if (!isInit) {
          this._GlobalDataService.alert(
            "Une erreur techniques s'est produite."
          );
          this.isDeleted = false;
        }
      },
    });
  }

  private updateCampagne() {
    this._CampagneService.put(this._GlobalDataService.Campagne).subscribe({
      next: () => { },
      error: () => { },
    });
  }

  public addFile() {
    const inputFile = this.getInputFile();
    if (inputFile) {
      inputFile.click();
    }
  }

  private getInputFile(): HTMLInputElement {
    return document.getElementById(
      'file-upload-perso-standalone'
    ) as HTMLInputElement;
  }

  private readCsv(data: Object) {
    if (!data) {
      return;
    }
    let line = 0;
    let headers = [];
    let rows = [];
    for (const rowIndex in data) {
      line++;
      if (line === 1) {
        headers = headers.concat(['N°'], data[rowIndex]);
      } else {
        let csvRow = {};
        csvRow['N°'] = rowIndex;
        console.log("headers length: ", headers.length);
        for (let i = 1; i < headers.length; i++) {
          csvRow[headers[i]] = data[rowIndex][i - 1];
        }
        rows.push(csvRow);
      }
    }
    this.csvHeaders = headers;
    this.csvRows = rows;

    console.log("headers", this.csvHeaders);
    console.log("rows: ", this.csvRows);
  }

  public deleteFile() {
    const onSuccess = () => {
      this.isDeleting = true;
      this.deleteFileCall();
    };

    this._GlobalDataService.confirm({
      titre: 'Supprimer?',
      message:
      'En supprimant votre fichier de ciblage vous perdez votre progression dans le \"Studio de création\".',
      BouttonConfirmation: true,
      onConfirm: onSuccess.bind(this),
      onCancel: () => console.log('onCancel'),
    });
  }


  private deleteFileCall() {
    const IdCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._FichierPersoStandalone.delete(IdCampagne).subscribe({
      next: (_) => {
        // Reinit values
        const inputFile = this.getInputFile();
        inputFile.value = '';
        this.csvFileName = undefined;
        this.csvHeaders = undefined;
        this.csvRows = undefined;
        this.isDeleted = true;

        // Update campagne
        this._GlobalDataService.Campagne.importFile = "";
        this.updateCampagne()

        this.isDeleting = false;

      },
      error: (e) => {
        console.log(e);
        this.isDeleting = false;
        this.isDeleted = false;
        this._GlobalDataService.alert("Une erreur technique s'est produite.");
      },
    });
  }
}
