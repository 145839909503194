export class Commande {
    IdCommande!: string;
    IdCampagne!: string;
    CodeStatut: string;
    IdStatut!: string;
    CreeParLogin!: string;
    DateCreation!: string;
    ModifieParLogin!: string;
    DateModification!: string;
    NCommandeLaPoste!: string;
    StatutCommande!: string;
    UrlBat!: string;
    LoginBAT!: string;
    MotDePasseBAT!: string;
    NombrePli!: string;
    RaisonDuStatut!: string;
    RaisonDuStatutSocrate!: string;
    NombrePliPND!: string;
    IsPurged:boolean;

    constructor() { }
  }
