import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-campagne-typologie-enveloppe',
  templateUrl: './campagne-typologie-enveloppe.component.html',
  styleUrls: ['./campagne-typologie-enveloppe.component.scss']
})
export class CampagneTypologieEnveloppeComponent implements OnInit {

  typologie: string = "enveloppe";
  navigationOption: NavigationBehaviorOptions = { state: {} };

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,    
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {
    if (this.router.getCurrentNavigation()!.extras.state) {
      const routeState = this.router.getCurrentNavigation()!.extras.state;
      if (routeState) {
        this.typologie = routeState['typologie'];
        console.log(this.typologie);
      }
    }
  }
  
  ngOnInit(): void {
  }

  gotoTypologieSelection(): void {
    console.log('gotoTypologieSelection');
    this._CampagneTypologieService.navigate(navPaths.selection, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }  

}
