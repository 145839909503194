import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogData } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-dialog-creation-option-change',
  templateUrl: './dialog-creation-option-change.component.html',
  styleUrls: ['./dialog-creation-option-change.component.scss']
})
export class DialogCreationOptionChangeComponent {

  onConfirm?;
  onCancel?;
  confirmMsg?: any;
  cancelMsg?: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _sanitizer: DomSanitizer
  ) {
    this.onConfirm = data.onConfirm || function () { };
    this.onCancel = data.onCancel || function () { };
  }


  trustHtml(htmlText: string) {
    return this._sanitizer.bypassSecurityTrustHtml(htmlText);
  }
  
}


