import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, OnDestroy, Type } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class PopoutService implements OnDestroy {

  POPOUT_MODALS: any = {};

  constructor(
      ) {
  }

  ngOnDestroy() { }

  openPopoutModal(url: string, target: string ) {
    const windowInstance = this.openOnce(
      url,
      target
    );

    // Wait for window instance to be created
    setTimeout(() => {
      console.log('new tab tab is openned');
      this.POPOUT_MODALS['windowInstance'] = windowInstance;
    }, 1000);
  }

  openOnce(url: string, target: string | undefined) {
    // Open a blank "target" window
    // or get the reference to the existing "target" window
    const winRef = window.open('', target, '');
    // If the "target" window was just opened, change its url
    if (winRef!.location.href === 'about:blank') {
      winRef!.location.href = url;
    }
    return winRef;
  }

  isPopoutWindowOpen() {
    return this.POPOUT_MODALS['windowInstance'] && !this.POPOUT_MODALS['windowInstance'].closed;
  }

  focusPopoutWindow() {
    this.POPOUT_MODALS['windowInstance'].focus();
  }

  closePopoutModal() {
    console.log('closePopoutModal');
    Object.keys(this.POPOUT_MODALS).forEach(() => {
      if (this.POPOUT_MODALS['windowInstance']) {
        this.POPOUT_MODALS['windowInstance'].close();
      }
    });
  }

}
