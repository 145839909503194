import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-enveloppe-dimension-card',
  templateUrl: './enveloppe-dimension-card.component.html',
  styleUrls: ['./enveloppe-dimension-card.component.scss']
})
export class EnveloppeDimensionCardComponent implements OnInit {

  navigationOption = {};

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {
    this.navigationOption = { typologie: 'enveloppe' };
  }

  ngOnInit(): void {
    // Filter products with selection
    this._CampagneTypologieService.dataDimensionsEnveloppes = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );

    // Keep CodeDimensionsEnveloppe
    this._CampagneTypologieService.dataDimensionsEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataDimensionsEnveloppes, 'CodeDimensionsEnveloppe');

    // Set value if only one
    if (this._CampagneTypologieService.dataDimensionsEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeDimensionsEnveloppe = this._CampagneTypologieService.dataDimensionsEnveloppes[0].CodeDimensionsEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeDimensionsEnveloppe = "";
    }
  }

  ngAfterViewInit() {
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeDimensionsEnveloppe')
      .valueChanges.subscribe((value: string) => {
        // if valid
        if (this._CampagneTypologieService.EnveloppeFormGroup.get('CodeDimensionsEnveloppe').valid) {

          console.log('CodeDimensionsEnveloppe value changed')
          console.log(this._CampagneTypologieService.EnveloppeFormGroup.get('CodeDimensionsEnveloppe').value);

          setTimeout(() => {
            console.log(this._CampagneTypologieService.EnveloppeFormGroup.value)
          })

          // update model
          this._GlobalDataService.Campagne.CodeDimensionsEnveloppe = value;
        }
      });
  }


  Valider() {
    if (!this._GlobalDataService.Campagne.CodeDimensionsEnveloppe) {
      this._GlobalDataService.alert("Veuillez sélectionner une Dimension");
      return;
    }

    this.dimensionChanged();
    this._CampagneTypologieService.navigate(navPaths.grammage, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  dimensionChanged(): void {

    this._GlobalDataService.Campagne.isDirty = true;

    // Grammage
    this._CampagneTypologieService.dataGrammagesEnveloppes = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );

    this._CampagneTypologieService.dataGrammagesEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataGrammagesEnveloppes, 'CodeGrammageEnveloppe');

    if (this._CampagneTypologieService.dataGrammagesEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeGrammageEnveloppe = this._CampagneTypologieService.dataGrammagesEnveloppes[0].CodeGrammageEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeGrammageEnveloppe = "";
    }

    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeGrammageEnveloppe').patchValue(this._GlobalDataService.Campagne.CodeGrammageEnveloppe, { emitEvent: false });
  }
}
