<mat-grid-list
  class="gtmt-stepper-nav"
  cols="2"
  rowHeight="8rem"
  [gutterSize]="'4rem'"
>
  <!-- Previous -->
  <mat-grid-tile class="gtmt-jc-end" colspan="1" rowspan="1">
    <button
      *ngIf="prevDisplay"
      mat-raised-button
      color="white"
      [disabled]="prevDisable"
      (click)="previousEvent()"
    >
      Etape précédente
    </button>
  </mat-grid-tile>

  <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
    <!-- Next -->
    <button
      *ngIf="nextDisplay"
      mat-raised-button
      color="yellow"
      [disabled]="nextDisable"
      (click)="nextEvent()"
    >
      Etape suivante
    </button>

    <!-- Validate -->
    <button
      *ngIf="validateDisplay"
      mat-raised-button
      color="blue"
      class="validate"
      [class.is-loading]="validateLoading"
      [disabled]="validateDisable"
      (click)="validateEvent()"
    >
      <span>Valider ma commande</span>
      <!-- Loader -->
      <mat-spinner *ngIf="validateLoading" diameter="24"></mat-spinner>
    </button>
  </mat-grid-tile>
</mat-grid-list>
