<mat-list>
    <mat-list-item>
        <span> Format </span>
        <span [mdePopoverTriggerFor]="appPopover" (mouseenter)="$event.stopPropagation()" style="cursor:pointer"> {{
            support.support }} </span>
    </mat-list-item>
    <mat-list-item>
        <span> Dimension </span>
        <span> {{ support.dimension }} </span>
    </mat-list-item>
    <mat-list-item>
        <span> Grammage </span>
        <span> {{ support.grammage }} </span>
    </mat-list-item>
    <mat-list-item>
        <span> Finition </span>
        <span> {{ support.finition }} </span>
    </mat-list-item>
</mat-list>

<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapAutoCaptureEnabled]="false">
    <mat-card class="gtmt-popover-card">
        <mat-card-header> Format
        </mat-card-header>
        <mat-card-content>
            <p>
                {{support.support}}
            </p>
        </mat-card-content>
    </mat-card>
</mde-popover>