import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ThumbnailChiliResponse,
  ThumbnailType,
} from '@core/models/thumbnail-chili-response.model';
import {
  CampagnePlanificationService,
  GlobalDataService,
  PaoService,
} from '@core/services';
import { lastValueFrom } from 'rxjs';
import { DialogChiliBatComponent } from './dialog-chili-bat/dialog-chili-bat.component';
import { Router } from '@angular/router';
import { DialogMessageComponent } from 'app/campagne/components';
import { CampagneService } from '@core/services/campagne.service';
import { StatusChiliResponse } from '@core/models/status-chili-response.model';

@Component({
  selector: 'app-chili-bat-thumbnail',
  templateUrl: './chili-bat-thumbnail.component.html',
  styleUrls: ['./chili-bat-thumbnail.component.scss'],
})
export class ChiliBatThumbnailComponent implements OnInit {
  public thumbnailBatSmall: string;
  public isLoadingStatus = false;

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _paoService: PaoService,
    public dialog: MatDialog,
    private _router: Router,
    private _CampagnePlanificationService: CampagnePlanificationService,
    private _CampagneService: CampagneService
  ) {}

  ngOnInit(): void {
    const idCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._paoService.getThumbnails(idCampagne).subscribe({
      next: (response) => {
        this.getBatThumbnailsFromResponse(response);
      },
      error: (e) => {
        console.log('Error getThumbnails(): ', e);
        this._GlobalDataService.alert(
          'Erreur lors du chargement des vignettes.'
        );
      },
    });
  }

  /*
   * Find first Thumbnails and use it on BAT
   */
  private getBatThumbnailsFromResponse(response: {
    thumbnails: Array<ThumbnailChiliResponse>;
  }): void {
    if (
      response.thumbnails.length === 0 ||
      response.thumbnails[0] === undefined
    ) {
      return;
    }

    const thumbnailsBat = response.thumbnails[0];
    this.thumbnailBatSmall = thumbnailsBat.urls.find(
      (u) => u.type === ThumbnailType.Small
    ).url;
  }

  /**
   * Open a popup window with the Chili BAT Iframe, check the creation status beforehand.
   */
  public async openChiliBAT(): Promise<void> {
    // Check Chili creation status
    this.isLoadingStatus = true;

    // Get last Creation Status
    const idCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._paoService.getStatus(idCampagne).subscribe({
      next: (res) => {
        this.isLoadingStatus = false;
        if (res?.status !== StatusChiliResponse.VALIDEE) {
          this._GlobalDataService.alert("La création Chili n'est pas validée.");
          return;
        }
        this.openDialog();
      },
      error: (err) => {
        this.isLoadingStatus = false;
        console.log(err);
        this._GlobalDataService.alert(
          'Chargement du statut de création Chili impossible.'
        );
      },
    });
  }

  private openDialog(): void {
    let dialogRef = this.dialog.open(DialogChiliBatComponent, {
      panelClass: 'chili-bat-dialog',
      data: {
        idCampagne: this._GlobalDataService.Campagne.IdCampagne,
      },
    });

    this.handleDialogClosed(dialogRef);
  }

  private async handleDialogClosed(
    dialogRef: MatDialogRef<DialogChiliBatComponent, any>
  ) {
    const campaignId = this._GlobalDataService.Campagne.IdCampagne;
    let statusCampaign: string;
    try {
      await lastValueFrom(dialogRef.afterClosed());
      let campaign: { status: string; message: string; campagne: any } =
        await lastValueFrom(this._CampagneService.get(campaignId));

      statusCampaign = campaign.campagne.CodeStatut;
    } catch (e) {
      console.log(e);
    }

    // BAT OK: update the campaign and refresh the page
    if (statusCampaign === 'ACTIVEE' || statusCampaign === 'ACTIVE_DATE_FIN') {
      const campaignId = this._GlobalDataService.Campagne.IdCampagne;
      let response: { status: string; message: string; campagne: any } =
        await lastValueFrom(this._CampagneService.get(campaignId));

      // Backend response campaign model is different from frontend campaign model, need to manually set missing fields
      // ex: creationType (front) <> CreationType (back)
      this.setMissingFieldsCampaign(response);

      this._GlobalDataService.Campagne = response.campagne;

      // Refresh the page
      let path: string = this.routerPath();
      let suivi: string = this.suiviPath();
      this._router.navigateByUrl(path).then(() => {
        this._router.navigate(
          [
            path,
            {
              outlets: {
                navview: [suivi, this._GlobalDataService.Campagne.IdCampagne],
              },
            },
          ],
          {
            queryParams: this._GlobalDataService.objectParams,
            queryParamsHandling: 'merge',
            skipLocationChange: true,
          }
        );
      });
    }

    // BAT KO: Redirection to campaign creation
    else if (statusCampaign === 'A_CORRIGER') {
      this.redirectionCreationDialog();
    } else {
    }
  }

  private redirectionCreationDialog() {
    let path: string;

    let doRedirection = () => {
      path = this.routerPath();
      this._GlobalDataService.isLoaded = true;
      this._CampagnePlanificationService.reset();
      this._router.navigate(
        [
          path,
          {
            outlets: {
              navview: [
                'campagne-details',
                this._GlobalDataService.Campagne.IdCampagne,
              ],
            },
          },
        ],
        {
          queryParams: this._GlobalDataService.objectParams,
          queryParamsHandling: 'merge',
          skipLocationChange: true,
        }
      );
    };

    this.dialog.open(DialogMessageComponent, {
      panelClass: 'laposte-msg-panel',
      data: {
        message: 'Veuillez modifier votre création sur le studio de création.',
        titre: 'Bon à tirer - Création Chili incomplète',
        BouttonConfirmation: false,
        onConfirm: doRedirection,
      },
    });
  }

  private routerPath(): string {
    let path: string;
    switch (this._GlobalDataService.CONTEXT) {
      case 'STANDALONE':
        path = '/standalone/home';
        break;

      case 'ELOQUA':
        path = '/eloqua';
        break;

      case 'SALESFORCE':
        path = '/salesforce';
        break;

      default:
        console.log('routerPath(): Unknown campaign context.');
    }
    return path;
  }

  private suiviPath(): string {
    if (this._GlobalDataService.Campagne.FrequenceEnvoi === 'Récurrente')
      return 'suivi-campagne-recurrente';

    if (this._GlobalDataService.Campagne.FrequenceEnvoi === 'Une seule fois')
      return 'suivi-campagne-une-seule-fois';

    return '';
  }

  private setMissingFieldsCampaign(response: any): void {
    this._GlobalDataService.Campagne.creationType =
      response.campagne.CreationType;
    this._GlobalDataService.Campagne.importFile = response.campagne.ImportFile;
    this._GlobalDataService.Campagne.importOption =
      response.campagne.ImportOption;
  }
}
