import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CampagnePlanificationService, GlobalDataService } from '@core/services';
import { StringifyOptions } from 'querystring';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-vitesse-distribution',
  templateUrl: './vitesse-distribution.component.html',
  styleUrls: ['./vitesse-distribution.component.scss']
})
export class VitesseDistributionComponent implements OnInit {

  vitesses = ['Classique', 'Rapide'];

  CodeVitesseSub: Subscription;

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _formBuilder: FormBuilder,
    private _datePipe: DatePipe,
    public _CampagnePlanificationService: CampagnePlanificationService,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.CodeVitesseSub = this._CampagnePlanificationService.form.get('CodeVitesse').valueChanges
      .subscribe((value: string) => {
        // if valid
        if (this._CampagnePlanificationService.form.get('CodeVitesse').valid) {
          // update model
          this._GlobalDataService.Campagne.CodeVitesse = value;
          this._GlobalDataService.Campagne.isDirty = true;
          this.init();
        }
      });
  }

  init() {
    console.log(this._GlobalDataService.Campagne.DateRemiseImprimeur);
    if (this._GlobalDataService.isValidDate(this._GlobalDataService.Campagne.DateRemiseImprimeur)) {
      // update model
      let dateTheorique = new Date(this._CampagnePlanificationService.form.get('DateRemiseImprimeur').value);
      if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
        dateTheorique.setDate(dateTheorique.getDate() + 12);
      } else {
        dateTheorique.setDate(dateTheorique.getDate() + 7);
      }
      this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = this._datePipe.transform(dateTheorique, 'yyyy-MM-dd HH:mm:ss');
      this._CampagnePlanificationService.form.get('DateTheoriqueDepotLaPoste').patchValue(this._datePipe.transform(dateTheorique, 'dd/MM/yyyy'),{ emitEvent: false });


      let dateRetourBAT = new Date();
      dateRetourBAT.setDate(dateTheorique.getDate() + 5);
      let DateEstimeeDistribution = new Date();
      if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
        DateEstimeeDistribution.setDate(dateRetourBAT.getDate() + 12);
      } else {
        DateEstimeeDistribution.setDate(dateRetourBAT.getDate() + 7);
      }
      this._CampagnePlanificationService.form.get('DateEstimeeDistribution').patchValue(this._datePipe.transform(DateEstimeeDistribution, 'dd/MM/yyyy'),{ emitEvent: false });

      console.log(this._GlobalDataService.Campagne.DateRemiseImprimeur);
    }
    else {
      this._GlobalDataService.Campagne.DateRemiseImprimeur = 'null'
      this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = 'null'
      this._CampagnePlanificationService.form.get('DateTheoriqueDepotLaPoste').patchValue(null,{ emitEvent: false });
      this._CampagnePlanificationService.form.get('DateEstimeeDistribution').patchValue(null,{ emitEvent: false });
    }
  } 

  // checkVitesse(vitesse: string) {
  //   return this._GlobalDataService.CampagneExt.VitessesProduit.indexOf(vitesse) > -1; 
  // }

  ngOnDestroy() {
    if (this.CodeVitesseSub) this.CodeVitesseSub.unsubscribe();
  }

}
