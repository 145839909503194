<div class="gtmt-chili-bat">
  <!-- Thumbnail -->
  <img [src]="thumbnailBatSmall" />

  <!-- Centered button -->
  <button
    mat-raised-button
    color="blue"
    (click)="openChiliBAT()"
    [disabled]="isLoadingStatus"
  >
    <span>Voir</span>
    <mat-spinner *ngIf="isLoadingStatus" diameter="20"></mat-spinner>
  </button>
</div>
