<mat-card class="gtmt-ar-container" [formGroup]="_campagneAdressesService.form">

  <mat-card-header>
    <mat-card-title>
      <h2>
        Adresse de retour expéditeur
      </h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <p>
      Veuillez sélectionner ou saisir une adresse de retour pour cette campagne courrier dans le cas ou le pli ne serait
      pas distribuable (PND)
    </p>

    <mat-grid-list cols="2" rowHeight="2rem" [gutterSize]="'2.5rem'" style="width:100%">

      <!-- Adresse de retour expéditeur -->

      <mat-grid-tile class="gtmt-ai-end" colspan="1" rowspan="3">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Sélectionner une adresse </mat-label>
          <input #adresseRetourRef ma matInput formControlName="nomAdresseRetour"
            placeholder="Rechercher une adresse de retour" type="text">
          <button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon svgIcon="Loupe"></mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile class="gtmt-ai-end" colspan="1" rowspan="3">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Adresse sélectionnée </mat-label>
          <input #adresseRetourRef ma matInput formControlName="NomAdresse" readonly type="text">
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="9">
        <div cdkDropList #filteredAvailableRecordsRef="cdkDropList" [cdkDropListData]="filteredAvailableRecords"
          class="gtmt-drop-list" cdkDragLockAxis="x" [cdkDropListConnectedTo]="[selectedRecordsRef]"
          (cdkDropListDropped)="drop($event)">
          <cdk-virtual-scroll-viewport appendOnly itemSize="60">
            <div class="gtmt-drop-box">
              <li>
                Créer une nouvelle adresse de retour
              </li>
              <button mat-raised-button color="blue" (click)="addAdresse()">Créer</button>
            </div>

            <div class="gtmt-drop-box" *ngFor="let record of filteredAvailableRecords" cdkDrag
            (click)="updateAdresse(record)"
            >
              <li [ngClass]="{
                'gtmt-notfavorite': record.Favori === '0',
                'gtmt-favorite': record.Favori === '1' }">
                {{record.NomAdresse}}
              </li>
              <button mat-raised-button color="blue" (click)="select(record)">Sélectionner</button>

              <div class="gtmt-drop-box" *cdkDragPreview [src]="record.NomAdresse" [alt]="record.NomAdresse">
                <li>
                  {{record.NomAdresse}}
                </li>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </mat-grid-tile>


      <mat-grid-tile colspan="1" rowspan="9">
        <div cdkDropList #selectedRecordsRef="cdkDropList"
          [cdkDropListData]="_GlobalDataService.CampagneExt.selectedAdresses" class="gtmt-drop-list"
          [cdkDropListSortingDisabled]="false" cdkDragLockAxis="x"
          [cdkDropListConnectedTo]="[filteredAvailableRecordsRef]" (cdkDropListDropped)="drop($event)">

          <div *ngFor="let record of _GlobalDataService.CampagneExt.selectedAdresses" cdkDrag>
            <app-adresse-retour-card [adresse]="record" (edit)="editAdresse(record)" (remove)="unselect(record)">
            </app-adresse-retour-card>
          </div>
        </div>
      </mat-grid-tile>

    </mat-grid-list>
  </mat-card-content>

  <mat-card-actions align="end">
    <mat-grid-list cols="2" rowHeight="4rem" [gutterSize]="'2.5rem'" style="width:100%">

      <mat-grid-tile colspan="1" rowspan="1">
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-raised-button color="blue" (click)="Vider()">
          Vider
        </button>
        <button mat-raised-button color="blue" (click)="ValiderSelection()">
          Valider la sélection
        </button>
      </mat-grid-tile>

    </mat-grid-list>
  </mat-card-actions>

</mat-card>