import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccountService, AnnonceurService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-profile-info-card',
  templateUrl: './profile-info-card.component.html',
  styleUrls: ['./profile-info-card.component.scss']
})
export class ProfileInfoCardComponent implements OnInit {

  constructor(public _GlobalDataService: GlobalDataService, private _AccountService: AccountService, private _AnnonceurService: AnnonceurService) { }

  userForm = new FormGroup({
    login: new FormControl(""),
    email: new FormControl(""),
    firstName: new FormControl(""),
    lastName: new FormControl("")
  });

  ngOnInit(): void {
    const login = this._GlobalDataService.getJWTLoginName();

    this._AccountService.getUserDetails(login)
    .subscribe(response => {
      if (!response.user) {
        console.error("No user received");
        return ;
      }

      this.userForm.get('login').setValue(response.user.login);
      this.userForm.get('email').setValue(response.user.email);
      this.userForm.get('firstName').setValue(response.user.firstName);
      this.userForm.get('lastName').setValue(response.user.lastName);
    });
  }

  saveUserChanges() {
    this._AccountService.saveUserDetails(this.userForm.value)
    .subscribe(response => {
      this._GlobalDataService.alert("Modifications du profil enregistrées.");
    }, err => {
      this._GlobalDataService.alert("Une erreur est survenue lors de la mise à jour de vos informations personnelles : " + JSON.stringify(err));
    });
  }
}
