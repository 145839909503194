<div class="admin-compte-client">
  <mat-toolbar class="page-title">
    <h1>Gestion annonceurs</h1>
  </mat-toolbar>

  <!-- Form Informations -->
  <form [formGroup]="form">
    <mat-card class="parameter-card">
      <mat-toolbar>
        <h2>Fiche annonceur</h2>
      </mat-toolbar>

      <mat-card-content>
        <!-- Update annonceur model -->
        <p class="modified">
          Dernière modification :
          {{
            _GlobalDataService.Annonceur.DateModification | date : "dd/MM/yyyy"
          }}
          par {{ _GlobalDataService.Annonceur.ModifieParLogin }}
        </p>

        <!-- Info Annonceur -->
        <mat-grid-list cols="2" rowHeight="2rem">
          <!-- Login -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Login</mat-label>
              <input type="text" matInput formControlName="login" />
            </mat-form-field>
          </mat-grid-tile>

          <!-- Nom du client* ANNONCEUR -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Nom de l'annonceur</mat-label>
              <input
                type="text"
                matInput
                formControlName="annonceurName"
                required
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- N° du client* ANNONCEUR -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>N° client</mat-label>
              <input
                type="text"
                matInput
                formControlName="NumeroClient"
                required
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- N° du contrat* -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>N° contrat</mat-label>
              <input
                type="text"
                matInput
                formControlName="NumeroContrat"
                required
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- N° Contrat Alliage -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>N° contrat Alliage</mat-label>
              <input
                type="text"
                matInput
                formControlName="NumeroContratAlliage"
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- Identifiant Alliage -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Identifiant Alliage</mat-label>
              <input
                type="text"
                matInput
                formControlName="IdentifiantAlliageGE"
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- SIRET* -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>SIRET</mat-label>
              <input type="text" matInput formControlName="Siret" required />
            </mat-form-field>
          </mat-grid-tile>

          <!-- Raison Sociale* -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Raison sociale</mat-label>
              <input
                type="text"
                matInput
                formControlName="RaisonSociale"
                required
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- Nom de l’utilisateur* -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Nom de l’utilisateur</mat-label>
              <input
                type="text"
                matInput
                formControlName="NomContact"
                required
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- Prénom de l’utilisateur* -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Prénom de l’utilisateur</mat-label>
              <input
                type="text"
                matInput
                formControlName="PrenomContact"
                required
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- Adresse e-mail* -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Adresse e-mail</mat-label>
              <input
                type="email"
                matInput
                formControlName="EmailContact"
                required
              />
            </mat-form-field>
          </mat-grid-tile>

          <!-- Téléphone -->
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Téléphone</mat-label>
              <input type="text" matInput formControlName="TelephoneContact" />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions>
        <button
          class="btn-actions"
          mat-raised-button
          color="primary"
          [disabled]="!form.valid"
          (click)="saveAnnonceur()"
        >
          Enregistrer
        </button>
      </mat-card-actions>
    </mat-card>
  </form>

  <!-- Form SFTP -->
  <form
    [formGroup]="form"
    *ngIf="_GlobalDataService.Annonceur['TypeAnnonceur'] == 'STANDALONE'"
  >
    <mat-card>
      <mat-toolbar>
        <h2>Informations SFTP</h2>
      </mat-toolbar>

      <mat-card-content>
        <mat-grid-list cols="2" rowHeight="2rem">
          <mat-grid-tile colspan="1" rowspan="6">
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>Login SFTP</mat-label>
              <input
                type="text"
                matInput
                formControlName="LoginSFTP"
                readonly
              />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions>
        <button
          class="btn-actions"
          mat-raised-button
          color="primary"
          [disabled]="isGeneratePasswordDisable()"
          (click)="generatePassword()"
        >
          <span>Générer MDP SFTP</span>
          <mat-spinner
            *ngIf="generatePasswordLoading"
            diameter="24"
          ></mat-spinner>
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
