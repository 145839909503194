import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class CommandeService {
  _url = environment.url_api + '/commande';
  _urlStandalone = environment.url_api + '/standalone/commande';
  _url_scheduler = environment.url_scheduler + '/commande';

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,
  ) { }

  /**
   * 
   * @param filtre exemple :
   * {
    "filter": [
        {
            "type": "text",
            "field": "StatutCampagne",
            "contains": ["Confirmée", ]
      },
      {
        "type": "date",
        "field": "DateCreation",
        "start": "2020-07-30 00:00:00",
        "end": "2020-08-19 00:00:00"
      }
    ]
  }
  */

  getListByIdCampagne(idCampagne: string) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/campagnelist/' + idCampagne;
        break;
      }
      case "STANDALONE": {
        url = this._urlStandalone + '/campagnelist/' + idCampagne;
        break;
      }
      default: {
        url = this._urlStandalone + '/campagnelist/' + idCampagne;
        break;
      }
    }
    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe((responseOrder) => {
          if (responseOrder && Array.isArray(responseOrder.commande)) {
            responseOrder.commande.forEach((order: { [x: string]: string | number; }) => {
              if (order["CodeStatut"])
                order["StatutCommandeLibelle"] = this._GlobalDataService.mappingStatut[order["CodeStatut"]];
            });
            subscriber.next(responseOrder);
          }
          else
            subscriber.next({ "commande": [] });
          subscriber.complete();
        }, err => { subscriber.error(err); });
    });
    return observable;
  }

  getAdminListByIdCampagne(idCampagne: string) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/admin-list/campagne/' + idCampagne;
    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe((responseOrder) => {
          if (responseOrder && Array.isArray(responseOrder.commande)) {
            responseOrder.commande.forEach((order: { [x: string]: string | number; }) => {
              if (order["CodeStatut"])
                order["StatutCommandeLibelle"] = this._GlobalDataService.mappingStatut[order["CodeStatut"]];
            });
            subscriber.next(responseOrder);
          }
          else
            subscriber.next({ "commande": [] });
          subscriber.complete();
        }, err => { subscriber.error(err); });
    });
    return observable;
  }

  demandeAnnulationCommande(IdCommande: string) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url_scheduler + '/' + IdCommande + '/cancel';
    return this._http.post<any>(url, {
      params: this._GlobalDataService.objectParams
    }, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });

  }
}
