<mat-grid-list cols="1" rowHeight="7rem">
  <mat-grid-tile rowspan="7">
    <ejs-accumulationchart #chartStatus *ngIf="campagneDataIsLoaded" [height]='height' [width]='width'
      id="chart-container" [legendSettings]='StatutlegendSettings' [title]='Statuttitle'
      [enableSmartLabels]='statusEnableSmartLabels' [enableBorderOnMouseMove]='statusEnableBorderOnMouseMove'
      (pointClick)='pointClick($event,"statusPie")' (loaded)='pieStatusLoaded($event)'>
      <e-accumulation-series-collection>

        <e-accumulation-series [dataLabel]='statusDatalabel' [dataSource]='Statutpiedata' xName='statut'
          yName='pourcentage' type='Pie' radius='80%' innerRadius='70%' [palettes]='paletteStatus'
          [animation]='statusAnimation' [border]='border'>
        </e-accumulation-series>
        
      </e-accumulation-series-collection>
    </ejs-accumulationchart>

    <div id="center-status-title" style="visibility: hidden; position: absolute;">{{centerStatusTitle}}</div>
  </mat-grid-tile>

  <!-- <mat-grid-tile rowspan="7">
    <ejs-accumulationchart #chartCourrier
                           *ngIf="courrierDataIsLoaded"
                           [height]='height'
                           [width]='width'
                           id="chart-container-2"
                           [legendSettings]='CourrierlegendSettings'
                           [title]='Courriertitle'
                           [enableSmartLabels]='courrierEnableSmartLabels'
                           [enableBorderOnMouseMove]='courrierEnableBorderOnMouseMove'
                           (pointClick)='pointClick($event,"courrierPie")'
                           (loaded)='pieCourrierLoaded($event)'>
      <e-accumulation-series-collection>
        <e-accumulation-series [dataLabel]='Courrierdatalabel'
                               [dataSource]='Courrierpiedata'
                               xName='statut'
                               yName='pourcentage'
                               type='Pie'
                               radius='80%'
                               innerRadius='70%'
                               [palettes]='paletteCourrier'
                               [animation]='courrierAnimation'>
        </e-accumulation-series>
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
    <div id="center-courrier-title"
         style="visibility: hidden; position: absolute;">{{centerCourrierTitle}}</div>
  </mat-grid-tile> -->
  <mat-grid-tile>
    <div id="legends">
      <div class="legend">
        <div *ngFor="let legend of statusLegend;let index = index;" (click)="filterStatusByStatus(index)">
          <span [ngStyle]="{'color': legend.color}">■</span>
          {{legend.name}}
        </div>
      </div>
    
      <!-- <div class="legend">
        <div>
          <div *ngFor="let legend of courrierLegend;let index = index;"
               (click)="filterCourrierByStatus(index)">
            <span [ngStyle]="{'color': legend.color}">■</span>
            {{legend.name}}
          </div>
        </div>
      </div> -->
    
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div id="Reinitialiser">
      <!-- <button  class="exportBtn btn btn-large btn-default"  (click)="vider()">Vider</button> -->
      <button mat-raised-button color="blue"  (click)="remplir()">Réinitialiser
        <mat-icon svgIcon="Refresh"></mat-icon>
      </button>      
    </div>
  </mat-grid-tile>

</mat-grid-list>








