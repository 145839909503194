import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { GlobalDataService } from './global-data.service';
import { Observable, catchError, map, of } from 'rxjs';
import { IframeChili } from '@core/models/iframe-chili.model';
import { ChiliGetCreationDetails } from '@core/models/chili-get-creation-details.model';
import { ThumbnailChiliResponse } from '@core/models/thumbnail-chili-response.model';

@Injectable({
  providedIn: 'root',
})
export class PaoService {
  private _url = environment.url_api + '/pao';
  private GET_THUMBNAILS_URL = '/get-thumbnails';
  private GET_STATUS_URL = '/get-status';

  constructor(
    private _http: HttpClient,
    private _GlobalDataService: GlobalDataService
  ) {}

  /**
   * Get the iframe for the campaign
   *
   * @param idCampaign
   */
  public getIframe(campaignId: string): Observable<HttpResponse<IframeChili>> {
    let url = this._url + '/get-iframe';
    return this._http.get<HttpResponse<IframeChili>>(url, {
      observe: 'response',
      params: {
        campaignId,
      },
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  /**
   * get authentication
   *
   * @param campaignId
   * @returns
   */
  getAuthentication(campaignId: string) {
    let url = this._url + '/check-autorisation';

    return this._http.get<any>(url, {
      params: {
        campaignId, 
        ...this._GlobalDataService.objectParams
      },
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  /**
   * Check close chili and call save()
   */
  quitChili(callback: (isSaved: boolean) => void) {
    // Save chili creation and close iframe
    const onSuccess = () => {
      callback(true);
    }

    // Go back to step cration
    const onCancel = () => {
      this.goToCreationStep();
      callback(false);
    }

    this._GlobalDataService.confirm({
      // titre: 'Attention !',
      message:
        'Attention vous quittez le Studio de création, sauvegarder votre progression.',
      BouttonConfirmation: true,
      confirmMsg: 'Sauvegarder et quitter le studio',
      onConfirm: onSuccess.bind(this),
      onCancel: onCancel.bind(this),
    });
  }

  goToCreationStep() {
    console.log('Go to Step Creation.');
    this._GlobalDataService.stepper.selectedIndex = 2;
  }

  /**
   * Call Backend API
   * @returns Chili creation's status
   */
  getStatus(idCampagne: string): Observable<{ status: 'VALIDEE' | 'BROUILLON' }> {
    let url = this._url + '/get-status';

    return this._http.get<{ status: 'VALIDEE' | 'BROUILLON' }>(url, {
      params: { campaignId: idCampagne },
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  /**
   * Call Backend API
   * @returns Chili creation details
   */
  getDetails(idCampagne: string): Observable<ChiliGetCreationDetails> {
    let url = this._url + '/get-details';

    return this._http.get<ChiliGetCreationDetails>(url, {
      params: { campaignId: idCampagne },
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  /**
   * Call Backend API
   * @returns Chili creation's thumbnails(vignettes)
   */
  getThumbnails(idCampagne: string): Observable<{
    thumbnails: Array<ThumbnailChiliResponse>;
  }> {
    let url = this._url + this.GET_THUMBNAILS_URL;

    return this._http.get<any>(url, {
      params: { campaignId: idCampagne },
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  /**
   * Get the BAT iframe for the campaign
   *
   * @param idCampaign
   */
  getBatIframe(campaignId: string) {
    let url = this._url + '/get-iframe-bat';
    return this._http.get<any>(url, {
      params: {
        campaignId,
      },
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  /**
   * Get the Chili iframe for the campaign during personalisation step.
   *
   * @param idCampaign the id of the campaign
   */
  getIframePersonnalisation(campaignId: string) {
    let url = this._url + '/get-iframe-customization';
    return this._http.get<any>(url, {
      params: {
        campaignId,
      },
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  /**
   * Check if the Chili template is completed during campaign creation.
   *
   * @param campaignId
   * @returns
   */
  checkCompletedStatus(
    campaignId: string,
    notCompleteMsg: string
  ): Observable<boolean> {
    let url = this._url + '/get-status';
    return this._http
      .get<any>(url, {
        params: {
          campaignId,
        },
        headers: new HttpHeaders({
          Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
        }),
      })
      .pipe(
        map((response: { status: string }) => {
          // TODO: Valid status
          if (response.status === 'BROUILLON' || response.status === 'VALIDEE') {
            return true;
          } else {
            this._GlobalDataService.alert(notCompleteMsg);
            return false;
          }
        }),
        catchError((_) => {
          console.log('checkCompletedStatus() - Error: request response.');
          return of(false);
        })
      );
  }

  /**
   * Get the address iframe for the campaign.
   *
   * @param campaignId
   * @returns
   */
  public getAddressIframe(campaignId: string): Observable<IframeChili> {
    let url = this._url + '/get-iframe-address';
    return this._http.get<IframeChili>(url, {
      params: {
        campaignId,
      },
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }
}
