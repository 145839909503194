import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  ThumbnailChiliResponse,
  ThumbnailName,
  ThumbnailType,
} from '@core/models/thumbnail-chili-response.model';
import { PaoService } from '@core/services/pao.service';
import { Subscription } from 'rxjs';
import { ThumbnailChiliDialogComponent } from './thumbnail-chili-dialog/thumbnail-chili-dialog.component';

@Component({
  selector: 'app-thumbnail-chili-recap',
  templateUrl: './thumbnail-chili-recap.component.html',
  styleUrls: ['./thumbnail-chili-recap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailChiliRecapComponent implements OnInit, OnDestroy {
  @Input()
  public idCampaign: string;

  @Input()
  public isPurged: boolean;

  @Input()
  public selected: 'classic' | 'letterEnvelop';

  @Input('stepper')
  public stepperObs: EventEmitter<StepperSelectionEvent>;

  public iframe: SafeResourceUrl;
  public thumbnailSmall: SafeResourceUrl;
  public thumbnailLarge: SafeResourceUrl;
  public iframeLetterSmall: SafeResourceUrl;
  public iframeEnvelopeSmall: SafeResourceUrl;
  public iframeLetterLarge: SafeResourceUrl;
  public iframeEnvelopeLarge: SafeResourceUrl;
  public errorMessage = undefined;

  public isLoading = false;

  private stepperChanges: Subscription;

  constructor(
    private _paoService: PaoService,
    private _sanitizer: DomSanitizer,
    private _cd: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.loadThumbnails();
  }

  public ngOnDestroy(): void {
    this.stepperChanges?.unsubscribe();
  }

  public async loadThumbnails() {
    this.setIsLoading(true);

    this._paoService.getThumbnails(this.idCampaign).subscribe({
      next: (res) => {
        this.setIsLoading(false);

        // Empty array is returned when request is redundant
        if (!res?.thumbnails || res.thumbnails.length === 0) {
          this.errorMessage =
            "L'affichage des vignettes n'est pas encore disponible";
          return;
        }

        switch (this.selected) {
          case 'classic': {
            this.getThumbnailFromResponse(res);
            break;
          }
          case 'letterEnvelop': {

            this.getLetterEnveloppeThumbnailFromResponse(res);
            break;
          }
          default: {
            console.log('loadThumbnail(): default switch case, no thumbnails.');
          }
        }
        this._cd.markForCheck();
      },
      error: (_) => {
        this.setIsLoading(false);
        this.errorMessage =
          'Erreur technique. Chargement des vignettes impossible';
      },
    });
  }

  private setIsLoading(state: boolean): void {
    this.isLoading = state;
    this._cd.markForCheck();
  }

  private getThumbnailFromResponse(response: {
    thumbnails: Array<ThumbnailChiliResponse>;
  }): void {
    const firstThumbnail: ThumbnailChiliResponse = response.thumbnails.find(
      (t) => t.pageNum === 1
    );
    this.thumbnailSmall = this.iframeLetterLarge = this.findOrUndefined(
      firstThumbnail,
      ThumbnailType.Small
    );
    this.thumbnailLarge = this.findOrUndefined(
      firstThumbnail,
      ThumbnailType.Large
    );
  }

  private getLetterEnveloppeThumbnailFromResponse(response: {
    thumbnails: Array<ThumbnailChiliResponse>;
  }): void {

    console.log(response);
    // TODO: to be updated with GA
    const thumbnailLetter: ThumbnailChiliResponse = response.thumbnails.find(
      (t) => t.name === ThumbnailName.Lettre
    );
    this.iframeLetterSmall = this.iframeLetterLarge = this.findOrUndefined(
      thumbnailLetter,
      ThumbnailType.Small
    );
    this.iframeLetterLarge = this.findOrUndefined(
      thumbnailLetter,
      ThumbnailType.Large
    );

    const thumbnailEnvelope: ThumbnailChiliResponse = response.thumbnails.find(
      (t) => t.name === ThumbnailName.Enveloppe
    );
    this.iframeEnvelopeSmall = this.findOrUndefined(
      thumbnailEnvelope,
      ThumbnailType.Small
    );
    this.iframeEnvelopeLarge = this.findOrUndefined(
      thumbnailEnvelope,
      ThumbnailType.Large
    );
  }

  private findOrUndefined(
    thumbnail: ThumbnailChiliResponse,
    type: ThumbnailType
  ): SafeResourceUrl | undefined {
    const url = thumbnail?.urls?.find((u) => u.type === type)?.url;
    if (url) {
      return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return undefined;
  }

  public open(url: SafeResourceUrl) {
    this._dialog.open(ThumbnailChiliDialogComponent, {
      panelClass: 'thumbnail-chili-dialog',
      data: {
        url,
      },
    });
  }
}
