<mat-toolbar class="gtmt-footer">
	<mat-grid-list cols="2" rowHeight="8rem">
		<mat-grid-tile class="gtmt-flex-start" colspan="1" rowspan="1">
			<mat-icon class="laposte-logo" svgIcon="logolp_part"></mat-icon>
		</mat-grid-tile>

		<mat-grid-tile class="gtmt-flex-end" colspan="1" rowspan="1" *ngIf="_GlobalDataService.isEloquaContext()">
			<img alt="Eloqua Logo" style="width: 10rem;" src="assets/eloqua.png"/>
		</mat-grid-tile>
	</mat-grid-list>
</mat-toolbar>