import { Component, OnInit } from '@angular/core';
import { Campagne } from '@core/models/campagne.model';
import { GlobalDataService } from '@core/services';
import { AuditLogService } from '@core/services/audit-log.service';
import { CampagneService } from '@core/services/campagne.service';
import { CommandeService } from '@core/services/commande.service';

@Component({
  selector: 'app-suivi-campagne-info',
  templateUrl: './suivi-campagne-info.component.html',
  styleUrls: ['./suivi-campagne-info.component.scss']
})
export class SuiviCampagneInfoComponent implements OnInit {

  statutsAutoriseAnnulationCampagne = ['TERMINEE', 'ANNULATION_DEMANDE', 'ANNULEE', 'EN_CREATION'];


  constructor(
    public _GlobalDataService: GlobalDataService,
    private _CampagneService: CampagneService,
    private _AuditLogService: AuditLogService
  ) { }

  ngOnInit(): void {
  }

  demandeAnnulationCampagne() {
    let data = {
      message: "Etes-vous sur de vouloir annuler cette campagne ?\n"
        + "Nous vous rappelons que des frais d'annulation sont susceptibles de vous être facturés selon les modalités décrites à l'article \"Annulation\" de votre contrat.",
      titre: 'Confirmation',
      BouttonConfirmation: true,
      confirmMsg: "Annuler",
      cancelMsg: "Ne pas annuler",
      campagne: this._GlobalDataService.Campagne as Campagne
    }
    const dialogRef = this._GlobalDataService.dialogAnnulation(data);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === true) {
        //traitement
        console.log("Campagne", this._GlobalDataService.Campagne);
        var oldStatut = this._GlobalDataService.Campagne.StatutCampagne;
        var oldCodeStatut = this._GlobalDataService.Campagne.CodeStatut;
        this._GlobalDataService.Campagne.StatutCampagne = "Annulation demandée";
        this._GlobalDataService.Campagne.CodeStatut = "ANNULATION_DEMANDE";


        this._AuditLogService.add(this._GlobalDataService.Campagne.IdCampagne, '0', `Annulation campagne (${this._GlobalDataService.Campagne.IdCampagne})`).subscribe(
          result => {
            this._CampagneService.put(this._GlobalDataService.Campagne)
              .subscribe(
                response => {
                  console.log(response);
                  this._GlobalDataService.alert("Demande d'annulation envoyée ");
                },
                error => {
                  this._GlobalDataService.openSnackBarInfo("Une erreur s'est produite lors de la demande d'annulation");

                  this._GlobalDataService.Campagne.StatutCampagne = oldStatut;
                  this._GlobalDataService.Campagne.CodeStatut = oldCodeStatut;

                }
              );
          });
      } else {
        this._GlobalDataService.Campagne.CommentaireAnnulation = null;
      }
    });
  }


}
