import * as fromComponents from './components';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MdePopoverModule } from '@material-extended/mde';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DynamicFilterPipe } from './pipes/dynamic-filter.pipe';
import { MentionsLegalesComponent } from './components/mentions-legales/mentions-legales.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { FichierCiblageCardComponent } from './components/fichier-ciblage-card/fichier-ciblage-card.component';
import { ChiliBatThumbnailComponent } from './components/chili-bat-thumbnail/chili-bat-thumbnail.component';
import { DialogChiliBatComponent } from './components/chili-bat-thumbnail/dialog-chili-bat/dialog-chili-bat.component';



@NgModule({
  declarations: [...fromComponents.components, DynamicFilterPipe, MentionsLegalesComponent, FichierCiblageCardComponent, ChiliBatThumbnailComponent, DialogChiliBatComponent],
  imports: [
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    MatSortModule,
    CommonModule,
    MatToolbarModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatListModule,
    MatDialogModule,
    MatChipsModule,
    MatCardModule,
    MatBadgeModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatStepperModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    RxReactiveFormsModule,
    MatTableExporterModule,
    MatProgressBarModule,
    MatTableModule,
    DragDropModule,
    ScrollingModule,
    MdePopoverModule,
    MatPasswordStrengthModule,
  ],
  exports: [
    PdfViewerModule,
    NgxExtendedPdfViewerModule,    
    MatSortModule,
    MatToolbarModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatBadgeModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatDialogModule,
    MatChipsModule,
    MatCardModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatStepperModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    RxReactiveFormsModule,
    MatTableExporterModule,
    MatProgressBarModule,
    MatTableModule,
    DragDropModule,
    ScrollingModule,
    MdePopoverModule,
    MatPasswordStrengthModule,
    ...fromComponents.components,
    DynamicFilterPipe
  ],
  providers: [MatDatepickerModule],
})
export class SharedModule { }
