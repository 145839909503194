import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EstimationRequest } from '@core/models/estimationRequest.model';
import { environment } from 'environments/environment';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class EstimationService {


  // https://d2efzxgfups17q.cloudfront.net/service/simulation : PUT

  _url = environment.url_api + '/simulation';


  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,

    ) { }

  /**
   * Estimation d'un produit
   * @param EstimationRequest
   * POST /simulation/put
   */
  put(estimationRequest: EstimationRequest) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let request = Object.assign({}, estimationRequest);

    let url = this._url;
    return this._http.put<any>(url, { request }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });



  }

}
