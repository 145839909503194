<mat-card class="gtmt-card-container">
    <mat-card-title>Votre sélection</mat-card-title>
    <mat-card-content>

        <mat-grid-list cols="5" rowHeight="5rem" [gutterSize]="'1.5rem'" style="width:100%;">

            <!-- First Row - icones -->
            <!-- Format  /Typologie -->
            <!-- 1 lettre / 1 enveloppe, etc -->
            <mat-grid-tile class="gtmt-field-icon-container" rowspan="2">
                <mat-icon *ngIf="showFormat" svgIcon="{{support.typologie}}"></mat-icon>
            </mat-grid-tile>

            <!-- Dimension -->
            <mat-grid-tile class="gtmt-field-icon-container" rowspan="2">
                <mat-icon *ngIf="showDimension" svgIcon="dimension"></mat-icon>
            </mat-grid-tile>

            <!-- Grammage -->
            <mat-grid-tile class="gtmt-field-icon-container" rowspan="2">
                <mat-icon *ngIf="showGrammage" svgIcon="grammage"></mat-icon>
            </mat-grid-tile>

            <!-- Finition -->
            <mat-grid-tile class="gtmt-field-icon-container" rowspan="2">
                <mat-icon *ngIf="showFinition" svgIcon="finition"></mat-icon>
            </mat-grid-tile>

            <!-- Encre -->
            <mat-grid-tile class="gtmt-field-icon-container" rowspan="2">
                <mat-icon *ngIf="showEncre" svgIcon="encre"></mat-icon>
            </mat-grid-tile>


            <!-- Second Row - Labels -->
            <!-- Format / Typologie -->
            <mat-grid-tile class="gtmt-field-label-container" rowspan="0.5">
                <ng-container *ngIf="showFormat">
                    <span>Format</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeFormat()"></mat-icon>
                </ng-container>
            </mat-grid-tile>

            <!-- Dimension -->
            <mat-grid-tile class="gtmt-field-label-container" rowspan="0.5">
                <ng-container *ngIf="showDimension">
                    <span class="gtmt-field-label">Dimension</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeDimension()"></mat-icon>
                </ng-container>

            </mat-grid-tile>

            <!-- Grammage -->
            <mat-grid-tile class="gtmt-field-label-container" rowspan="0.5">
                <ng-container *ngIf="showGrammage">
                    <span class="gtmt-field-label">Grammage</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeGrammage()"></mat-icon>
                </ng-container>
            </mat-grid-tile>

            <!-- Finition -->
            <mat-grid-tile class="gtmt-field-label-container" rowspan="0.5">
                <ng-container *ngIf="showFinition">
                    <span class="gtmt-field-label">Finition</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeFinition()"></mat-icon>
                </ng-container>
            </mat-grid-tile>

            <!-- Encre -->
            <mat-grid-tile class="gtmt-field-label-container" rowspan="0.5">
                <ng-container *ngIf="showEncre">
                    <span class="gtmt-field-label">Encre</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeEncre()"></mat-icon>
                </ng-container>
            </mat-grid-tile>

            <!-- Third Row - Values -->
            <!-- Format / Typologie -->
            <mat-grid-tile class="gtmt-field-value-container gtmt-border-top" rowspan="0.5">
                <span *ngIf="showFormat" class="gtmt-field-value">{{support.typologie}}</span>
            </mat-grid-tile>

            <!-- Dimension -->
            <mat-grid-tile rowspan="0.5" [ngClass]="{
                'gtmt-field-value-container': true,
                'gtmt-border-top': showDimension
                }">
                <span *ngIf="showDimension" class="gtmt-field-value">{{support.dimension}}</span>
            </mat-grid-tile>

            <!-- Grammage -->
            <mat-grid-tile rowspan="0.5" [ngClass]="{
                'gtmt-field-value-container': true,
                'gtmt-border-top': showGrammage
                }">
                <span *ngIf="showGrammage" class="gtmt-field-value">{{support.grammage}}</span>
            </mat-grid-tile>

            <!-- Finition -->
            <mat-grid-tile rowspan="0.5" [ngClass]="{
                    'gtmt-field-value-container': true,
                    'gtmt-border-top': showFinition
                    }">
                <span *ngIf="showFinition" class="gtmt-field-value">{{support.finition}}</span>
            </mat-grid-tile>

            <!-- Encre -->
            <mat-grid-tile rowspan="0.5" [ngClass]="{
                'gtmt-field-value-container': true,
                'gtmt-border-top': showEncre
                }">                
                <span *ngIf="showEncre" class="gtmt-field-value">{{support.Encre}}</span>
            </mat-grid-tile>

        </mat-grid-list>

    </mat-card-content>
</mat-card>