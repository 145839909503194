<div
  class="password-control"
  [class.change]="type === 'change'"
  [class.reset]="type === 'reset'"
>
  <!-- Left part : Form -->
  <div class="left">
    <!-- Title Injected -->
    <ng-content select="[header]"></ng-content>

    <form [formGroup]="formGroup">
      <!-- Ancien mot de passe -->
      <mat-form-field
        *ngIf="type === 'change'"
        appearance="fill"
        floatLabel="always"
      >
        <mat-label>Ancien mot de passe</mat-label>
        <input
          matInput
          maxlength="100"
          placeholder="........."
          formControlName="oldPassword"
          type="password"
          [type]="hideOldPassword ? 'password' : 'text'"
          (keyup)="keyup()"
        />
        <mat-icon matSuffix (click)="hideOldPassword = !hideOldPassword">
          {{ hideOldPassword ? "visibility_off" : "visibility" }}</mat-icon
        >
        <mat-error *ngIf="formGroup.get('oldPassword').hasError('incorrect')">
          L'ancien mot de pass n'est pas correct.
        </mat-error>
      </mat-form-field>

      <!-- Nouveau mot de passe -->
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Nouveau mot de passe</mat-label>
        <input
          matInput
          [maxlength]="maxLength"
          #password
          placeholder="........."
          formControlName="password"
          type="password"
          [type]="hideNewPassword ? 'password' : 'text'"
          (keyup)="keyup()"
        />
        <mat-hint align="end" aria-live="polite">
          {{ password.value.length }} / {{ pwdStrenght.max }}
        </mat-hint>

        <!-- @angular-material-extensions/password-strength's main component -->
        <mat-password-strength
          #pwdStrenght
          class="green"
          (onStrengthChanged)="passwordStrenght = $event"
          [password]="password.value"
          [min]="minLength"
          [max]="maxLength"
          hidden
        >
        </mat-password-strength>

        <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">
          {{ hideNewPassword ? "visibility_off" : "visibility" }}</mat-icon
        >
      </mat-form-field>

      <!-- Confirmation mot de passe -->
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Confirmation du nouveau mot de passe</mat-label>
        <input
          matInput
          [maxlength]="maxLength"
          placeholder="........."
          formControlName="passwordConfirm"
          type="password"
          [type]="hideConfirm ? 'password' : 'text'"
          (keyup)="keyup()"
        />
        <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
          hideConfirm ? "visibility_off" : "visibility"
        }}</mat-icon>
        <mat-error
          *ngIf="formGroup.get('passwordConfirm').hasError('incorrect')"
        >
          Le nouveau mot de passe et le mot de passe de confirmation ne
          correspondent pas.
        </mat-error>
      </mat-form-field>

      <!-- Message -->
      <div>
        <span class="success" *ngIf="successMessage">
          {{ successMessage }}
        </span>
        <span class="error" *ngIf="errorMessage">
          {{ errorMessage }}
        </span>
        &nbsp;
      </div>

      <!-- Validate -->
      <button
        mat-raised-button
        color="blue"
        [disabled]="isSending || isFormInvalid(false)"
        (click)="validate()"
      >
        <div class="flex-center">
          <ng-container *ngIf="type === 'change'"> Enregistrer </ng-container>
          <ng-container *ngIf="type === 'reset'">
            Réinitialiser votre mot de passe
          </ng-container>
          <mat-spinner *ngIf="isSending" diameter="16"></mat-spinner>
        </div>
      </button>
    </form>
  </div>

  <!-- Right part : Strength informations -->
  <div class="right strengthinfo-container">
    <div class="strengthinfo-content">
      <span>Votre mot de passe doit répondre au conditions suivantes :</span>
      <!--Password's strength info-->
      <mat-password-strength-info
        [passwordComponent]="pwdStrenght"
        lowerCaseCriteriaMsg="Contient au moins 1 lettre minuscule"
        upperCaseCriteriaMsg="Contient au moins 1 lettre majuscule"
        digitsCriteriaMsg="Contient au moins 1 chiffre de 0 à 9"
        specialCharsCriteriaMsg="Contient au moins 1 caractère spéciale : &#126; !&#64; &#35; &#36; %&#94; &amp; &#42; &#95; -&#43; &#61; &#39; &#124; &#40; &#41; &#123; &#125; &#91; &#93; :&#59; &#34; ,&#46; ?&#60; &#62; &#47;"
        minCharsCriteriaMsg="Contient au moins {{
          minLength
        }} caractères et au maximum {{ maxLength }} caractères"
      >
      </mat-password-strength-info>
      <span>Veuillez ne pas réutiliser les 9 derniers mots de passe.</span>
    </div>
  </div>
</div>
