import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Adresse } from '@core/models/adresse.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { CampagneExt } from '@core/models/campagne-ext.model';
import { Campagne } from '@core/models/campagne.model';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { Commande } from '@core/models/commande.model';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { Support } from '@core/models/support.model';
import { HelpComponent } from 'app/campagne/components';
import { forkJoin, from } from 'rxjs';
import { AdresseService } from './adresse.service';
import { CampagneAdressesService } from './campagne-adresses.service';
import { CampagneIdentificationService } from './campagne-identification.service';
import { CampagnePlanificationService } from './campagne-planification.service';
import { CampagneTypologieService } from './campagne-typologie.service';
import { CampagneService } from './campagne.service';
import { ChampsFusionService } from './champs-fusion.service';
import { CommandeService } from './commande.service';
import { FichierCreaService } from './fichier-crea.service';
import { GlobalDataService } from './global-data.service';
import { PersonalisationSfService } from './personalisation-sf.service';
import { ProduitService } from './produit.service';
import { SharedListSalesforceService } from './shared-list-salesforce.service';
import { StatutCommandeService } from './statut-commande.service';

@Injectable({
  providedIn: 'root'
})
export class CampagneDetailsService {

  constructor(
    public router: Router,
    private domSanitizer: DomSanitizer,
    public _GlobalDataService: GlobalDataService,
    private _CampagneService: CampagneService,
    private _AdresseService: AdresseService,
    public _HelpComponent: HelpComponent,
    private _FichierCreaService: FichierCreaService,
    private _ProduitService: ProduitService,
    private _ChampsFusionService: ChampsFusionService,
    private _SharedListSalesforceService: SharedListSalesforceService,
    private _StatutCommandeService: StatutCommandeService,
    private _CommandeService: CommandeService,
    private _CampagnePlanificationService: CampagnePlanificationService,
    public personalisationSfService: PersonalisationSfService,
  ) { }

  navigate(view: string, IdCampagne: string) {
    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }

    this.router.navigate([path, { outlets: { 'navview': [view, IdCampagne] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  downloadFile(fileName: string) {
    // console.log("download file " + fileName);
    return this._FichierCreaService.download(fileName, this._GlobalDataService.Campagne.IdCampagne)
      .subscribe(response => {
        var blob = new Blob([response], { type: response.type });
        //Create a link element, hide it, direct 
        //it towards the blob, and then 'click' it programatically

        //Create a DOMString representing the blob 
        //and point the link element towards it


        if (blob.type.startsWith('image')) {
          let objectURL = URL.createObjectURL(blob);
          this._GlobalDataService.CampagneExt.FichiersCreationsURL[fileName] = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
          this._GlobalDataService.CampagneExt.FichiersCreationsType[fileName] = 'image';

        }
        else {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            this._GlobalDataService.CampagneExt.FichiersCreationsURL[fileName] = new Uint8Array(fileReader.result as ArrayBuffer);
            this._GlobalDataService.CampagneExt.FichiersCreationsType[fileName] = blob.type;
          };
          fileReader.readAsArrayBuffer(blob);
        }
      });
  }


  initAvailableCamapgnes() {
    this._GlobalDataService.availableCamapgnes.forEach((campagne: Campagne) => {
      campagne.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
      let arr = this._GlobalDataService.availableAdresses.filter(adresse => adresse.IdAdresse == campagne.IdAdresse);
      if (arr.length > 0) campagne.Adresse = arr[0];
    })
  }

  async gotoDetailCampagne() {

    console.log("Current campagne = {} ", this._GlobalDataService.Campagne)

    this._GlobalDataService.Campagne.isDirty = false;

    // init CampagneExt
    this._GlobalDataService.CampagneExt = new CampagneExt();


    // Options de retour
    this._GlobalDataService.setOptionRetour();


    // Statut Mapping
    await this._GlobalDataService.getStatutMapping();

    // EmailNotification
    if (typeof this._GlobalDataService.Campagne.EmailNotification === "undefined") this._GlobalDataService.Campagne.EmailNotification = "";

    // this.showSpinner = true;

    this._GlobalDataService.produits = new Array<any>();
    this._GlobalDataService.availableAdresses = new Array<Adresse>();
    this._GlobalDataService.availableCamapgnes = new Array<Campagne>();

    if (this._GlobalDataService.isEloquaContext()) {
      this._GlobalDataService.availableEloquaChampsFusion = new Array<ChampsFusion>();
    }

    this._GlobalDataService.CampagneExt.Produit = {};
    this._GlobalDataService.CampagneExt.Support = new Support()
    this._GlobalDataService.CampagneExt.Enveloppe = new Support()

    this._GlobalDataService.CampagneExt.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
    this._GlobalDataService.CampagneExt.FichiersCreation = new Array<FichiersCreation>();
    this._GlobalDataService.CampagneExt.ChampsFusion = new Array<ChampsFusion>();
    this._GlobalDataService.CampagneExt.FichiersCreationsURL = {};
    this._GlobalDataService.CampagneExt.FichiersCreationsType = {};

    this._GlobalDataService.CampagneExt.NbFichiersCreation = 0;
    this._GlobalDataService.CampagneExt.NbFichiersPersonnalisation = 0;
    this._GlobalDataService.CampagneExt.NbFichiersLogo = 0;

    this._GlobalDataService.CampagneExt.Commandes = new Array<Commande>();
    this._GlobalDataService.CampagneExt.CurrentCommande = new Commande();

    this._GlobalDataService.Campagne.Percentage = "0";

    this._GlobalDataService.CampagneExt.VitessesProduit = new Array<string>();

    this._GlobalDataService.CampagneExt.VitessesProduit.push("Classique");
    this._GlobalDataService.CampagneExt.VitessesProduit.push("Rapide");

    // init des dates
    if (!this._GlobalDataService.Campagne.DateRemiseImprimeur) this._GlobalDataService.Campagne.DateRemiseImprimeur = "null";
    if (!this._GlobalDataService.Campagne.DateDeDebut) this._GlobalDataService.Campagne.DateDeDebut = "null";
    if (!this._GlobalDataService.Campagne.DateDeFin) this._GlobalDataService.Campagne.DateDeFin = "null";
    if (!this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste) this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = "null";


    let produits$ = from(this._ProduitService.getList());
    let availableEloquaChampsFusion$ = from(this._ChampsFusionService.listChampsDisponible());
    let availableAdresses$ = from(this._AdresseService.list(this._GlobalDataService.Campagne.IdAnnonceur));
    let availableCamapgnes$ = from(this._CampagneService.getListAvailable(this._GlobalDataService.Annonceur.IdAnnonceur));


    let produit$ = from(this._ProduitService.getProduitById(this._GlobalDataService.Campagne.IdProduit));

    let fichiersCreation$ = from(this._FichierCreaService.list(this._GlobalDataService.Campagne.IdCampagne));
    let sharedLists$ = from(this._SharedListSalesforceService.getSharedLists());
    let champsFusion$ = from(this._ChampsFusionService.list(this._GlobalDataService.Campagne.IdCampagne));
    let commandes$$ = from(this._CommandeService.getListByIdCampagne(this._GlobalDataService.Campagne.IdCampagne));


    let joindMap = {};
    let joined$: any;
    if (this._GlobalDataService.isSalesforceContext()) {
      if (this._GlobalDataService.Campagne.CodeStatut === "EN_CREATION" ||
        this._GlobalDataService.Campagne.CodeStatut === "A_CORRIGER") {
        joindMap = { fichiersCreation: fichiersCreation$, champsFusion: champsFusion$, sharedLists: sharedLists$, availableCamapgnes: availableCamapgnes$, produits: produits$, availableAdresses: availableAdresses$ };
      }
      else {
        joindMap = { fichiersCreation: fichiersCreation$, champsFusion: champsFusion$, produits: produits$, availableCamapgnes: availableCamapgnes$, availableAdresses: availableAdresses$ };
      }
    }
    else if (this._GlobalDataService.isEloquaContext()) {
      if (this._GlobalDataService.Campagne.CodeStatut === "EN_CREATION" ||
        this._GlobalDataService.Campagne.CodeStatut === "A_CORRIGER") {
        joindMap = { fichiersCreation: fichiersCreation$, champsFusion: champsFusion$, availableEloquaChampsFusion: availableEloquaChampsFusion$, availableAdresses: availableAdresses$, availableCamapgnes: availableCamapgnes$, produits: produits$ };
      }
      else {
        joindMap = { fichiersCreation: fichiersCreation$, champsFusion: champsFusion$, produits: produits$, availableCamapgnes: availableCamapgnes$, availableAdresses: availableAdresses$ };
      }
    }
    else if (this._GlobalDataService.isStandaloneContext()) {
      joindMap = { fichiersCreation: fichiersCreation$, produits: produits$, availableCamapgnes: availableCamapgnes$, availableAdresses: availableAdresses$, champsFusion: champsFusion$ };
    }

    if (this._GlobalDataService.Campagne.IdProduit != '0') {
      joindMap['produit'] = produit$;
    }

    if (this._GlobalDataService.Campagne.CodeStatut != "EN_CREATION" &&
      this._GlobalDataService.Campagne.CodeStatut != "A_CORRIGER") {
      joindMap['commandes'] = commandes$$;
    }

    joined$ = forkJoin(joindMap);


    joined$.subscribe(
      (result: any) => {
        console.log('init data = ', result);

        // availableEloquaChampsFusion
        if (this._GlobalDataService.isEloquaContext()) {
          if (this._GlobalDataService.Campagne.CodeStatut === "EN_CREATION" ||
            this._GlobalDataService.Campagne.CodeStatut === "A_CORRIGER") {
            for (var i = 0; i < result.availableEloquaChampsFusion.ContactFields.length; i++) {
              let CF: ChampsFusion = {
                Id: result.availableEloquaChampsFusion.ContactFields[i].Id,
                ChampFusion: result.availableEloquaChampsFusion.ContactFields[i].FieldName,
                internalName: result.availableEloquaChampsFusion.ContactFields[i].internalName,
              }
              this._GlobalDataService.availableEloquaChampsFusion.push(CF);
            }
          }
        }

        // availableAdresses
        this._GlobalDataService.availableAdresses = result.availableAdresses.adresse;

        // availableCamapgnes
        if (this._GlobalDataService.Campagne.CodeStatut === "EN_CREATION" ||
          this._GlobalDataService.Campagne.CodeStatut === "A_CORRIGER") {
          if (result.availableCamapgnes.campagne != '[]') {
            this._GlobalDataService.availableCamapgnes = result.availableCamapgnes.campagne.filter((campagne: Campagne) => campagne.NomCampagne != '');
          }
          this.initAvailableCamapgnes();
        }

        // produits
        this._GlobalDataService.produits = result.produits.produit;

        // Produit
        if (this._GlobalDataService.Campagne.IdProduit != '0') {
          this._GlobalDataService.CampagneExt.Produit = result.produit.produit;
          this.initProduit();
        }

        // Adress Retour
        if (this._GlobalDataService.Campagne.IdAdresse != '0') {
          const filteredAdresses = this._GlobalDataService.availableAdresses.filter((a: AdresseRetour) => a.IdAdresse === this._GlobalDataService.Campagne.IdAdresse)
          if (filteredAdresses.length > 0) this._GlobalDataService.CampagneExt.Adresse = filteredAdresses[0];
        }

        // Campagne parente Duplication
        if (this._GlobalDataService.Campagne.IdCampagneCopie != '0') {
          const CampagneParente = this._GlobalDataService.availableCamapgnes.filter((c: Campagne) => c.IdCampagne == this._GlobalDataService.Campagne.IdCampagneCopie);
          if (CampagneParente.length > 0) this._GlobalDataService.Campagne.NomCampagneParente = CampagneParente[0].NomCampagne;
        }

        // fichierCreation
        if (typeof result.fichiersCreation.fichierCreation == 'object') {
          this._GlobalDataService.CampagneExt.FichiersCreation = result.fichiersCreation.fichierCreation;
          this._GlobalDataService.CampagneExt.NbFichiersCreation = this._GlobalDataService.getNbFichiersCreation();
          this._GlobalDataService.CampagneExt.NbFichiersPersonnalisation = this._GlobalDataService.getNbFichiersPersonnalisation();
          this._GlobalDataService.CampagneExt.NbFichiersLogo = this._GlobalDataService.getNbFichiersLogo();

          // desactivation du preview @a activer si besoin  
          // if (this._GlobalDataService.Campagne.CodeStatut === "EN_CREATION" ||
          //   this._GlobalDataService.Campagne.CodeStatut === "A_CORRIGER") {
          //   this._GlobalDataService.CampagneExt.FichiersCreation.forEach(f => {
          //     this.downloadFile(f.NomFichier);
          //   })
          // }
        }

        // sharedLists
        if (this._GlobalDataService.isSalesforceContext()) {
          if (this._GlobalDataService.Campagne.CodeStatut === "EN_CREATION" ||
            this._GlobalDataService.Campagne.CodeStatut === "A_CORRIGER") {
            this._GlobalDataService.sharedLists = result.sharedLists.entry;
          }
        }

        // ChampsFusion  

        // if (this._GlobalDataService.isEloquaContext() || this._GlobalDataService.isSalesforceContext() || this._GlobalDataService.isStandaloneContext()) {
        //   if (result.champsFusion.ChampsFusion.length > 0 && result.champsFusion.ChampsFusion[0].Sequence >= 0) {
        //     result.champsFusion.ChampsFusion.sort((a: any, b: any) => parseInt(a.Sequence) - parseInt(b.Sequence));
        //     let CF: any;
        //     for (var i = 0; i < result.champsFusion.ChampsFusion.length; i++) {
        //       // CF = result.champsFusion.ChampsFusion[i];
        //       CF = {
        //         Id: result.champsFusion.ChampsFusion[i].Id,
        //         ChampFusion: result.champsFusion.ChampsFusion[i].ChampFusion,
        //         internalName: result.champsFusion.ChampsFusion[i].InternalName,
        //         Sequence: result.champsFusion.ChampsFusion[i].Sequence,
        //         IsPaveAdresse: result.champsFusion.ChampsFusion[i].IsPaveAdresse == '1' ? 'true' : 'false',
        //       }
        //       this._GlobalDataService.CampagneExt.ChampsFusion.push(CF);
        //     }
        //     if (this._GlobalDataService.isSalesforceContext()) {
        //       if (this._GlobalDataService.Campagne.CodeStatut === "EN_CREATION" ||
        //         this._GlobalDataService.Campagne.CodeStatut === "A_CORRIGER") {
        //         this.personalisationSfService.initialise();
        //       }
        //     }
        //   }
        // }

        console.log(result.champsFusion);
        if (result.champsFusion.ChampsFusion.length > 0 && result.champsFusion.ChampsFusion[0].Sequence >= 0) {
          result.champsFusion.ChampsFusion.sort((a: any, b: any) => parseInt(a.Sequence) - parseInt(b.Sequence));
          let CF: any;
          for (var i = 0; i < result.champsFusion.ChampsFusion.length; i++) {
            // CF = result.champsFusion.ChampsFusion[i];
            CF = {
              Id: result.champsFusion.ChampsFusion[i].Id,
              ChampFusion: result.champsFusion.ChampsFusion[i].ChampFusion,
              internalName: result.champsFusion.ChampsFusion[i].InternalName,
              Sequence: result.champsFusion.ChampsFusion[i].Sequence,
              IsPaveAdresse: result.champsFusion.ChampsFusion[i].IsPaveAdresse == '1' ? 'true' : 'false',
            }
            this._GlobalDataService.CampagneExt.ChampsFusion.push(CF);
          }
          if (this._GlobalDataService.isSalesforceContext()) {
            if (this._GlobalDataService.Campagne.CodeStatut === "EN_CREATION" ||
              this._GlobalDataService.Campagne.CodeStatut === "A_CORRIGER") {
              this.personalisationSfService.initialise();
            }
          }
        }

        // Fil d'Ariane
        this._GlobalDataService.checkStepCompletion();


        // Campagne Récurrente
        if (this._GlobalDataService.Campagne.CodeStatut != "EN_CREATION" &&
          this._GlobalDataService.Campagne.CodeStatut != "A_CORRIGER" && this._GlobalDataService.Campagne.FrequenceEnvoi === 'Récurrente') {
          if (result.commandes.commande.length > 0) {
            this._GlobalDataService.CampagneExt.Commandes = result.commandes.commande;
            this._GlobalDataService.CampagneExt.CurrentCommande = result.commandes.commande[0];
            console.log(this._GlobalDataService.CampagneExt.CurrentCommande);

            this._StatutCommandeService.getStatutsByIdCommandeAdmin(this._GlobalDataService.CampagneExt.CurrentCommande.IdCommande)
              .subscribe(
                response => {
                  if (Array.isArray(response.StatutCommande)) {
                    let listeStatutCommande = response.StatutCommande;

                    let currentStatutCommandeArray = listeStatutCommande.filter((s: any) => ['CONFORMITEOK', 'BAT_AVAILABLEOK', 'BAT_ACCEPTEDOK', 'BAT_REJECTEDOK'].indexOf(s.codeStatut) > -1)
                    let currentStatutCommandeBATOKArray = currentStatutCommandeArray.filter((s: any) => ['BAT_ACCEPTEDOK'].indexOf(s.codeStatut) > -1);

                    if (currentStatutCommandeBATOKArray.length >= 1)
                      this._GlobalDataService.Campagne.DateValidationBAT = currentStatutCommandeBATOKArray[0].dateModification;

                    console.log(currentStatutCommandeArray);
                    if (currentStatutCommandeArray.length >= 1) {
                      this._GlobalDataService.CampagneExt.CurrentStatutCommande = currentStatutCommandeArray[currentStatutCommandeArray.length - 1];

                      this._GlobalDataService.CampagneExt.CurrentStatutCommande.StatutLibelle = this._GlobalDataService.mappingStatut[this._GlobalDataService.CampagneExt.CurrentStatutCommande.codeStatut];

                      console.log(this._GlobalDataService.CampagneExt.CurrentStatutCommande);
                    }
                  }
                },
                () => { }
              );
            this._GlobalDataService.isLoaded = true;
          }
          //redirection vers détail campagne      
          this.navigate('suivi-campagne-recurrente', this._GlobalDataService.Campagne.IdCampagne);
        }
        // Campagne Une seule fois
        else if (this._GlobalDataService.Campagne.CodeStatut != "EN_CREATION" &&
          this._GlobalDataService.Campagne.CodeStatut != "A_CORRIGER" && this._GlobalDataService.Campagne.FrequenceEnvoi === 'Une seule fois') {
          if (result.commandes.commande.length > 0) {
            this._GlobalDataService.CampagneExt.CurrentCommande = result.commandes.commande[0];
            this._StatutCommandeService.getStatutsByIdCommandeAdmin(this._GlobalDataService.CampagneExt.CurrentCommande.IdCommande)
              .subscribe(
                response => {
                  if (Array.isArray(response.StatutCommande)) {
                    let listeStatutCommande = response.StatutCommande;
                    this._GlobalDataService.CampagneExt.currentStatutCommandeArray = listeStatutCommande;
                    console.log(listeStatutCommande);
                    for (var i = listeStatutCommande.length - 1; i > 0; i--) {
                      const percentage = this._GlobalDataService.mappingPercentage[listeStatutCommande[i].codeStatut];
                      if (percentage != '0') {
                        this._GlobalDataService.Campagne.Percentage = percentage;
                        break;
                      }
                    }
                    this._GlobalDataService.isLoaded = true;
                  }
                },
                () => { }
              );
          }
          console.log(this._GlobalDataService.CampagneExt.CurrentCommande);
          //redirection vers détail campagne    
          this.navigate('suivi-campagne-une-seule-fois', this._GlobalDataService.Campagne.IdCampagne);
        }
        else {
          this._GlobalDataService.isLoaded = true;
          this._CampagnePlanificationService.reset();
          this.navigate('campagne-details', this._GlobalDataService.Campagne.IdCampagne);
        }

      },
      error => { console.log(error); }
    );
  }

  initProduit() {
    this._GlobalDataService.CampagneExt.VitessesProduit = this._GlobalDataService.getVitessesProduit();

    // const filteredProduits = this._GlobalDataService.produits.filter((p: any) => p.IdProduit === this._GlobalDataService.Campagne.IdProduit)
    // if (filteredProduits.length > 0) this._GlobalDataService.CampagneExt.Produit = filteredProduits[0];

    const LibelleGrammageSupport = this._GlobalDataService.getLibelleProduit('CodeGrammageSupport', this._GlobalDataService.Campagne.CodeGrammageSupport, 'GrammageSupport');
    const LibelleFinitionSupport = this._GlobalDataService.getLibelleProduit('CodeFinitionSupport', this._GlobalDataService.Campagne.CodeFinitionSupport, 'FinitionSupport');
    const LibelleSupport = this._GlobalDataService.getLibelleProduit('CodeSupport', this._GlobalDataService.Campagne.CodeSupport, 'support');
    const LibelleDimensionsSupport = this._GlobalDataService.getLibelleProduit('CodeDimensionsSupport', this._GlobalDataService.Campagne.CodeDimensionsSupport, 'DimensionsSupport');

    const LibelleGrammageEnveloppe = this._GlobalDataService.getLibelleProduit('CodeGrammageEnveloppe', this._GlobalDataService.Campagne.CodeGrammageEnveloppe, 'GrammageEnveloppe');
    const LibelleFinitionEnveloppe = this._GlobalDataService.getLibelleProduit('CodeFinitionEnveloppe', this._GlobalDataService.Campagne.CodeFinitionEnveloppe, 'FinitionEnveloppe');
    const LibelleEnveloppe = this._GlobalDataService.getLibelleProduit('CodeEnveloppe', this._GlobalDataService.Campagne.CodeEnveloppe, 'enveloppe');
    const LibelleDimensionsEnveloppe = this._GlobalDataService.getLibelleProduit('CodeDimensionsEnveloppe', this._GlobalDataService.Campagne.CodeDimensionsEnveloppe, 'DimensionsEnveloppe');

    this._GlobalDataService.CampagneExt.Support = {
      support: LibelleSupport,
      dimension: LibelleDimensionsSupport,
      grammage: LibelleGrammageSupport,
      finition: LibelleFinitionSupport,
      typologie: "",
      Encre: "",
    } as Support

    this._GlobalDataService.CampagneExt.Enveloppe = {
      support: LibelleEnveloppe,
      dimension: LibelleDimensionsEnveloppe,
      grammage: LibelleGrammageEnveloppe,
      finition: LibelleFinitionEnveloppe,
      typologie: "",
      Encre: "",
    } as Support
  }

}
