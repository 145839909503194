import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '@core/services';
import { AuditLogService } from '@core/services/audit-log.service';
import { CommandeService } from '@core/services/commande.service';
import { CampagneService } from '@core/services/campagne.service';
import { AnnonceurService } from '@core/services';
import { Annonceur } from '@core/models/annonceur.model';
import { Subscription } from 'rxjs';
import { clearScreenDown } from 'readline';

@Component({
  selector: 'app-suivi-campagne-commande',
  templateUrl: './suivi-campagne-commande.component.html',
  styleUrls: ['./suivi-campagne-commande.component.scss']
})
export class SuiviCampagneCommandeComponent implements OnInit {

  statutsAutoriseAnnulationCommande = [
    'CANCEL_CAMPAIGNACCEPTED',
    'ANNULATIONDEMANDE', 'ANNULATIONOK', 'ANNULATIONKO',
    'COMPLIANCE_CHECKKO', 'BAT_REJECTED', 'DEPOSITIN_PROGRESS',
    'DEPOSITDONE', 'DISTRIBTERMINE'
  ];

  // statutsAutoriseAnnulationCampagne = ['TERMINEE', 'ANNULATION_DEMANDE', 'ANNULEE', 'EN_CREATION'];


  _demandeAnnulationCommandeSub: Subscription | undefined;
  _afterClosedSub: Subscription | undefined;


  constructor(
    public _GlobalDataService: GlobalDataService,
    private _CommandeService: CommandeService,
    // private _CampagneService: CampagneService,
    private _AuditLogService: AuditLogService
  ) { }

  ngOnInit(): void {
  }


  /* Comportement peut se changer dans les évolutions
  Distingué par FrequenceEnvoi
    - Une seule fois : √ campagne × commande --> griser le bouton 
                        √ campagne √ commande --> annuler la commande
    - Récurrente : √ campagne × commande --> annuler la campagne pour éviter de générer des commandes 
                    √ campagne √ commande --> annuler la campagne pour éviter de générer futures commandes, ne touche pas commande en cours 
  */
  demandeAnnulationCommande(commande: any) {
    let data = {
      message: "Etes-vous sur de vouloir annuler cette commande ?",
      titre: 'Confirmation',
      BouttonConfirmation: true,
    }
    const dialogRef = this._GlobalDataService.confirm(data);
    this._afterClosedSub = dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log("Commande", commande)
      if (result === true) {
        //call API
        this._demandeAnnulationCommandeSub = this._CommandeService.demandeAnnulationCommande(commande.IdCommande)
          .subscribe(
            (response: any) => {
              this._GlobalDataService.CampagneExt.CurrentCommande.CodeStatut = 'ANNULATIONDEMANDE'

              // this._GlobalDataService.Campagne.StatutCampagne = "Annulation demandée";
              // this._GlobalDataService.Campagne.CodeStatut = "ANNULATION_DEMANDE";
            }
          );
        this._AuditLogService.add(commande.IdCampagne, commande.IdCommande, `Annulation commande`);
      }
    });
  }

/*   demandeAnnulationCampagne() {
    let data = {
      message: "Etes-vous sur de vouloir annuler cette campagne ?\n"
        + "Nous vous rappelons que des frais d'annulation sont susceptibles de vous être facturés selon les modalités décrites à l'article \"Annulation\" de votre contrat.",
      titre: 'Confirmation',
      BouttonConfirmation: true,
      confirmMsg: "Annuler",
      cancelMsg: "Ne pas annuler",
      campagne: this._GlobalDataService.Campagne
    }
    const dialogRef = this._GlobalDataService.dialogAnnulation(data);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === true) {
        //traitement
        console.log("Campagne", this._GlobalDataService.Campagne);
        var oldStatut = this._GlobalDataService.Campagne.StatutCampagne;
        var oldCodeStatut = this._GlobalDataService.Campagne.CodeStatut;
        this._GlobalDataService.Campagne.StatutCampagne = "Annulation demandée";
        this._GlobalDataService.Campagne.CodeStatut = "ANNULATION_DEMANDE";

        this._AuditLogService.add(this._GlobalDataService.Campagne.IdCampagne, '0', `Annulation campagne (${this._GlobalDataService.Campagne.IdCampagne})`).subscribe(
          result => {
            this._CampagneService.put(this._GlobalDataService.Campagne)
              .subscribe(
                response => {
                  console.log(response);
                  this._GlobalDataService.alert("Demande d'annulation envoyée ");
                },
                error => {
                  this._GlobalDataService.openSnackBarInfo("Une erreur s'est produite lors de la demande d'annulation");

                  this._GlobalDataService.Campagne.StatutCampagne = oldStatut;
                  this._GlobalDataService.Campagne.CodeStatut = oldCodeStatut;

                }
              );
          });
      } else {
        this._GlobalDataService.Campagne.CommentaireAnnulation = null;
      }
    });
  } */

  ngOnDestroy() {
    if (this._afterClosedSub) this._afterClosedSub.unsubscribe();
    if (this._demandeAnnulationCommandeSub) this._demandeAnnulationCommandeSub.unsubscribe();
  }

}
