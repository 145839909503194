import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '@core/services';
import { HelpComponent } from '../../../campagne/components/help/help.component';

@Component({
  selector: 'app-gestion-favoris',
  templateUrl: './gestion-favoris.component.html',
  styleUrls: ['./gestion-favoris.component.scss']
})
export class GestionFavorisComponent implements OnInit {


  constructor(
    public _GlobalDataService: GlobalDataService,
    public _HelpComponent: HelpComponent
  ) { }

  ngOnInit(): void {
  }

  openHelp(signet: string) {
    this._GlobalDataService.referenceDocHelp = "#" + signet;
    this._HelpComponent.OpenHelpFile();
  }  

}
