<mat-card>
    <mat-card-title>
        <h1>
            Statut de la campagne courrier
        </h1>
        <span>
            <strong>
                {{_GlobalDataService.Campagne.Percentage}} %
            </strong> 
        </span>        
    </mat-card-title>
    <mat-card-subtitle>
        {{_GlobalDataService.Campagne.StatutCampagne}}
    </mat-card-subtitle>
    <mat-card-content>
        <mat-progress-bar mode="determinate" [value]="_GlobalDataService.Campagne.Percentage"></mat-progress-bar>
    </mat-card-content>
    <li>
        Progression de la campagne courrier
    </li>
</mat-card>