<cdk-virtual-scroll-viewport itemSize="44">
    <mat-list>
        <mat-list-item *ngFor="let cf of cfs; let i = index">
            <span> {{ "Champ "+ i }} </span>
            <span [mdePopoverTriggerFor]="appPopover" (mouseenter)="$event.stopPropagation()" style="cursor:pointer"> {{
                cf.ChampFusion}} </span>
            <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false"
                [mdeFocusTrapAutoCaptureEnabled]="false">
                <mat-card class="gtmt-popover-card">
                    <mat-card-header> {{ "Champ "+ i}}
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            {{ cf.ChampFusion}}
                        </p>
                    </mat-card-content>
                </mat-card>
            </mde-popover>
        </mat-list-item>
    </mat-list>
</cdk-virtual-scroll-viewport>