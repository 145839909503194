import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Annonceur } from '@core/models/annonceur.model';
import { CampagneExt } from '@core/models/campagne-ext.model';
import { AnnonceurService, CampagneDetailsService, GlobalDataService, SalesforceService } from '@core/services';
import { CampagneService } from '@core/services/campagne.service';


@Component({
  selector: 'app-salesforce-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  url: string = "";

  interactionIdSF: string = "";

  constructor(
    private activatedRoute: ActivatedRoute,    
    private _SalesforceService: SalesforceService,
    private _GlobalDataService: GlobalDataService,
    private _AnnonceurService: AnnonceurService,
    private _CampagneService: CampagneService,
    private _sanitizer: DomSanitizer,
    private _CampagneDetailsService: CampagneDetailsService,
    private router: Router) {

    // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
    // if (!localStorage.getItem("interactionIdSF") || !localStorage.getItem("SALESFORCE_APP_JWT")  )  {
    // if (!this._GlobalDataService.getinteractionIdSF()  || !this._GlobalDataService.getJwtoken()  )  {
    //   this.alert();
    // }

    this.activatedRoute.queryParams.subscribe(params => {
       this.interactionIdSF = params['id'];
       this._GlobalDataService.setinteractionIdSF( this.interactionIdSF );
       console.log( this.interactionIdSF );
    });
  }

  async ngOnInit(): Promise<void> {

    this._GlobalDataService.CONTEXT = "SALESFORCE";

    this._GlobalDataService.refreshSFToken(this.interactionIdSF)
    .subscribe(async (result) => {
      console.log("result is ", result);
      if (result) {
        console.log('id token ', result.id_token);
        this._GlobalDataService.setJwtoken(result.id_token);
      //get Annonceur
      this._AnnonceurService.get()
        .subscribe(
          response => this.successResponse(response),
          error => this.errorResponse(error)
        );

      } else {
        this.router.navigate(['/erreur'], {
          queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
        });
      }
    },
      (error) => {
        console.debug("isAuth fail", error);
      }
    );
  }

  alert() {

    let data = {
      message: "Votre session a expiré. Veuillez vous reconnecter à SalesCloud pour continuer.",
      titre: 'Confirmation',
      BouttonConfirmation: false,
    }
    const confirmdialogRef = this._GlobalDataService.confirm(data);

    confirmdialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result === true) {
        window.close();
      } else {
        return;
      }
    });

  }

  async successResponse(response: { annonceur: Annonceur; }) {
    console.log(response);
    await this._GlobalDataService.getStatutMapping();
    if (typeof response.annonceur === "undefined") {
      console.log("Annonceur n'existe pas sur cette insatnce Salescloud");
      this.router.navigate(['/campagne/home', { outlets: { 'navview': ['parametrage'] } }], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true
      }).then((e) => {
        if (e) {
          console.log("Navigation is successful!");
        } else {
          console.log("Navigation has failed!");
        }
      });
    } else {
      //récupérer la campagne en cours ou bien créer une (côté front) et la retourner
      console.log("Annonceur existe sur cette insatnce Salescloud");
      console.log(response.annonceur);

      this._GlobalDataService.Annonceur = response.annonceur;
      this._CampagneService.getCurrentCampagne()
        .subscribe(
          response => this.SuccessCurrentCampagne(response.campagne),
          error => this.ErrorCurrentCampagne(error)
        );
    }
  }

  errorResponse(error: any) {
    console.log('appcomponent', error);
    console.log('this._GlobalDataService.objectParams', this._GlobalDataService.objectParams);
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    });
  }

  navigate(view: string, IdCampagne: string) {
    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }

    this.router.navigate([path, { outlets: { 'navview': [view, IdCampagne] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }
  
  SuccessCurrentCampagne(campagne) {
    if (typeof campagne === 'object') {    
      this._GlobalDataService.CampagneExt = new CampagneExt();

      this._GlobalDataService.Campagne = campagne;

      console.log('current Campagne : ', this._GlobalDataService.Campagne);

      console.log('Id Campagne : ', this._GlobalDataService.Campagne.IdCampagne);

      console.log("campagne.StatutCampagne", campagne.StatutCampagne);

      console.log("campagne.CodeStatut", campagne.CodeStatut);

      this._CampagneDetailsService.gotoDetailCampagne();

    } else {

      this._CampagneDetailsService.navigate('campagne-details', this._GlobalDataService.Campagne.IdCampagne);
    }
    this._GlobalDataService.campagneBeforeChanges = Object.assign({}, this._GlobalDataService.Campagne);

    console.log("Downloading logo");
    this._AnnonceurService.downloadLogo()
      .subscribe((response) => {
        console.log("Download logo response", response);
        if (response) {
          var urlCreator = window.URL;
          this._GlobalDataService.logo = this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response))
        }
        else {
          this._GlobalDataService.logo = null;
        }
      }, error => {
        console.log("Download logo error", error);
        this._GlobalDataService.logo = null;
      });
  }

  ErrorCurrentCampagne(error: string) {
    //une erreur est survenue => redirection page erreur
    console.log('CreationCampagneComponent : ErrorCurrentCampagne', error)
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    });
    // this.isLoaded = true;
  }



}
