import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRouteAccessService implements CanActivate {

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.debug("UserRouteAccessService.canActivate");
    return new Observable<boolean>(sub => {
      this._authenticationService.isAuthenticated()
        .subscribe(
          (res) => {
            console.log("isAuthenticated ? ", res);
            if (res) {
              sub.next(true);
              sub.complete();
            }
            else {
              this._router.navigate(["/administration/login"], {
                queryParams: {
                  return: state.url
                }
              });
              sub.next(false);
              sub.complete();
            }
          },
          () => {
            this._router.navigate(["/administration/login"], {
              queryParams: {
                return: state.url
              }
            });
            sub.next(false);
            sub.complete();
          }
        )
    });
  }
}
