import { Component, Input, OnInit } from '@angular/core';
import { Support } from '@core/models/support.model';

@Component({
  selector: 'app-support-info-card',
  templateUrl: './support-info-card.component.html',
  styleUrls: ['./support-info-card.component.scss']
})
export class SupportInfoCardComponent implements OnInit {
  @Input() support!: Support;

  constructor() {
    this.support = {
      typologie:"",
      support:"",
      dimension:"",
      grammage:"",
      Encre: "",
      finition: ""
    }
  }


  ngOnInit(): void {
  }

}
