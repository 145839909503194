<mat-toolbar>
    <h1> Gestion des favoris</h1>
    <!-- <mat-icon svgIcon="Infos" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click"
        #popoverTrigger="mdePopoverTrigger" style="cursor: pointer;">
    </mat-icon> -->
    <mat-icon svgIcon="Aide" (click)="openHelp('_Ref124864485')" style="cursor: pointer;">
    </mat-icon>
</mat-toolbar>

<mat-grid-list cols="2" rowHeight="10rem" [gutterSize]="'5rem'">

    <mat-grid-tile  class="gtmt-jc-start gtmt-ai-start"  [colspan]="1" [rowspan]="4">
        <app-adresse-retour-favori></app-adresse-retour-favori>
    </mat-grid-tile>

    <mat-grid-tile  class="gtmt-jc-start gtmt-ai-start"  [colspan]="1" [rowspan]="4">
        <app-logo-alliage-favori></app-logo-alliage-favori>
    </mat-grid-tile>
    
</mat-grid-list>

<!-- <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="gtmt-popover-card">
        <mat-card-header> Point d’information
            <mat-icon svgIcon="Infos"></mat-icon>
        </mat-card-header>
        <mat-card-content>
        </mat-card-content>
    </mat-card>
</mde-popover> -->