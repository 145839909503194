
export class Enveloppe {
    enveloppe: string;
    dimension: string;
    grammage: string;
    Encre: string;
    finition: string;

    constructor() {
        this.enveloppe="";
        this.dimension = "";
        this.grammage = "";
        this.Encre = "";
        this.finition = "";
     }  
}