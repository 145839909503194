import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ElementRef, Inject, AfterViewInit, ViewChild  } from '@angular/core';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.scss']
})
export class MentionsLegalesComponent implements OnInit {

  constructor() { }
  
  ngOnInit(): void {
  }

}
