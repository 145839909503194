<div class="header">
  <!-- Close Button -->
  <button mat-dialog-close>
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

  <!-- Title -->
<div class="title">
  <h2>Validation du BAT</h2>
  <mat-icon svgIcon="information" mdePopoverTriggerOn="hover" [mdePopoverTriggerFor]="appPopover"></mat-icon>
</div>

<!-- Content -->
<div class="dialog-content">
  <!-- IFrame BAT -->
  <iframe *ngIf="!isLoading && iframeBAT" [src]="iframeBAT"></iframe>
  <!-- Spinner loader -->
  <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>

<!-- Popover -->
<mde-popover #appPopover="mdePopover">
  <mat-card class="gtmt-popover-card">
    <mat-card-header>
      Point d’information
      <mat-icon svgIcon="Infos"></mat-icon>
    </mat-card-header>
    <mat-card-content>
      <p>
        Aperçu basé sur un échantillon de 10 contacts.
      </p>
    </mat-card-content>
  </mat-card>
</mde-popover>
