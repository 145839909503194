import { ElementRef } from '@angular/core';

export class NavigateUtil {
  /**
   * Go To Top SideNavContent
   *
   * @param el ElementRef
   */
  public static goToTopSideNavContent(el: ElementRef) {
    const parentElement = el.nativeElement.closest('mat-sidenav-content');
    if (parentElement) {
      parentElement.scrollTo({ top: 0 });
    }
  }
}
