export interface IAnnonceur {
    IdAnnonceur: string;
    EloquaInstallId: string;
    EloquaInstanceId: string;
    EloquaSiteId: string;
    EloquaSiteName: string;
    EloquaAppId: string;
    
    oauth_consumer_key: string;
    oauth_nonce: string;
    oauth_signature: string;
    oauth_signature_method: string;
    oauth_timestamp: string;
    oauth_version: string;
    user: string;
    username: string;


    NumeroClient: string;
    NumeroContrat: string;
    NumeroContratAlliage: string;
    IdentifiantAlliageGE: string;
    NomContact: string;
    PrenomContact: string;
    EmailContact: string;
    LoginContact: string;
    TelephoneContact: string;
    RoleContact: string;
    Siret: string;
    RaisonSociale: string;

    IsEloquaUser: string;
    IsStandaloneUser: string;
    IsMarketingCloudUser: string;

    // For Standalone
    annonceurName: string;
    login: string;
    loginSFTP: string;
    passwordSFTP: string;
    email: string;

}

export class Annonceur implements IAnnonceur {
    IdAnnonceur: string = "";
    EloquaInstallId: string = "";
    EloquaInstanceId: string = "";
    EloquaSiteId: string = "";
    EloquaSiteName: string = "";
    EloquaAppId: string = "";

    oauth_consumer_key: string = "";
    oauth_nonce: string = "";
    oauth_signature: string = "";
    oauth_signature_method: string = "";
    oauth_timestamp: string = "";
    oauth_version: string = "";
    user: string = "";
    username: string = "";

    NumeroClient: string = "";
    NumeroContrat: string = "";
    NumeroContratAlliage: string = "";
    IdentifiantAlliageGE: string ="";
    NomContact: string ="";
    PrenomContact: string = "";
    EmailContact: string = "";
    LoginContact: string = "";
    TelephoneContact: string = "";
    RoleContact: string = "";
    Siret: string = "";
    RaisonSociale: string = "";

    IsEloquaUser: string = "";
    IsStandaloneUser: string = "";
    IsMarketingCloudUser: string = "";
    
    // For Standalone
    annonceurName: string = "";
    login: string = "";
    loginSFTP: string = "";
    passwordSFTP: string = "";
    email: string = "";

    DateCreation: string;
    CreeParLogin: string = "";

    ModifieParLogin: string;
    DateModification: string;
}
