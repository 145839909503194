import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Campagne } from '@core/models/campagne.model';
import { GlobalDataService } from '@core/services';
import { CampagneService } from '@core/services/campagne.service';
import { Subscription } from 'rxjs';

import * as $ from 'jquery';



@Component({
  selector: 'app-campagne-validation-bat',
  templateUrl: './campagne-validation-bat.component.html',
  styleUrls: ['./campagne-validation-bat.component.scss']
})
export class CampagneValidationBatComponent implements OnInit {

  campagne?: Campagne;
  name = 'Angular 6';
  safeSrc: SafeResourceUrl;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public _GlobalDataService: GlobalDataService,

  ) {

    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("/gtmt/gtmt_bat.cfm");
    // this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://ded147lhthj4l.cloudfront.net/service/bat");
    //  this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://ded147lhthj4l.cloudfront.net//gtmt/gtmt_bat.cfm");

    console.log(this.safeSrc);

   }


  ngOnInit(): void {
  }

  uploadDone() {
    console.log("uploadDone");
    var iframe: any = document.getElementById("iframe");
    var login = iframe.contentWindow.document.getElementById("coclico");
    var password = iframe.contentWindow.document.getElementById("pwd");
    var submit = iframe.contentWindow.document.getElementsByName("valider")[0];

    if (login) {
      login.value = this._GlobalDataService.Campagne.LoginBAT;
      console.log(login);
    }
    if (password) {
      password.value = this._GlobalDataService.Campagne.MotDePasseBAT;
      console.log(password);
    }
    if( login && password && submit) submit.click();

  }

  ngOnDestroy() {
  }

}
