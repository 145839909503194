<!-- Créations -->
<div
  class="campagne-creation"
  [class.iframe-chili]="this._GlobalDataService.isIframeChiliLoaded"
>
  <!-- Option selection -->
  <div
    class="option"
    [class.template-valid]="
      templatesType && _GlobalDataService.isIframeChiliLoaded
    "
  >
    <app-toolbar-title
      *ngIf="!_GlobalDataService.isIframeChiliLoaded"
      title="Téléchargement de fichier de création"
      type="radio"
      [radioCheck]="_GlobalDataService.Campagne.creationType === 'upload'"
      [stateFromInput]="true"
      (radioState)="checkCreationSelection('upload')"
    >
    </app-toolbar-title>

    <!-- Upload Création/Personalisation-->
    <div *ngIf="_GlobalDataService.Campagne.creationType === 'upload'">
      <!-- Votre sélection -->
      <app-typologie-card></app-typologie-card>
      <!-- Fichiers de créations -->
      <app-fichiers-creations></app-fichiers-creations>
      <!-- Fichiers de personnalisations -->
      <app-fichiers-personnalisations></app-fichiers-personnalisations>
    </div>

    <app-toolbar-title
      *ngIf="!_GlobalDataService.isIframeChiliLoaded"
      title="Studio de création"
      type="radio"
      [radioCheck]="_GlobalDataService.Campagne.creationType === 'chili'"
      [stateFromInput]="true"
      (radioState)="checkCreationSelection('chili')"
    >
    </app-toolbar-title>

    <ng-container
      *ngIf="
        _GlobalDataService.Campagne.creationType === 'chili' &&
        !this._GlobalDataService.isIframeChiliLoaded
      "
    >
      <!-- Import fichiers de ciblage -->
      <div
        *ngIf="
          _GlobalDataService.isStandaloneContext() &&
          !_GlobalDataService.isIframeChiliLoaded
        "
      >
        <p *ngIf="!isCiblageLoaded" class="gtmt-crea-message">
          Pour pouvoir utiliser le studio de création, vous devez importer un
          fichier de ciblage.
          <a href="#" onclick="return false" (click)="showFichierCiblage()"
            ><strong>Importer un fichier de ciblage</strong></a
          >
        </p>
        <app-campagne-standalone-ciblage
          *ngIf="isCiblageLoaded"
        ></app-campagne-standalone-ciblage>
      </div>

      <!-- Ouvrir button -->
      <div class="chili-content">
        <button
          *ngIf="!_GlobalDataService.isIframeChiliLoaded"
          [disabled]="
            _GlobalDataService.isStandaloneContext() &&
            !_GlobalDataService.Campagne.importFile
          "
          mat-raised-button
          color="blue"
          (click)="loadChili()"
        >
          Ouvrir &gt;
        </button>
      </div>
    </ng-container>
  </div>

  <!-- IFrame Chili creation loaded -->
  <ng-container
    *ngIf="_GlobalDataService.Campagne.creationType === 'chili' && this.iframe"
  >
    <div
      class="chili"
      [class.template-valid]="
        (templatesType && _GlobalDataService.isIframeChiliLoaded) ||
        iframeHasError
      "
    >
      <!-- Go Back Buttons -->
      <div id="gobackbuttons">
        <button
          mat-raised-button
          color="blue"
          (click)="checkCreationSelection('upload')"
        >
          Changer d'option de création
        </button>
        <button mat-raised-button color="blue" (click)="leaveAction()">
          Quitter
        </button>
      </div>

      <!-- Parcours Chili -->
      <div class="chili-content" [class.is-loading]="isLoadingChiliIframe">
        <!-- spinner -->
        <div *ngIf="isLoadingChiliIframe" class="spinner-container">
          <mat-spinner></mat-spinner>
        </div>

        <!-- Background iframe and Error message -->
        <div *ngIf="iframeHasError" class="spinner-container">
          <div class="error-message">
            <div class="icon">
              <mat-icon svgIcon="attention"></mat-icon>
            </div>
            <div>
              Nous rencontrons actuellement un problème technique.
              <br />
              Veuillez réessayer ultérieurement. Merci de votre compréhension.
            </div>
          </div>
        </div>

        <!-- Chili Iframe -->
        <iframe
          id="iframe"
          *ngIf="!iframeHasError"
          [ngClass]="
            _GlobalDataService.isChiliFullpageMode
              ? 'iframe-fullpage'
              : 'iframe-creation'
          "
          [src]="getIframe()"
          frameborder="0"
          allowfullscreen
          sandbox="allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
        ></iframe>
      </div>
    </div>
  </ng-container>

  <!-- Stepper navigation  -->
  <app-campagne-stepper-nav
    [class.stepper-nav-masked]="_GlobalDataService.isChiliFullpageMode"
    [prevDisplay]="true"
    [nextDisplay]="true"
    (previous)="goTo('previous')"
    (next)="goTo('next')"
  ></app-campagne-stepper-nav>

  <!-- Pop Over -->
  <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="gtmt-popover-card">
      <mat-card-header>
        Point d’information
        <mat-icon svgIcon="Infos"></mat-icon>
      </mat-card-header>
      <mat-card-content>
        <p>
          Veuillez joindre tous les éléments nécessaires à l’impression de votre
          commande courrier :
        </p>

        <p>
          <li>fichiers haute définition (PDF HD, 600 DPI),</li>
          <li>
            fichiers basse définition avec repères pour la personnalisation,
          </li>
          <li>fichiers sources (type InDesign),</li>
          <li>polices de caractères (OTF, TTF) si nécessaire</li>
          <li>images en haute définition</li>
        </p>

        <p>
          Merci de vous assurer que vous avez bien chargé les fichiers
          concernant l’enveloppe. Consultez
          <span (click)="openHelp('new_campaign_step3')" style="cursor: pointer"
            ><strong>l’Aide</strong>
          </span>
          pour obtenir plus d’informations.
        </p>

        <p>
          Consultez
          <span (click)="openHelp('new_campaign_step3')" style="cursor: pointer"
            ><strong>l’Aide</strong>
          </span>
          pour obtenir plus d’informations sur le guide de fabrication, le
          modèle de message pour l’équipe de production et le simulateur de
          tarif.
        </p>
      </mat-card-content>
    </mat-card>
  </mde-popover>

  <!-- Loader overlay on loading -->
  <mat-spinner-overlay [overlay]="true" *ngIf="displaySpinnerOverlay()">
  </mat-spinner-overlay>
</div>
