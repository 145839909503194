<mat-card class="gtmt-logo-container">

    <mat-card-header>
        <mat-card-title>
            <h2>
                Service alliage
            </h2>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>

        <p>
            Pour améliorer votre campagne courrier nous vous proposons le traitement des plis non distribués. Collecte
            des motifs et mise à jour de votre base de contact
        </p>


    </mat-card-content>

    <mat-card-actions align="end">
        <mat-grid-list cols="2" rowHeight="4rem" [gutterSize]="'2.5rem'" style="width:100%">

            <mat-grid-tile colspan="1" rowspan="1">
            </mat-grid-tile>

            <mat-grid-tile class="gtmt-jc-end" colspan="1" rowspan="1">
                <button mat-raised-button color="blue" (click)="ValiderSelection()">
                    Valider la sélection
                </button>
            </mat-grid-tile>

        </mat-grid-list>
    </mat-card-actions>

</mat-card>