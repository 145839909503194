<!-- <mat-toolbar class="gtmt-header">
	<a mat-icon-button *ngIf="showButtonRetour" href="javascript:history.back()">
		<mat-icon>chevron_left</mat-icon>
	</a>
	<mat-grid-list cols="9" rowHeight="6rem">

		<mat-grid-tile colspan="2" rowspan="1">
			<img alt="GTMT Logo" style="max-width: 23rem;" src="assets/logo_capmailingplateforme.png" />

		</mat-grid-tile>

		<mat-grid-tile colspan="4" rowspan="1">
			<h1>
				{{titre}}
			</h1>
		</mat-grid-tile>

		<mat-grid-tile class="gtmt-flex-end" colspan="3" rowspan="1">
			<div>
				<button title="Aide" mat-icon-button (click)="OpenHelpFile()">
					<mat-icon>help_outline</mat-icon>
				</button>
			</div>
			<div [hidden]="!showButtonSetting">
				<button title="Mon compte client" mat-icon-button>
					<mat-icon (click)="goToParametre()">settings_outline</mat-icon>
				</button>
			</div>
			<div (click)="showInfo()">
				<button title="Information application" mat-icon-button>
					<mat-icon>info_outline</mat-icon>
				</button>
			</div>
		</mat-grid-tile>
	</mat-grid-list>
</mat-toolbar> -->

<mat-toolbar class="gtmt-header">
	<a mat-icon-button *ngIf="showButtonRetour" href="javascript:history.back()" aria-label="back">
		<mat-icon>chevron_left</mat-icon>
	</a>
	<img alt="GTMT Logo" style="max-width: 23rem; margin-left: 8px; margin-top: -3px" src="assets/logo_capmailingplateforme.png" />
	<span class="spacer"></span>
	<button title="Information application" mat-icon-button (click)="showInfo()">
		<mat-icon>info_outline</mat-icon>
	</button>
	<button title="Mon compte client" mat-icon-button *ngIf="showButtonSetting">
		<mat-icon (click)="goToParametre()">settings_outline</mat-icon>
	</button>
	<button title="Aide" mat-icon-button (click)="OpenHelpFile()">
		<mat-icon svgIcon="Aide"></mat-icon>
	</button>
	<!-- <app-firestore *ngIf="environment.name == 'dev'"></app-firestore> -->
</mat-toolbar>