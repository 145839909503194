import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService, GlobalDataService } from '@core/services';


@Component({
  selector: 'app-create-annonceur',
  templateUrl: './create-annonceur.component.html',
  styleUrls: ['./create-annonceur.component.scss']
})
export class CreateAnnonceurComponent implements OnInit {


  hidePasswordSFTP: boolean = true;

  constructor(
    private router: Router,
    public _AccountService: AccountService, 
    private _GlobalDataService: GlobalDataService) { }


  ngOnInit(): void {
  }

  onSubmit() {
    console.log("Submit : ", this._AccountService.userForm);

    if (this._AccountService.EditMode) {
      this._AccountService.saveAnnonceurDetails(this._AccountService.userForm.value)
        .subscribe(
          (response) => {
            this._GlobalDataService.alert(`L'annonceur ${this._AccountService.userForm.value.annonceurName} a été mis à jour avec succès`);
            this._AccountService.userForm.reset();
          },
          (error) => {
            this._GlobalDataService.alert(`Erreur technique lors de la mise à jour de l'annonceur ${this._AccountService.userForm.value.annonceurName}`);
            console.log(error);
          }
        );
    }
    else {
      this._AccountService.standaloneCreateAnnonceur(this._AccountService.userForm.value)
        .subscribe(
          (response) => {
            this._GlobalDataService.alert(`L'annonceur ${this._AccountService.userForm.value.annonceurName} a été créé avec succès`);
            this._AccountService.userForm.reset();
            this.gotoGestionAnnonceurs();
          },
          (error) => {
            this._GlobalDataService.alert(`Erreur technique lors de la création de l'annonceur ${this._AccountService.userForm.value.annonceurName}`);
            console.log(error);
          }
        );
    }
  }

  gotoGestionAnnonceurs() {
    console.log('gotoGestionAnnonceurs');
    // this.router.navigate([{ outlets: {  navview: ['/administration/admin-campagnes/9'] }}]).then((e) => {
    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['manage-annonceurs'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

}
