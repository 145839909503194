import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StdUserRouteAccessService, UserRouteAccessService } from '@core/services';
import { MentionsLegalesComponent } from '@shared/components/mentions-legales/mentions-legales.component';
import { ResetComponent, SuiviCampagneRecurrenteComponent, SuiviCampagnesComponent, SuiviCampagneUneSeuleFoisComponent, SuiviDetailCampagneComponent } from 'app/administration/components';
import { GestionFavorisComponent } from 'app/administration/components/gestion-favoris/gestion-favoris.component';
import { CampagneDetailsComponent, CampagneTypologieDimensionComponent, CampagneTypologieEncreComponent, CampagneTypologieEnveloppeComponent, CampagneTypologieFinitionComponent, CampagneTypologieFormatComponent, CampagneTypologieGrammageComponent, CampagneTypologiePageComponent, CampagneTypologieSelectionComponent, CampagneTypologieSelectionEnveloppeComponent, CampagneTypologieSelectionLettreComponent, CampagneValidationBatComponent, MessageConfirmationComponent } from 'app/campagne/components';
import { ChangePasswordCheckComponent, ExpiredPasswordComponent, ChangePasswordRequestComponent, CompteClientComponent, LoginComponent, LoginPageComponent } from './components';
import { CreateUserComponent } from './components/admin-annonceur/create-user/create-user.component';
import { ManageUsersComponent } from './components/admin-annonceur/manage-users/manage-users.component';
import { CreateAnnonceurComponent } from './components/admin-laposte/create-annonceur/create-annonceur.component';
import { ManageAnnonceursComponent } from './components/admin-laposte/manage-annonceurs/manage-annonceurs.component';
import { HomeComponent } from './components/home/home.component';
import { BienvenueComponent } from './components/bienvenue/bienvenue.component';
import { ProfileComponent } from './components/profile/profile.component';

const routes: Routes = [
  { 
    path: '', 
    component: LoginPageComponent
  },
  { 
    path: 'loginpage', 
    component: LoginPageComponent,
    children: [
      { 
        path: 'login', 
        component: LoginComponent, 
        outlet: 'loginview',
      },   
      { 
        path: 'change-password-request', 
        component: ChangePasswordRequestComponent, 
        outlet: 'loginview',
      },   
      { 
        path: 'expired-password', 
        component: ExpiredPasswordComponent, 
        outlet: 'loginview',
      },
      { 
        path: 'change-password-check', 
        component: ChangePasswordCheckComponent, 
        outlet: 'loginview',
      },      
      { 
        path: 'mentions-legales', 
        component: MentionsLegalesComponent, 
        outlet: 'loginview',
      },       
    ]     
  },    
  { 
    path: 'password-reset', 
    component: ResetComponent 
  },  
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [StdUserRouteAccessService],
    children: [
      { 
        path: 'bienvenue', 
        component: BienvenueComponent, 
        outlet: 'navview',
      },
      { 
        path: 'parametrage', 
        component: CompteClientComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },
      { 
        path: 'gestion-favoris', 
        component: GestionFavorisComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },      
      { 
        path: 'create-user', 
        component: CreateUserComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },      
      { 
        path: 'user-profile', 
        component: ProfileComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },             
      {
        path: 'suivi-campagnes/:IdAnnonceur',
        component: SuiviCampagnesComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },   
      {
        path: 'suivi-detail-campagne/:IdCampagne',
        component: SuiviDetailCampagneComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },     
      {
        path: 'suivi-campagne-une-seule-fois/:IdCampagne',
        component: SuiviCampagneUneSeuleFoisComponent, 
        canActivate: [UserRouteAccessService],
        outlet: 'navview',
      },
      {
        path: 'suivi-campagne-recurrente/:IdCampagne',
        component: SuiviCampagneRecurrenteComponent, 
        canActivate: [UserRouteAccessService],
        outlet: 'navview',       
      },       
      { 
        path: 'nouvelle-campagne', 
        component: CampagneDetailsComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
        children: [
          {
            path: 'typologie-selection',
            component: CampagneTypologieSelectionComponent,
            outlet: 'navtypologie',
          },
          {
            path: 'typologie-selection-lettre',
            component: CampagneTypologieSelectionLettreComponent,
            outlet: 'navtypologie',
          },
          {
            path: 'typologie-selection-enveloppe',
            component: CampagneTypologieSelectionEnveloppeComponent,
            outlet: 'navtypologie',
          },           
          {
            path: 'typologie-format',
            component: CampagneTypologieFormatComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-dimension',
            component: CampagneTypologieDimensionComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-grammage',
            component: CampagneTypologieGrammageComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-finition',
            component: CampagneTypologieFinitionComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-encre',
            component: CampagneTypologieEncreComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-page',
            component: CampagneTypologiePageComponent,
            outlet: 'navtypologie'
          },   
          {
            path: 'typologie-enveloppe',
            component: CampagneTypologieEnveloppeComponent,
            outlet: 'navtypologie'
          },                   
        ]        
      },
      { 
        path: 'campagne-details/:IdCampagne', 
        component: CampagneDetailsComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
        children: [
          {
            path: 'typologie-selection',
            component: CampagneTypologieSelectionComponent,
            outlet: 'navtypologie',
          },
          {
            path: 'typologie-selection-lettre',
            component: CampagneTypologieSelectionLettreComponent,
            outlet: 'navtypologie',
          },
          {
            path: 'typologie-selection-enveloppe',
            component: CampagneTypologieSelectionEnveloppeComponent,
            outlet: 'navtypologie',
          },           
          {
            path: 'typologie-format',
            component: CampagneTypologieFormatComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-dimension',
            component: CampagneTypologieDimensionComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-grammage',
            component: CampagneTypologieGrammageComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-finition',
            component: CampagneTypologieFinitionComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-encre',
            component: CampagneTypologieEncreComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-page',
            component: CampagneTypologiePageComponent,
            outlet: 'navtypologie'
          },   
          {
            path: 'typologie-enveloppe',
            component: CampagneTypologieEnveloppeComponent,
            outlet: 'navtypologie'
          },                   
        ]        
      },  
      { 
        path: 'message-confirmation/:NomCampagne', 
        component: MessageConfirmationComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },    
      {
        path: 'validation-bat',
        component: CampagneValidationBatComponent,
        outlet: 'navview',
        // resolve: { campagne: CampageResolverService },  
      },
      { 
        path: 'manage-annonceurs', 
        component: ManageAnnonceursComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },   
      { 
        path: 'create-annonceur', 
        component: CreateAnnonceurComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },   
      { 
        path: 'manage-users', 
        component: ManageUsersComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },      
      { 
        path: 'create-user', 
        component: CreateUserComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      }, 
      { 
        path: 'mentions-legales', 
        component: MentionsLegalesComponent, 
        outlet: 'navview',
      },   
    ],
  },


  // { path: 'profile', component: ProfileComponent, canActivate: [StdUserRouteAccessService] },


  // { path: 'admin-annonceur/manage-users', component: ManageUsersComponent, canActivate: [StdUserRouteAccessService] },
  // { path: 'admin-annonceur/create-user', component: CreateUserComponent, canActivate: [StdUserRouteAccessService] },

  // { path: 'admin-laposte/create-annonceur', component: CreateAnnonceurComponent, canActivate: [StdUserRouteAccessService] },
  // { path: 'admin-laposte/manage-annonceurs', component: ManageAnnonceursComponent, canActivate: [StdUserRouteAccessService] },

  // { path: 'liste-campagnes', component: ListeCampagnesComponent, canActivate: [StdUserRouteAccessService] },
  // { path: 'creation-campagne/:IdCampagne', component: CampagneDetailsComponent, canActivate: [StdUserRouteAccessService] },
  // { path: 'creation-campagne', component: CampagneDetailsComponent, canActivate: [StdUserRouteAccessService] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StandaloneRoutingModule { }
