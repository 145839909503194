<mat-card>
    <mat-card-content>
        <mat-grid-list *ngIf="adresse" class="gtmt-card-container" cols="1" rowHeight="3rem" [gutterSize]="'0.5rem'" style="width: 100%">
            <!-- Raison sociale -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <span class="gtmt-label">Raison sociale</span>
                <mat-icon svgIcon="Crayon" (click)="onEdit($event)"></mat-icon>
                <mat-icon svgIcon="Croix" (click)="onRemove($event)"></mat-icon>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{adresse.RaisonSociale}}</span>
            </mat-grid-tile>

            <!-- Numéro / libellé de la voie -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <span class="gtmt-label">Numéro / libellé de la voie</span>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{adresse.Numero}}</span>
            </mat-grid-tile>

            <!-- Ville -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <span class="gtmt-label">Ville</span>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{adresse.Ville}}</span>
            </mat-grid-tile>            

            <!-- Ville -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <span class="gtmt-label">Code postal</span>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{adresse.CodePostal}}</span>
            </mat-grid-tile>            
        </mat-grid-list>
    </mat-card-content>
</mat-card>