import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { AdressesDestinatairesService, GlobalDataService, PersonalisationService } from '@core/services';
import { MatStepper } from '@angular/material/stepper';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { ChampsFusion } from '@core/models/champsFusion.model';

@Component({
  selector: 'app-campagne-personnalisation',
  templateUrl: './campagne-personnalisation.component.html',
  styleUrls: ['./campagne-personnalisation.component.scss']
})
export class CampagnePersonnalisationComponent implements OnInit, OnDestroy {
  

  @ViewChild('champFusionRef') private champFusionRef!: ElementRef;

  messagePurge = "L’aperçu de la personnalisation n’est plus disponible.";

  constructor(
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    public _ChampsFusionService: ChampsFusionService,
    public personalisationService: PersonalisationService,
    public  adressesDestinatairesService: AdressesDestinatairesService,
  ) {

  }

  ngOnInit() {
    this.personalisationService.initialise();
  }

  ngAfterViewInit() {

    // streams
    const keyup2$ = fromEvent(this.champFusionRef.nativeElement, 'keyup');

    // wait .5s between keyups to emit current value
    keyup2$
      .pipe(
        map((event: any) => event.currentTarget.value),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filterChampsFusion(value)),
      )
      .subscribe((result) => {
        console.log(result);
        this.personalisationService.filteredAvailableChampsFusion = result;
      });
  }


  private filterChampsFusion(champFusionName: string): any[] {
    return this.personalisationService.availableChampsFusion
      .filter(cf => cf.ChampFusion.toLowerCase().includes(champFusionName.toLowerCase()))
      .filter(cf => this.personalisationService.selectedChampsFusion.indexOf(cf) < 0);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  selectChampFusion(champsFusion: ChampsFusion) {
    transferArrayItem(
      this.personalisationService.filteredAvailableChampsFusion,
      this.personalisationService.selectedChampsFusion,
      this.personalisationService.filteredAvailableChampsFusion.indexOf(champsFusion),
      this.personalisationService.selectedChampsFusion.length - 1
    );
  }

  unselectChampFusion(champsFusion: ChampsFusion) {
    transferArrayItem(
      this.personalisationService.selectedChampsFusion,
      this.personalisationService.filteredAvailableChampsFusion,
      this.personalisationService.selectedChampsFusion.indexOf(champsFusion),
      this.personalisationService.filteredAvailableChampsFusion.length - 1
    );
  }

  Vider() {
    console.log('Vider');
    this.personalisationService.filteredAvailableChampsFusion = this.personalisationService.filteredAvailableChampsFusion.concat(this.personalisationService.selectedChampsFusion);
    this.personalisationService.selectedChampsFusion = [];
  }

  submit() {
    this._stepper.next();
  }

  goToPrevious(){
    this._stepper.previous();
  }

  ngOnDestroy() {
  }

}

