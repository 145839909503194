import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { GlobalDataService } from '@core/services';
import { FichierCreaService } from '@core/services/fichier-crea.service';

@Component({
  selector: 'app-fichiers-crea-card',
  templateUrl: './fichiers-crea-card.component.html',
  styleUrls: ['./fichiers-crea-card.component.scss']
})
export class FichiersCreaCardComponent implements OnInit {

  @Input() IdCampagne?: string;

  @Input() FichiersCreation?: Array<FichiersCreation>;

  @Output('FichiersCreation')
  requestFichiersCreation: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _FichierCreaService: FichierCreaService,
    public _GlobalDataService: GlobalDataService,

  ) {
  }

  ngOnInit(): void {
    if (this.IdCampagne) {
      this._FichierCreaService.list(this.IdCampagne as string)
        .subscribe({
          next: (response) => {
            if (typeof response.fichierCreation === 'object') {
              this.FichiersCreation = response.fichierCreation;
              this.requestFichiersCreation.emit({
                FichiersCreation: response.fichierCreation
              });

            }
          },
          error: (e) => console.error(e)
        });
    }
  }

  downloadFile(fileName: string) {
    // console.log("download file " + fileName);
    return this._FichierCreaService.download(fileName, this.IdCampagne)
      .subscribe(response => {
        console.log(response);
        var blob = new Blob([response], { type: response.type });
        //Create a link element, hide it, direct
        //it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style.display = "none";
        a.target = "_blank";
        a.rel = "noopener noreferrer"
        document.body.appendChild(a);
        //Create a DOMString representing the blob
        //and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);;
      });
    // return this._sanitizer.bypassSecurityTrustUrl(base64File);
  }
}
