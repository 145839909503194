import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-support-grammage-card',
  templateUrl: './support-grammage-card.component.html',
  styleUrls: ['./support-grammage-card.component.scss']
})
export class SupportGrammageCardComponent implements OnInit {
  @Input() typologie?: string;

  navigationOption = {};

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,    
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,    
  ) {
   }

  ngOnInit(): void {
    this.navigationOption = { typologie: this.typologie };

    // Grammage
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.produits
      .filter((element: { CodeDimensionsSupport: string | undefined; CodeSupport: string | undefined; CodeTypologie: any; }) => element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataGrammagesSupports, 'CodeGrammageSupport');

    if (this._CampagneTypologieService.dataGrammagesSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeGrammageSupport = this._CampagneTypologieService.dataGrammagesSupports[0].CodeGrammageSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeGrammageSupport = "";
    }

  }

  ngAfterViewInit() {
    this._CampagneTypologieService.SupportFormGroup.get('CodeGrammageSupport')
        .valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneTypologieService.SupportFormGroup.get('CodeGrammageSupport').valid) {

        console.log('CodeGrammageSupport value changed')
        console.log(this._CampagneTypologieService.SupportFormGroup.get('CodeGrammageSupport').value);
       
        setTimeout(() => {
          console.log(this._CampagneTypologieService.SupportFormGroup.value)
        })

        // update model
        this._GlobalDataService.Campagne.CodeGrammageSupport = value;
      }
    });
  }


  Valider() {
    if (!this._GlobalDataService.Campagne.CodeGrammageSupport) {
      this._GlobalDataService.alert("Veuillez sélectionner un Grammage");
      return;
    }    
    this.grammageChanged();
    this._CampagneTypologieService.navigate(navPaths.finition, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  grammageChanged() : void {
    console.log('_GlobalDataService.Campagne.CodeGrammageSupport = ');
    console.log(this._GlobalDataService.Campagne.CodeGrammageSupport)  
    
    this._GlobalDataService.Campagne.isDirty = true;

    // Finition
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataFinitionsSupports, 'CodeFinitionSupport');

    if (this._CampagneTypologieService.dataFinitionsSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeFinitionSupport = this._CampagneTypologieService.dataFinitionsSupports[0].CodeFinitionSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeFinitionSupport = "";
    }

    this._CampagneTypologieService.SupportFormGroup.get('CodeFinitionSupport').patchValue(this._GlobalDataService.Campagne.CodeFinitionSupport, { emitEvent: false });


    // Encre
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.produits
      .filter((element: { CodeFinitionSupport: any; CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeFinitionSupport == this._GlobalDataService.Campagne.CodeFinitionSupport
        && element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEncresSupports, 'CodeFinitionSupport');


    if (this._CampagneTypologieService.dataEncresSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeEncreSupport = this._CampagneTypologieService.dataEncresSupports[0].CodeEncreSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeEncreSupport = "";
    }

    this._CampagneTypologieService.SupportFormGroup.get('CodeEncreSupport').patchValue(this._GlobalDataService.Campagne.CodeEncreSupport, { emitEvent: false });

  }
}
