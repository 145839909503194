<!-- Save Campaign button -->
<div *ngIf="!this._GlobalDataService.isIframeChiliLoaded" class="gtmt-btn-save">
  <app-campagne-save [campaignName]="this.campaignName"> </app-campagne-save>
</div>

<mat-horizontal-stepper
  [class.disabled]="_GlobalDataService.isIframeChiliLoaded"
  [@.disabled]="true"
  [selectedIndex]="selectedIndex"
  labelPosition="end"
  [linear]="false"
  #stepper
  color="accent"
  (selectionChange)="selectionChange($event)"
  (click)="onClickMatStepper($event)"
>
  <!-- Debut -->

  <!-- Identification -->
  <mat-step completed="false">
    <ng-template matStepLabel>Identification </ng-template>

    <mat-grid-list cols="10" rowHeight="12rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="10" rowspan="10">
        <app-campagne-identification
          style="width: 100%"
          *ngIf="_GlobalDataService.isLoaded"
          (nameChangeEvent)="campaignNameChange($event)"
        >
        </app-campagne-identification>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Typologie -->
  <mat-step completed="false">
    <ng-template matStepLabel> Typologie </ng-template>

    <mat-grid-list cols="10" rowHeight="12rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="10">
        <app-campagne-typologie
          style="width: 100%"
          *ngIf="_GlobalDataService.isLoaded"
        ></app-campagne-typologie>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="20">
        <app-estimation-card
          style="width: 100%"
          *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo"
          [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          "
          [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          "
        >
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Créations -->
  <mat-step completed="false">
    <ng-template matStepLabel> Créations </ng-template>

    <mat-grid-list cols="10" rowHeight="12rem" [gutterSize]="'2rem'">
      <!-- Iframe Chili in creation component is full screen when chili mode -->
      <mat-grid-tile
        class="gtmt-ai-start"
        [colspan]="_GlobalDataService.isIframeChiliLoaded ? 10 : 8"
        rowspan="100"
      >
        <app-campagne-creation
          style="width: 100%"
          [stepper]="stepper"
          *ngIf="_GlobalDataService.isLoaded"
        ></app-campagne-creation>
      </mat-grid-tile>
      <mat-grid-tile
        *ngIf="!_GlobalDataService.isIframeChiliLoaded"
        class="gtmt-ai-start"
        colspan="2"
        rowspan="100"
      >
        <app-estimation-card
          style="width: 100%"
          *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo"
          [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          "
          [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          "
        >
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Personnalisation -->
  <mat-step completed="false">
    <ng-template matStepLabel> Personnalisation </ng-template>

    <ng-template matStepContent>
      <mat-grid-list cols="10" rowHeight="16rem" [gutterSize]="'2rem'">
        <mat-grid-tile
          class="gtmt-ai-start"
          [colspan]="
            _GlobalDataService.Campagne.creationType !== 'chili' ? 8 : 10
          "
          rowspan="10"
        >
          <!-- Personnalisation : Upload Type -->
          <ng-container
            *ngIf="
              _GlobalDataService.isLoaded &&
              (_GlobalDataService.Campagne.creationType !== 'chili')
            "
          >
            <!-- Eloqua -->
            <app-campagne-personnalisation
              style="width: 100%"
              *ngIf="_GlobalDataService.isEloquaContext()"
            >
            </app-campagne-personnalisation>

            <!-- Salesforce-->
            <app-campagne-personnalisation-sf
              style="width: 100%"
              *ngIf="_GlobalDataService.isSalesforceContext()"
            >
            </app-campagne-personnalisation-sf>

            <!-- Standalone -->
            <app-campagne-personnalisation-standalone
              style="width: 100%"
              *ngIf="_GlobalDataService.isStandaloneContext()"
            >
            </app-campagne-personnalisation-standalone>
          </ng-container>

          <!-- Personnalisation : Chili Type -->
          <ng-container
            *ngIf="
              _GlobalDataService.isLoaded &&
              _GlobalDataService.Campagne.creationType === 'chili'
            "
          >
            <div class="perso-chili">
              <app-chili-iframe-personnalisation
                [idCampagne]="this._GlobalDataService.Campagne.IdCampagne"
              >
              </app-chili-iframe-personnalisation>

              <!-- Stepper navigation  -->
              <app-campagne-stepper-nav
                [prevDisplay]="true"
                [nextDisplay]="true"
                (previous)="stepper.previous()"
                (next)="stepper.next()"
              ></app-campagne-stepper-nav>
            </div>
          </ng-container>
        </mat-grid-tile>
        <mat-grid-tile
          *ngIf="_GlobalDataService.Campagne.creationType !== 'chili'"
          class="gtmt-ai-start"
          colspan="2"
          rowspan="10"
        >
          <app-estimation-card
            style="width: 100%"
            *ngIf="_GlobalDataService.isLoaded"
            [showInfo]="_GlobalDataService.Campagne.showInfo"
            [showRefresh]="_GlobalDataService.Campagne.showRefresh"
            [showunitAmountWithoutTax]="
              _GlobalDataService.Campagne.showunitAmountWithoutTax
            "
            [showamountWithoutTax]="
              _GlobalDataService.Campagne.showamountWithoutTax
            "
          >
          </app-estimation-card>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-template>
  </mat-step>

  <!-- Planification -->
  <mat-step completed="false">
    <ng-template matStepLabel> Planification </ng-template>

    <mat-grid-list cols="10" rowHeight="14rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="16">
        <app-campagne-planification
          style="width: 100%"
          *ngIf="_GlobalDataService.isLoaded"
        >
        </app-campagne-planification>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="16">
        <app-estimation-card
          style="width: 100%"
          *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo"
          [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          "
          [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          "
        >
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Adresses -->
  <mat-step completed="false">
    <ng-template matStepLabel> Adresses </ng-template>

    <ng-template matStepContent>
      <mat-grid-list cols="10" rowHeight="18rem" [gutterSize]="'2rem'">
        <mat-grid-tile
          class="gtmt-ai-start"
          [colspan]="
            _GlobalDataService.Campagne.creationType !== 'chili' ? 8 : 10
          "
          rowspan="10"
        >
          <app-campagne-adresses
            style="width: 100%"
            *ngIf="_GlobalDataService.isLoaded"
          ></app-campagne-adresses>
        </mat-grid-tile>
        <mat-grid-tile
          *ngIf="_GlobalDataService.Campagne.creationType !== 'chili'"
          class="gtmt-ai-start"
          colspan="2"
          rowspan="10"
        >
          <app-estimation-card
            style="width: 100%"
            *ngIf="_GlobalDataService.isLoaded"
            [showInfo]="_GlobalDataService.Campagne.showInfo"
            [showRefresh]="_GlobalDataService.Campagne.showRefresh"
            [showunitAmountWithoutTax]="
              _GlobalDataService.Campagne.showunitAmountWithoutTax
            "
            [showamountWithoutTax]="
              _GlobalDataService.Campagne.showamountWithoutTax
            "
          >
          </app-estimation-card>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-template>
  </mat-step>

  <!-- Récapitulatif -->
  <mat-step completed="false">
    <ng-template matStepLabel> Récapitulatif </ng-template>

    <mat-grid-list cols="10" rowHeight="12rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="18">
        <app-campagne-recapitulatif
          style="width: 100%"
          [stepper]="stepper"
          *ngIf="_GlobalDataService.isLoaded"
        >
        </app-campagne-recapitulatif>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="18">
        <app-estimation-card
          style="width: 100%"
          *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo"
          [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          "
          [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          "
        >
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Fin -->
</mat-horizontal-stepper>
