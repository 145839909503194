<form [formGroup]="userForm">
  <mat-card class="parameter-card">
    <mat-card-header>
      <mat-card-title>Ajout d'utilisateur</mat-card-title>
    </mat-card-header>

    <!-- Form -->
    <mat-card-content>
      <mat-grid-list cols="1" rowHeight="5rem">
        <!-- Login -->
        <mat-grid-tile colspan="1" rowspan="2">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Login</mat-label>
            <input type="text" matInput formControlName="login" required />
          </mat-form-field>
        </mat-grid-tile>

        <!-- Firstname -->
        <mat-grid-tile colspan="1" rowspan="2">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Prénom</mat-label>
            <input type="text" matInput formControlName="firstname" required />
          </mat-form-field>
        </mat-grid-tile>

        <!-- Lastname -->
        <mat-grid-tile colspan="1" rowspan="2">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="lastname" required />
          </mat-form-field>
        </mat-grid-tile>

        <!-- Email -->
        <mat-grid-tile colspan="1" rowspan="2">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Email</mat-label>
            <input type="text" matInput formControlName="email" required />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>

    <!-- Actions -->
    <mat-card-actions>
      <!-- Create User -->
      <button
        class="btn-create-user"
        mat-raised-button
        color="primary"
        [disabled]="userForm.invalid || isLoadingCreateUser"
        (click)="onSubmit()"
      >
        <span>Créer l'utilisateur</span>
        <mat-spinner *ngIf="isLoadingCreateUser" diameter="24"></mat-spinner>
      </button>
    </mat-card-actions>
  </mat-card>
</form>
