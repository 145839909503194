<form [formGroup]="_AccountService.userForm">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Création d'un compte client</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <mat-toolbar>
                <h2>
                    Informations compte client
                </h2>
            </mat-toolbar>

            <mat-grid-list cols="2" rowHeight="2rem">
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Nom de l'annonceur</mat-label>
                        <input type="text" matInput formControlName="annonceurName" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° client</mat-label>
                        <input type="text" matInput formControlName="NumeroClient">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° contrat</mat-label>
                        <input type="text" matInput formControlName="NumeroContrat">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° contrat Alliage</mat-label>
                        <input type="text" matInput formControlName="NumeroContratAlliage">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Identifiant Alliage</mat-label>
                        <input type="text" matInput formControlName="IdentifiantAlliageGE">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>SIRET</mat-label>
                        <input type="text" matInput formControlName="Siret">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Raison sociale</mat-label>
                        <input type="text" matInput formControlName="RaisonSociale">
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-toolbar>
                <h2>
                    Informations SFTP
                </h2>                
            </mat-toolbar>            

            <mat-grid-list cols="2" rowHeight="2rem">
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Login SFTP</mat-label>
                        <input type="text" matInput formControlName="LoginSFTP">
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-toolbar>
                <h2>
                    Informations Administrateur
                </h2>                
            </mat-toolbar>

            <mat-grid-list cols="2" rowHeight="2rem">
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Nom</mat-label>
                        <input type="text" matInput formControlName="lastname" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Prénom</mat-label>
                        <input type="text" matInput formControlName="firstname" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Email</mat-label>
                        <input type="text" matInput formControlName="email" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Login</mat-label>
                        <input type="text" matInput formControlName="login" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Téléphone</mat-label>
                        <input type="text" matInput formControlName="TelephoneContact">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Rôle</mat-label>
                        <input type="text" matInput formControlName="RoleContact">
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button color="primary" [disabled]="!_AccountService.userForm.valid" (click)="onSubmit()">Valider /
                Enregistrer</button>
        </mat-card-actions>
    </mat-card>
</form>

