import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Campagne } from '@core/models/campagne.model';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-champ-fusion-card',
  templateUrl: './champ-fusion-card.component.html',
  styleUrls: ['./champ-fusion-card.component.scss'],
  // changeDetection: ChangeDetectionStrategy.Default
})
export class ChampFusionCardComponent implements OnInit {


  @Input() IdCampagne?: string;
  @Input() cfs?: Array<ChampsFusion>;


  @Output('ChampsPersonnalisation')
  requestChampsPersonnalisation: EventEmitter<any> = new EventEmitter<any>();

  constructor(public _ChampsFusionService: ChampsFusionService,
  ) {

    this.cfs = new Array<ChampsFusion>();

  }
  ngOnInit(): void {
    if (this.IdCampagne) {
      this._ChampsFusionService.list(<string>this.IdCampagne)
      .subscribe({
        next: (response: any) => {
          if (response.ChampsFusion.length > 0) {
            for (var i = 0; i < response.ChampsFusion.length; i++) {
              // CF = result.champsFusion.ChampsFusion[i];
              var CF = {
                Id: response.ChampsFusion[i].Id,
                ChampFusion: response.ChampsFusion[i].ChampFusion,
                internalName: response.ChampsFusion[i].InternalName,
                Sequence: response.ChampsFusion[i].Sequence,
                IsPaveAdresse: response.ChampsFusion[i].IsPaveAdresse == '1' ? 'true' : 'false',
              }
              this.cfs .push(CF);
            }

            // this.cfs = response.ChampsFusion;            
            this.cfs = this.cfs.filter( (cf: ChampsFusion) => cf.IsPaveAdresse == '0' || cf.IsPaveAdresse == 'false' ).sort((a: ChampsFusion, b: ChampsFusion) => (a.Sequence - b.Sequence));          
            this.requestChampsPersonnalisation.emit({              
              ChampsPersonnalisation: this.cfs
            });            
            console.log(this.cfs);            
          }
        },
        error: (e) => console.error(e)

      });
    }
  }
}

