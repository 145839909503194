import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccountService, AnnonceurService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-profile-annonceur-card',
  templateUrl: './profile-annonceur-card.component.html',
  styleUrls: ['./profile-annonceur-card.component.scss']
})
export class ProfileAnnonceurCardComponent implements OnInit {

  constructor(public _GlobalDataService: GlobalDataService, private _AccountService: AccountService, private _AnnonceurService: AnnonceurService) { }

  annonceurForm = new FormGroup({
    login: new FormControl(""),
    annonceurName: new FormControl(""),
    NumeroClient: new FormControl(""),
    NumeroContrat: new FormControl(""),
    NumeroContratAlliage: new FormControl(""),
    IdentifiantAlliageGE: new FormControl(""),
    email: new FormControl(""),
    TelephoneContact: new FormControl(""),
    RoleContact: new FormControl(""),
    Siret: new FormControl(""),
    RaisonSociale: new FormControl("")
  });

  ngOnInit(): void {
    const login = this._GlobalDataService.getJWTLoginName();

    if (this._GlobalDataService.isAdminAnnonceur()) {
      this._AnnonceurService.get()
      .subscribe(response => {
        console.log("annonceur = ", response);
        this.annonceurForm.get('login').setValue(login);
        this.annonceurForm.get('annonceurName').setValue(response.annonceur.annonceurName);
        this.annonceurForm.get('NumeroClient').setValue(response.annonceur.NumeroClient);
        this.annonceurForm.get('NumeroContrat').setValue(response.annonceur.NumeroContrat);
        this.annonceurForm.get('NumeroContratAlliage').setValue(response.annonceur.NumeroContratAlliage);
        this.annonceurForm.get('IdentifiantAlliageGE').setValue(response.annonceur.IdentifiantAlliageGE);
        this.annonceurForm.get('email').setValue(response.annonceur.EmailContact);
        this.annonceurForm.get('TelephoneContact').setValue(response.annonceur.TelephoneContact);
        this.annonceurForm.get('RoleContact').setValue(response.annonceur.RoleContact);
        this.annonceurForm.get('Siret').setValue(response.annonceur.Siret);
        this.annonceurForm.get('RaisonSociale').setValue(response.annonceur.RaisonSociale);
      })
    }
  }

  saveAnnonceurChanges() {
    this._AccountService.saveAnnonceurDetails(this.annonceurForm.value)
    .subscribe(response => {
      this._GlobalDataService.alert("Modifications de l'annonceur enregistrées.");
    }, err => {
      this._GlobalDataService.alert("Une erreur est survenue lors de la mise à jour de vos informations personnelles : " + JSON.stringify(err));
    });
  }


}
