<mat-card>

    <h2>
        <span>Fichiers de ciblage</span>
        <mat-icon svgIcon="question" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="hover"
            #popoverTrigger="mdePopoverTrigger" style="cursor: pointer"></mat-icon>
    </h2>

    <mat-card-content>
        <mat-card-tile class="gtmt-add-file">
            <span>
                {{ csvFileName || "Fichiers de ciblage format .CSV UTF8" }}
            </span>
            <input type="file" id="file-upload-perso-standalone" style="display: none" (change)="uploadFile($event)"
                accept=".csv" />
            <button mat-raised-button color="blue" *ngIf="csvFileName == undefined" (click)="addFile()">
                Ajouter un fichier
                <mat-icon>control_point</mat-icon>
            </button>
            <button mat-raised-button color="blue" *ngIf="csvFileName" (click)="deleteFile()">
                Supprimer le fichier
            </button>
        </mat-card-tile>
        <mat-card-tile class="gtmt-deleted" *ngIf="isDeleted == true" style="font-size: 1.6rem; font-weight: 700">
            Fichier supprimé avec succès !
        </mat-card-tile>
        <div *ngIf="csvHeaders && csvRows">
            <app-tab-visualisation [headers]="csvHeaders" [rows]="csvRows"></app-tab-visualisation>
        </div>
    </mat-card-content>
</mat-card>


<!-- Popover messages -->
<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="gtmt-popover-card">
        <mat-card-content> &nbsp; </mat-card-content>
    </mat-card>
</mde-popover>

<!-- Loader overlay on upload -->
<mat-spinner-overlay [overlay]="true" *ngIf="isUploading || isDeleting">
</mat-spinner-overlay>