<table mat-table [dataSource]="dataSource" matTableExporter matSort multiTemplateDataRows #exporter="matTableExporter">
    <!-- CodeGTMT Column -->
    <ng-container matColumnDef="CodeGTMT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code GTMT </th>
        <td mat-cell *matCellDef="let commande"> {{commande.NCommandeLaPoste}} </td>
    </ng-container>

    <!-- Statut Column -->
    <ng-container matColumnDef="Statut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
        <td mat-cell *matCellDef="let commande"> {{ _GlobalDataService.mappingStatut[commande.CodeStatut]}} </td>
    </ng-container>

    <!-- DateDeTransmission Column -->
    <ng-container matColumnDef="DateDeTransmission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de transmission </th>
        <td mat-cell *matCellDef="let commande"> {{commande.DateCreation | date :'dd/MM/yyyy'}} </td>
    </ng-container>

    <!-- DateDEnvoi Column -->
    <ng-container matColumnDef="DateEnvoi">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date d'envoi </th>
        <td mat-cell *matCellDef="let commande"> {{commande.DateEnvoi | date :'dd/MM/yyyy'}} </td>
    </ng-container>

    <!-- DateDepositDone Column -->
    <ng-container matColumnDef="DateDepositDone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de dépôt</th>
        <td mat-cell *matCellDef="let commande"> {{commande.DateDepositDone | date :'dd/MM/yyyy' }} </td>
    </ng-container>

    <!-- NombreDePlis Column -->
    <ng-container matColumnDef="NombreDePlis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de plis </th>
        <td mat-cell *matCellDef="let commande"> {{commande.NombrePli}} </td>
    </ng-container>

    <!-- Annulation Column -->
    <ng-container matColumnDef="Annulation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Annulation </th>
        <td mat-cell *matCellDef="let commande">
            <button mat-raised-button color="blue" [disabled]="autoriserAnnulationCommande(commande)"
                (click)="demandeAnnulationCommande(commande); commande.CodeStatut = 'ANNULATIONDEMANDE'">
                Annuler
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr title="Commande" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow>
        <td colspan="13">Aucune donnée</td>
    </tr>
</table>

<mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>


