import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-chili-creation-preview',
  templateUrl: './chili-creation-preview.component.html',
  styleUrls: ['./chili-creation-preview.component.scss']
})
export class ChiliCreationPreviewComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public chiliCreationThumbnail: any) { }

  ngOnInit(): void {
  }

}
