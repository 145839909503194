import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuditLogService } from '../../../core/services/audit-log.service';
import { Annonceur } from '@core/models/annonceur.model';
import { AccountService, AnnonceurService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-liste-annonceurs',
  templateUrl: './liste-annonceurs.component.html',
  styleUrls: ['./liste-annonceurs.component.scss']
})
export class ListeAnnonceursComponent implements OnInit {
  isLoaded = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null | undefined;

  pageLength = 100;
  pageSize = 10;
  pageChangeEvent(event) { console.log(event) }

  dataSource = new MatTableDataSource<Annonceur>();
  annonceurs: Annonceur[];


  // columns = ['NomContact', 'PrenomContact', 'EmailContact', 'RoleContact', 'TelephoneContact', 'Siret', 'RaisonSociale',
  //   'NumeroClient', 'NumeroContrat', 'NumeroContratAlliage', 'IdentifiantAlliageGE', "Campagnes"];
  columns = ['RaisonSociale', 'NumeroContrat', 'NomContact', 'PrenomContact', 'EmailContact', 'Campagnes'];
  displayedColumns = this.columns.slice();
  columnsLabels = {
    "NomContact": "Nom",
    "PrenomContact": "Prénom",
    "EmailContact": "Email",
    "RoleContact": "Rôle",
    "TelephoneContact": "",
    "Siret": "SIRET",
    "RaisonSociale": "Raison Sociale",
    "NumeroClient": "Téléphone",
    "NumeroContrat": "Numéro Contrat",
    "NumeroContratAlliage": "Numéro Contrat Alliage",
    "IdentifiantAlliageGE": "Identifiant Alliage GE",
    "IdAnnonceur": "Identifiant Annonceur",
    "Campagnes": "Campagnes",
  };
  availableCanaux = {
    "eloqua": "null",
    "standalone": "STANDALONE",
    "salesforce": "SALESFORCE"
  }

  constructor(
    private router: Router,
    // private _AuditLogService: AuditLogService,
    public _AccountService: AccountService,
    private _AnnonceurService: AnnonceurService,
    private _GlobalDataService: GlobalDataService
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this._AnnonceurService.AdminList()
      .subscribe(
        response => {
          // this._AuditLogService.add(0, 0, `Accès La Poste`).subscribe();
          console.log("response", response)
          // si enlever les annonceurs canal non renseigné?
          // this.annonceurs = response.annonceur.filter(annonceur=> annonceur.IsEloquaUser=="1"||annonceur.IsMarketingCloudUser=="1"||annonceur.IsStandaloneUser=="1");
          this.annonceurs = response.annonceur;
          this.dataSource.data = this.annonceurs;
        },
        error => { console.log(error); }
      );
    console.log(this.annonceurs);
    this.initListAnnonceurs();
  }

  initListAnnonceurs() {
    this.dataSource.data = this.annonceurs;
  }

  filterAnnonceurs(canal: string) {
    console.log("filter", canal);
    this.initListAnnonceurs();
    if (canal != "all") {
      if (canal != "eloqua") {
        this.dataSource.data = this.dataSource.data.filter(annonceur => annonceur['TypeAnnonceur']
          == this.availableCanaux[canal]);
        console.log("annonceurs", this.dataSource.data)
        return;
      }
      this.dataSource.data = this.dataSource.data.filter(annonceur => annonceur['TypeAnnonceur']
        == this.availableCanaux[canal] || annonceur['TypeAnnonceur'] == '');
      console.log("annonceurs", this.dataSource.data)
      return;
    };
  }

  gotoSuiviCampagnes(idAnnonceur: any) {
    console.log(idAnnonceur);
    console.log('gotoCreateAnnonceur');

    this._GlobalDataService.Annonceur.IdAnnonceur = idAnnonceur;
    this.router.navigate(["/administration/home", { outlets: { 'navview': ['suivi-campagnes', this._GlobalDataService.Annonceur.IdAnnonceur] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  gotoGestionAnnonceur(annonceur: any) {
    console.log('gotoCreateAnnonceur');
    console.log("annonceur", annonceur);
    this._GlobalDataService.Annonceur = annonceur;
    console.log("this._GlobalDataService.Annonceur", this._GlobalDataService.Annonceur)


    // for evolutions
    // if (row.TypeAnnonceur == 'STANDALONE') {

    //   // API creation annonceur
    //   this._AccountService.listAnnonceurs()
    //   .subscribe((response) => {
    //     console.log("listAnnonceurs response", response);
    //     console.log("response", response);
    //   });

    //   this._AccountService.EditMode = true;
    //   this._AccountService.userForm.get('annonceurName').patchValue(row.annonceurName, { emeitEvent: false });
    //   this._AccountService.userForm.get('NumeroClient').patchValue(row.numeroClient, { emeitEvent: false });
    //   this._AccountService.userForm.get('NumeroContrat').patchValue(row.numeroContrat, { emeitEvent: false });
    //   this._AccountService.userForm.get('NumeroContratAlliage').patchValue(row.numeroContratAlliage, { emeitEvent: false });
    //   this._AccountService.userForm.get('IdentifiantAlliageGE').patchValue(row.identifiantAlliageGE, { emeitEvent: false });
    //   this._AccountService.userForm.get('login').patchValue(row.login, { emeitEvent: false });
    //   this._AccountService.userForm.get('LoginSFTP').patchValue(row.loginSFTP, { emeitEvent: false });
    //   this._AccountService.userForm.get('PasswordSFTP').patchValue(row.passwordSFTP, { emeitEvent: false });
    //   this._AccountService.userForm.get('email').patchValue(row.email, { emeitEvent: false });
    //   this._AccountService.userForm.get('firstname').patchValue(row.firstName, { emeitEvent: false });
    //   this._AccountService.userForm.get('lastname').patchValue(row.lastName, { emeitEvent: false });
    //   this._AccountService.userForm.get('TelephoneContact').patchValue(row.telephoneContact, { emeitEvent: false });
    //   this._AccountService.userForm.get('RoleContact').patchValue(row.roleContact, { emeitEvent: false });
    //   this._AccountService.userForm.get('Siret').patchValue(row.Siret, { emeitEvent: false });
    //   this._AccountService.userForm.get('RaisonSociale').patchValue(row.taisonSociale, { emeitEvent: false });


    //   console.log(this._AccountService.userForm);

    //   this.router.navigate(["/administration/home", { outlets: { 'navview': ['create-annonceur'] } }], {
    //     queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
    //     skipLocationChange: true
    //   }).then((e) => {
    //     if (e) {
    //       console.log("Navigation is successful!");
    //     } else {
    //       console.log("Navigation has failed!");
    //     }
    //   });
    //   return;

    // }

    this.router.navigate(["/administration/home", { outlets: { 'navview': ['compte-client'] } }], {
      // queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });



  }
}

