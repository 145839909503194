<mat-card>

    <div class="gtmt-header-image">
        <mat-icon svgIcon="send">
        </mat-icon>
    </div>

    <mat-card-header>

        <mat-card-title>
            Vérifiez vos mails ! 
        </mat-card-title>
        <mat-card-subtitle>
            Nous vous avons envoyé un mail afin de réinitialiser votre mot de passe Cap Mailing Plateforme
        </mat-card-subtitle>


    </mat-card-header>


</mat-card>

<mat-toolbar>
    <mat-toolbar-row>
        Vous n’avez pas reçu de mail ? 
    </mat-toolbar-row>
    <mat-toolbar-row>
       Vérifiez vos spams ou&nbsp;<strong (click)="gotorequestReset()" style="cursor:pointer"> cliquez ici pour renvoyer un mail.</strong> 
    </mat-toolbar-row>
</mat-toolbar>


