<mat-card class="gtmt-card-container">
  <mat-card-title>Validation du bon à tirer</mat-card-title>

  <mat-card-subtitle>
    <p>
      Afin que votre commande soit prise en compte et lancé en production, il est nécessaire de valider le bon
      à tirer. Cela vous permet de nous donner votre approbation sur l’impression réalisée avant le tirage complet.
    </p>
  </mat-card-subtitle>

  <mat-card-content>
    <mat-grid-list [cols]="2" rowHeight="8rem" [gutterSize]="'2rem'">

      <mat-grid-tile colspan="1" rowspan="1">
        <h2>Numéro de commande</h2>
        <span>{{_GlobalDataService.Campagne?.GTMTCommande}}</span>
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="1">
        <h2>Date de commande</h2>
        <span>{{_GlobalDataService.Campagne?.DateModification}}</span>
      </mat-grid-tile>

      <!-- <mat-grid-tile colspan="2" rowspan="6">
      </mat-grid-tile> -->

    </mat-grid-list>
  </mat-card-content>

  <mat-card-actions align="end">

  </mat-card-actions>

</mat-card>

<iframe id="iframe" (load)="uploadDone()" width="100%" height="100%" frameBorder="0" [src]="safeSrc"></iframe>
