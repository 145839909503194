<mat-card class="gtmt-card-container" [formGroup]="_CampagneTypologieService.SupportFormGroup">
    
    <mat-card-title>Finition</mat-card-title>

    <mat-card-content>
        <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'">
            <mat-radio-group formControlName="CodeFinitionSupport"
                aria-labelledby="dimension-radio-group-label" class="dimension-radio-group">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="0.5"
                    (click)="_CampagneTypologieService.SupportFormGroup.get('CodeFinitionSupport').setValue(dataFinitionsSupport.CodeFinitionSupport)"
                    *ngFor="let dataFinitionsSupport of _CampagneTypologieService.dataFinitionsSupports">
                    <span>  {{dataFinitionsSupport.FinitionSupport}}</span>
                    <mat-radio-button [value]="dataFinitionsSupport.CodeFinitionSupport">                       
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="Valider()">
            Valider la sélection
        </button>
    </mat-card-actions>

</mat-card>