import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';
import { ProduitService } from '@core/services/produit.service';
import { Subscription } from 'rxjs';
import { HelpComponent } from 'app/campagne/components';


@Component({
  selector: 'app-campagne-typologie-format',
  templateUrl: './campagne-typologie-format.component.html',
  styleUrls: ['./campagne-typologie-format.component.scss']
})
export class CampagneTypologieFormatComponent implements OnInit {

  navigationOption = {};

  CodeSupportSub: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
    public _HelpComponent: HelpComponent,
  ) {
    // this. _CampagneTypologieService.initDataSupportTypologies(this._GlobalDataService.Campagne);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.CodeSupportSub = this._CampagneTypologieService.SupportFormGroup.get('CodeTypologie')
      .valueChanges.subscribe((value: string) => {
        // if valid
        if (this._CampagneTypologieService.SupportFormGroup.get('CodeTypologie').valid) {

          console.log('CodeTypologie value changed to')
          console.log(this._CampagneTypologieService.SupportFormGroup.get('CodeTypologie').value);

          setTimeout(() => {
            console.log('this._CampagneTypologieService.SupportFormGroup.value =');
            console.log(this._CampagneTypologieService.SupportFormGroup.value)
          })

          // update model
          this._GlobalDataService.Campagne.CodeTypologie = value;
          this._GlobalDataService.Campagne.IdProduit = '0';
          this._GlobalDataService.CampagneExt.Produit = {};
          this._GlobalDataService.Campagne.isDirty = true;
        }
      });
  }

  Valider() {

    if (!this._CampagneTypologieService.SupportFormGroup.get('CodeTypologie').value) {
      this._GlobalDataService.alert("Veuillez choisir un format avant de valider.");
      return;
    }

    this.formatChanged();
    if (this._CampagneTypologieService.dataSupports.length > 1) {
      this._CampagneTypologieService.navigate(navPaths.selection, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
    }
    else {
      this.navigationOption = { typologie: 'autre' };
      this._CampagneTypologieService.navigate(navPaths.dimension, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
    }
  }

  reset() {
    this._CampagneTypologieService.SupportFormGroup.get('CodeSupport').reset();
    this._CampagneTypologieService.SupportFormGroup.get('CodeDimensionsSupport').reset();
    this._CampagneTypologieService.SupportFormGroup.get('CodeGrammageSupport').reset();
    this._CampagneTypologieService.SupportFormGroup.get('CodeFinitionSupport').reset();
    this._CampagneTypologieService.SupportFormGroup.get('CodeEncreSupport').reset();

    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEnveloppe').reset();
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeDimensionsEnveloppe').reset();
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeGrammageEnveloppe').reset();
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeFinitionEnveloppe').reset();
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEncreEnveloppe').reset();
  }

  formatChanged() {

    this.reset();

    // Support
    this._CampagneTypologieService.dataSupports = this._GlobalDataService.produits
      .filter((element: {
        CodeTypologie: string | undefined;
      }) => element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie);
    this._CampagneTypologieService.dataSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataSupports, 'CodeSupport');

    this._GlobalDataService.Campagne.CodeSupport = ""
    this._GlobalDataService.Campagne.CodeDimensionsSupport = "";
    this._GlobalDataService.Campagne.CodeGrammageSupport = "";
    this._GlobalDataService.Campagne.CodeFinitionSupport = "";
    this._GlobalDataService.Campagne.CodeEncreSupport = "";

    this._GlobalDataService.Campagne.CodeEnveloppe = "";
    this._GlobalDataService.Campagne.CodeDimensionsEnveloppe = "";
    this._GlobalDataService.Campagne.CodeGrammageEnveloppe = "";
    this._GlobalDataService.Campagne.CodeFinitionEnveloppe = "";
    this._GlobalDataService.Campagne.CodeEncreEnveloppe = "";


    if (this._CampagneTypologieService.dataSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeSupport = this._CampagneTypologieService.dataSupports[0].CodeSupport;
    }


    // Dimension
    this._CampagneTypologieService.dataDimensionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeSupport: any; CodeTypologie: any; }) => element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataDimensionsSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataDimensionsSupports, 'CodeDimensionsSupport');

    if (this._CampagneTypologieService.dataDimensionsSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeDimensionsSupport = this._CampagneTypologieService.dataDimensionsSupports[0].CodeSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeDimensionsSupport = "";
    }

    // Grammage
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.produits
      .filter((element: { CodeDimensionsSupport: string | undefined; CodeSupport: string | undefined; CodeTypologie: any; }) => element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataGrammagesSupports, 'CodeGrammageSupport');

    if (this._CampagneTypologieService.dataGrammagesSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeGrammageSupport = this._CampagneTypologieService.dataGrammagesSupports[0].CodeGrammageSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeGrammageSupport = "";
    }

    // Finition
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataFinitionsSupports, 'CodeFinitionSupport');

    if (this._CampagneTypologieService.dataFinitionsSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeFinitionSupport = this._CampagneTypologieService.dataFinitionsSupports[0].CodeFinitionSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeFinitionSupport = "";
    }

    // Encre
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.produits
      .filter((element: { CodeFinitionSupport: any; CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeFinitionSupport == this._GlobalDataService.Campagne.CodeFinitionSupport
        && element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEncresSupports, 'CodeFinitionSupport');


    if (this._CampagneTypologieService.dataEncresSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeEncreSupport = this._CampagneTypologieService.dataEncresSupports[0].CodeEncreSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeEncreSupport = "";
    }


  }

  openHelp(signet: string) {
    this._GlobalDataService.referenceDocHelp = "#" + signet;
    this._HelpComponent.OpenHelpFile();
    return false;
  }

  ngOnDestroy() {
    this.CodeSupportSub.unsubscribe();
  }

}
