<div class="expired-password">
  <app-password-control
    [type]="'change'"
    [errorEvent]="errorSubject.asObservable()"
    [isSending]="isSendingPassword"
    (passwordSend)="changePassword($event)"
  >
    <div class="header" header>
      <p class="title">
        Créer votre nouveau mot de passe<br />Cap&nbsp;Mailing&nbsp;Plateforme
      </p>
      <p class="text">
        Votre mot de passe a expiré. Veuillez créer un nouveau mot de passe.
      </p>
    </div>
  </app-password-control>
</div>
