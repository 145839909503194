<h2 mat-dialog-title>Confirmation</h2>
<mat-dialog-content class="mat-typography">
  <p>Je confirme que la campagne de courrier postal est de nature MARKETING PUBLICITAIRE</p>
  <p>et exclut dès lors tout envoi par La Poste de courriers transactionnels dits "de gestion"</p>

  <br>
  <p>
    Attention, toute commande validée après 17h00 sera transmise aux équipes de production le jour ouvré suivant à
    partir de 8h00. Merci de votre compréhension.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Annuler</button>
  <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Je confirme</button>
</mat-dialog-actions>