import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalDataService, PaoService } from '@core/services';

@Component({
  selector: 'app-dialog-chili-bat',
  templateUrl: './dialog-chili-bat.component.html',
  styleUrls: ['./dialog-chili-bat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogChiliBatComponent implements OnInit {
  public iframeBAT: SafeResourceUrl | undefined;
  public isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogChiliBatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { idCampagne: string },
    private _saniziter: DomSanitizer,
    private _paoService: PaoService,
    public _GlobalDataService: GlobalDataService,
    private _cd: ChangeDetectorRef
  ) {
    dialogRef.disableClose = true;
  }

  public ngOnInit(): void {
    this.getIframeUrl();
  }

  private async getIframeUrl() {
    // Start Loader
    this.isLoading = true;
    this._cd.markForCheck();

    // Call IFrame BAT API
    this._paoService.getBatIframe(this.data.idCampagne).subscribe({
      next: (res: { url: string }) => {
        this.isLoading = false;
        if (res.url) {
          this.iframeBAT = this._saniziter.bypassSecurityTrustResourceUrl(
            res.url
          );
        }
        this._cd.markForCheck();
      },
      error: (_) => {
        this.isLoading = false;
        this._GlobalDataService.alert(
          "Erreur lors du chargement de l'adresse du bon à tirer."
        );
        this._cd.markForCheck();
      },
    });
  }
}
