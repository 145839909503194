import { Component, ElementRef, OnInit } from '@angular/core';

import { CampagneAdressesService, CampagnePlanificationService, CampagneTypologieService, GlobalDataService, PaoService } from '@core/services';
import { Router } from '@angular/router';
import { CampagneService } from '@core/services/campagne.service';
import { forkJoin, from, Subscription } from 'rxjs';
import { AdresseService } from '@core/services/adresse.service';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { environment } from 'environments/environment';
import { HelpComponent } from 'app/campagne/components';
import { ProduitService } from '@core/services/produit.service';
import { CampagneExt } from '@core/models/campagne-ext.model';
import { Campagne } from '@core/models/campagne.model';
import { CampagneIdentificationService } from '@core/services/campagne-identification.service';
import { environment as env} from 'environments/environment';

export interface sidenavItem {
  title: string;
  route?: string;
  icon?: string;
  id?: boolean;
  action(path?: string): void;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  sidenavWidth: string = "8.2rem;";
  logoWidth: string = "4rem;";

  logomargin: string = "2rem;";
  helpmargin: string = "2rem;";


  ngStyle: string = "";

  isLinear = true;

  public sidenavItems: sidenavItem[] = [];
  isExpanded: boolean = false;
  selectedItem = 0;

  version = environment.version;

  getAdminDetailsCampagneByIdSub: Subscription | undefined;

    // Chili
    private SAVE_MESSAGE = { save: true };
    private targetOrigin = env.targetOriginChili;

  constructor(
    public router: Router,
    public _GlobalDataService: GlobalDataService,
    private _CampagneService: CampagneService,
    private _AdresseService: AdresseService,
    public _HelpComponent: HelpComponent,
    private _FichierCreaService: FichierCreaService,
    private _ProduitService: ProduitService,
    private _CampagneTypologieService: CampagneTypologieService,
    private _CampagneAdressesService: CampagneAdressesService,
    private _CampagnePlanificationService: CampagnePlanificationService,
    private _CampagneIdentificationService: CampagneIdentificationService,
    private _PaoService: PaoService,
    private elementRef: ElementRef
  ) {

    console.log('Campagne home page');
  }

  ngOnInit(): void {

    if (this._GlobalDataService.isAdminLaposte()) {
      this.sidenavItems = [   
        {
          title: 'Gestion des annonceurs       ',
          icon: 'lettre1',
          action: this.gotoGestionAnnonceurs.bind(this),
        },
      ];
    }
    else if (this._GlobalDataService.isAdminAnnonceur()) {
      this.sidenavItems = [      
        {
          title: 'Gestion des utilisateurs       ',
          icon: 'bloc-notes2',
          action: this.gotoManageUsers.bind(this),
        },
        {
          title: 'Espace Client           ',
          icon: 'EspaceClient',
          action: this.gotoParametrage.bind(this),
        },
        {
          title: 'Favoris                 ',
          icon: 'EtoileVide',
          action: this.gotoGestionFavoris.bind(this),
        }, 
        {
          title: 'Suivi de campagne        ',
          icon: 'SuiviCampagnes',
          action: this.gotoSuiviCampagne.bind(this),
        }              
      ];
    }
    else {
      this.sidenavItems = [
        {
          title: 'Nouvelle Campagne       ',
          icon: 'lettre1',
          action: this.gotoNewCampagne.bind(this),
        },
        {
          title: 'Détails Campagne       ',
          icon: 'lettre1',
          action: this.gotoDetailCampagne.bind(this),
        },
        {
          title: 'Espace Client           ',
          icon: 'EspaceClient',
          action: this.gotoParametrage.bind(this),
        },
        // {
        //   title: 'Gestion des utilisateurs',
        //   icon: 'bloc-notes2',
        //   action: this.gotoManageUsers.bind(this),
        // },        
        {
          title: 'Favoris                 ',
          icon: 'EtoileVide',
          action: this.gotoGestionFavoris.bind(this),
        },        
        {
          title: 'Suivi de campagne        ',
          icon: 'SuiviCampagnes',
          action: this.gotoSuiviCampagne.bind(this),
        }
      ];
    }
  }

  gotoCreateAnnonceur() {
    console.log('gotoCreerAnnonceur');
    // this.router.navigate([{ outlets: {  navview: ['/administration/admin-campagnes/9'] }}]).then((e) => {
    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['create-annonceur'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  gotoGestionAnnonceurs() {
    console.log('gotoGestionAnnonceurs');
    this.router.navigate(["/administration/home", { outlets: { 'navview': ['liste-annonceurs'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }


  // async navigate(index: number) {
  //   console.log(index);
  // }

  async gotoSuiviCampagne() {

    // Check if quite Chili mode
    if (this._GlobalDataService.isIframeChiliLoaded) {
      await this._PaoService.quitChili((isSaved) => {
        if (isSaved) {
          this._GlobalDataService.isIframeChiliLoaded = false;
          this.sendChiliPostMessage();
        }
      });
      return;
    }

    console.log('gotoSuiviCampagne');

    // this.router.navigate([{ outlets: {  navview: ['/administration/admin-campagnes/9'] }}]).then((e) => {
    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['suivi-campagnes', this._GlobalDataService.Annonceur.IdAnnonceur] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }


  async gotoNewCampagne() {
    // Check if quite Chili mode
    if (this._GlobalDataService.isIframeChiliLoaded) {
      await this._PaoService.quitChili((isSaved) => {
        this._GlobalDataService.isIframeChiliLoaded = false;
        if (isSaved) {
          this.sendChiliPostMessage();
        }
      });
      return;
    }
    console.log('gotoNewCampagne');
    this._CampagneService.getCurrentCampagne()
      .subscribe(
        response => this.SuccessCurrentCampagne(response.campagne),
        error => this.ErrorCurrentCampagne(error)
      );
  }

  navigate(view: string, IdCampagne: string) {
    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }

    this.router.navigate([path, { outlets: { 'navview': [view, IdCampagne] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }


  gotoDetailCampagne() {
    console.log('gotoDetailCampagne');
    if (typeof this._GlobalDataService.Campagne === 'undefined') {
      this._GlobalDataService.alert("Aucune campagne en cours.");
      return;
    }
    console.log(this._GlobalDataService.Campagne);
    if (typeof this._GlobalDataService.Campagne.EmailNotification == 'undefined') this._GlobalDataService.Campagne.EmailNotification = "";

    if (this._GlobalDataService.Campagne.CodeStatut != "EN_CREATION" &&
      this._GlobalDataService.Campagne.CodeStatut != "A_CORRIGER") {
        if (this._GlobalDataService.Campagne.FrequenceEnvoi === 'Récurrente') {
          this.navigate('suivi-campagne-recurrente', this._GlobalDataService.Campagne.IdCampagne);
        }
        else {
          this.navigate('suivi-campagne-une-seule-fois', this._GlobalDataService.Campagne.IdCampagne);
        }      
    }
    else {
      this.navigate('campagne-details', this._GlobalDataService.Campagne.IdCampagne);
    }
  }


  async gotoParametrage() {
    
    // Check if quite Chili mode
    if (this._GlobalDataService.isIframeChiliLoaded) {
      await this._PaoService.quitChili((isSaved) => {
        this._GlobalDataService.isIframeChiliLoaded = false;
        if (isSaved) {
          this.sendChiliPostMessage();
        }
      });
      return;
    }

    console.log('gotoParametrage');

    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['parametrage'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  // Save Chili creation by sending postMessage
  sendChiliPostMessage() {
    try {
      const iframeChili = this.elementRef.nativeElement.querySelector('#iframe');
      console.log(iframeChili);

      if (iframeChili) {
        iframeChili.contentWindow.postMessage(
          this.SAVE_MESSAGE,
          this.targetOrigin
        );
      } else {
        console.error("iframe not found");
      }
    } catch (error) {
      console.error("Error on sending Saving Chili Creation postMessage", error);
    }
  }

  async gotoGestionFavoris() {

    // Check if quite Chili mode
    if (this._GlobalDataService.isIframeChiliLoaded) {
      await this._PaoService.quitChili((isSaved) => {
        this._GlobalDataService.isIframeChiliLoaded = false;
        if (isSaved) {
          this.sendChiliPostMessage();
        }
      });
      return;
    }

    console.log('gotoGestionFavoris');

    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['gestion-favoris'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }



  gotoManageUsers() {
    console.log('gotoManageUsers');
    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['manage-users'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  gotoUserProfile() {
    console.log('gotoUserProfile');
    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['user-profile'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  initialise() {

    this._GlobalDataService.CampagneExt.Produit = {};
    this._GlobalDataService.CampagneExt.VitessesProduit = new Array<string>();
    this._GlobalDataService.CampagneExt.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
    this._GlobalDataService.CampagneExt.FichiersCreation = new Array<FichiersCreation>();
    this._GlobalDataService.CampagneExt.ChampsFusion = new Array<ChampsFusion>();
    this._GlobalDataService.CampagneExt.FichiersCreationsURL = {};
    this._GlobalDataService.CampagneExt.FichiersCreationsType = {};
    this._GlobalDataService.CampagneExt.NbFichiersCreation = 0;
    this._GlobalDataService.CampagneExt.NbFichiersPersonnalisation = 0;
    this._GlobalDataService.CampagneExt.NbFichiersLogo = 0;

    this._GlobalDataService.CampagneExt.VitessesProduit = ['Classique', 'Rapide'];

    this._GlobalDataService.Campagne.volume="";
    this._GlobalDataService.Campagne.unitAmountWithoutTax="";
    this._GlobalDataService.Campagne.amountWithoutTax = "";

    this._GlobalDataService.Campagne.showInfo = false;
    this._GlobalDataService.Campagne.showRefresh = false;
    this._GlobalDataService.Campagne.showunitAmountWithoutTax = false;
    this._GlobalDataService.Campagne.showamountWithoutTax = false;
    this._GlobalDataService.Campagne.OptionRetour = '';
    this._GlobalDataService.checkStepCompletion();

    //  Reset Forms
    this._CampagneAdressesService.reset();

    this._CampagnePlanificationService.reset();

    this._CampagneTypologieService.reset();

    this._CampagneIdentificationService.reset();
    
    // Reset stepper
    if (this._GlobalDataService.stepper) {
      this._GlobalDataService.stepper.selectedIndex = 0;
    }

    // Reset notification emails
    this._GlobalDataService.emails = [];

  }

  SuccessCurrentCampagne(campagne) {
    // this._AuditLogService.add(campagne.IdCampagne, 0, `Accès annonceur (${campagne.IdCampagne})`).subscribe();
    if (typeof campagne === 'object') {

      //affecter la campagne reçu

      this._GlobalDataService.Campagne = campagne;

      if (typeof this._GlobalDataService.Campagne.EmailNotification == 'undefined') this._GlobalDataService.Campagne.EmailNotification = "";

      this._GlobalDataService.CampagneExt = new CampagneExt();


      console.log('current Campagne : ', this._GlobalDataService.Campagne);

      console.log('Id Campagne : ', this._GlobalDataService.Campagne.IdCampagne);

      console.log("campagne.StatutCampagne", campagne.StatutCampagne);

      console.log("campagne.CodeStatut", campagne.CodeStatut);

      this.initialise();

      this._GlobalDataService.isLoaded = true;
      this.router.navigate(['/standalone/home', { outlets: { 'navview': ['campagne-details', this._GlobalDataService.Campagne.IdCampagne] } }], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true
      }).then((e) => {
        if (e) {
          console.log("Navigation is successful!");
        } else {
          console.log("Navigation has failed!");
        }
      });


    } else {
      this._GlobalDataService.isLoaded = true;
      this.router.navigate(["/standalone/home", { outlets: { 'navview': ['nouvelle-campagne'] } }], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true
      }).then((e) => {
        if (e) {
          console.log("Navigation is successful!");
        } else {
          console.log("Navigation has failed!");
        }
      });
    }
    this._GlobalDataService.campagneBeforeChanges = Object.assign({}, this._GlobalDataService.Campagne);
  }

  ErrorCurrentCampagne(error: string) {
    //une erreur est survenue => redirection page erreur
    console.log('CreationCampagneComponent : ErrorCurrentCampagne', error)
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    });
    this._GlobalDataService.isLoaded = true;
  }



  increase() {
    this.isExpanded = true;
  }
  decrease() {
    this.isExpanded = false;
  }

  openHelp() {
    this._GlobalDataService.referenceDocHelp = '';
    this._HelpComponent.OpenHelpFile();
  }

  gotoMentionsLegales(){
    let path = 'standalone/home'
    this.router.navigate([path, {outlets : {'navview' : 'mentions-legales'}}])
  }


  ngOnDestroy() {
    if (this.getAdminDetailsCampagneByIdSub) this.getAdminDetailsCampagneByIdSub.unsubscribe();
  }

}
