<mat-card class="gtmt-card-container">
    <mat-card-title>Votre sélection</mat-card-title>
    <mat-card-content>
        <mat-grid-list class="gtmt-first-container" cols="1" rowHeight="5rem" style="width:100%;">
            <!-- First Row - icones -->
            <!-- Format -->
            <mat-grid-tile class="gtmt-icon-container gtmt-jc-start" rowspan="2">
                <mat-icon  svgIcon="Envoi sous enveloppe"></mat-icon>
            </mat-grid-tile>

            <!-- Second Row - Labels -->
            <!-- Format -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <span class="gtmt-label">Format</span>
                <span class="gtmt-value">{{support.support}}</span>
                <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeFormat()"></mat-icon>
            </mat-grid-tile>
        </mat-grid-list>

        <mat-divider></mat-divider>

        <mat-grid-list class="gtmt-second-container"  cols="4" rowHeight="6rem" [gutterSize]="'5rem'" style="width:100%;">
            <!-- Lettre -->
            <mat-grid-tile  class="gtmt-lettre-container gtmt-jc-start gtmt-ai-start" rowspan="8">
                <app-selection-lettre-card></app-selection-lettre-card>
            </mat-grid-tile>

            <!-- Enveloppe -->
            <mat-grid-tile class="gtmt-lettre-container gtmt-jc-start gtmt-ai-start" rowspan="8">
                <app-selection-enveloppe-card></app-selection-enveloppe-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-card-content>
</mat-card>