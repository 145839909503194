
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Annonceur } from '@core/models/annonceur.model';
import { AnnonceurService, AuthenticationService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  constructor(public _GlobalDataService: GlobalDataService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _AnnonceurService: AnnonceurService,
    private _AuthenticationService: AuthenticationService
  ) {

    this.activeRoute.queryParams.subscribe(params => {
      this._GlobalDataService.objectParams = params; // Print the parameter to the console. 
    });
    console.log('this._GlobalDataService.objectParams!', this._GlobalDataService.objectParams);

  }

  ngOnInit() {
    this._GlobalDataService.CONTEXT = "ELOQUA";
    this._AuthenticationService.authenticateEloqua("eloqua/bootstrap", "GET")
      .subscribe(async response => {
        if (response.id_token) {
          // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
          // localStorage.setItem("ELOQUA_APP_JWT", response.id_token);
          this._GlobalDataService.setJwtoken(response.id_token);
          console.debug(`Stored ELOQUA_APP_JWT = ${response.id_token}`);

          await this._GlobalDataService.getStatutMapping();

          if (Object.keys(this._GlobalDataService.objectParams).length !== 0) {
            //get Annonceur
            this._AnnonceurService.get()
              .subscribe(
                response => this.successResponse(response),
                error => this.errorResponse(error)
              );
          } else {
            console.log('this._GlobalDataService.objectParams', this._GlobalDataService.objectParams);
            this.router.navigate(['/erreur'], {
              queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
            });
          }
        }
      }, error => {
        console.log("Error happened while getting JWT token");
      });
  }

  successResponse(response: { annonceur: Annonceur; }) {
    console.log('AppComponent - response.annonceur', response.annonceur);
    console.log(response.annonceur);
    console.log('response.annonceur.IdAnnonceur', response.annonceur.IdAnnonceur);
    //l'annonceur n'existe pas dans la base
    if (response.annonceur.IdAnnonceur === undefined) {
      this._GlobalDataService.Annonceur = response.annonceur;
      this.router.navigate(['/parametrage'], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
      });
    } else {
      this._GlobalDataService.Annonceur = response.annonceur;
      this.router.navigate(['/creation-campagne'], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
      });
    }
  }

  errorResponse(error: any) {
    console.log('appcomponent', error);
    console.log('this._GlobalDataService.objectParams', this._GlobalDataService.objectParams);
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
    });
  }


}
