import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tab-visualisation',
  templateUrl: './tab-visualisation.component.html',
  styleUrls: ['./tab-visualisation.component.scss'],
})
export class TabVisualisationComponent {
  public _headers: string[] = [];
  public _rows: any[] = [];

  @Input()
  public set headers(headers: string[]) {
    this._headers = headers.map((header) => this.replaceEmpty(header));
  }

  @Input()
  public set rows(rows: any[]) {
    this._rows = rows.map((row) => this.replaceEmptyKeys(row));
  }

  public form: FormGroup;
  public position: string[] = ['position'];

  constructor() {
    console.log('TabVisualisationComponent');
    this.form = new FormGroup({
      filter: new FormControl(),
    });
  }

  private replaceEmpty(value: string) {
    return value === '' ? ' ' : value;
  }

  private replaceEmptyKeys(dictionary: { [key: string]: any }): {
    [key: string]: any;
  } {
    const newDictionary: { [key: string]: any } = {};
    Object.keys(dictionary).forEach((key) => {
      let newKey = key === '' ? ' ' : key;
      newDictionary[newKey] = dictionary[key];
    });
    return newDictionary;
  }
}
