<form [formGroup]="form">
    <mat-card class="parameter-card">

        <mat-card-header>
            <mat-card-title>Mon compte client</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <mat-grid-list cols="2" rowHeight="1.2rem" gutterSize="0.5rem">

                <mat-grid-tile colspan="2" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Nom du client</mat-label>
                        <input matInput placeholder="Nom du client" formControlName="annonceurName" maxLength="50" required>
                        <mat-error *ngIf="form.get('annonceurName').hasError('required')">
                            Nom du client est requis.
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° client</mat-label>
                        <input matInput placeholder="N° client" formControlName="NumeroClient" maxLength="50" required>
                        <mat-error *ngIf="form.get('NumeroClient').hasError('required')">
                            Numéro de client est requis.
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° Contrat </mat-label>
                        <input matInput placeholder="N° Contrat" formControlName="NumeroContrat" maxLength="50">
                        <mat-error *ngIf="form.get('NumeroContrat').hasError('required')">
                            Numéro de contrat est requis.
                        </mat-error>                        
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>N° Contrat Alliage </mat-label>
                        <input matInput placeholder="N° Contrat Alliage" formControlName="NumeroContratAlliage"
                            maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Identifiant Alliage </mat-label>
                        <input matInput placeholder="Identifiant Alliage" formControlName="IdentifiantAlliageGE"
                            maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Nom du contact</mat-label>
                        <input matInput placeholder="Nom" formControlName="NomContact" maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Prénom</mat-label>
                        <input matInput placeholder="Prénom" formControlName="PrenomContact" maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Adresse e-mail</mat-label>
                        <input matInput placeholder="Adresse e-mail" formControlName="EmailContact" maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Téléphone </mat-label>
                        <input matInput placeholder="Téléphone" formControlName="TelephoneContact" maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Rôle</mat-label>
                        <input matInput placeholder="Rôle" formControlName="RoleContact" maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>SIRET</mat-label>
                        <input matInput placeholder="SIRET" formControlName="Siret" maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label>Raison sociale</mat-label>
                        <input matInput placeholder="Raison sociale" formControlName="RaisonSociale" maxLength="50">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="12" style="overflow: visible">
                    <p style="margin-right: 10px; font-size: 16px">Logo</p>
                    <input style="display: none" type="file" (change)="uploadLogo($event)" #fileInput>
                    <img id="logo" (click)="fileInput.click()" [src]="_GlobalDataService.logo">
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
        <mat-card-actions>
            <!-- <button mat-raised-button (click)="goToHome()">Annuler</button> -->
            <button mat-raised-button [disabled]="form.invalid" (click)="submit()">Valider/Enregistrer</button>
        </mat-card-actions>

    </mat-card>
</form>

<mat-card>
    <mat-card-content class="mentions-legales">
        <div>
            <p>Pour accéder à votre contrat et à vos factures ：<br>
                <a href="https://www.assistant-courrier.laposte.fr/" target="_blank">connectez-vous à l’Assistant Courrier
                    La Poste</a>
            </p>
        </div>
        <div>
            <p>Retrouvez nos mentions légales :<br>
                <a href="" (click)="gotoMentionsLegales()">Mentions légales</a>
            </p>
        </div>
    </mat-card-content>

</mat-card>