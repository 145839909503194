import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { CampagneAdressesService, GlobalDataService } from '@core/services';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campagne-adresses-options-retour',
  templateUrl: './campagne-adresses-options-retour.component.html',
  styleUrls: ['./campagne-adresses-options-retour.component.scss']
})
export class CampagneAdressesOptionsRetourComponent implements OnInit {

  showSpinner: boolean = false;

  OptionsRetour = [
    { code: 'Alliage', title: "Activer l’option Alliage" },
    { code: 'AdresseRetour', title: "Sélectionner une adresse de retour" }
  ]

  OptionRetourSub: Subscription;

  // form = this._formBuilder.group({
  //   OptionRetour: new FormControl(this._GlobalDataService.Campagne.OptionRetour),
  //   Alliage: new FormControl(this._GlobalDataService.Campagne.Alliage),
  //   AdresseRetour: new FormControl({ value: this._GlobalDataService.Campagne.IdAdresse > 0 ? true : false, disabled: false },
  //     [Validators.required]
  //   ),
  // });

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _formBuilder: FormBuilder,
    private _FichierCreaService: FichierCreaService,
    public _campagneAdressesService: CampagneAdressesService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.OptionRetourSub = this._campagneAdressesService.form.get('OptionRetour').valueChanges
      .subscribe((option: string) => {
        // if valid
        if (this._campagneAdressesService.form.get('OptionRetour').valid) {
          // update model
          console.log(option);
          this._GlobalDataService.Campagne.OptionRetour = option;
          if (option == 'AdresseRetour') {
            this._GlobalDataService.Campagne.Alliage = false;

            this._GlobalDataService.CampagneExt.FichiersCreation.forEach( (f, i) => {
              if (f.FichierCreation == '2') {
                this.deleteFile(i,f);
              }
            })
          }
          else {
            this._GlobalDataService.Campagne.IdAdresse = '0';
            this._GlobalDataService.CampagneExt.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
            this._GlobalDataService.CampagneExt.selectedAdresses = [];
            this._GlobalDataService.Campagne.isDirty = true;
          }
        }
      });
  }

  deleteFile(index: number, Fichier: FichiersCreation) {
    this._GlobalDataService.CampagneExt.FichiersCreation.splice(index, 1);
    this._GlobalDataService.CampagneExt.NbFichiersLogo =  this._GlobalDataService.getNbFichiersLogo();
    //API DELETE Fichier
    this.showSpinner = true;
    this._FichierCreaService.delete(Fichier.IdfichierCreation)
      .subscribe(
        response => {
          console.log(response)
          this.showSpinner = false;
        },
        error => this.errorResonse(error),
      );

  }

  errorResonse(HttpErrorResponse: { error: { message: string; }; }) {
    this.showSpinner = false;
    console.error("Error!", HttpErrorResponse);
    if (HttpErrorResponse.error.message && HttpErrorResponse.error.message != "") {
      this._GlobalDataService.alert("Une erreur est survenue . Merci de relancer l'estimation tarifaire.", HttpErrorResponse.error.message);
    }
  }


  ngOnDestroy() {
    if (this.OptionRetourSub) this.OptionRetourSub.unsubscribe();
  }
}
