import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';
import { GlobalDataService } from '../services/global-data.service';

@Injectable({
  providedIn: 'root'
})
export class StdUserRouteAccessService implements CanActivate {

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _GlobalDataService: GlobalDataService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log("StdUserRouteAccessService.canActivate");
    //this._GlobalDataService.CONTEXT = "STANDALONE";
    //this._GlobalDataService.CONTEXT = "SALESFORCE";

    return new Observable<boolean>(sub => {
      this._authenticationService.isAuthenticated()
        .subscribe(
          (res) => {
            console.log("isAuthenticated ? ", res);
            if (res) {
              sub.next(true);
              sub.complete();
            }
            else {
              this._router.navigate(["/standalone/login"], {
                queryParams: {
                  return: state.url
                }
              });
              sub.next(false);
              sub.complete();
            }
          },
          () => {
            this._router.navigate(["/standalone/login"], {
              queryParams: {
                return: state.url
              }
            });
            sub.next(false);
            sub.complete();
          }
        )
    });
  }
}
