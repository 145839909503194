import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { GlobalDataService } from '@core/services';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { HelpComponent } from '../../help/help.component';
import { CampagneCreationComponent } from '../campagne-creation.component';
import { FileRequestLimitService } from '@core/services/file-request-limit.service';

@Component({
  selector: 'app-fichiers-personnalisations',
  templateUrl: './fichiers-personnalisations.component.html',
  styleUrls: ['./fichiers-personnalisations.component.scss']
})
export class FichiersPersonnalisationsComponent implements OnInit {

  public fichiers_crea: string;

  isLoading: boolean = false; //chargement des fichiers
  showSpinner: boolean = false;

  CreationForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _FichierCreaService: FichierCreaService,
    public _GlobalDataService: GlobalDataService,
    public _HelpComponent: HelpComponent,
    private _sanitizer: DomSanitizer,
    private _FileRequestLimitService: FileRequestLimitService,
  ) {
    this.CreationForm = this._formBuilder.group({
      fichiers_crea: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    // this._GlobalDataService.Campagne.FichiersCreation.forEach(f => {
    //   if (f.FichierCreation == '0') {
    //     this.downloadFile(f.NomFichier);
    //   }
    // })
  }

  _downloadFile(fileName: string) {
    // console.log("download file " + fileName);
    return this._FichierCreaService.download(fileName, this._GlobalDataService.Campagne.IdCampagne)
      .subscribe(response => {
        console.log(response);
        var blob = new Blob([response], { type: response.type });
        //Create a link element, hide it, direct 
        //it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style.display = "none";
        a.target = "_blank";
        a.rel = "noopener noreferrer"
        document.body.appendChild(a);
        //Create a DOMString representing the blob 
        //and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);;
      });
    // return this._sanitizer.bypassSecurityTrustUrl(base64File);
  }

  downloadFile(fileName: string) {
    // console.log("download file " + fileName);
    return this._FichierCreaService.download(fileName, this._GlobalDataService.Campagne.IdCampagne)
      .subscribe(response => {
        var blob = new Blob([response], { type: response.type });
        //Create a link element, hide it, direct 
        //it towards the blob, and then 'click' it programatically

        //Create a DOMString representing the blob 
        //and point the link element towards it
        let url = window.URL.createObjectURL(blob);


        if (blob.type.startsWith('image')) {
          let objectURL = URL.createObjectURL(blob);
          this._GlobalDataService.CampagneExt.FichiersCreationsURL[fileName] = this._sanitizer.bypassSecurityTrustUrl(objectURL);
          this._GlobalDataService.CampagneExt.FichiersCreationsType[fileName] = 'image';

        }
        else {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            this._GlobalDataService.CampagneExt.FichiersCreationsURL[fileName] = new Uint8Array(fileReader.result as ArrayBuffer);
            this._GlobalDataService.CampagneExt.FichiersCreationsType[fileName] = blob.type;
          };
          fileReader.readAsArrayBuffer(blob);
        }
      });
  }


  refreshFichierCrea() {
    this._FichierCreaService.list(this._GlobalDataService.Campagne.IdCampagne as string)
      .subscribe(
        response => {
          if (typeof response.fichierCreation === 'object') {
            this._GlobalDataService.CampagneExt.FichiersCreation = response.fichierCreation;
          }
          this.isLoading = false;
          this.showSpinner = false;
        },
        error => { this.isLoading = false; }
      );
  }

  deleteFile(index: any, Fichier: FichiersCreation) {
    // Reinit input values
    this.getInputFile().value = '';

    const onSuccess = (file: FichiersCreation) => {
      this.showSpinner = true;
      this._FichierCreaService.delete(file.IdfichierCreation)
        .subscribe(
          response => {
            console.log('Response : ' + response);
            this._GlobalDataService.CampagneExt.FichiersCreation.splice(index, 1);
            delete this._GlobalDataService.CampagneExt.FichiersCreationsURL[Fichier.NomFichier];
            this._GlobalDataService.CampagneExt.NbFichiersPersonnalisation--;
            this.showSpinner = false;
          },
          error => this.errorResonse(error),
        );
    }

    this._GlobalDataService.confirm({
      "titre": "Supprimer?",
      "message": "Voulez-vous vraiment supprimer le fichier portant le nom " + this.sanitizeString(Fichier.NomFichier) + "?",
      "BouttonConfirmation": true,
      "onConfirm": onSuccess.bind(this, Fichier),
      "onCancel": console.log('onCancel')
    });
  }

  errorResonse(HttpErrorResponse: { error: { message: string; }; }) {
    this.isLoading = false;
    this.showSpinner = false;
    console.error("Error!", HttpErrorResponse);
    if (HttpErrorResponse.error.message && HttpErrorResponse.error.message != "") {
      this._GlobalDataService.alert("Une erreur est survenue :", HttpErrorResponse.error.message);
    }
  }

  getFileDetailsV2(e: any) {
    console.log("GET FILE DETAILS V2");
    for (var i = 0; i < e.target.files.length; i++) {
      this.isLoading = true;

      const file: File = e.target.files[i];
      this._FileRequestLimitService.checkFileSize(file, CampagneCreationComponent.MAX_SIZE_FILE);

      // Check file is not empty
      if(file.size === 0) {
        this._GlobalDataService.alert("Le fichier " + this.sanitizeString(file.name) + " est vide!")
        break;
      }
      if(file.name.indexOf('.') === -1) {
        this._GlobalDataService.alert("Le fichier " + this.sanitizeString(file.name) + " n'a pas de type.")
        break;
      }

      const filename = file.name;
      const fileExt: string = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
      if (file.type.match('application/vnd.ms-excel') || fileExt === 'xlsx') {
        this._GlobalDataService.CampagneExt.FichiersCreationsType[filename] = 'Excel'
      }
      else if (['doc', 'docx'].indexOf(fileExt) != -1 ) {
        this._GlobalDataService.CampagneExt.FichiersCreationsType[filename] = 'Word'
      }   
      else if (['ppt', 'pptx'].indexOf(fileExt) != -1 ) {
        this._GlobalDataService.CampagneExt.FichiersCreationsType[filename] = 'PPT'
      }           
      else if (['ttf', 'otf', 'lst'].indexOf(fileExt) != -1 ) {
        this._GlobalDataService.CampagneExt.FichiersCreationsType[filename] = 'Typo'
      }      
      else if (['idml', 'indd'].indexOf(fileExt) != -1) {
        this._GlobalDataService.CampagneExt.FichiersCreationsType[filename] = 'Adobe'
      }      
      else if (file.type.startsWith('image')) {
        this._GlobalDataService.CampagneExt.FichiersCreationsType[filename] = 'image'
      }   
      else if (file.type.match('application/pdf')) {
        this._GlobalDataService.CampagneExt.FichiersCreationsType[filename] = 'FichierPDF'
      }      
      else {
        this._GlobalDataService.alert("Le format du fichier " + this.sanitizeString(file.name) + " n'est pas pris en charge !")
        break;
      }

      const onSuccess = (file: any, idCampagne: string, override?: boolean) => {
        this.showSpinner = true;
        this._FichierCreaService.uploadFile(file, idCampagne)
          .subscribe(
            response => {
              //refresh list
              this._FichierCreaService.add({
                NomFichier: filename,
                TypeFichier: this._GlobalDataService.CampagneExt.FichiersCreationsType[filename],
                IdCampagne: idCampagne,
                FichierPersonnalisation: true
              })
                .subscribe(
                  (result) => {
                    console.log(result);
                    if (!override) {
                      this._GlobalDataService.CampagneExt.FichiersCreation.push(
                        {
                          NomFichier: filename,
                          TypeFichier: this._GlobalDataService.CampagneExt.FichiersCreationsType[filename],
                          IdCampagne: idCampagne,
                          IdfichierCreation: result.idfichier,
                          FichierCreation: '0'
                        }
                      )
                      this._GlobalDataService.CampagneExt.NbFichiersPersonnalisation++;
                    }

                    this.showSpinner = false;
                  },
                  (error) => this.errorResonse(error)
                );
            },
            error => this.errorResonse(error),
          );
      }


      // Check if there is already a file with the same name
      let waitForConfirmation = false;
      for (let i = 0; i < this._GlobalDataService.CampagneExt.FichiersCreation!.length; i++) {
        console.log("processing file", file);
        const fichier = this._GlobalDataService.CampagneExt.FichiersCreation && this._GlobalDataService.CampagneExt.FichiersCreation[i];

        if (fichier!.NomFichier === file.name && fichier!.FichierCreation === '1') {
          this._GlobalDataService.alert("Un fichier portant le même nom " + this.sanitizeString(filename) + " est déjà chargé parmi les fichiers de créations.")
          waitForConfirmation = true;
          break;
        }
        // If there is a file with same name, ask if overwrite or not
        else if (fichier!.NomFichier === file.name) {
          this._GlobalDataService.confirm({
            "titre": "Remplacer?",
            "message": "Un fichier portant le même nom " + this.sanitizeString(filename) + " est déjà chargé. Souhaitez-vous le remplacer ?",
            "BouttonConfirmation": true,
            "onConfirm": onSuccess.bind(this, file, this._GlobalDataService.Campagne.IdCampagne, true),
            "onCancel": console.log('onCancel') //this.refreshFichierCrea.bind(this)
          });
          waitForConfirmation = true;
          break;
        }
      }


      // Call on success if no files with the same name were found
      if (!waitForConfirmation) onSuccess.call(this, file, this._GlobalDataService.Campagne.IdCampagne!);



      //permet de vider le champ et autoriser l'uload d'un nouveau fichier avec le même nom!
      if (i == e.target.files.length - 1) {
        //this.CreationForm.get('fichiers_crea')!.reset();
        this.fichiers_crea = "";
      }      
    }
  }



  openHelp(signet: string) {
    this._GlobalDataService.referenceDocHelp = "#" + signet;
    this._HelpComponent.OpenHelpFile();
  }  

  addFile() {
    let element: HTMLInputElement = this.getInputFile();
    if (element) {
      element.click()
    };
  }

  /**
   * VLN03 : Escape HTML char to prevent XSS
   * 
   * @param str a string
   * @returns the string sanitize for html
   */
  private sanitizeString(str: string): string {
    return str.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");
  }

  /**
   * Get the html element from the input id
   */
  private getInputFile(): HTMLInputElement {
    return document.getElementById(
      'file-upload-perso'
    ) as HTMLInputElement;
  }
}
