import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar-title',
  templateUrl: './toolbar-title.component.html',
  styleUrls: ['./toolbar-title.component.scss'],
})
export class ToolbarTitleComponent {
  @Input()
  public title = '';

  @Input()
  public type: 'radio' | null = undefined;

  @Input()
  public radioCheck: boolean = false;

  @Input()
  public unselectable: boolean = false;

  @Input()
  public stateFromInput: boolean = false;

  @Input()
  public icon: 'information' | 'question' | null = undefined;

  @Input()
  public disabled = false;

  @Output()
  public radioState = new EventEmitter<boolean>();

  public toggleRadio() {
    if(this.disabled) {
      return;
    }
    if (!this.stateFromInput) {
      if (this.unselectable) {
        this.radioCheck = true;
      } else {
        this.radioCheck = !this.radioCheck;
      }
    }
    this.radioState.emit(this.radioCheck);
  }
}
