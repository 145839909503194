<div
  class="app-campagne-recapitulatif"
  [class.campaign-purged]="_GlobalDataService.Campagne.isPurged"
>
  <!-- Créations -->
  <mat-toolbar style="background: #f9fafe; border-radius: 0.3rem">
    <h1>
      {{ title }}
      <span *ngIf="_GlobalDataService.Campagne.isPurged">
        <span>&nbsp;-&nbsp;</span>
        <span class="message-purge">Cette campagne est purgée</span>
      </span>
    </h1>
  </mat-toolbar>

  <p>
    Le volume requis pour cette commande est de
    <B class="gtmt-chips">
      {{ _GlobalDataService.CampagneExt.Produit.MinFabrication }} PLIS MINIMUM
    </B>
    et
    <B class="gtmt-chips"
      >{{
        _GlobalDataService.Campagne.IdProduit === "0" ||
        _GlobalDataService.CampagneExt.Produit.MaxFabrication === "0"
          ? "PAS DE "
          : "de " + _GlobalDataService.CampagneExt.Produit.MaxFabrication
      }}
      PLIS MAXIMUM
    </B>
    <br />
    A noter que pour les campagnes courriers récurrentes, ces conditions
    s’appliquent pour chaque envoi
  </p>

  <mat-grid-list
    class="recap-blocs"
    cols="3"
    rowHeight="6.5rem"
    [gutterSize]="'1.5rem'"
  >
    <mat-grid-tile colspan="3" rowspan="1" class="gtmt-grid-tile-hide">
      <mat-toolbar>
        <h2>Identification de la campagne</h2>
      </mat-toolbar>
    </mat-grid-tile>

    <!-- Statut de la campagne courrier -->
    <mat-grid-tile
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      colspan="1"
      rowspan="2"
    >
      <span> Statut de la campagne courrier </span>
      <span>
        {{ _GlobalDataService.Campagne.StatutCampagne }}
      </span>
    </mat-grid-tile>

    <!-- Date de remise imprimeur -->
    <mat-grid-tile
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      colspan="1"
      rowspan="2"
    >
      <span> Date de remise imprimeur </span>
      <span>
        {{
          (_GlobalDataService.Campagne.DateRemiseImprimeur === "null"
            ? ""
            : (_GlobalDataService.Campagne.DateRemiseImprimeur
              | date : "dd/MM/yyyy")) || "Non renseignée"
        }}
      </span>
    </mat-grid-tile>

    <!-- Nom de la campagne courrier -->
    <mat-grid-tile
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      colspan="1"
      rowspan="2"
    >
      <span
        [mdePopoverTriggerFor]="appPopover"
        (mouseenter)="$event.stopPropagation()"
        style="cursor: pointer"
      >
        Nom de la campagne courrier
        <mat-icon
          svgIcon="Crayon"
          (click)="gotoStep(isteps.identification)"
        ></mat-icon>
      </span>
      <span class="gtmt-overflow-ellipsis">
        {{ _GlobalDataService.Campagne.NomCampagne }}
      </span>
    </mat-grid-tile>

    <!-- Bon de commande -->
    <mat-grid-tile
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      [colspan]="1"
      [rowspan]="3"
    >
      <span>
        Bon de commande
        <mat-icon
          svgIcon="Crayon"
          (click)="gotoStep(isteps.identification)"
        ></mat-icon>
      </span>
      <span>
        {{ _GlobalDataService.Campagne.BonDeCommande || "Non renseigné" }}
      </span>
    </mat-grid-tile>

    <!-- Description -->
    <mat-grid-tile
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      [colspan]="1"
      [rowspan]="3"
    >
      <span>
        Description
        <mat-icon
          svgIcon="Crayon"
          (click)="gotoStep(isteps.identification)"
        ></mat-icon>
      </span>
      <span class="gtmt-overflow-ellipsis">
        {{ _GlobalDataService.Campagne.Description || "Non renseignée" }}
      </span>
    </mat-grid-tile>

    <!-- Adresse de notification -->
    <mat-grid-tile
      [colspan]="1"
      [rowspan]="3"
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      [formGroup]="EmailNotificationForm"
    >
      <mat-grid-list cols="1" rowHeight="6.5rem">
        <mat-grid-tile
          colspan="1"
          rowspan="1"
          class="gtmt-grid-tile-hide gtmt-jc-start"
        >
          <span
            matBadge="{{ _GlobalDataService.emails.length }}"
            matBadgeOverlap="false"
            style="width: fit-content"
          >
            Adresse de notification
          </span>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="2" class="gtmt-grid-tile-hide">
          <mat-form-field
            class="example-chip-list"
            appearance="fill"
            floatLabel="never"
          >
            <mat-chip-list
              #chipList
              aria-label="Video keywords"
              multiple
              [formControl]="addressNotifCtrl"
            >
              <mat-chip
                *ngFor="let email of _GlobalDataService.emails.slice(0, 1)"
                [selected]="email"
                [value]="email"
                (removed)="remove(email)"
              >
                <span class="gtmt-overflow-ellipsis">
                  {{ email }}
                </span>
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
              <input
                placeholder="Ajouter une adresse..."
                #emailInput
                [formControl]="emailtCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let email of filteredEmails | async"
                [value]="email"
                (click)="$event.stopPropagation()"
              >
                <mat-grid-list [cols]="9" rowHeight="2rem">
                  <mat-grid-tile class="gtmt-jc-start" [colspan]="8">
                    <span class="gtmt-overflow-ellipsis">
                      {{ email }}
                    </span>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="1">
                    <mat-icon
                      style="float: right; margin-left: 1rem"
                      (click)="remove(email)"
                      >cancel
                    </mat-icon>
                  </mat-grid-tile>
                </mat-grid-list>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-grid-tile>

    <!-- Typologie -->
    <mat-grid-tile colspan="1" rowspan="1" class="gtmt-grid-tile-hide">
      <mat-toolbar>
        <h2>Typologie</h2>
      </mat-toolbar>
    </mat-grid-tile>

    <!-- Créations -->
    <mat-grid-tile
      colspan="1"
      rowspan="1"
      class="gtmt-grid-tile-hide"
      *ngIf="
        !(
          this._GlobalDataService.Campagne.creationType === 'chili' &&
          this._GlobalDataService.Campagne.CodeTypologie === 'ITE001'
        )
      "
    >
      <mat-toolbar>
        <h2>Créations</h2>
      </mat-toolbar>
    </mat-grid-tile>

    <!-- Placeholder Block -->
    <mat-grid-tile
      *ngIf="
        _GlobalDataService.isStandaloneContext() &&
        _GlobalDataService.Campagne.importOption === 'sftp'
      "
      colspan="1"
      rowspan="1"
      class="gtmt-placeholder"
    >
    </mat-grid-tile>

    <!-- Personnalisation -->
    <mat-grid-tile
      *ngIf="
        !(
          _GlobalDataService.isStandaloneContext() &&
          _GlobalDataService.Campagne.importOption === 'sftp'
        )
      "
      colspan="1"
      rowspan="1"
      class="gtmt-grid-tile-hide"
    >
      <mat-toolbar>
        <h2>Personnalisation</h2>
      </mat-toolbar>
    </mat-grid-tile>

    <!-- Placeholder Chili Thumbnail Envelope -->
    <mat-grid-tile
      *ngIf="
        this._GlobalDataService.Campagne.creationType === 'chili' &&
        this._GlobalDataService.Campagne.CodeTypologie === 'ITE001'
      "
      colspan="1"
      rowspan="5"
      class="gtmt-placeholder"
    >
    </mat-grid-tile>

    <!-- Support -->
    <mat-grid-tile
      colspan="1"
      rowspan="4"
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
    >
      <span>
        Support
        <mat-icon
          svgIcon="Crayon"
          *ngIf="!_GlobalDataService.isStandaloneContext()"
          (click)="gotoStep(isteps.typologie)"
        >
        </mat-icon>
        <mat-icon
          svgIcon="Crayon"
          *ngIf="_GlobalDataService.isStandaloneContext()"
          (click)="gotoStep(isteps_standalone.typologie)"
        >
        </mat-icon>
      </span>
      <mat-divider></mat-divider>
      <app-support-info-card [support]="support"></app-support-info-card>
    </mat-grid-tile>

    <!-- Fichiers de créations -->
    <mat-grid-tile
      colspan="1"
      rowspan="4"
      class="tile gtmt-fd-col gtmt-ai-start"
      *ngIf="this._GlobalDataService.Campagne.creationType !== 'chili'"
      [class.purged]="_GlobalDataService.Campagne.isPurged"
    >
      <span class="header">
        Fichiers de créations
        <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
          <mat-icon
            svgIcon="Crayon"
            *ngIf="!_GlobalDataService.isStandaloneContext()"
            (click)="gotoStep(isteps.creations)"
          >
          </mat-icon>
          <mat-icon
            svgIcon="Crayon"
            *ngIf="_GlobalDataService.isStandaloneContext()"
            (click)="gotoStep(isteps_standalone.creations)"
          >
          </mat-icon>
        </ng-container>
      </span>
      <mat-divider></mat-divider>
      <!-- Content -->
      <app-fichiers-crea-card
        *ngIf="!_GlobalDataService.Campagne.isPurged"
        [IdCampagne]="_GlobalDataService.Campagne.IdCampagne"
        [FichiersCreation]="_GlobalDataService.CampagneExt.FichiersCreation"
      >
      </app-fichiers-crea-card>
      <!-- Purged -->
      <div *ngIf="_GlobalDataService.Campagne.isPurged" class="purged-content">
        <mat-icon
          svgIcon="attention"
          [mdePopoverTriggerFor]="purgedPopover"
        ></mat-icon>
      </div>
    </mat-grid-tile>

    <!-- Chili Thumbnails : Small -->
    <mat-grid-tile
      colspan="1"
      rowspan="4"
      class="tile gtmt-fd-col gtmt-ai-start"
      [class.purged]="_GlobalDataService.Campagne.isPurged"
      *ngIf="
        this._GlobalDataService.Campagne.creationType === 'chili' &&
        this._GlobalDataService.Campagne.CodeTypologie !== 'ITE001'
      "
    >
      <span class="header">
        <span> Studio de création </span>
        <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
          <mat-icon svgIcon="Crayon" (click)="gotoStep(isteps.creations)">
          </mat-icon>
        </ng-container>
      </span>
      <mat-divider></mat-divider>
      <!-- content -->
      <div *ngIf="!_GlobalDataService.Campagne.isPurged" class="tile-content">
        <app-thumbnail-chili-recap
          *ngIf="this.chiliStatus === 'VALIDEE'"
          [idCampaign]="this._GlobalDataService.Campagne.IdCampagne"
          [selected]="'classic'"
        ></app-thumbnail-chili-recap>
      </div>

      <!-- Purged -->
      <div *ngIf="_GlobalDataService.Campagne.isPurged" class="purged-content">
        <mat-icon
          svgIcon="attention"
          [mdePopoverTriggerFor]="purgedPopover"
        ></mat-icon>
      </div>
    </mat-grid-tile>

    <!-- Champ de personnalisation -->
    <mat-grid-tile
      *ngIf="
        !(
          _GlobalDataService.isStandaloneContext() &&
          _GlobalDataService.Campagne.importOption === 'sftp'
        )
      "
      colspan="1"
      rowspan="4"
      class="tile gtmt-fd-col gtmt-ai-start"
      [class.purged]="_GlobalDataService.Campagne.isPurged"
    >
      <span class="header">
        Champ de personnalisation
        <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
          <mat-icon
            *ngIf="!_GlobalDataService.Campagne.isPurged"
            svgIcon="Crayon"
            (click)="gotoStep(isteps.personalisation)"
          ></mat-icon>
        </ng-container>
      </span>
      <mat-divider></mat-divider>
      <!-- Content -->
      <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
        <!-- Création Classique -->
        <ng-container
          *ngIf="_GlobalDataService.Campagne.creationType !== 'chili'"
        >
          <!-- Salesforce -->
          <app-champ-fusion-card
            *ngIf="_GlobalDataService.isSalesforceContext()"
            [cfs]="personalisationSfService.selectedChampsFusion"
          ></app-champ-fusion-card>
          <!-- Eloqua / Standalone -->
          <app-champ-fusion-card
            *ngIf="
              _GlobalDataService.isEloquaContext() ||
              _GlobalDataService.isStandaloneContext()
            "
            [cfs]="personalisationService.selectedChampsFusion"
          ></app-champ-fusion-card>
        </ng-container>
        <!-- Création Chili -->
        <ng-container
          *ngIf="
            _GlobalDataService.Campagne.creationType === 'chili' &&
            this.chiliStatus === 'VALIDEE'
          "
        >
          <div *ngIf="isLoadingChiliDetails" class="spinner-container">
            <mat-spinner diameter="24"></mat-spinner>
          </div>
          <app-champ-fusion-card
            *ngIf="!isLoadingChiliDetails"
            [cfs]="chiliContactFields"
          ></app-champ-fusion-card>
        </ng-container>
      </ng-container>

      <!-- Purged -->
      <div *ngIf="_GlobalDataService.Campagne.isPurged" class="purged-content">
        <mat-icon
          svgIcon="attention"
          [mdePopoverTriggerFor]="purgedPopover"
        ></mat-icon>
      </div>
    </mat-grid-tile>

    <!-- Chili Thumbnails : Envelope -->
    <mat-grid-tile
      colspan="3"
      rowspan="1"
      class="gtmt-grid-tile-hide"
      *ngIf="
        this._GlobalDataService.Campagne.creationType === 'chili' &&
        this._GlobalDataService.Campagne.CodeTypologie === 'ITE001'
      "
    >
      <mat-toolbar>
        <h2>Créations</h2>
      </mat-toolbar>
    </mat-grid-tile>

    <!-- Chili Thumbnails : Large -->
    <mat-grid-tile
      colspan="3"
      rowspan="4"
      class="tile gtmt-fd-col gtmt-ai-start"
      [class.purged]="_GlobalDataService.Campagne.isPurged"
      *ngIf="
        this._GlobalDataService.Campagne.creationType === 'chili' &&
        this._GlobalDataService.Campagne.CodeTypologie === 'ITE001'
      "
    >
      <span class="header">
        <span>Studio de création</span>
        <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
          <mat-icon
            *ngIf="!_GlobalDataService.Campagne.isPurged"
            svgIcon="Crayon"
            (click)="gotoStep(isteps.creations)"
          ></mat-icon>
        </ng-container>
      </span>
      <mat-divider></mat-divider>
      <!-- Content -->
      <div *ngIf="!_GlobalDataService.Campagne.isPurged" class="tile-content">
        <app-thumbnail-chili-recap
          *ngIf="this.chiliStatus === 'VALIDEE'"
          [idCampaign]="this._GlobalDataService.Campagne.IdCampagne"
          [selected]="'letterEnvelop'"
        ></app-thumbnail-chili-recap>
      </div>

      <!-- Purged -->
      <div *ngIf="_GlobalDataService.Campagne.isPurged" class="purged-content">
        <mat-icon
          svgIcon="attention"
          [mdePopoverTriggerFor]="purgedPopover"
        ></mat-icon>
      </div>
    </mat-grid-tile>

    <!-- Fichier de ciblage -->
    <mat-grid-tile
      *ngIf="
        _GlobalDataService.isStandaloneContext() && 
        _GlobalDataService.Campagne.importFile
      "
      colspan="3"
      rowspan="1"
      class="gtmt-grid-tile-hide"
    >
      <mat-toolbar>
        <h2>Fichier de ciblage</h2>
      </mat-toolbar>
    </mat-grid-tile>
    <mat-grid-tile
      *ngIf="
        _GlobalDataService.isStandaloneContext() && 
        _GlobalDataService.Campagne.importFile
      "
      colspan="3"
      rowspan="1"
      class="gtmt-grid-tile-hide"
    >
      <div class="gtmt-recap-ciblage">
        <p>
          Fichier de ciblage :
          <strong>{{ _GlobalDataService.Campagne.importFile }}</strong>
        </p>
      </div>
    </mat-grid-tile>

    <!-- Planification -->
    <mat-grid-tile colspan="3" rowspan="1" class="gtmt-grid-tile-hide">
      <mat-toolbar>
        <h2>Planification</h2>
      </mat-toolbar>
    </mat-grid-tile>

    <!-- Fréquence -->
    <mat-grid-tile
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      colspan="1"
      rowspan="2"
    >
      <span>
        Fréquence
        <mat-icon
          *ngIf="!_GlobalDataService.isStandaloneContext()"
          (click)="gotoStep(isteps.planification)"
        >
          edit</mat-icon
        >
        <mat-icon
          svgIcon="Crayon"
          *ngIf="_GlobalDataService.isStandaloneContext()"
          (click)="gotoStep(isteps_standalone.planification)"
        >
        </mat-icon>
      </span>
      <span>
        {{ _GlobalDataService.Campagne.FrequenceEnvoi }}
      </span>
    </mat-grid-tile>

    <!-- Vitesse -->
    <mat-grid-tile
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      colspan="1"
      rowspan="2"
    >
      <span>
        Vitesse
        <mat-icon
          *ngIf="!_GlobalDataService.isStandaloneContext()"
          (click)="gotoStep(isteps.planification)"
        >
          edit</mat-icon
        >
        <mat-icon
          svgIcon="Crayon"
          *ngIf="_GlobalDataService.isStandaloneContext()"
          (click)="gotoStep(isteps_standalone.planification)"
        >
        </mat-icon>
      </span>
      <span>
        {{ _GlobalDataService.Campagne.CodeVitesse }}
      </span>
    </mat-grid-tile>

    <!-- Adresses -->
    <mat-grid-tile
      colspan="3"
      rowspan="1"
      class="gtmt-grid-tile-hide"
    >
      <mat-toolbar>
        <h2>Adresses</h2>
      </mat-toolbar>
    </mat-grid-tile>

    <!-- Pavé adresse -->
    <mat-grid-tile
      *ngIf="
        !(
          _GlobalDataService.isStandaloneContext() &&
          _GlobalDataService.Campagne.importOption === 'sftp'
        )
      "
      colspan="1"
      rowspan="4"
      class="tile gtmt-fd-col gtmt-ai-start"
      [class.purged]="_GlobalDataService.Campagne.isPurged"
    >
      <span class="header">
        Pavé adresse
        <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
          <mat-icon
            *ngIf="!_GlobalDataService.Campagne.isPurged"
            svgIcon="Crayon"
            (click)="gotoStep(isteps.adresses)"
          ></mat-icon>
        </ng-container>
      </span>
      <mat-divider></mat-divider>
      <!-- Content -->
      <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
        <!-- Création Classique -->
        <ng-container
          *ngIf="_GlobalDataService.Campagne.creationType !== 'chili'"
        >
          <!-- Salesforce -->
          <app-champ-fusion-card
            *ngIf="_GlobalDataService.isSalesforceContext()"
            [cfs]="adressesDestinatairesSfService.selectedChampsFusion"
          ></app-champ-fusion-card>
          <!-- Eloqua / Standalone -->
          <app-champ-fusion-card
            *ngIf="
              _GlobalDataService.isEloquaContext() ||
              _GlobalDataService.isStandaloneContext()
            "
            [cfs]="adressesDestinatairesService.selectedChampsFusion"
          ></app-champ-fusion-card>
        </ng-container>
        <!-- Création Chili -->
        <ng-container
          *ngIf="
            _GlobalDataService.Campagne.creationType === 'chili' &&
            this.chiliStatus === 'VALIDEE'
          "
        >
          <div *ngIf="isLoadingChiliDetails" class="spinner-container">
            <mat-spinner diameter="24"></mat-spinner>
          </div>
          <app-champ-fusion-card
            *ngIf="!isLoadingChiliDetails"
            [cfs]="chiliAddressesFields"
          ></app-champ-fusion-card>
        </ng-container>
      </ng-container>

      <!-- Purged -->
      <div *ngIf="_GlobalDataService.Campagne.isPurged" class="purged-content">
        <mat-icon
          svgIcon="attention"
          [mdePopoverTriggerFor]="purgedPopover"
        ></mat-icon>
      </div>
    </mat-grid-tile>

    <!-- Adresse de retour -->
    <mat-grid-tile
      colspan="1"
      rowspan="4"
      class="tile gtmt-fd-col gtmt-ai-start"
      *ngIf="!_GlobalDataService.Campagne.Alliage"
      [class.purged]="_GlobalDataService.Campagne.isPurged"
    >
      <span class="header">
        Adresse de retour
        <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
          <mat-icon
            svgIcon="Crayon"
            *ngIf="!_GlobalDataService.isStandaloneContext()"
            (click)="gotoStep(isteps.adresses)"
          >
          </mat-icon>
          <mat-icon
            svgIcon="Crayon"
            *ngIf="_GlobalDataService.isStandaloneContext()"
            (click)="gotoStep(isteps_standalone.adresses)"
          >
          </mat-icon>
        </ng-container>
      </span>
      <mat-divider></mat-divider>
      <!-- Content -->
      <ng-container *ngIf="!_GlobalDataService.Campagne.isPurged">
        <!-- Création Classique -->
        <ng-container
          *ngIf="_GlobalDataService.Campagne.creationType !== 'chili'"
        >
          <app-adresse-retour
            *ngIf="_GlobalDataService.CampagneExt.Adresse"
            [adresse]="_GlobalDataService.CampagneExt.Adresse"
          ></app-adresse-retour>
        </ng-container>
        <!-- Création Chili -->
        <ng-container
          *ngIf="
            _GlobalDataService.Campagne.creationType === 'chili' &&
            this.chiliStatus === 'VALIDEE'
          "
        >
          <div *ngIf="isLoadingChiliDetails" class="spinner-container">
            <mat-spinner diameter="24"></mat-spinner>
          </div>
          <app-adresse-retour
            *ngIf="!isLoadingChiliDetails"
            [adresse]="adresseRetour"
          ></app-adresse-retour>
        </ng-container>
      </ng-container>

      <!-- Purged -->
      <div *ngIf="_GlobalDataService.Campagne.isPurged" class="purged-content">
        <mat-icon
          svgIcon="attention"
          [mdePopoverTriggerFor]="purgedPopover"
        ></mat-icon>
      </div>
    </mat-grid-tile>

    <!-- Alliage -->
    <mat-grid-tile
      class="gtmt-fd-col gtmt-ai-start gtmt-jc-space-evenly"
      colspan="1"
      rowspan="2"
      *ngIf="_GlobalDataService.Campagne.Alliage"
    >
      <span>
        Alliage
        <mat-icon
          svgIcon="Crayon"
          (click)="gotoStep(isteps.adresses)"
        ></mat-icon>
      </span>
      <span>
        {{ _GlobalDataService.Campagne.Alliage ? "Oui" : "Non" }}
      </span>
    </mat-grid-tile>

    <!-- Confirmation -->
    <mat-grid-tile colspan="3" rowspan="2" class="gtmt-grid-tile-hide">
      <mat-card [formGroup]="ConfirmationForm">
        <mat-card-title
          (click)="campagne_confirmation = !campagne_confirmation"
        >
          <span (click)="campagne_confirmation = !campagne_confirmation">
            Je confirme que la campagne de courrier postal est de nature
          </span>
          <span
            style="color: #e91e63; cursor: pointer; margin-left: 1.5rem"
            (click)="openHelpMarketing()"
          >
            "MARKETING PUBLICITAIRE"
          </span>
        </mat-card-title>
        <mat-card-content>
          <mat-checkbox
            formControlName="campagne_confirmation"
            id="campagne_confirmation"
            [labelPosition]="'before'"
          >
            Je confirme
          </mat-checkbox>
          <div
            class="recap-purge-alert"
            *ngIf="
              _GlobalDataService.Campagne.isPurged && ConfirmationForm.valid
            "
          >
            Validation de campagne impossible: cette campagne est purgée.
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

  <!-- Stepper navigation  -->
  <app-campagne-stepper-nav
    [prevDisplay]="true"
    [validateDisplay]="true"
    [validateDisable]="!campagne_confirmation || activationLoading"
    [validateLoading]="activationLoading"
    (previous)="goToPrevious()"
    (validate)="onConfirmClick()"
  ></app-campagne-stepper-nav>
</div>

<!-- Popover : campaign info -->
<mde-popover
  #appPopover="mdePopover"
  [mdePopoverOverlapTrigger]="false"
  [mdeFocusTrapAutoCaptureEnabled]="false"
>
  <mat-card class="gtmt-popover-card">
    <mat-card-header> Nom de la campagne courrier </mat-card-header>
    <mat-card-content>
      <p>
        {{ _GlobalDataService.Campagne.NomCampagne }}
      </p>
    </mat-card-content>
  </mat-card>
</mde-popover>

<!-- Popover : Purged -->
<mde-popover
  #purgedPopover="mdePopover"
  class="purged-popover"
  [mdePopoverOverlapTrigger]="false"
  [mdeFocusTrapAutoCaptureEnabled]="false"
  [mdePopoverPositionY]="'below'"
  [mdePopoverCloseOnClick]="true"
  [arrowWidth]="0"
>
  <mat-card class="gtmt-popover-card" style="text-align: center">
    <mat-card-content>
      <div>
        <mat-icon
          svgIcon="attention"
          style="width: 26px; height: 26px"
        ></mat-icon>
      </div>
      <div
        style="
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: center;
        "
      >
        Cette campagne est purgée.<br />
        L’accès n’est pas disponible pour cette étape.
      </div>
    </mat-card-content>
  </mat-card>
</mde-popover>
