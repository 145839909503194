import { CreateUserComponent } from "./admin-annonceur/create-user/create-user.component";
import { ManageUsersComponent } from "./admin-annonceur/manage-users/manage-users.component";
import { CreateAnnonceurComponent } from "./admin-laposte/create-annonceur/create-annonceur.component";
import { ManageAnnonceursComponent } from "./admin-laposte/manage-annonceurs/manage-annonceurs.component";
import { CompteClientComponent } from "./compte-client/compte-client.component";
import { ChangePasswordComponent } from "./compte-client/change-password/change-password.component";
import { HomeComponent } from "./home/home.component";
import { BienvenueComponent } from "./bienvenue/bienvenue.component";
import { ListeCampagnesComponent } from "./liste-campagnes/liste-campagnes.component";
import { ChangePasswordCheckComponent } from "./login-page/change-password-check/change-password-check.component";
import { ChangePasswordRequestComponent } from "./login-page/change-password-request/change-password-request.component";
import { LoginPageComponent } from "./login-page/login-page.component";
import { LoginComponent } from "./login-page/login/login.component";
import { ProfileComponent } from "./profile/profile.component";
import { VisualisationAdresseComponent } from "./visualisation-adresse/visualisation-adresse.component";
import { ExpiredPasswordComponent } from "./login-page/expired-password/expired-password.component";

export const components: any[] = [
    HomeComponent,
    BienvenueComponent,
    LoginPageComponent,
    ProfileComponent,
    VisualisationAdresseComponent,
    ListeCampagnesComponent,
    ManageUsersComponent,
    CreateUserComponent,
    CreateAnnonceurComponent,
    ManageAnnonceursComponent,
    CompteClientComponent ,
    LoginComponent,
    LoginPageComponent,
    ChangePasswordRequestComponent, 
    ChangePasswordComponent, 
    ChangePasswordCheckComponent,
    ExpiredPasswordComponent
]

export * from "./admin-annonceur/create-user/create-user.component";
export * from "./admin-annonceur/manage-users/manage-users.component";
export * from "./admin-laposte/create-annonceur/create-annonceur.component";
export * from "./admin-laposte/manage-annonceurs/manage-annonceurs.component";
export * from "./home/home.component";
export * from "./bienvenue/bienvenue.component";
export * from "./liste-campagnes/liste-campagnes.component";
export * from "./login-page/login-page.component";
export * from "./profile/profile.component";
export * from "./visualisation-adresse/visualisation-adresse.component";
export * from "./compte-client/compte-client.component";
export * from "./login-page/login/login.component";
export * from "./login-page/change-password-check/change-password-check.component";
export * from "./login-page/change-password-request/change-password-request.component";
export * from "./login-page/expired-password/expired-password.component";
