export class EstimationRequest {
    offerCode: string;
    serviceCode: string;
    custAccNumber: string;
    contractNumber: string;
    contractVersionNumber: string;
    quantity: string;
    criteria: Criteria;

    constructor() {
        this.offerCode = "3122";
        this.serviceCode = "GTMT_MOE_GTMT";
        this.custAccNumber = "";
        this.contractNumber = "";
        this.contractVersionNumber = "0";
        this.quantity = "";
        this.criteria = new Criteria();
    }
}

export class Criteria {
    orderStatus: string;
    campaignStatus: string;
    item_type: string;
    folding: string;
    printFormatValue: string;
    paperGrammageCode: string;
    glossLevel: string;
    printingInkType: string;
    windowEnvelope: string;
    envelopType: string;
    envelope_paperGrammage: string;
    envelope_glossLevel: string;
    envelope_printingInkType: string;
    deliveryPriority: string;
    campaignProductionMode: string;

    constructor() {
        this.orderStatus = "DONE";
        this.campaignStatus = "";
        this.item_type = "";
        this.folding = "";
        this.printFormatValue = "";
        this.paperGrammageCode = "";

        this.glossLevel = "";
        this.printingInkType = "";
        this.windowEnvelope = "";
        this.envelopType = "";
        this.envelope_paperGrammage = "";
        this.envelope_glossLevel = "";        

        this.envelope_printingInkType = "";
        this.deliveryPriority = "";
        this.campaignProductionMode = "";           
    }

}
