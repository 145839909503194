import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GlobalDataService } from './global-data.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChampsFusionService {

  _eloquaUrl = environment.url_api + '/champ-fusion';
  _salesforceUrl = environment.url_api + '/salesforce/champ-fusion'
  _standaloneUrl = environment.url_api + '/standalone/champ-fusion'

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,
  ) { }

  upsert(request: any) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url!: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._eloquaUrl + '/upsert'
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/upsert';
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/upsert';
      }
    }

    return this._http.post<any>(url, { request: request }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

  /**
   * retourne la liste des champs de fusion d'une campagne
   * @param idCampagne
   */
  list(idCampagne: string) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url!: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._eloquaUrl + '/list/' + idCampagne;
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/list/' + idCampagne;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/list/' + idCampagne;
        break;
      }
      default: {
        return new Observable();
      }
    }

    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

  /**
 * retourne la liste des champs de fusion favoris d'un annonceur
 * @param idAnnonceur
 */
  listFav(idAnnonceur: string) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url = environment.url_api + '/champfusion-favori/list/' + idAnnonceur;
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

    // récupérer champs dispo sur Standalone
    listChampsDisponibleStandalone() {
      let idCampagne = this._GlobalDataService.Campagne.IdCampagne ;
      let url = environment.url_api + '/standalone/contactFields/' + idCampagne;
      
      return this._http.get<any>(url, {
        params: {},
        headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
      });
    }

  /**
   * Only used in the Eloqua version to list available fields (requests Eloqua API)
   *
   */
  listChampsDisponible() {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url = environment.url_api + '/contactFields';
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });

  }

  deleteSFChampFusion(idCampagne) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url;
    url = this._salesforceUrl + '/delete/' + idCampagne;
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

}
