<mat-card>
    <mat-card-title>Informations annonceur</mat-card-title>
    <mat-card-content>
        <form [formGroup]="annonceurForm">
            <mat-grid-list cols="2" rowHeight="1.2rem">
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Nom de l'annonceur</mat-label>
                        <input type="text" matInput formControlName="annonceurName" readonly required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Numero client</mat-label>
                        <input type="text" matInput formControlName="NumeroClient" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Numero contrat</mat-label>
                        <input type="text" matInput formControlName="NumeroContrat" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Numero contrat Alliage</mat-label>
                        <input type="text" matInput formControlName="NumeroContratAlliage" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Identifiant Alliage GE</mat-label>
                        <input type="text" matInput formControlName="IdentifiantAlliageGE" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Email contact</mat-label>
                        <input type="text" matInput formControlName="email" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Téléphone contact</mat-label>
                        <input type="text" matInput formControlName="TelephoneContact" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Rôle contact</mat-label>
                        <input type="text" matInput formControlName="RoleContact" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Siret</mat-label>
                        <input type="text" matInput formControlName="Siret" required>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="6">
                    <mat-form-field>
                        <mat-label>Raison sociale</mat-label>
                        <input type="text" matInput formControlName="RaisonSociale" required>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button [disabled]="!annonceurForm.valid" (click)="saveAnnonceurChanges()" mat-flat-button color="primary"
            class="pull-right">Enregistrer
            modifications</button>
    </mat-card-actions>
</mat-card>