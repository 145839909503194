import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GlobalDataService } from './global-data.service';
import { SharedListSalesforceService } from './shared-list-salesforce.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalisationSfService {

  public form!: FormGroup;

  public controls: any;

  champFusion: string;
  sharedListName: string;

  sharedLists: Array<any>;
  availableChampsFusion: Array<any> = [];
  filteredAvailableChampsFusion: Array<any> = [];
  selectedChampsFusion: Array<any> = [];

  constructor(
    private _formBuilder: FormBuilder,
    public _GlobalDataService: GlobalDataService,
    private _SharedListSalesforceService: SharedListSalesforceService    
  ) {

    if (this._GlobalDataService.isStandaloneContext()) return;

    this.controls = {
      sharedListName: new FormControl(this.sharedListName, [
      ]),
      champFusion: new FormControl(this.champFusion, [
      ]),
    };
    this.form = this._formBuilder.group(this.controls);
    // this.initialise();
   }

  public initialise() {
    this.selectedChampsFusion = this._GlobalDataService.CampagneExt.ChampsFusion.filter(cf => cf.IsPaveAdresse == '0' || cf.IsPaveAdresse == 'false');

    this.sharedLists = this._GlobalDataService.sharedLists;

    if (typeof this._GlobalDataService.Campagne.ExtensionId == 'undefined') {
      this._GlobalDataService.Campagne.ExtensionId = "";
    }
    if (this._GlobalDataService.Campagne.ExtensionId != "") {
      this.getSharedListDetaile(this._GlobalDataService.Campagne.ExtensionId);
    }
  }

  getSharedListDetaile(ExtensionId: string) {
    console.log(ExtensionId);
    const resultat = this.sharedLists.find(sharedList => sharedList.id === ExtensionId);
    console.log(resultat);
    if (resultat) {
      this.sharedListName = resultat.dataExtensionName;
      this.form.reset();
      this.form.get("sharedListName").patchValue(this.sharedListName, { emitEvent: false });
      this._SharedListSalesforceService.getDataExtensionList(ExtensionId, '', resultat.dataExtensionName)
        .subscribe(async (response) => {
          console.log("SF response CF", response);
          if (response === null) {
            this.availableChampsFusion = [];
          } else {
            this.availableChampsFusion = response;
            this.filteredAvailableChampsFusion = this.availableChampsFusion.filter(acf => !this.selectedChampsFusion.map(scf => scf.Id).includes(acf.Id));
          }
        });
    }
  }

}
