import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '@core/services';
import { NavigateUtil } from '@shared/utils/navigate.util';

@Component({
  selector: 'app-suivi-campagne-une-seule-fois',
  templateUrl: './suivi-campagne-une-seule-fois.component.html',
  styleUrls: ['./suivi-campagne-une-seule-fois.component.scss']
})
export class SuiviCampagneUneSeuleFoisComponent implements AfterViewInit {

  constructor(
    private el: ElementRef,
    private router: Router,
    public _GlobalDataService: GlobalDataService,
  ) { }

  ngAfterViewInit(): void {
    // Go to top
    NavigateUtil.goToTopSideNavContent(this.el);
  }

  gotoSuiviCampagne() {
    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }

    this.router.navigate([path, { outlets: { 'navview': ['suivi-campagnes', this._GlobalDataService.Annonceur.IdAnnonceur] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
        // this.showSpinner = false;

      } else {
        console.log("Navigation has failed!");
        // this.showSpinner = false;
      }
    });
  }
}
