import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EloquaRoutingModule } from './eloqua-routing.module';
import { BootstrapComponent } from './components/bootstrap/bootstrap.component';
import { StartComponent } from './components/start/start.component';
import { SharedModule } from '@shared/shared.module';


@NgModule({
  declarations: [
    BootstrapComponent,
    StartComponent
  ],
  imports: [
    CommonModule,
    EloquaRoutingModule,
    SharedModule
  ]
})
export class EloquaModule { }
