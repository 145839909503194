import { Component, Input, OnInit } from '@angular/core';
import { StatutCommande } from '@core/models/statut-commande.model';
import { GlobalDataService } from '@core/services';
import { StatutCommandeService } from '@core/services/statut-commande.service';

@Component({
  selector: 'app-suivi-campagne-caroussel',
  templateUrl: './suivi-campagne-caroussel.component.html',
  styleUrls: ['./suivi-campagne-caroussel.component.scss']
})
export class SuiviCampagneCarousselComponent implements OnInit {

  @Input()
  public idCommande: string;

  public items = [];
  public currentStatutCommandeArray = [];
  public maxRecord = 12;
  public slidesize: number = 3;
  public cs = -1 * this.slidesize; // start index of current slide 
  public previtems = [];


  constructor(
    private _StatutCommandeService: StatutCommandeService,
  ) { }

  ngOnInit(): void {
    // Get order statuses
    this._StatutCommandeService
      .getStatutsByIdCommandeAdmin(this.idCommande)
      .subscribe((response: any) => {
        this.init(response.StatutCommande);
      });
  }

  init(data: any) {
    this.currentStatutCommandeArray = data;

    if (this.currentStatutCommandeArray.length >= 1) {
      this.currentStatutCommandeArray.forEach((sc: StatutCommande) => {
        sc.Title = 'Terminé'
      });
      this.currentStatutCommandeArray[this.currentStatutCommandeArray.length - 1].Title = 'En cours';

      this.currentStatutCommandeArray.push({
        idCommande!: '',
        idStatutCommande!: '',
        IdStatut!: '',
        creeParLogin!: '',
        modifieParLogin!: '',
        dateCreation!: 'A venir',
        dateModification!: 'A venir',
        codeStatut!: '',
        Statut!: '',
        StatutLibelle!: 'A venir',
        Title: 'A venir'
      })
    }

    this.items = [];
    this.previtems = [];
    this.cs = -1 * this.slidesize;



    this.maxRecord = this.currentStatutCommandeArray.length;
    if (this.currentStatutCommandeArray.length <= 3) {
      this.items = this.currentStatutCommandeArray;
    }
    else {
      this.initCarousel(this.slidesize);
    }
  }

  initCarousel(size) {
    let arr = this.nextSet(size);
    this.previtems = this.nextSet(size);
    arr = arr.concat(this.previtems);
    if (arr.length > 0) {
      this.items = arr;
    }
  }

  nextSet(size: number) {
    // Generate an infinte chunk of data
    let arr = [];
    let start = 0;
    let end = 0;
    start = this.cs = (this.cs + size) % this.maxRecord;
    end = this.cs + size;
    arr = this.currentStatutCommandeArray.slice(start, end);
    if (arr.length < size) {
      // complete arr with items from the head of data array
      // Ex arr =  24,0,1
      arr = arr.concat(this.currentStatutCommandeArray.slice(0, size - arr.length));
      // this.cs = -1 * this.size;
    }
    return arr;
  }

  previousSet(size: number) {
    // Generate an infinte chunk of data
    let arr = [];
    let start = 0;
    let end = 0;
    if (this.cs - size < 0) this.cs = size;
    start = this.cs = (this.cs - size) % this.maxRecord;
    end = this.cs + size;
    arr = this.currentStatutCommandeArray.slice(start, end);
    return arr;
  }


  fetch(event: any) {
    console.log("fetch direction : " + event);
    console.log("fetch - cs  : " + this.cs);
    console.log("current - previous  : ");
    console.log(this.previtems);
    if (event == "prev") {
      let currentRecordSet = this.previousSet(this.slidesize);
      if (currentRecordSet.length > 0) {
        if (this.cs == -1 * this.slidesize) {
          this.items = currentRecordSet;
        } else {
          this.items = currentRecordSet.concat(this.previtems);
          this.previtems = currentRecordSet;
        }
        console.log("this.items : ");
        console.log(this.items);
      }
    } else if (event == "next") {
      if (this.cs == -1 * this.slidesize) {
        this.initCarousel(this.slidesize);
      }
      let currentRecordSet = this.nextSet(this.slidesize);
      if (currentRecordSet.length > 0) {
        this.items = this.previtems.concat(currentRecordSet);
        this.previtems = currentRecordSet;
        console.log(this.items);
      }
    }
  }
}
