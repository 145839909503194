import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class StatutCommandeService {
  _url = environment.url_api + '/statut-commande';

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,
  ) { }

  getStatutsByIdCommande(IdCommande: string) {
  
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/commande/' + IdCommande;
    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) })
        .subscribe(response => {
          if (response && Array.isArray(response["StatutCommande"])) {
            response.StatutCommande.forEach((statut: { [x: string]: string | number; }) => {
              if (statut["codeStatut"])
                statut["StatutLibelle"] = this._GlobalDataService.mappingStatut[statut["codeStatut"]];
            });
            subscriber.next(response);
          }
          else
            subscriber.next({ "StatutCommande": [] });
          subscriber.complete();
        }, err => { subscriber.error(err); });
    });
    return observable
  }

  getStatutsByIdCommandeAdmin(IdCommande: string) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = environment.url_api + '/admin-statut-commande/commande/' + IdCommande;
    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, { params: this._GlobalDataService.objectParams, headers: new HttpHeaders({ "Authorization": `Bearer ${ this._GlobalDataService.getJwtoken() }` }) })
        .subscribe(response => {
          if (response && Array.isArray(response["StatutCommande"])) {
            response.StatutCommande.forEach((statut: { [x: string]: string | number; }) => {
              if (statut["codeStatut"])
                statut["StatutLibelle"] = this._GlobalDataService.mappingStatut[statut["codeStatut"]];
            });
            subscriber.next(response);
          }
          else
            subscriber.next({ "StatutCommande": [] });
          subscriber.complete();
        }, err => { subscriber.error(err); });
    });
    return observable
  }
}
