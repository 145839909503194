<!-- Header  -->
<!-- <app-header [titre]="titre" [showButtonRetour]="false"></app-header> -->

<!-- standalone -->
<mat-sidenav-container *ngIf="_GlobalDataService.isStandaloneContext()" class="gtmt-sidenav-container">
    <mat-sidenav-content>
        <form class="gtmt-form-container">
            <h2 mat-dialog-title>Merci ! </h2>
            <mat-dialog-content class="mat-typography">
                <p>Votre commande "{{NomCampagne}}" a bien été enregistrée. Vous pouvez suivre l'avancement de votre campagne à partir de l'écran de suivi de campagne.
                <p>Nota Bene : Votre commande peut prendre plusieurs minutes avant d'être mise à jour après son activation. 
                    Les services de création de commande de La Poste sont disponibles de 8h00 à 17h00.</p>
            </mat-dialog-content>
        </form>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- salesforce et eloqua -->
<mat-sidenav-container *ngIf="!_GlobalDataService.isStandaloneContext()" class="gtmt-sidenav-container">
    <mat-sidenav-content>
        <form class="gtmt-form-container">
            <h2 mat-dialog-title>Merci ! </h2>
            <mat-dialog-content class="mat-typography">
                <p>Votre commande "{{NomCampagne}}" a bien été enregistrée. Il ne vous reste plus qu'à activer la
                    campagne depuis le
                    workflow {{ context }} afin que celle ci soit transmise aux équipes de production. Vous pourrez ensuite
                    suivre
                    l'avancement de votre campagne.
                </p>
                <p>Attention de vérifier la cohérence entre les dates de début et de fin de campagne {{ context }} et les dates
                    de début et de fin de la campagne Cap Mailing</p>

                <p>Nota Bene : votre demande peut prendre plusieurs minutes avant d'être mise à jour après son activation dans {{ context }}. Les services de création de commande de La Poste sont disponibles de 8h00 à 17h00.</p>
            </mat-dialog-content>

            <!-- "Scripts may close only the windows that were opened by it." -->
            <!-- <button mat-raised-button (click)="closePopOut()">Fermer CapMailing Plateforme</button> -->

        </form>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- Footer -->
<!-- <app-footer>
</app-footer> -->