<!-- Accueil -->
<div class="bienvenue" [class.loaded]="loaded">
  <!-- Espace libre -->
  <div class="emtpy-container">
    <div class="empty"></div>
  </div>

  <!-- Logo -->
  <div class="logo">
    <img src="/assets/images/Logo-LaPoste_HD.png" alt="logo" />
  </div>

  <!-- Texte -->
  <div class="text">
    <span>Bienvenue dans </span>
    <span class="app-name">Cap&nbsp;Mailing&nbsp;Plateforme</span>
  </div>

  <!-- Boutons -->
  <div class="btn-actions">
    <button class="btn-action" (click)="goToNewCampaign()">
      <mat-icon class="left" svgIcon="new"></mat-icon>
      <span>Créer une nouvelle campagne</span>
    </button>
    <button
      class="btn-action"
      (click)="goToCampaigns()"
      [disabled]="isBtnSuiviCampagneEnable()"
    >
      <span>Suivi de campagnes</span>
      <mat-icon class="right" svgIcon="go-to"></mat-icon>
    </button>
  </div>

  <!-- Bas de page -->
  <div class="bottom">
    Des questions ? N’hésitez pas à consulter notre
    <span class="help" (click)="openHelp()">aide en ligne</span> ou à nous
    contacter directement à l’adresse
    <a href="mailto:support.capmailingplateforme@laposte.fr"
      >support.capmailingplateforme&#64;laposte.fr</a
    >
  </div>
</div>
