import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagnePlanificationService, CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-enveloppe-encre-card',
  templateUrl: './enveloppe-encre-card.component.html',
  styleUrls: ['./enveloppe-encre-card.component.scss']
})
export class EnveloppeEncreCardComponent implements OnInit {

  navigationOption = {};

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
    private _CampagnePlanificationService: CampagnePlanificationService
  ) {
    this.navigationOption = { typologie: 'enveloppe' };
  }

  ngOnInit(): void {
    // Encre
    this._CampagneTypologieService.dataEncresEnveloppes = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );
    this._CampagneTypologieService.dataEncresEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEncresEnveloppes, 'CodeEncreEnveloppe');

    if (this._CampagneTypologieService.dataEncresEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeEncreEnveloppe = this._CampagneTypologieService.dataEncresEnveloppes[0].CodeEncreEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeEncreEnveloppe = "";
    }
  }

  ngAfterViewInit() {
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEncreEnveloppe')
      .valueChanges.subscribe((value: string) => {
        // if valid
        if (this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEncreEnveloppe').valid) {

          console.log('CodeEncreEnveloppe value changed')
          console.log(this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEncreEnveloppe').value);

          setTimeout(() => {
            console.log(this._CampagneTypologieService.EnveloppeFormGroup.value)
          })

          // update model
          this._GlobalDataService.Campagne.CodeEncreEnveloppe = value;
        }
      });
  }

  Valider() {
    if (!this._GlobalDataService.Campagne.CodeEncreEnveloppe) {
      this._GlobalDataService.alert("Veuillez sélectionner un Encre");
      return;
    }
    this.encreChanged();
    this._CampagneTypologieService.navigate(navPaths.selection, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  encreChanged(): void {

    this._GlobalDataService.Campagne.isDirty = true;

    let listeProduits = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );

    this._GlobalDataService.CampagneExt.VitessesProduit = new Array<string>();

    if (listeProduits.length === 0) {
      this._GlobalDataService.Campagne.IdProduit = "0";
      this._GlobalDataService.CampagneExt.Produit = {};
    }
    else if (listeProduits.length === 1) {
      this._GlobalDataService.CampagneExt.Produit = listeProduits[0];
      this._GlobalDataService.Campagne.IdProduit = listeProduits[0].IdProduit;
      this._GlobalDataService.CampagneExt.VitessesProduit.push(this._GlobalDataService.CampagneExt.Produit.Vitesse)
      this._GlobalDataService.Campagne.CodeVitesse = this._GlobalDataService.CampagneExt.Produit.Vitesse;
    }
    else if (listeProduits.length > 1) {
      this._GlobalDataService.CampagneExt.Produit = listeProduits[0];
      this._GlobalDataService.Campagne.IdProduit = listeProduits[0].IdProduit;
      listeProduits.forEach((p: any) => {
        this._GlobalDataService.CampagneExt.VitessesProduit.push(p.Vitesse);
      })
    }
    this._CampagnePlanificationService.refreshPlanification(this._GlobalDataService.Campagne);
  }
}
