import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-enveloppe-grammage-card',
  templateUrl: './enveloppe-grammage-card.component.html',
  styleUrls: ['./enveloppe-grammage-card.component.scss']
})
export class EnveloppeGrammageCardComponent implements OnInit {

  navigationOption = {};

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {
    this.navigationOption = { typologie: 'enveloppe' };
  }

  ngOnInit(): void {
    // Grammage
    this._CampagneTypologieService.dataGrammagesEnveloppes = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );
    this._CampagneTypologieService.dataGrammagesEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataGrammagesEnveloppes, 'CodeGrammageEnveloppe');

    if (this._CampagneTypologieService.dataGrammagesEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeGrammageEnveloppe = this._CampagneTypologieService.dataGrammagesEnveloppes[0].CodeGrammageEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeGrammageEnveloppe = "";
    }

    console.log('_GlobalDataService.Campagne.CodeGrammageEnveloppe = ');
    console.log(this._GlobalDataService.Campagne.CodeGrammageEnveloppe)

  }

  ngAfterViewInit() {
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeGrammageEnveloppe')
        .valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneTypologieService.EnveloppeFormGroup.get('CodeGrammageEnveloppe').valid) {

        console.log('CodeGrammageEnveloppe value changed')
        console.log(this._CampagneTypologieService.EnveloppeFormGroup.get('CodeGrammageEnveloppe').value);
       
        setTimeout(() => {
          console.log(this._CampagneTypologieService.EnveloppeFormGroup.value)
        })

        // update model
        this._GlobalDataService.Campagne.CodeGrammageEnveloppe = value;
      }
    });
  }



  Valider() {
    if (!this._GlobalDataService.Campagne.CodeGrammageEnveloppe) {
      this._GlobalDataService.alert("Veuillez sélectionner un Grammage");
      return;
    }

    this.grammageChanged();
    this._CampagneTypologieService.navigate(navPaths.finition, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  grammageChanged(): void {

    this._GlobalDataService.Campagne.isDirty = true;

    // Finition
    this._CampagneTypologieService.dataFinitionsEnveloppes = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );
     this._CampagneTypologieService.dataFinitionsEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataFinitionsEnveloppes, 'CodeFinitionEnveloppe');

    if (this._CampagneTypologieService.dataFinitionsEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeFinitionEnveloppe = this._CampagneTypologieService.dataFinitionsEnveloppes[0].CodeFinitionEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeFinitionEnveloppe = "";
    }

    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeFinitionEnveloppe').patchValue(this._GlobalDataService.Campagne.CodeFinitionEnveloppe, { emitEvent: false });
  }
}
