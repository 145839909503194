<h2 mat-dialog-title>{{data.titre}}</h2>
<mat-dialog-content class="mat-typography">

    <div [innerHTML]="trustHtml(data.message)"></div>
    <br>
    <mat-form-field style="width: 50%">
        <mat-label>Commentaire</mat-label>
        <textarea matInput maxlength="200" name="commentaireAnnulation" [(ngModel)]="_GlobalDataService.Campagne.CommentaireAnnulation" #ctrl="ngModel"></textarea>
    </mat-form-field>

</mat-dialog-content>
<div mat-dialog-actions align="end">
    <button *ngIf="data.BouttonConfirmation" mat-raised-button mat-dialog-close
        (click)="onCancel()">{{ data.cancelMsg || "Annuler" }}</button>
    <button *ngIf="data.BouttonConfirmation" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial
        (click)="onConfirm()">{{ data.confirmMsg || "Je confirme" }}</button>
    <button *ngIf="!data.BouttonConfirmation" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial
        (click)="onConfirm()">OK</button>
</div>