import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StdUserRouteAccessService, UserRouteAccessService } from '@core/services';
import { AdminConfirmationComponent } from './components/account/admin-confirmation/admin-confirmation.component';
import { ResetComponent } from './components/account/reset/reset.component';
import { SuiviCampagnesComponent } from './components/suivi-campagnes/suivi-campagnes.component';
import { SuiviDetailCampagneComponent } from './components/suivi-detail-campagne/suivi-detail-campagne.component';
import { ListeAnnonceursComponent } from './components/liste-annonceurs/liste-annonceurs.component';
import { ChangePasswordCheckComponent, ChangePasswordComponent, ChangePasswordRequestComponent, HomeComponent,   LoginPageComponent, SuiviCampagneRecurrenteComponent, SuiviCampagneUneSeuleFoisComponent } from './components';
import { LoginComponent } from './components/login-page/login/login.component';
import { CompteClientComponent } from './components/compte-client/compte-client.component';
import { MentionsLegalesComponent } from './../shared/components/mentions-legales/mentions-legales.component'
import { CreateAnnonceurComponent } from './components/create-annonceur/create-annonceur.component';


const routes: Routes = [
  { 
    path: '', 
    component: LoginPageComponent
  },
  { 
    path: 'loginpage', 
    component: LoginPageComponent,
    children: [
      { 
        path: 'login', 
        component: LoginComponent, 
        outlet: 'loginview',
      },   
      { 
        path: 'change-password-request', 
        component: ChangePasswordRequestComponent, 
        outlet: 'loginview',
      },   
      { 
        path: 'change-password', 
        component: ChangePasswordComponent, 
        outlet: 'loginview',
      },
      { 
        path: 'change-password-check', 
        component: ChangePasswordCheckComponent, 
        outlet: 'loginview',
      },       
    ]     
  }, 

  {
    path: 'home',
    component: HomeComponent,
    canActivate: [StdUserRouteAccessService],
    children: [
      { 
        path: 'liste-annonceurs', 
        component: ListeAnnonceursComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },
      { 
        path: 'create-annonceur', 
        component: CreateAnnonceurComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },
      { 
        path: 'compte-client', 
        component: CompteClientComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },   
      {
        path: 'suivi-campagnes/:IdAnnonceur',
        component: SuiviCampagnesComponent, 
        canActivate: [StdUserRouteAccessService],
        outlet: 'navview',
      },   
      { 
        path: 'mentions-legales', 
        component: MentionsLegalesComponent, 
        outlet: 'navview',
      },   
    ],
  },


  { path: 'account/activate', component: AdminConfirmationComponent, canActivate: [] },
  { path: 'account/reset/finish', component: ResetComponent, canActivate: [] },

  { path: 'login', component: LoginComponent, canActivate: [] },
  // { path: 'liste-annonceurs', component: ListeAnnonceursComponent, canActivate: [UserRouteAccessService] },
  {
    path: 'admin-campagnes/:IdAnnonceur',
    component: SuiviCampagnesComponent, canActivate: [UserRouteAccessService]
  },
  {
    path: 'suivi-campagne-une-seule-fois/:IdCampagne',
    component: SuiviCampagneUneSeuleFoisComponent, canActivate: [UserRouteAccessService]
  },
  {
    path: 'suivi-campagne-recurrente/:IdCampagne',
    component: SuiviCampagneRecurrenteComponent, canActivate: [UserRouteAccessService]
  },
  {
    path: 'suivi-detail-campagne/:IdCampagne',
    component: SuiviDetailCampagneComponent, canActivate: [UserRouteAccessService]
  },
  { path: 'suivi-detail-campagne/:IdCampagne/:showButtonRetour', component: SuiviDetailCampagneComponent, canActivate: [UserRouteAccessService] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
