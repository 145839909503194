<!-- Votre sélection Lettre -->
<app-typologie-lettre-card 
    [EditMode] = true 
    [showFormat] = true 
    [showPage] = true
    [showDimension]="_GlobalDataService.Campagne.CodeDimensionsSupport != ''"
    [showGrammage]="_GlobalDataService.Campagne.CodeGrammageSupport != ''"
    [showFinition]="_GlobalDataService.Campagne.CodeFinitionSupport != ''"
    [showEncre]="_GlobalDataService.Campagne.CodeEncreSupport != ''">
</app-typologie-lettre-card>


