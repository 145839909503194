import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-support-dimension-card',
  templateUrl: './support-dimension-card.component.html',
  styleUrls: ['./support-dimension-card.component.scss']
})
export class SupportDimensionCardComponent implements OnInit {

  @Input() typologie?: string;

  navigationOption = {};

  CodeDimensionsSupportSub: Subscription;

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {
   }

  ngOnInit(): void {
    this.navigationOption = { typologie: this.typologie };
  }

  ngAfterViewInit() {  
    this.CodeDimensionsSupportSub = this._CampagneTypologieService.SupportFormGroup.get('CodeDimensionsSupport')
        .valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneTypologieService.SupportFormGroup.get('CodeDimensionsSupport').valid) {

        console.log('CodeDimensionsSupport value changed to ')
        console.log(this._CampagneTypologieService.SupportFormGroup.get('CodeDimensionsSupport').value);
       
        setTimeout(() => {
          console.log("this._CampagneTypologieService.SupportFormGroup = ");
          console.log(this._CampagneTypologieService.SupportFormGroup.value)
        })

        // update model
        this._GlobalDataService.Campagne.CodeDimensionsSupport = value;
      }
    });
  }


  Valider() {
    if (!this._GlobalDataService.Campagne.CodeDimensionsSupport) {
      this._GlobalDataService.alert("Veuillez sélectionner une Dimension");
      return;
    }     
    this.dimensionChanged();
    this._CampagneTypologieService.navigate(navPaths.grammage, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  dimensionChanged(): void {
   
    this._GlobalDataService.Campagne.isDirty = true;

    // Grammage
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.produits
      .filter((element: { CodeDimensionsSupport: string | undefined; CodeSupport: string | undefined; CodeTypologie: any; }) => element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataGrammagesSupports, 'CodeGrammageSupport');

    if (this._CampagneTypologieService.dataGrammagesSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeGrammageSupport = this._CampagneTypologieService.dataGrammagesSupports[0].CodeGrammageSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeGrammageSupport = "";
    } 

    this._CampagneTypologieService.SupportFormGroup.get('CodeGrammageSupport').patchValue(this._GlobalDataService.Campagne.CodeGrammageSupport, { emitEvent: false });


    // Finition
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataFinitionsSupports, 'CodeFinitionSupport');

    if (this._CampagneTypologieService.dataFinitionsSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeFinitionSupport = this._CampagneTypologieService.dataFinitionsSupports[0].CodeFinitionSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeFinitionSupport = "";
    }

    this._CampagneTypologieService.SupportFormGroup.get('CodeFinitionSupport').patchValue(this._GlobalDataService.Campagne.CodeFinitionSupport, { emitEvent: false });


    // Encre
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.produits
      .filter((element: { CodeFinitionSupport: any; CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeFinitionSupport == this._GlobalDataService.Campagne.CodeFinitionSupport
        && element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEncresSupports, 'CodeFinitionSupport');

    if (this._CampagneTypologieService.dataEncresSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeEncreSupport = this._CampagneTypologieService.dataEncresSupports[0].CodeEncreSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeEncreSupport = "";
    }

    this._CampagneTypologieService.SupportFormGroup.get('CodeEncreSupport').patchValue(this._GlobalDataService.Campagne.CodeEncreSupport, { emitEvent: false });
  }

  selectOption(e) {
    console.log(e);
  }

  ngOnDestroy() {
    this.CodeDimensionsSupportSub.unsubscribe();
  }

}
