import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GlobalDataService } from './global-data.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  pieStatusdataSub: Subject<Object[]> = new Subject<Object[]>();
  pieStatusdataRaw: any[] = [];
  pieStatusdata: any[] = [];
  pieCourrierdataSub: Subject<Object[]> = new Subject<Object[]>();
  pieCourrierdataRaw: any[] = [];
  pieCourrierdata: any[] = [];

  _url = environment.url_api;

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,
  ) { }

  getChartCampagneByIdAnnonceur(IdAnnonceur: string) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url = this._url + '/campagne/charts-statut/' + IdAnnonceur;
    let res = this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
    res.subscribe(
      response => {
        this.chartsCampagneResponse(response);
      },
      error => { }
    );
  }

  chartsCampagneResponse(response: { campagne: string | any[]; TotalNumberOfStatutCampagne: any; }) {
    if (typeof response.campagne === 'object') {
      this.pieStatusdataRaw = [];
      for (let index = 0; index < response.campagne.length; index++) {
        let pourcentage = (Math.round(100 * Number(response.campagne[index].NumberOfStatut)) / Number(response.TotalNumberOfStatutCampagne)).toFixed(2);
        let text = response.campagne[index].Statut + ' ' + pourcentage + '%'
        const element = {
          statut: response.campagne[index].Statut,
          pourcentage: pourcentage,
          text: text
        }
        this.pieStatusdataRaw.push(element);
      }
      this.pieStatusdata = [...this.pieStatusdataRaw];
      this.pieStatusdataSub.next(this.pieStatusdataRaw);
    }
  }

  filterCampagneDataByStatus(selectedStatus: any) {
    this.pieStatusdata = this.pieStatusdata.filter(statusData => !selectedStatus.includes(statusData.statut));
    this.pieStatusdataSub.next(this.pieStatusdata);
  }


  addCampagneDataStatus(selectedStatus: any) {
    const index = this.pieStatusdataRaw.findIndex(statusData => selectedStatus.includes(statusData.statut));
    this.pieStatusdata.splice(index, 0, this.pieStatusdataRaw[index]);
    this.pieStatusdataSub.next(this.pieStatusdata);
  }

  getChartCourrierByIdAnnonceur(IdAnnonceur: string) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url = this._url + '/courrier/charts-courrier/' + IdAnnonceur;
    let res = this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
    res.subscribe(
      response => {
        this.chartsCourrierResponse(response);
      },
      error => { }
    );
  }


  chartsCourrierResponse(response: { courrier: string | any[]; TotalNumberOfStatutCourrier: any; }) {
    //Chart courrier
    if (typeof response.courrier === 'object') {
      for (let index = 0; index < response.courrier.length; index++) {
        let pourcentage = (Math.round(100 * Number(response.courrier[index].NumberOfStatut)) / Number(response.TotalNumberOfStatutCourrier)).toFixed(2);
        let text = response.courrier[index].Statut + ' ' + pourcentage + '%'
        const element = {
          statut: response.courrier[index].Statut,
          pourcentage: pourcentage,
          text: text
        }
        this.pieCourrierdataRaw.push(element);
      }
      this.pieCourrierdata = [...this.pieCourrierdataRaw];
      this.pieCourrierdataSub.next(this.pieCourrierdataRaw);
    }
  }

  filterCourrierDataByStatus(selectedStatus: any) {
    this.pieCourrierdata = this.pieCourrierdata.filter(statusData => !selectedStatus.includes(statusData.statut));
    this.pieCourrierdataSub.next(this.pieCourrierdata);
  }


  addCourrierDataStatus(selectedStatus: any) {
    const index = this.pieCourrierdataRaw.findIndex(statusData => selectedStatus.includes(statusData.statut));
    this.pieCourrierdata.splice(index, 0, this.pieCourrierdataRaw[index]);
    this.pieCourrierdataSub.next(this.pieCourrierdata);
  }

  onComponentDestroy() {
    this.pieStatusdataSub.next([]);
    this.pieCourrierdataSub.next([]);
  }
}
