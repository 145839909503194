import { InjectionToken } from '@angular/core';

export interface PopoutData {
  modalName: string;
}

export const POPOUT_MODAL_DATA = new InjectionToken<PopoutData>('POPOUT_MODAL_DATA');

export enum PopoutModalName {
  'startComp'      = 'START_COMP'
}

export let POPOUT_MODALS: any = {};

