import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAnnonceur, Annonceur } from '../models/annonceur.model';
import { Observable } from 'rxjs';
import { GlobalDataService } from './global-data.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnnonceurService {

  _url: string = environment.url_api + '/annonceur';
  _standaloneUrl: string = environment.url_api + '/standalone/annonceur';
  _salesforceUrl: string = environment.url_api + '/salesforce/annonceur';

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,) { }


    // ajout nom du client
  add(Annonceur: Annonceur) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url = this._url + '/add';

    return this._http.post<any>(url, { request: Annonceur }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    })
  }

  // ajout nom du client
  put(Annonceur: Annonceur) {//put

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url;

    switch (this._GlobalDataService.CONTEXT) {
      case "SALESFORCE": {
        url = this._salesforceUrl + '/' + Annonceur.IdAnnonceur;
        break;
      }
      default: {
        url = this._url + '/' + Annonceur.IdAnnonceur;
        break;
      }
    }

    return this._http.put<any>(url, { request: Annonceur }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    })
  }

  get(): Observable<any> {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url : string = "";

    console.log( this._GlobalDataService.CONTEXT);
    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/get';
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/get'
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/get';
        break;
      }
    }
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }
  /**
   * 
   * @param filtre exemple :
   * {
    "filter": [
        {
            "type": "text",
            "field": "StatutCampagne",
            "contains": ["Confirmée", ]
      },
      {
        "type": "date",
        "field": "DateCreation",
        "start": "2020-07-30 00:00:00",
        "end": "2020-08-19 00:00:00"
      }
    ]
  }
   */
  list(filtre?: any): Observable<any> {
    
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url = this._url + '/list';
    if (!filtre) {
      filtre = {
        "filter": []
      }

    }
    return this._http.post<any>(url, filtre, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

  AdminList(filtre?: any): Observable<any> {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    

    let url = this._url + '/admin-list';
    if (!filtre) {
      filtre = {
        "filter": []
      }

    }
    return this._http.post<any>(url, filtre, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  uploadLogo(file: any) {
    
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url : string = "";

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/logo/upload';
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/logo/upload'
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/logo/upload'
        break;
      }
    }
    let formData = new FormData();    
    formData.append("file", file);


    return this._http.post<any>( url, formData, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

  downloadLogo() {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url : string = "";

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/logo/get';
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/logo/get'
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/logo/get'
        break;
      }
    }

    return this._http.get(url, {
      params: this._GlobalDataService.objectParams,
      responseType: "blob",
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

  getJwtoken() {
    return this._GlobalDataService.getJwtoken();
  }

}
