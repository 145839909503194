import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccountService, AnnonceurService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(public _GlobalDataService: GlobalDataService, private _AccountService: AccountService, private _AnnonceurService: AnnonceurService) { }

  ngOnInit(): void {
  }

}
