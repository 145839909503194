import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Adresse } from '@core/models/adresse.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { CampagneAdressesService, GlobalDataService } from '@core/services';
import { AdresseService } from '@core/services/adresse.service';
import { CampagneService } from '@core/services/campagne.service';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { AdresseRetourDialogComponent } from '../adresse-retour-dialog/adresse-retour-dialog.component';

@Component({
  selector: 'app-campagne-adresse-retour',
  templateUrl: './campagne-adresse-retour.component.html',
  styleUrls: ['./campagne-adresse-retour.component.scss']
})
export class CampagneAdresseRetourComponent implements OnInit {


  @ViewChild('adresseRetourRef') private adresseRetourRef!: ElementRef;


  availableRecords: Array<AdresseRetour> = [];
  filteredAvailableRecords: Array<AdresseRetour> = [];
  selectedRecords: Array<AdresseRetour> = [];;


  public controls: any;

  public form!: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    private _CampagneService: CampagneService,
    private _AdresseService: AdresseService,
    public _campagneAdressesService: CampagneAdressesService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.initialise();
  }

  public initialise() {
    this._GlobalDataService.CampagneExt.selectedAdresses = new Array<AdresseRetour>();
    this._GlobalDataService.CampagneExt.selectedAdresses.push(this._GlobalDataService.CampagneExt.Adresse);
    this.availableRecords = this._GlobalDataService.availableAdresses;
    this.filteredAvailableRecords = this.availableRecords.filter(adresse => adresse.IdAdresse != this._GlobalDataService.Campagne.IdAdresse);
    this._campagneAdressesService.form.get('NomAdresse').setValue(this._GlobalDataService.CampagneExt.selectedAdresses[0].NomAdresse);
  }

  ngAfterViewInit() {
    // streams
    const keyup2$ = fromEvent(this.adresseRetourRef.nativeElement, 'keyup');

    // wait .5s between keyups to emit current value
    keyup2$
      .pipe(
        map((event: any) => event.currentTarget.value),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filter(value)),
      )
      .subscribe((result) => {
        console.log(result);
        this.filteredAvailableRecords = result;
      });
  }

  private filter(adresseRetour: string): any[] {
    return this.availableRecords
      .filter(adresse => adresse.NomAdresse.toLowerCase().includes(adresseRetour.toLowerCase()))
      .filter(adresse => this._GlobalDataService.CampagneExt.selectedAdresses.indexOf(adresse) < 0);
  }

  drop(event: CdkDragDrop<any[]>) {
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Drap and Drop left to Right
      const currItem = event.previousContainer.data[event.previousIndex];
      if (event.previousContainer.id < event.container.id) {
        this.select(currItem);
      }
      else {
        this.unselect(currItem);
      }
    }
  }


  select(adresse: AdresseRetour) {
    if (this._GlobalDataService.Campagne.IdAdresse != '0') {
      transferArrayItem(
        this._GlobalDataService.CampagneExt.selectedAdresses,
        this.filteredAvailableRecords,
        0,
        this.filteredAvailableRecords.indexOf(adresse)
      );
    }
    transferArrayItem(
      this.filteredAvailableRecords,
      this._GlobalDataService.CampagneExt.selectedAdresses,
      this.filteredAvailableRecords.indexOf(adresse),
      0
    );
    this._campagneAdressesService.form.get('NomAdresse').setValue(adresse.NomAdresse);
    if (this._GlobalDataService.CampagneExt.selectedAdresses.length > 0 ) {
      this._GlobalDataService.Campagne.IdAdresse = this._GlobalDataService.CampagneExt.selectedAdresses[0].IdAdresse;
      this._GlobalDataService.CampagneExt.Adresse =  this._GlobalDataService.CampagneExt.selectedAdresses[0];  
      this._GlobalDataService.Campagne.isDirty = true;
    }

  }

  unselect(adresse: AdresseRetour) {
    this.updateFilteredAvailableRecords();
    if (this._GlobalDataService.Campagne.IdAdresse != '0') {
      transferArrayItem(
        this._GlobalDataService.CampagneExt.selectedAdresses,
        this.filteredAvailableRecords,
        this._GlobalDataService.CampagneExt.selectedAdresses.indexOf(adresse),
        this.filteredAvailableRecords.length - 1
      );
      this._campagneAdressesService.form.get('NomAdresse').setValue(null);
      this._GlobalDataService.Campagne.IdAdresse = '0';
      this._GlobalDataService.CampagneExt.Adresse = null;
      this._GlobalDataService.Campagne.isDirty = true;
    }
  }

  addAdresse() {
    let config = {
      width: "900px",
      data: {
        editmode: false,
      }
    };

    const dialogRef = this.dialog.open(AdresseRetourDialogComponent, config);

    dialogRef.afterOpened().subscribe(() => {
      console.log('The dialog was openned');
    });

    dialogRef.beforeClosed().subscribe(async () => {
      console.log('The dialog before close');
      this.initialise();
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  ValiderSelection() {
    if (this._GlobalDataService.Campagne.isDirty) {
      this._CampagneService.put(this._GlobalDataService.Campagne)
        .subscribe(
          response => {
            console.log('Valider Selection est oK');
            this._GlobalDataService.Campagne.isDirty = false;
          },
          error => {
            this._GlobalDataService.openSnackBarInfo(error.error.message);
          }
        );
    }
  }

  Vider() {
    this.updateFilteredAvailableRecords();
    if (this._GlobalDataService.Campagne.IdAdresse != '0') {
      console.log('Vider');
      // this.filteredAvailableRecords = this.filteredAvailableRecords.concat(this._GlobalDataService.CampagneExt.selectedAdresses);
      // this._GlobalDataService.CampagneExt.selectedAdresses = new Array<AdresseRetour>();

      transferArrayItem(
        this._GlobalDataService.CampagneExt.selectedAdresses,
        this.filteredAvailableRecords,
        this._GlobalDataService.CampagneExt.selectedAdresses.indexOf(this._GlobalDataService.CampagneExt.Adresse),
        this.filteredAvailableRecords.length - 1
      );
      this._campagneAdressesService.form.get('NomAdresse').setValue(null);
      this._GlobalDataService.Campagne.IdAdresse = '0';
      this._GlobalDataService.CampagneExt.Adresse = null;
      this._GlobalDataService.Campagne.isDirty = true;


    }
  }

  updateFilteredAvailableRecords(){
    this._GlobalDataService.availableAdresses = new Array<Adresse>();
    this._AdresseService.list(this._GlobalDataService.Annonceur.IdAnnonceur).subscribe(
      (result: any) => {
        this._GlobalDataService.availableAdresses = result.adresse;
        this.availableRecords = this._GlobalDataService.availableAdresses;
        this.filteredAvailableRecords = this.availableRecords.filter(adresse => adresse.IdAdresse != this._GlobalDataService.Campagne.IdAdresse);
      }
    )
  }

  async editAdresse(adresse: Adresse) {

    if (this._GlobalDataService.Campagne.IdAdresse === '0')  return;

    let config = {
      width: "900px",
      data: {
        editmode: true,
        adresse: adresse
      }
    };

    const dialogRef = this.dialog.open(AdresseRetourDialogComponent, config);

    dialogRef.afterOpened().subscribe(() => {
      console.log('The dialog was openned');
    });

    dialogRef.beforeClosed().subscribe(async () => {
      console.log('The dialog before close');
      this._GlobalDataService.CampagneExt.selectedAdresses[0] = this._GlobalDataService.CampagneExt.Adresse;
      this._campagneAdressesService.form.get('NomAdresse').setValue(this._GlobalDataService.CampagneExt.selectedAdresses[0].NomAdresse);
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }


  submit() {
    //call API
    this._stepper.next();
  }

  updateAdresse(adresse: AdresseRetour) {
    console.log(adresse);
    adresse.Favori =  adresse.Favori === '0' ? '1' : '0';
    this._AdresseService.add(adresse)
      .subscribe(
          response => console.log(response),
          error =>  this._GlobalDataService.alert("Une erreur s'est produite lors de la mise à jour")
      );
  }


  ngOnDestroy() {
  }

}
