<mat-card
  [ngClass]="{
    'gtmt-card-big-container': showRefresh,
    'gtmt-card-small-container': !showRefresh
  }"
  [formGroup]="_GlobalDataService.formEstimate"
>
  <mat-card-title>Estimation tarifaire</mat-card-title>
  <mat-card-content>
    <p *ngIf="showInfo">
      Si vous souhaitez obtenir une estimation tarifaire de votre campagne en
      fin de parcours, merci de renseigner votre volume.
    </p>

    <mat-grid-list cols="1" rowHeight="5rem" style="width: 100%">
      <mat-grid-tile rowspan="2">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Volume</mat-label>
          <input
            type="text"
            matInput
            placeholder="Volume"
            formControlName="volume"
          />
          <button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon svgIcon="Crayon"></mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile rowspan="1" *ngIf="showunitAmountWithoutTax">
        <span class="name">Coût unitaire</span>
        <span class="data">
          {{
            _GlobalDataService.Campagne.unitAmountWithoutTax | number : "1.2-2"
          }}
          € HT</span
        >
      </mat-grid-tile>

      <mat-grid-tile rowspan="1" *ngIf="showamountWithoutTax">
        <span class="name">Coût globale</span>
        <span class="data">
          {{
            _GlobalDataService.Campagne.amountWithoutTax | number : "1.2-2"
          }}
          € HT</span
        >
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
  <mat-card-actions *ngIf="showRefresh">
    <button
      mat-raised-button
      color="blue"
      [disabled]="
        !_GlobalDataService.Campagne?.volume ||
        !_GlobalDataService.CampagneExt?.Produit
      "
      (click)="estimate()"
    >
      Rafraichir
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
