<div
  class="statut-commande-card flex flex-column flex-min"
  [ngClass]="{
    inprogress: statutCommande.Title === 'En cours',
    upcoming: statutCommande.Title === 'A venir',
    done: statutCommande.Title === 'Terminé',
    chili: this._GlobalDataService.Campagne.creationType === 'chili'
  }"
>
  <div class="bloc-top flex flex-column flex-initial">
    <!-- Type -->
    <div class="type flex-initial">
      <span
        *ngIf="
          statutCommande.codeStatut != 'BAT_AVAILABLEOK' &&
          statutCommande.codeStatut != 'BAT_SYMFONY_AVAILABLE'
        "
      >
        Production
      </span>
      <span
        *ngIf="
          statutCommande.codeStatut === 'BAT_AVAILABLEOK' ||
          statutCommande.codeStatut === 'BAT_SYMFONY_AVAILABLE'
        "
      >
        Bon à tirer
      </span>
    </div>

    <!-- Libelle -->
    <div class="libelle flex-initial">
      <span>
        {{ statutCommande.StatutLibelle }}
      </span>
    </div>
  </div>

  <div class="bloc-bottom flex flex-column flex-min">
    <!-- Date -->
    <div class="date flex-initial">
      <span class="text">Date</span>
      <span>
        {{
          statutCommande.dateCreation != "A venir"
            ? (statutCommande.dateCreation | date : "dd/MM/yyyy")
            : "A venir"
        }}
      </span>
    </div>

    <!-- Info -->
    <div class="info flex flex-column flex-min">
      <!-- BAT-->
      <ng-container
        *ngIf="
          statutCommande.codeStatut === 'BAT_AVAILABLEOK' ||
          statutCommande.codeStatut === 'BAT_SYMFONY_AVAILABLE'
        "
      >
        <!-- Classique -->
        <ng-container
          *ngIf="this._GlobalDataService.Campagne.creationType !== 'chili'"
        >
          <!-- Is Active -->
          <button
            *ngIf="!_GlobalDataService.Campagne.isPurged"
            mat-raised-button
            color="blue"
            (click)="VisualiserBAT()"
          >
            Voir
          </button>

          <!-- Is purged -->
          <button
            *ngIf="_GlobalDataService.Campagne.isPurged"
            mat-raised-button
            color="blue"
            style="cursor: pointer"
            [mdePopoverTriggerFor]="purgePopover"
            [disabled]="true"
            (mouseenter)="$event.stopPropagation()"
          >
            Voir
          </button>
        </ng-container>

        <!-- Chili -->
        <ng-container
          *ngIf="this._GlobalDataService.Campagne.creationType === 'chili'"
        >
          <div class="chili-vignette">
            <app-chili-bat-thumbnail></app-chili-bat-thumbnail>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<mde-popover
  #purgePopover="mdePopover"
  [mdePopoverOverlapTrigger]="false"
  [mdeFocusTrapAutoCaptureEnabled]="false"
  mdePopoverPositionY="above"
>
  <mat-card class="gtmt-popover-card"> Le BAT a été purgé </mat-card>
</mde-popover>
