import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataService } from '@core/services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private _GlobalDataService: GlobalDataService,
  ) { }

  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.resetKey = params['key'];
    // });

  }
}
