<app-typologie-enveloppe-card>
    [EditMode]=true 
    [showFormat]=true 
    [showEnveloppe]=false
    [showDimension]=false 
    [showGrammage]=false 
    [showFinition]=false
    [showEncre]=false>
</app-typologie-enveloppe-card>

<mat-toolbar>
    <h2>Paramétrage de l'enveloppe</h2>
    <button mat-raised-button color="blue" (click)="gotoTypologieSelection()">Terminer</button>
</mat-toolbar>   

<app-enveloppe-card></app-enveloppe-card>

