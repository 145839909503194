import { Component, Input, OnInit } from '@angular/core';
import { Campagne } from '@core/models/campagne.model';
import { GlobalDataService } from '@core/services';
import { FichierPersoStandaloneService } from '@core/services/fichier-perso-standalone.service';

@Component({
  selector: 'app-fichier-ciblage-card',
  templateUrl: './fichier-ciblage-card.component.html',
  styleUrls: ['./fichier-ciblage-card.component.scss']
})
export class FichierCiblageCardComponent implements OnInit {

  @Input() campagne?: Object;
  @Input() isPurged?: boolean = false;

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _FichierPersoStandalone: FichierPersoStandaloneService
  ) {
  }

  ngOnInit(): void { }

  public downloadFile(filename: string): void {
    this._FichierPersoStandalone.download(this.campagne['IdCampagne'])
      .subscribe(response => {
        var blob = new Blob([response], { type: response.type});
        let a = document.createElement("a");
        a.style.display = "none";
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.download = filename;
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
      })
  }
}
