<div class="login">
  <mat-card [formGroup]="form">
    <mat-card-header>
      <mat-card-title>
        Connectez vous à votre espace<br />Cap&nbsp;Mailing&nbsp;Plateforme
      </mat-card-title>
      <mat-card-subtitle>
        Veuillez entrer vos identifiants de connexion pour accéder au service
        Cap Mailing Plateforme
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Utilisateur</mat-label>
        <input
          matInput
          placeholder="dupont"
          formControlName="loginName"
          (keydown.enter)="login()"
          [(ngModel)]="username"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Mot de passe</mat-label>
        <input
          matInput
          placeholder="........."
          formControlName="password"
          type="password"
          [type]="hide ? 'password' : 'text'"
          (keydown.enter)="login()"
          [(ngModel)]="password"
        />
        <mat-icon matSuffix (click)="toggleVisibility()">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
        <mat-hint align="end" (click)="resetPassword()"
          >Mot de passe oublié ?</mat-hint
        >
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button
        class="submit-login"
        mat-raised-button
        color="blue"
        (click)="login()"
        [disabled]="!(username && password) || isLoginLoading"
      >
        <span>Connexion</span>
        <mat-spinner *ngIf="isLoginLoading" diameter="20" color="accent"></mat-spinner>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
