import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CampagnePlanificationService,
  GlobalDataService,
} from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-choix-recurrence',
  templateUrl: './choix-recurrence.component.html',
  styleUrls: ['./choix-recurrence.component.scss'],
})
export class ChoixRecurrenceComponent implements OnInit, OnDestroy {
  recurrences = ['Une seule fois', 'Récurrente'];

  frequenceEnvoiSub: Subscription;

  constructor(
    public _GlobalDataService: GlobalDataService,
    public _CampagnePlanificationService: CampagnePlanificationService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.frequenceEnvoiSub = this._CampagnePlanificationService.form
      .get('FrequenceEnvoi')
      .valueChanges.subscribe((frequenceEnvoi: string) => {
        // if valid
        if (
          this._CampagnePlanificationService.form.get('FrequenceEnvoi').valid
        ) {
          // update model
          this._GlobalDataService.Campagne.FrequenceEnvoi = frequenceEnvoi;
          this._GlobalDataService.Campagne.isDirty = true;
          this.init();
        }
      });
  }

  init() {
    if (this._GlobalDataService.Campagne.FrequenceEnvoi == 'Une seule fois') {
      this._CampagnePlanificationService.form.get('Recurrence').reset();
      this._GlobalDataService.Campagne.Recurrence = '';

      this._CampagnePlanificationService.form.get('DetailRecurrence').reset();
      this._GlobalDataService.Campagne.DetailRecurrence = '';

      this._CampagnePlanificationService.form.get('DateDeDebut').reset();
      this._GlobalDataService.Campagne.DateDeDebut = 'null';

      this._CampagnePlanificationService.form.get('DateDeFin').reset();
      this._GlobalDataService.Campagne.DateDeFin = 'null';
    } else if (
      this._GlobalDataService.Campagne.FrequenceEnvoi == 'Récurrente'
    ) {
      this._CampagnePlanificationService.form.get('DetailRecurrence').reset();
      this._GlobalDataService.Campagne.DetailRecurrence = '';
    } else {
      this._CampagnePlanificationService.form.reset();
    }
  }

  public ngOnDestroy() {
    this.frequenceEnvoiSub?.unsubscribe();
  }

  public updateFrequenceEnvoi(recurrence: string) {
    this._CampagnePlanificationService.form
      .get('FrequenceEnvoi')
      .setValue(recurrence);
    this.informationRecurrente(recurrence);
  }

  private informationRecurrente(recurrence: string) {
    if (
      this._GlobalDataService.isStandaloneContext() &&
      recurrence === 'Récurrente'
    ) {
      this._GlobalDataService.alert(
        'Pour créer les commandes de vos futures occurrences, veuillez déposer vos fichiers de ciblage via SFTP.'
      );
    }
  }
}
