export class Support {
    typologie: string;
    support: string;
    dimension: string;
    grammage: string;
    Encre: string;
    finition: string;

    constructor() {
        this.typologie = "";
        this.support = "";
        this.dimension = "";
        this.grammage = "";
        this.Encre = "";
        this.finition = "";
    }
}


export enum navPaths {
    'selection' = 'typologie-selection',
    'selection-lettre' = 'typologie-selection-lettre',
    'selection-enveloppe' = 'typologie-selection-enveloppe',
    'format' = 'typologie-format',
    'dimension' = 'typologie-dimension',
    'grammage' = 'typologie-grammage',
    'finition' = 'typologie-finition',
    'encre' = 'typologie-encre',
    'page' = 'typologie-page',
    'enveloppe' = 'typologie-enveloppe'
}
