<mat-card class="gtmt-card-container" [formGroup]="_CampagneTypologieService.SupportFormGroup">
    <mat-card-title>Dimension</mat-card-title>
    <mat-card-content>

        <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'">
            <mat-radio-group formControlName="CodeDimensionsSupport" aria-labelledby="dimension-radio-group-label"
                class="dimension-radio-group">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="1" 
                   (click)="_CampagneTypologieService.SupportFormGroup.get('CodeDimensionsSupport').setValue(dataDimensionsSupport.CodeDimensionsSupport)"
                    *ngFor="let dataDimensionsSupport of _CampagneTypologieService.dataDimensionsSupports">
                    <span> {{dataDimensionsSupport.DimensionsSupport}}</span>
                    <mat-radio-button [value]="dataDimensionsSupport.CodeDimensionsSupport">
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list>


    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="Valider()">
            Valider la sélection
        </button>
    </mat-card-actions>

</mat-card>