import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalDataService } from '@core/services';
import { NavigateUtil } from '@shared/utils/navigate.util';

declare const window: any;

@Component({
  selector: 'app-message-confirmation',
  templateUrl: './message-confirmation.component.html',
  styleUrls: ['./message-confirmation.component.scss'],
})
export class MessageConfirmationComponent implements AfterViewInit {
  public titre = 'Confirmation';
  public NomCampagne?: string;

  public context = 'Eloqua';

  constructor(
    private activeRoute: ActivatedRoute,
    private elementRef: ElementRef,
    public _GlobalDataService: GlobalDataService
  ) {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      this.NomCampagne = params.get('NomCampagne') as string;
    });

    // Lower case the first letter of the context
    if (this._GlobalDataService.CONTEXT) {
      this.context =
        this._GlobalDataService.CONTEXT.charAt(0) +
        this._GlobalDataService.CONTEXT.slice(1).toLowerCase();
    }
  }

  ngAfterViewInit(): void {
    // Go to top
    NavigateUtil.goToTopSideNavContent(this.elementRef);
  }

  closePopOut() {
    window.top.close();
  }
}
