import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-change-password-request',
  templateUrl: './change-password-request.component.html',
  styleUrls: ['./change-password-request.component.scss']
})
export class ChangePasswordRequestComponent implements OnInit {

  form: FormGroup;

  constructor(
    private _GlobalDataService: GlobalDataService,
    private _formBuilder: FormBuilder,
    private _AccountService: AccountService,
    public router: Router,
    private activatedRoute: ActivatedRoute,    
  ) { 
    this.form = this._formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(254), Validators.email]],
    });
  }

  ngOnInit(): void {
  }

  gotoLogin() {
    const path='login';
    this.router.navigate([{ outlets: { 'loginview': path } }],
    {
      relativeTo: this.activatedRoute.parent,
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true,
    }).then((e) => {
      if (e) {
        console.log("Navigation to " + path + " is successful!");
      } else {
        console.log("Navigation to " + path + " has failed!");
      }
    });
  }


  requestReset() {
    if (this.form.get('email').invalid) {
      this._GlobalDataService.alert("Le champ Adresse mail est obligatoire."
        + "<br>Merci de compléter les champs concernés");
      return;
    }
    this._AccountService.standaloneResetPasswordInit(this.form.get(['email'])!.value)
      .subscribe((response) => {
        console.log(response);
        // this._GlobalDataService.alert("Demande de changement de mot de passe effectuée.");
        const path='change-password-check';
        this.router.navigate([{ outlets: { 'loginview': path } }],
        {
          relativeTo: this.activatedRoute.parent,
          queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
          skipLocationChange: true,
        }).then((e) => {
          if (e) {
            console.log("Navigation to " + path + " is successful!");
          } else {
            console.log("Navigation to " + path + " has failed!");
          }
        });

      });

  }
}
