import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalDataService } from './global-data.service';
import { environment } from 'environments/environment';
import { ContactResponse } from '@core/models/contact-response.model';

@Injectable({
  providedIn: 'root',
})
export class FichierPersoStandaloneService {
  _url = environment.url_standalone + '/personnalisation';

  constructor(
    private _http: HttpClient,
    public _GlobalDataService: GlobalDataService
  ) {}

  public upload(file: File, idCampagne: string) {
    let url: string = this._url + '/' + idCampagne;
    let formData = new FormData();
    formData.append('file', file);

    return this._http.post<any>(url, formData, {
      params: {},
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  public delete(idCampagne: string) {
    let url: string = this._url + '/' + idCampagne;
    return this._http.delete<any>(url, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  public readContact(idCampagne: string) {
    let url: string = this._url + '/' + idCampagne;

    return this._http.get<ContactResponse>(url, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    });
  }

  public download(idCampagne: string) {
    let url: string = this._url + '/download/' + idCampagne;

    return this._http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._GlobalDataService.getJwtoken()}`,
      }),
    })
  }
}
