<app-rythme-envoi></app-rythme-envoi>
<app-vitesse-distribution></app-vitesse-distribution>
<app-choix-recurrence *ngIf="_GlobalDataService.Campagne.CodeVitesse"></app-choix-recurrence>
<app-campagne-une-seule-fois *ngIf="_GlobalDataService.Campagne.FrequenceEnvoi == 'Une seule fois'">
</app-campagne-une-seule-fois>
<app-campagne-recuerrente *ngIf="_GlobalDataService.Campagne.FrequenceEnvoi == 'Récurrente' ">
</app-campagne-recuerrente>

<!-- Stepper navigation  -->
<app-campagne-stepper-nav
  [prevDisplay]="true"
  [nextDisplay]="true"
  (previous)="goToPrevious()"
  (next)="submit()"
></app-campagne-stepper-nav>
