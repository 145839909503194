<mat-grid-list [cols]="3" rowHeight="8rem" [gutterSize]="'2rem'">

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Commande envoyée le </h2>
        <span>{{_GlobalDataService.CampagneExt.CurrentCommande?.DateCreation | date: 'dd/MM/yyyy' }}</span>
    </mat-grid-tile>

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Numéro de commande</h2>
        <span>{{_GlobalDataService.CampagneExt.CurrentCommande?.IdCommande}}</span>

    </mat-grid-tile>

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Nombre de plis</h2>
        <span>{{_GlobalDataService.CampagneExt.CurrentCommande?.NombrePli}}</span>

    </mat-grid-tile>

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Référence La Poste</h2>
        <span>{{_GlobalDataService.CampagneExt.CurrentCommande?.NCommandeLaPoste}}</span>

    </mat-grid-tile>

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Commande transmise le</h2>
        <span>{{_GlobalDataService.CampagneExt.CurrentCommande?.DateCreation | date: 'dd/MM/yyyy' }}</span>

    </mat-grid-tile>

    <mat-grid-tile class="gtmt-ai-start" colspan="1" rowspan="1">
        <h2>Nombre de plis non distribués</h2>
        <span>{{_GlobalDataService.CampagneExt.CurrentCommande?.NombrePliPND}}</span>

    </mat-grid-tile>
</mat-grid-list>


<mat-grid-list [cols]="1" rowHeight="8rem" [gutterSize]="'2rem'">

    <!-- Comportement peut se changer dans les évolutions
    Distingué par FrequenceEnvoi
       - Une seule fois : √ campagne × commande -> griser le bouton 
                          √ campagne √ commande -> annuler la commande
       - Récurrente : √ campagne × commande -> annuler la campagne pour éviter de générer des commandes 
                      √ campagne √ commande -> annuler la campagne pour éviter de générer futures commandes, ne touche pas commande en cours  -->
    <mat-grid-tile class="gtmt-ai-end" colspan="1" rowspan="1">
        <button mat-raised-button color="blue"
            [disabled]="statutsAutoriseAnnulationCommande.indexOf(_GlobalDataService.CampagneExt.CurrentCommande.CodeStatut) > -1 || !_GlobalDataService.CampagneExt.CurrentCommande.CodeStatut"
            (click)="demandeAnnulationCommande(_GlobalDataService.CampagneExt.CurrentCommande)">
            Annuler cette campagne courrier
        </button>
    </mat-grid-tile>
</mat-grid-list>