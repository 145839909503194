import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { GlobalDataService } from './global-data.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProduitService {
  _url = environment.url_api + '/produit';
  _standaloneUrl = environment.url_api + '/standalone/produit';

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,) { }

  getProduitById(idProduit: string) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url: string = "";

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + "/" + idProduit;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + "/" + idProduit;
        break;
      }
      case "SALESFORCE": {
        url = this._standaloneUrl + "/" + idProduit;
        break;
      }
    }

    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

  /**
   * get la liste des produits
   * GET /produit/list
   */
  getList() {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    let url: string = "";

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/list';
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/list'
        break;
      }
      case "SALESFORCE": {
        url = this._standaloneUrl + '/list'
        break;
      }
    }

    let observable = new Observable<any>(subscriber => {
      this._http.get<any>(url, {
        params: this._GlobalDataService.objectParams,
        headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
      })
        .subscribe(
          response => {
            subscriber.next(response);
            subscriber.complete();
          });
    });
    return observable;
  }

}
