import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalDataService } from './global-data.service';
import { Router } from '@angular/router';

import * as $ from 'jquery';
// @ts-ignore
import * as Postmonger from 'postmonger';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { PopoutModalName } from '@core/models/popout.tokens';
import { PopoutService } from './popout.service';
import { AnnonceurService } from './annonceur.service';
import { CampagneService } from './campagne.service';

@Injectable({
  providedIn: 'root'
})
export class SalesforceService {
  _standaloneUrl = environment.url_api;

  url: string;

  constructor(private _http: HttpClient, 
    private popoutService: PopoutService,
    private _AnnonceurService: AnnonceurService,
    private _CampagneService: CampagneService,
    private _GlobalDataService: GlobalDataService, private _router: Router) {
    console.log("SalesforceService instanciated");

    if (window.location.hostname === 'localhost') {
      const port = window.location.port;
      this.url = 'http://localhost:' + port + '/salesforce/home';
    }
    else {
      this.url = environment.url_entrypoint + '/salesforce/home';
    }

    console.log(this.url);


  }


  registerListeners() {
    var http = this._http;
    var router = this._router;
    var globalDataService = this._GlobalDataService;
    var annonceurService = this._AnnonceurService;
    var campagneService = this._CampagneService;
    var popoutService = this.popoutService;
    var url = this.url;
    globalDataService.CONTEXT = "SALESFORCE";
    console.log("Register listeners");
    var connection = new Postmonger.Session();
    var authTokens = {};
    var payload: { [index: string]: any } = {};
    //let loadSFAttributes = this.loadSFAttributes.bind(this);
    $(window).ready(onRender);

    connection.on('initActivity', initialize);
    connection.on('requestedTokens', onGetTokens);
    connection.on('requestedEndpoints', onGetEndpoints);
    connection.on('requestedInteraction', onRequestedInteraction);
    connection.on('requestedTriggerEventDefinition', onRequestedTriggerEventDefinition);
    connection.on('requestedDataSources', onRequestedDataSources);

    connection.on('clickedNext', save);

    function onRender() {
      // JB will respond the first time 'ready' is called with 'initActivity'
      connection.trigger('ready');

      // connection.trigger('requestTokens');
      // connection.trigger('requestEndpoints');
      connection.trigger('requestInteraction');
      connection.trigger('requestTriggerEventDefinition');
      connection.trigger('requestDataSources');
    }

    function onRequestedDataSources(dataSources: any) {
      console.log('*** requestedDataSources ***');
      console.log(dataSources);
    }

    function onRequestedInteraction(interaction: any) {
      console.log('*** requestedInteraction ***');
      console.log(interaction);
      // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
      // localStorage.setItem("interactionIdSF", interaction.id);
      globalDataService.setinteractionIdSF(interaction.id);

      if (!interaction.id) {
        globalDataService.alert("Attention, votre campagne n’est pas enregistrée côté Salesforce.<br>Merci de fermer cette fenêtre, d’enregistrer la campagne dans Salesforce puis de rouvrir l’étape CAP Mailing Plateforme pour pouvoir continuer.");
        return;
      }

      connection.trigger('requestTokens');
    }

    function onRequestedTriggerEventDefinition(eventDefinitionModel: any) {
      console.log('*** requestedTriggerEventDefinition ***');
      console.log(eventDefinitionModel);
    }

    function initialize(data: {}) {
      console.log('*** initialize ***');
      console.log(data);
      if (data) {
        payload = data;
      }

      var hasInArguments = Boolean(
        payload['arguments'] &&
        payload['arguments'].execute &&
        payload['arguments'].execute.inArguments &&
        payload['arguments'].execute.inArguments.length > 0
      );

      var inArguments = hasInArguments ? payload['arguments'].execute.inArguments : {};

      console.log(inArguments);

      $.each(inArguments, function (index, inArgument) {
        $.each(inArgument, function (key, val) {


        });
      });

      connection.trigger('updateButton', {
        button: 'next',
        text: 'done',
        visible: true
      });
    }

    function onGetTokens(tokens: { fuel2token?: any; }) {
      console.log("token ", tokens);
      authTokens = tokens;

      connection.trigger('requestEndpoints');

     // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
     // localStorage.setItem("SF_FUEL_TOKEN", tokens.fuel2token);
      globalDataService.setSF_FUEL_TOKEN(tokens.fuel2token);
    }

    function openStartCompPopout(name: string) {
      const modalData = {
        modalName: PopoutModalName.startComp
      };
  
  
      if (!popoutService.isPopoutWindowOpen()) {
        popoutService.openPopoutModal(url + "?id=" + globalDataService.getinteractionIdSF(),name);
      } else {
        const sameComp = popoutService.POPOUT_MODALS['componentInstance'].name === name;
        // When popout modal is open and there is no change in data, focus on popout modal
        if (sameComp) {
          popoutService.focusPopoutWindow();
        } else {
          popoutService.POPOUT_MODALS['outlet'].detach();
          popoutService.focusPopoutWindow();
        }
      }
    }


    function SuccessCurrentCampagne(campagne) {
      if (typeof campagne === 'object') {    
        globalDataService.Campagne = campagne;
  
        console.log('current Campagne : ', globalDataService.Campagne);
  
        console.log('Id Campagne : ', globalDataService.Campagne.IdCampagne);
  
        console.log("campagne.StatutCampagne", campagne.StatutCampagne);
  
        console.log("campagne.CodeStatut", campagne.CodeStatut);
      } 
      openStartCompPopout('SF');
    }
  
    function ErrorCurrentCampagne(error: string) {
      //une erreur est survenue => redirection page erreur
      console.log('CreationCampagneComponent : ErrorCurrentCampagne', error)
      this.router.navigate(['/erreur'], {
        queryParams: globalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true
      });
    }

    function onGetEndpoints(endpoints: { fuelapiRestHost: string; }) {
      console.log("endpoint ", endpoints);
      // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
      //  localStorage.setItem("SF_FUEL_API_REST_HOST", endpoints.fuelapiRestHost);
      globalDataService.setSF_FUEL_API_REST_HOST( endpoints.fuelapiRestHost);      
      let baseUrl: string = environment.url_api + '/salesforce';
      let url = baseUrl + '/checkToken';

      http.post<any>(url, { request: { tokens: authTokens, fuelapiRestHost: endpoints.fuelapiRestHost, interactionIdSF: globalDataService.getinteractionIdSF() } }, {})
        .subscribe(async (result) => {
          console.log("result is ", result);
          if (result) {
            console.log('id token ', result.id_token);
            // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
            // localStorage.setItem("SALESFORCE_APP_JWT", result.id_token);
            globalDataService.setJwtoken(result.id_token);

            // get current campagne
            campagneService.getCurrentCampagne()
            .subscribe(
              response => SuccessCurrentCampagne(response.campagne),
              error => ErrorCurrentCampagne(error)
            );

          } else {
            router.navigate(['/erreur'], {
              queryParams: globalDataService.objectParams, queryParamsHandling: "merge"
            });
          }
        },
          (error) => {
            console.debug("isAuth fail", error);
          }
        );

    }

    function save() {
      var postcardURLValue = $('#postcard-url').val();
      var postcardTextValue = $('#postcard-text').val();

      payload['arguments'].execute.inArguments = [{
        "tokens": authTokens
      }];

      payload['metaData'].isConfigured = true;

      console.log(payload);
      connection.trigger('updateActivity', payload);
    }
  }

  // publish() {
  //   let baseUrl: string = environment.url_api + '/salesforce/publish';
  //   console.log("baseUrl ", baseUrl);
  //   return this._http.post<any>(baseUrl, {});
  // }

  loadSFAttributes() {
    // const schemaEndpoint = localStorage.getItem("SF_FUEL_API_REST_HOST") + "/contacts/v1/schema";
    // const attributeGroupsEndpoint = localStorage.getItem("SF_FUEL_API_REST_HOST") + "/contacts/v1/schemas/SCHEMA_ID/attributeGroups";
    const attributeSetsEndpoint = environment.url_api + "/salesforce/attributeSets";

    // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
    // return this._http.post<any>(attributeSetsEndpoint, { fuel2token: localStorage.getItem("SF_FUEL_TOKEN"), fuelapiRestHost: localStorage.getItem("SF_FUEL_API_REST_HOST") }, {
    //   headers: new HttpHeaders({ "Authorization": `Bearer ${localStorage.getItem("SF_FUEL_TOKEN")}` })
    // })
    return this._http.post<any>(attributeSetsEndpoint, { fuel2token: this._GlobalDataService.getSF_FUEL_TOKEN(), fuelapiRestHost: this._GlobalDataService.getSF_FUEL_API_REST_HOST() }, {
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getSF_FUEL_TOKEN()}` })
    })    
      .pipe(
        map(response => SalesforceService.flatMap(
          response.data.items.map((item: { fullyQualifiedName: any; attributes: any; }) => ({ fqn: item.fullyQualifiedName, attributes: item.attributes })),
          (x: { attributes: any; }) => x.attributes
        ).map((x: { id: any; fullyQualifiedName: any; }) => ({ Id: x.id, ChampFusion: x.fullyQualifiedName, internalName: x.fullyQualifiedName }))
        )
      );
  }

  getToken(clientId: any) {
    const url = environment.url_api + "/salesforce/getToken";

    return this._http.post<any>(url, { clientId });
  }

  static flatMap = (arr: any[], f: { (x: any): any; (arg0: any): any; }) => arr.reduce((r: string | string[], x: any) => r.concat(f(x)), []);

}

