import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalDataService } from '@core/services';
import { DialogData } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-dialog-annulation-campagne',
  templateUrl: './dialog-annulation-campagne.component.html',
  styleUrls: ['./dialog-annulation-campagne.component.scss']
})
export class DialogAnnulationCampagneComponent {
  onConfirm?;
  onCancel?;
  confirmMsg?;
  cancelMsg?;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
    public _GlobalDataService: GlobalDataService,
    private _sanitizer: DomSanitizer) {
      console.log("data = ", data);
      this.onConfirm = data.onConfirm || function() {};
      this.onCancel = data.onCancel || function() {};
    }


  trustHtml(htmlText) {
    return this._sanitizer.bypassSecurityTrustHtml(htmlText);
  }
}