<!-- Votre sélection Enveloppe -->
<app-typologie-enveloppe-card 
    [EditMode]=true 
    [showFormat]=true 
    [showPage]=false
    [showDimension]="_GlobalDataService.Campagne.CodeDimensionsEnveloppe != ''"
    [showGrammage]="_GlobalDataService.Campagne.CodeGrammageEnveloppe != ''"
    [showFinition]="_GlobalDataService.Campagne.CodeFinitionEnveloppe != ''"
    [showEncre]="_GlobalDataService.Campagne.CodeEncreEnveloppe != ''">
</app-typologie-enveloppe-card>



