import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { Campagne } from '@core/models/campagne.model';
import { GlobalDataService } from '.';
import { FormGroup } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class CampagneTypologieService {

  public SupportFormGroup: FormGroup;

  public EnveloppeFormGroup: FormGroup;

  public dataSupportTypologies: any;
  public dataSupports: any;
  public dataDimensionsSupports: any;
  public dataGrammagesSupports: any;
  public dataFinitionsSupports: any;
  public dataEncresSupports: any;

  public dataEnveloppeTypologies: any;
  public dataEnveloppes: any;
  public dataDimensionsEnveloppes: any;
  public dataGrammagesEnveloppes: any;
  public dataFinitionsEnveloppes: any;
  public dataEncresEnveloppes: any;

  public LibelleSupport: any;

  public navLinks: any;

  constructor(
    private router: Router,
    private _GlobalDataService: GlobalDataService,
  ) {
    if (typeof this._GlobalDataService.Campagne === 'object') {
    }
  }

  async navigate(path: string, activatedRoute: ActivatedRoute, navigationOption: NavigationBehaviorOptions) {
    this.router.navigate([{ outlets: { 'navtypologie': path } }],
      {
        state: navigationOption,
        relativeTo: activatedRoute,
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true,
      }).then((e) => {
        if (e) {
          console.log("Navigation to " + path + " is successful!");
        } else {
          console.log("Navigation to " + path + " has failed!");
        }
      });
  }

  refreshSupportFormGroup(campagne: Campagne) {
    this.SupportFormGroup.get('CodeTypologie').setValue(campagne.CodeTypologie, {emitEvent: false});
    this.SupportFormGroup.get('CodeSupport').setValue(campagne.CodeSupport, {emitEvent: false});
    this.SupportFormGroup.get('CodeDimensionsSupport').setValue(campagne.CodeDimensionsSupport, {emitEvent: false});
    this.SupportFormGroup.get('CodeGrammageSupport').setValue(campagne.CodeGrammageSupport, {emitEvent: false});
    this.SupportFormGroup.get('CodeFinitionSupport').setValue(campagne.CodeFinitionSupport, {emitEvent: false});
    this.SupportFormGroup.get('CodeEncreSupport').setValue(campagne.CodeEncreSupport, {emitEvent: false});

  }
  refreshEnveloppeFormGroup(campagne: Campagne) {
    this.EnveloppeFormGroup.get('CodeTypologie').setValue(campagne.CodeTypologie, {emitEvent: false});
    this.EnveloppeFormGroup.get('CodeEnveloppe').setValue(campagne.CodeEnveloppe, {emitEvent: false});
    this.EnveloppeFormGroup.get('CodeDimensionsEnveloppe').setValue(campagne.CodeDimensionsEnveloppe, {emitEvent: false});
    this.EnveloppeFormGroup.get('CodeGrammageEnveloppe').setValue(campagne.CodeGrammageEnveloppe, {emitEvent: false});
    this.EnveloppeFormGroup.get('CodeFinitionEnveloppe').setValue(campagne.CodeFinitionEnveloppe, {emitEvent: false});
    this.EnveloppeFormGroup.get('CodeEncreEnveloppe').setValue(campagne.CodeEncreEnveloppe, {emitEvent: false});
  }

  setCampagneSupport(campagne: Campagne) {
    this._GlobalDataService.Campagne.IdProduit = campagne.IdProduit;
    this._GlobalDataService.Campagne.CodeTypologie = campagne.CodeTypologie;
    this._GlobalDataService.Campagne.CodeSupport = campagne.CodeSupport;
    this._GlobalDataService.Campagne.CodeDimensionsSupport = campagne.CodeDimensionsSupport;
    this._GlobalDataService.Campagne.CodeGrammageSupport = campagne.CodeGrammageSupport;
    this._GlobalDataService.Campagne.CodeFinitionSupport = campagne.CodeFinitionSupport;
    this._GlobalDataService.Campagne.CodeEncreSupport = campagne.CodeEncreSupport;
  }

  setCampagneEnveloppe(campagne: Campagne) {
    this._GlobalDataService.Campagne.CodeEnveloppe = campagne.CodeEnveloppe;
    this._GlobalDataService.Campagne.CodeDimensionsEnveloppe = campagne.CodeDimensionsEnveloppe;
    this._GlobalDataService.Campagne.CodeGrammageEnveloppe = campagne.CodeGrammageEnveloppe;
    this._GlobalDataService.Campagne.CodeFinitionEnveloppe = campagne.CodeFinitionEnveloppe;
    this._GlobalDataService.Campagne.CodeEncreEnveloppe = campagne.CodeEncreEnveloppe;
    this._GlobalDataService.Campagne.CodeModeProduction = campagne.CodeModeProduction;
    this._GlobalDataService.Campagne.CodeProduitAdditionnel = campagne.CodeProduitAdditionnel;
  }

  initDataSupportTypologies(campagne: Campagne) {
    // Typologie (Type de support)
    this.dataSupportTypologies = this._GlobalDataService.distinct(this._GlobalDataService.produits, 'CodeTypologie');
  }

  initDataSupport(campagne: Campagne) {
    // Support
    this.dataSupports = this._GlobalDataService.produits
      .filter((element: {
        CodeTypologie: string | undefined;
      }) => element.CodeTypologie == campagne.CodeTypologie);
    this.dataSupports = this._GlobalDataService.distinct(this.dataSupports, 'CodeSupport');
  }

  initDataDimension(campagne: Campagne) {
    // Dimension
    this.dataDimensionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeSupport: string | undefined; CodeTypologie: string | undefined; }) => element.CodeSupport == campagne.CodeSupport
        && element.CodeTypologie == campagne.CodeTypologie)
    this.dataDimensionsSupports = this._GlobalDataService.distinct(this.dataDimensionsSupports, 'CodeDimensionsSupport');
  }


  initDataGrammage(campagne: Campagne) {
    // Grammage
    this.dataGrammagesSupports = this._GlobalDataService.produits
      .filter((element: { CodeDimensionsSupport: string | undefined; CodeSupport: string | undefined; CodeTypologie: any; }) => element.CodeDimensionsSupport == campagne.CodeDimensionsSupport
        && element.CodeSupport == campagne.CodeSupport
        && element.CodeTypologie == campagne.CodeTypologie)
    this.dataGrammagesSupports = this._GlobalDataService.distinct(this.dataGrammagesSupports, 'CodeGrammageSupport');
  }

  initDataFinition(campagne: Campagne) {
    // Finition
    this.dataFinitionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeGrammageSupport == campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == campagne.CodeSupport
        && element.CodeTypologie == campagne.CodeTypologie)
    this.dataFinitionsSupports = this._GlobalDataService.distinct(this.dataFinitionsSupports, 'CodeFinitionSupport');
  }

  initDataEncre(campagne: Campagne) {
    // Encre
    this.dataEncresSupports = this._GlobalDataService.produits
      .filter((element: { CodeFinitionSupport: any; CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeFinitionSupport == campagne.CodeFinitionSupport
        && element.CodeGrammageSupport == campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == campagne.CodeDimensionsSupport
        && element.CodeSupport == campagne.CodeSupport
        && element.CodeTypologie == campagne.CodeTypologie)
    this.dataEncresSupports = this._GlobalDataService.distinct(this.dataEncresSupports, 'CodeFinitionSupport');
  }

  initDataEnveloppes(campagne: Campagne) {    
    // Enveloppes
    this.dataEnveloppes = this._GlobalDataService.
    produits
      .filter((element: { CodeTypologie: string | undefined; CodeSupport: string | undefined; CodeDimensionsSupport: string | undefined; CodeGrammageSupport: string | undefined; CodeFinitionSupport: string | undefined; CodeEncreSupport: string | undefined; }) => element.CodeTypologie == campagne.CodeTypologie
        && element.CodeSupport == campagne.CodeSupport
        && element.CodeDimensionsSupport == campagne.CodeDimensionsSupport
        && element.CodeGrammageSupport == campagne.CodeGrammageSupport
        && element.CodeFinitionSupport == campagne.CodeFinitionSupport
        && element.CodeEncreSupport == campagne.CodeEncreSupport);
    this.dataEnveloppes = this._GlobalDataService.distinct(this.dataEnveloppes, 'CodeEnveloppe');

  }

  refreshTypologie(campagne: Campagne) {
    this.setCampagneSupport(campagne);
    this.setCampagneEnveloppe(campagne);

    this.initDataSupportTypologies(campagne);
    this.initDataSupport(campagne);
    this.initDataDimension(campagne);
    this.initDataGrammage(campagne);
    this.initDataFinition(campagne);
    this.initDataEncre(campagne);
    this.initDataEnveloppes(campagne);

    this.refreshSupportFormGroup(campagne);
    this.refreshEnveloppeFormGroup(campagne);
  }

  public filterProductBySelection(campagne: Campagne, products: Array<any>) {
    return products.filter((product: any) => {
      const states = [];
      // Format
      if (!campagne.CodeTypologie) {
        return true;
      }
      // Lettre
      if (campagne.CodeSupport) {
        states.push(product.CodeSupport == campagne.CodeSupport);
      }
      if (campagne.CodeDimensionsSupport) {
        states.push(
          product.CodeDimensionsSupport == campagne.CodeDimensionsSupport
        );
      }
      if (campagne.CodeGrammageSupport) {
        states.push(
          product.CodeGrammageSupport == campagne.CodeGrammageSupport
        );
      }
      if (campagne.CodeFinitionSupport) {
        states.push(
          product.CodeFinitionSupport == campagne.CodeFinitionSupport
        );
      }
      if (campagne.CodeEncreSupport) {
        states.push(product.CodeEncreSupport == campagne.CodeEncreSupport);
      }

      // Enveloppe
      if (campagne.CodeEnveloppe) {
        states.push(product.CodeEnveloppe == campagne.CodeEnveloppe);
      }
      if (campagne.CodeDimensionsEnveloppe) {
        states.push(
          product.CodeDimensionsEnveloppe == campagne.CodeDimensionsEnveloppe
        );
      }
      if (campagne.CodeGrammageEnveloppe) {
        states.push(
          product.CodeGrammageEnveloppe == campagne.CodeGrammageEnveloppe
        );
      }
      if (campagne.CodeFinitionEnveloppe) {
        states.push(
          product.CodeFinitionEnveloppe == campagne.CodeFinitionEnveloppe
        );
      }
      if (campagne.CodeEncreEnveloppe) {
        states.push(product.CodeEncreEnveloppe == campagne.CodeEncreEnveloppe);
      }

      for (const state of states) {
        if (!state) {
          return false;
        }
      }
      return true;
    });
  }

  reset() {
    if (typeof this.EnveloppeFormGroup != 'undefined') {
      this.refreshSupportFormGroup(this._GlobalDataService.Campagne);
    }
    if (typeof this.EnveloppeFormGroup != 'undefined') {
      this.refreshEnveloppeFormGroup(this._GlobalDataService.Campagne);
    }
  }
}


