<mat-card [formGroup]="_CampagnePlanificationService.form">
    <mat-card-header>
        <mat-card-title>
            <h2>
                Vitesse de distribution
            </h2>
            <h3 *ngIf=" _GlobalDataService.Campagne.CodeVitesse == 'Rapide'">
                <strong> Minimum de pli </strong> 1 plis
            </h3>
            <h3 *ngIf=" _GlobalDataService.Campagne.CodeVitesse == 'Classique'">
                <strong> Minimum de pli </strong> 1000 plis
            </h3>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <mat-grid-list cols="2" rowHeight="3.5rem" [gutterSize]="'7rem'">
            <mat-radio-group formControlName="CodeVitesse" aria-label="Choisir une vitesse">
                <mat-grid-tile [ngClass]="{
                    'gtmt-checkbox-container': true,
                    'gtmt-checkbox-container-disabled': _GlobalDataService.CampagneExt.VitessesProduit.indexOf(vitesse) === -1
                    }" colspan="1" rowspan="0.5"
                    (click)="_GlobalDataService.CampagneExt.VitessesProduit.indexOf(vitesse) > -1 && _CampagnePlanificationService.form.get('CodeVitesse').setValue(vitesse)"
                    *ngFor="let vitesse of vitesses">
                    <span
                        [class.gtmt-radio-disabled]="_GlobalDataService.CampagneExt.VitessesProduit.indexOf(vitesse) === -1">
                        {{vitesse}}</span>
                    <mat-radio-button [value]="vitesse"
                        [disabled]="_GlobalDataService.CampagneExt.VitessesProduit.indexOf(vitesse) === -1">
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list>
    </mat-card-content>

</mat-card>