import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '@core/services';

@Component({
  selector: 'app-suivi-campagne-bat',
  templateUrl: './suivi-campagne-bat.component.html',
  styleUrls: ['./suivi-campagne-bat.component.scss']
})
export class SuiviCampagneBatComponent implements OnInit {

  constructor(
    private router: Router,
    public _GlobalDataService: GlobalDataService,
  ) { }

  ngOnInit(): void {
  }

  VisualiserBAT() {
    let path = "/campagne/home";
    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }
    this.router.navigate([path, { outlets: { 'navview': ['validation-bat'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

}
