import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { GlobalDataService } from '@core/services';
import { DialogRedirectionRecapComponent } from '../dialog-redirection-recap/dialog-redirection-recap.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campagne-planification',
  templateUrl: './campagne-planification.component.html',
  styleUrls: ['./campagne-planification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampagnePlanificationComponent implements OnInit, OnDestroy {
  private _stepperChanges: Subscription;

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _stepper: MatStepper,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this._stepperChanges?.unsubscribe();
  }

  submit() {
    if (!this._GlobalDataService.Campagne.CodeVitesse) {
      this._GlobalDataService.alert("Merci de sélectionner la vitesse d'envoi");
      return;
    }

    if (this._GlobalDataService.Campagne.FrequenceEnvoi === 'Une seule fois') {
      if (
        !this._GlobalDataService.isValidDate(
          this._GlobalDataService.Campagne.DateRemiseImprimeur
        )
      ) {
        this._GlobalDataService.alert(
          'Merci de sélectionner la date de remise imprimeur'
        );
        return;
      }
    }

    if (this._GlobalDataService.Campagne.FrequenceEnvoi === 'Récurrente') {
      if (this._GlobalDataService.Campagne.Recurrence == '') {
        this._GlobalDataService.alert(
          "Merci de sélectionner une fréquence d'envoi"
        );
        return;
      }
      if (
        !this._GlobalDataService.isValidDate(
          this._GlobalDataService.Campagne.DateRemiseImprimeur
        )
      ) {
        this._GlobalDataService.alert(
          'Merci de sélectionner la date de remise imprimeur'
        );
        return;
      }
      if (this._GlobalDataService.Campagne.Recurrence == 'Hebdomadaire') {
        if (
          this._GlobalDataService.Campagne.DetailRecurrence == null ||
          this._GlobalDataService.Campagne.DetailRecurrence == 'null'
        ) {
          this._GlobalDataService.alert(
            'Merci de sélectionner le jour de la semaine'
          );
          return;
        }
      } else if (this._GlobalDataService.Campagne.Recurrence == 'Mensuelle') {
        if (
          this._GlobalDataService.Campagne.DetailRecurrence == null ||
          this._GlobalDataService.Campagne.DetailRecurrence == 'null'
        ) {
          this._GlobalDataService.alert(
            'Merci de sélectionner le jour du mois'
          );
          return;
        }
      } else if (this._GlobalDataService.Campagne.Recurrence == 'null') {
        if (
          this._GlobalDataService.Campagne.DetailRecurrence == null ||
          this._GlobalDataService.Campagne.DetailRecurrence == 'null'
        ) {
          this._GlobalDataService.alert('Merci de sélectionner une récurrence');
          return;
        }
      }
      if (
        !this._GlobalDataService.isValidDate(
          this._GlobalDataService.Campagne.DateDeDebut
        )
      ) {
        this._GlobalDataService.alert('Merci de sélectionner la date de début');
        return;
      }
      if (
        !this._GlobalDataService.isValidDate(
          this._GlobalDataService.Campagne.DateDeFin
        )
      ) {
        this._GlobalDataService.alert('Merci de sélectionner la date de fin');
        return;
      }
      if (
        new Date(this._GlobalDataService.Campagne.DateDeFin).getTime() <=
        new Date(this._GlobalDataService.Campagne.DateDeDebut).getTime()
      ) {
        this._GlobalDataService.alert('Merci de corriger la date de fin');
        return;
      }
    }

    //call stepper
    this.goToShortCut();
  }

  goToPrevious(){
    this._stepper.previous();
  }

  goToShortCut(){
    if (this._GlobalDataService.Campagne.creationType !== 'chili') {
      this._stepper.next();
      return;
    }
    
    const onCancel = () => {
      // Go to step Récapitulatif
      this._stepper.selectedIndex = 6;
    }
    const onSuccess = () => {
      // Go to step Adresse
      this._stepper.next();
    }

    this._GlobalDataService.confirmShortCutOption({
      message: "Voulez-vous visualiser vos données d'adresses ou passer directement à l'étape de récapitulatif ?",
      cancelMsg: "Passer au récapitulatif",
      onCancel: onCancel.bind(this),
      onConfirm: onSuccess.bind(this),
    })
  }

}
