import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalDataService } from './global-data.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedListSalesforceService {
  _url = environment.url_api;

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,) { }

  getSharedLists() {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/salesforce/ListesPartargeFields';
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }


  getContactsList(IdList, IdCampagne, DataExtensionName) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/salesforce/contactList/?idListe=' + IdList + '&idCampagne=' + IdCampagne + '&extensionName='+ DataExtensionName;
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

  getDataExtensionList(IdList, IdCampagne, DataExtensionName){
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = this._url + '/salesforce/extensionList/?idListe=' + IdList + '&idCampagne=' + IdCampagne + '&extensionName='+ DataExtensionName;
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }
}
