import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatutCommande } from '@core/models/statut-commande.model';
import { GlobalDataService } from '@core/services';

@Component({
  selector: 'app-statut-commande-card',
  templateUrl: './statut-commande-card.component.html',
  styleUrls: ['./statut-commande-card.component.scss']
})
export class StatutCommandeCardComponent implements OnInit {

  @Input() statutCommande!: StatutCommande;


  constructor(
    private router: Router,
    public _GlobalDataService: GlobalDataService
  ) { }

  ngOnInit(): void {
  }

  VisualiserBAT() {

    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }
    this.router.navigate([path, { outlets: { 'navview': ['validation-bat'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }


}
