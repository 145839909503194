import { Component, Input } from '@angular/core';
import { AdresseRetour } from '@core/models/adresseRetour.model';

@Component({
  selector: 'app-adresse-retour',
  templateUrl: './adresse-retour.component.html',
  styleUrls: ['./adresse-retour.component.scss'],
})
export class AdresseRetourComponent {
  @Input('adresse') 
  public adresse: AdresseRetour;
}
