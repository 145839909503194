import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '@core/services';

@Component({
  selector: 'app-suivi-campagne-progression',
  templateUrl: './suivi-campagne-progression.component.html',
  styleUrls: ['./suivi-campagne-progression.component.scss']
})
export class SuiviCampagneProgressionComponent implements OnInit {

  constructor(
    public _GlobalDataService: GlobalDataService,

  ) { }

  ngOnInit(): void {
  }

}
