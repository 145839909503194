import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Annonceur } from '@core/models/annonceur.model';
import { Criteria, EstimationRequest } from '@core/models/estimationRequest.model';
import { AccountService, AnnonceurService, GlobalDataService } from '@core/services';
import { AuditLogService } from '@core/services/audit-log.service';
import { EstimationService } from '@core/services/estimation.service';
import { Subscription } from 'rxjs';
import { HelpComponent } from '../help/help.component';

@Component({
  selector: 'app-parametrage',
  templateUrl: './parametrage.component.html',
  styleUrls: ['./parametrage.component.scss']
})
export class ParametrageComponent implements OnInit {
  logo = null;

  titre = "Administration par annonceur";
  form: FormGroup;
  formSub: Subscription;

  constructor(
    public router: Router,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private _AnnonceurService: AnnonceurService,
    public _GlobalDataService: GlobalDataService,
    public _HelpComponent: HelpComponent,
    private _sanitizer: DomSanitizer,
    private _EstimationService: EstimationService,
    private _AccountService: AccountService) {

    _GlobalDataService.referenceDocHelp = "#ID_annonceur";

    this.form = this._formBuilder.group({
      NumeroClient: new FormControl(this._GlobalDataService.Annonceur.NumeroClient, [Validators.required]),
      NumeroContrat: new FormControl(this._GlobalDataService.Annonceur.NumeroContrat, [Validators.required]),
      NumeroContratAlliage: new FormControl(this._GlobalDataService.Annonceur.NumeroContratAlliage, [Validators.minLength(2)]),
      IdentifiantAlliageGE: new FormControl(this._GlobalDataService.Annonceur.IdentifiantAlliageGE, []),
      NomContact: new FormControl(this._GlobalDataService.Annonceur.NomContact, []),
      PrenomContact: new FormControl(this._GlobalDataService.Annonceur.PrenomContact, []),
      EmailContact: new FormControl(this._GlobalDataService.Annonceur.EmailContact, []),
      TelephoneContact: new FormControl(this._GlobalDataService.Annonceur.TelephoneContact, []),
      RoleContact: new FormControl(this._GlobalDataService.Annonceur.RoleContact, []),
      Siret: new FormControl(this._GlobalDataService.Annonceur.Siret, []),
      RaisonSociale: new FormControl(this._GlobalDataService.Annonceur.RaisonSociale, []),
      annonceurName: new FormControl(this._GlobalDataService.Annonceur.annonceurName, []),
    });
  }


  ngOnInit(): void {
    Object.keys(this.form.controls).forEach(c => {
      if (this.form.get(c).hasValidator(Validators.required) && !this.form.get(c).value) {
        // this.form.get(c).patchValue(c, { emitEvent: false });
        // this.form.get(c).patchValue('', { emitEvent: false });
        //this.form.get(c).markAsDirty();
        this.form.get(c).markAsTouched({ onlySelf: true });
        this.form.get(c).updateValueAndValidity({ onlySelf: true });
      }
    });

    // this._AnnonceurService.downloadLogo()
    //   .subscribe(response => {
    //     var urlCreator = window.URL;
    //     this._GlobalDataService.logo = this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response))
    //   });

    // this.formSub = this.form.valueChanges.subscribe(data => {
    //   console.log('Form changes', data)
    //   console.log(' this.adresse changes', data)
    // })
  }

  submit() {
    console.log("validate the annonceur");

    // A revoir pour le cas Standalone @Ata
    // this._AuditLogService.add(`0`, `0`, `Validation paramètres annonceur`).subscribe();

    if (this.form.get('NumeroClient').invalid || this.form.get('NumeroContrat').invalid) {
      this._GlobalDataService.alert("Les champs numéro client et numéro de contrat sont obligatoires."
        + "<br>Le champ numéro client doit être numérique."
        + "<br>Merci de compléter les champs concernés");
      return false;
    }

    console.debug(this._GlobalDataService.Annonceur);
    if (this._GlobalDataService.Annonceur.IdAnnonceur === undefined) {// création
      this._GlobalDataService.Annonceur = new Annonceur();
    }

    this._GlobalDataService.Annonceur.NomContact = this.form.value.NomContact;
    this._GlobalDataService.Annonceur.PrenomContact = this.form.value.PrenomContact;
    this._GlobalDataService.Annonceur.EmailContact = this.form.value.EmailContact;
    this._GlobalDataService.Annonceur.RoleContact = this.form.value.RoleContact;
    this._GlobalDataService.Annonceur.TelephoneContact = this.form.value.TelephoneContact;
    this._GlobalDataService.Annonceur.Siret = this.form.value.Siret;
    this._GlobalDataService.Annonceur.RaisonSociale = this.form.value.RaisonSociale;
    this._GlobalDataService.Annonceur.NumeroClient = this.form.value.NumeroClient;
    this._GlobalDataService.Annonceur.NumeroContrat = this.form.value.NumeroContrat;
    this._GlobalDataService.Annonceur.NumeroContratAlliage = this.form.value.NumeroContratAlliage;
    this._GlobalDataService.Annonceur.IdentifiantAlliageGE = this.form.value.IdentifiantAlliageGE;
    this._GlobalDataService.Annonceur.annonceurName= this.form.value.annonceurName;

    this.validate();

    return true;

  }

  errorResponse(HttpErrorResponse) {
    console.error("Error!", HttpErrorResponse)
    if (HttpErrorResponse.error.message && HttpErrorResponse.error.message != "") {
      this._GlobalDataService.openSnackBarInfo("Une erreur s'est produite : " + HttpErrorResponse.error.message);
    }
  }

  successResponse(response) {
    this._GlobalDataService.Annonceur.IdAnnonceur = response.IdAnnonceur;
    if (response.status === 'success') {
      this._GlobalDataService.alert("La création de l'annonceur s'est passée avec succès (Id Annonceur = " + response.IdAnnounceur + ")");
      //this.goToHome();
    }
  }
  successResponsePUT(response) {
    console.log("response !", response);
    if (response.status === 'success') {
      this._GlobalDataService.alert("La mise à jour de l'annonceur s'est passée avec succès (Id Annonceur = " + this._GlobalDataService.Annonceur.IdAnnonceur + ")");
    }
    //this.goToHome();
  }

  openHelp(signet) {
    this._GlobalDataService.referenceDocHelp = "#" + signet;
    this._HelpComponent.OpenHelpFile();
  }

  uploadLogo($event) {
    console.log("uploadLogo", $event);
    let file = $event.target.files[0];
    this._AnnonceurService.uploadLogo(file)
      .subscribe(() => {
        this._AnnonceurService.downloadLogo()
          .subscribe(response => {
            var urlCreator = window.URL;
            this._GlobalDataService.logo = this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
          });
      });
  }

  validate() {
    let request: EstimationRequest = new EstimationRequest();

    request.custAccNumber = this.form.value.NumeroClient;
    request.contractNumber = this.form.value.NumeroContrat;
    request.quantity = "1";

    request.criteria = new Criteria();

    request.criteria.item_type = "ITE002";
    request.criteria.folding = "FOL016";
    request.criteria.printFormatValue = "PFV006";
    request.criteria.paperGrammageCode = "350";
    request.criteria.glossLevel = "GLO001";
    request.criteria.printingInkType = "INK001";
    request.criteria.windowEnvelope = "WIN005";
    request.criteria.envelopType = "NC001";
    request.criteria.envelope_paperGrammage = "NC";
    request.criteria.envelope_glossLevel = "GLO003";
    request.criteria.envelope_printingInkType = "INK002";
    request.criteria.deliveryPriority = "NUR001";
    request.criteria.campaignProductionMode = "CPM004";

    console.log(request);

    this._EstimationService.put(request)
      .subscribe(
        response => this.responseHandler(response),
        error => this.responseHandler(error)
      );


  }
  responseHandler(response: any) {
    console.log(response);
    //call API
    if (this._GlobalDataService.Annonceur.IdAnnonceur === "" ) {// création
      this._AnnonceurService.add(this._GlobalDataService.Annonceur)
        .subscribe(
          response => this.successResponse(response),
          error => this.errorResponse(error)
        );
    } else {//mise à jour de l'annonceur

      if (this._GlobalDataService.isStandaloneContext()) {
        this._AccountService.saveAnnonceurDetails(this._GlobalDataService.Annonceur)
          .subscribe(response => {
            this._GlobalDataService.alert("La mise à jour de l'annonceur s'est passée avec succès (Id Annonceur = " + this._GlobalDataService.Annonceur.IdAnnonceur + ")");
          }, err => {
            this._GlobalDataService.alert("Une erreur est survenue lors de la mise à jour de vos informations personnelles : " + JSON.stringify(err));
          });
      }
      // else if (this._GlobalDataService.isEloquaContext()) {
      else  {
        this._AnnonceurService.put(this._GlobalDataService.Annonceur)
          .subscribe(
            response => this.successResponsePUT(response),
            error => this.errorResponse(error)
          );
      }
    }

    return;


    if (typeof response.response === 'object' && response.response.status === 'SUCCESS') {
      //call API
      if (this._GlobalDataService.Annonceur.IdAnnonceur === undefined) {// création
        this._AnnonceurService.add(this._GlobalDataService.Annonceur)
          .subscribe(
            response => this.successResponse(response),
            error => this.errorResponse(error)
          );
      } else {//mise à jour de l'annonceur
        this._AnnonceurService.put(this._GlobalDataService.Annonceur)
          .subscribe(
            response => this.successResponsePUT(response),
            error => this.errorResponse(error)
          );
      }
    }
    else if (response.status === 500) {
      this._GlobalDataService.alert("Le serveur de vérification de La Poste est indisponible pour le moment, Veuillez réessayer ultérieurement.");
    }
    else if (response.response.errorCode === 'INVALID_CONTRACT') {
      this._GlobalDataService.alert("Le numéro de contrat saisi  " + this.form.value.NumeroContrat + "  est invalide");
    }
    else {
      // this._GlobalDataService.alert("Une erreur est survenue", response.response.errorMessage);
      this._GlobalDataService.alert("Le serveur de vérification de La Poste est indisponible pour le moment, Veuillez réessayer ultérieurement.");
    }
  }
  errorHandler(error: any) {
    console.log('errorHandler :' + error);
  }

  gotoMentionsLegales(){
    let path = 'campagne/home'
    this.router.navigate([path, {outlets : {'navview' : 'mentions-legales'}}]);
    return false;
  }

  ngDestroy() {
    this.formSub.unsubscribe();
  }
}
