import { Component, OnInit } from '@angular/core';
import { AccountService } from '@core/services/account.service';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalDataService } from '@core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {

  pageLength = 100;
  pageSize = 10;
  pageChangeEvent(event) { console.log(event) }

  users = [];
  columns = ["login", "firstName", "lastName", "email", "dateCreation"];
  columnsLabels = {
    "login": "Login",
    "firstName": "Prénom",
    "lastName": "Nom",
    "email": "Email",
    "dateCreation": "Date de création"
  };

  constructor(
    private router: Router,
    private _AccountService: AccountService,
    private _GlobalDataService: GlobalDataService
  ) { }

  ngOnInit(): void {
    console.log("ngOnInit ManageUsersComponent");
    this._AccountService.listUsers()
      .subscribe((response: { utilisateur: never[]; }) => {
        console.log("listUsers response", response);
        this.users = response.utilisateur;
      });
  }

  gotoCreateUser() {
    console.log('gotoManageUsers');
    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['create-user'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }



}