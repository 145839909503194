import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Campagne } from '@core/models/campagne.model';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class CampagnePlanificationService {

  // form = this._formBuilder.group({
  //   Recurrence: new FormControl(this._GlobalDataService.Campagne.Recurrence),
  //   CodeVitesse: new FormControl(this._GlobalDataService.Campagne.CodeVitesse),
  //   FrequenceEnvoi: new FormControl(this._GlobalDataService.Campagne.FrequenceEnvoi),
  //   DateRemiseImprimeur: new FormControl({ value: new Date(this._GlobalDataService.Campagne.DateRemiseImprimeur), disabled: false },
  //     [Validators.required]
  //   ),
  //   // DateTheoriqueDepotLaPoste: new FormControl({ value: this._datePipe.transform(new Date(this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste), 'dd/MM/yyyy'), disabled: true }),
  //   // DateEstimeeDistribution: new FormControl({ value: this._datePipe.transform(new Date(this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste), 'dd/MM/yyyy'), disabled: true }),
  //   DateTheoriqueDepotLaPoste: new FormControl({ disabled: true }),
  //   DateEstimeeDistribution: new FormControl({ disabled: true }),
  //   DetailRecurrence: new FormControl(this._GlobalDataService.Campagne.DetailRecurrence),//Jours de la semaine...
  //   DetailRecurrenceMois: new FormControl(this._GlobalDataService.Campagne.DetailRecurrence),//Le premier Lundi du mois/Le dernier Lundi du mois
  //   DateDeDebut: new FormControl(new Date(this._GlobalDataService.Campagne.DateDeDebut), [Validators.required]),
  //   DateDeFin: new FormControl(new Date(this._GlobalDataService.Campagne.DateDeFin), [Validators.required]),

  // }, { validator: this.validateDate });


  form = this._formBuilder.group({
    Recurrence: new FormControl(),
    CodeVitesse: new FormControl(),
    FrequenceEnvoi: new FormControl(),
    DateRemiseImprimeur: new FormControl([Validators.required]),
    // DateTheoriqueDepotLaPoste: new FormControl({ value: this._datePipe.transform(new Date(this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste), 'dd/MM/yyyy'), disabled: true }),
    // DateEstimeeDistribution: new FormControl({ value: this._datePipe.transform(new Date(this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste), 'dd/MM/yyyy'), disabled: true }),
    DateTheoriqueDepotLaPoste: new FormControl({ disabled: true }),
    DateEstimeeDistribution: new FormControl({ disabled: true }),
    DetailRecurrence: new FormControl(),//Jours de la semaine...
    DetailRecurrenceMois: new FormControl(),//Le premier Lundi du mois/Le dernier Lundi du mois
    DateDeDebut: new FormControl(new Date(), [Validators.required]),
    DateDeFin: new FormControl([Validators.required]),

  }, { validator: this.validateDate });


  constructor(
    public _GlobalDataService: GlobalDataService,
    private _formBuilder: FormBuilder,
    private _datePipe: DatePipe,
  ) {
    if (typeof this._GlobalDataService.Campagne === 'object') {
      this.form.get('Recurrence').patchValue(this._GlobalDataService.Campagne.Recurrence, { emeitEvent: false });
      this.form.get('CodeVitesse').patchValue(this._GlobalDataService.Campagne.CodeVitesse, { emeitEvent: false });
      this.form.get('FrequenceEnvoi').patchValue(this._GlobalDataService.Campagne.FrequenceEnvoi, { emeitEvent: false });
      this.form.get('DetailRecurrence').patchValue(this._GlobalDataService.Campagne.DetailRecurrence, { emitEvent: false });

      if (this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste != 'null') {
        this.form.get('DateTheoriqueDepotLaPoste').patchValue(this._datePipe.transform(new Date(this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste), 'dd/MM/yyyy'));
        this.form.get('DateEstimeeDistribution').patchValue(this._datePipe.transform(new Date(this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste), 'dd/MM/yyyy'));
      }
      else {
  
        if (this._GlobalDataService.Campagne.DateRemiseImprimeur != 'null') {
          // update model
          let dateTheorique = new Date(this._GlobalDataService.Campagne.DateRemiseImprimeur);
          if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
            dateTheorique.setDate(dateTheorique.getDate() + 12);
          } else {
            dateTheorique.setDate(dateTheorique.getDate() + 7);
          }
          this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = this._datePipe.transform(dateTheorique, 'yyyy-MM-dd HH:mm:ss');
          this.form.get('DateTheoriqueDepotLaPoste').patchValue(this._datePipe.transform(dateTheorique, 'dd/MM/yyyy'), { emitEvent: false });
        }
      }
    }
  }


  validateDate(form: FormGroup) {
    const now = new Date();;

    console.debug("DateRemiseImprimeur : " + form.controls['DateRemiseImprimeur'].value);
    if (form.controls['DateRemiseImprimeur'].value == null) {
      return { isInvalid: true };
    }
    else if (form.controls['DateRemiseImprimeur'].value < now) {
      return { isBeforeToday: true }; //date is before today's date
    }

    console.debug("DateDeDebut : " + form.controls['DateDeDebut'].value);
    if (form.controls['DateDeDebut'].value == null) {
      return { isInvalid: true };
    }
    else if (form.controls['DateDeDebut'].value < now) {
      return { isBeforeToday: true }; //date is before today's date
    }

    console.debug("DateDeFin : " + form.controls['DateDeFin'].value);
    if (form.controls['DateDeFin'].value == null) {
      return { isInvalid: true };
    }
    else if (form.controls['DateDeFin'].value < form.controls['DateDeDebut'].value) {
      return { isBeforeStartDate: true }; //date is before today's start date
    }
    return null;
  }


  refreshPlanification(campagne: Campagne) {
    this._GlobalDataService.Campagne.CodeVitesse = campagne.CodeVitesse;
    this._GlobalDataService.Campagne.FrequenceEnvoi = campagne.FrequenceEnvoi;
    this._GlobalDataService.Campagne.Recurrence = campagne.Recurrence;
    this._GlobalDataService.Campagne.DetailRecurrence = campagne.DetailRecurrence;
    this._GlobalDataService.Campagne.DateRemiseImprimeur = campagne.DateRemiseImprimeur;
    this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = campagne.DateTheoriqueDepotLaPoste;
    this._GlobalDataService.Campagne.DateDeDebut = campagne.DateDeDebut;
    this._GlobalDataService.Campagne.DateDeFin = campagne.DateDeFin;

    this.form.get('CodeVitesse').patchValue(campagne.CodeVitesse, { emitEvent: false });
    this.form.get('FrequenceEnvoi').patchValue(campagne.FrequenceEnvoi, { emitEvent: false });
    this.form.get('Recurrence').patchValue(campagne.Recurrence, { emitEvent: false });
    this.form.get('DetailRecurrence').patchValue(campagne.Recurrence, { emitEvent: false });

    this.form.get('DateRemiseImprimeur').patchValue(null, { emitEvent: false });
    this.form.get('DateTheoriqueDepotLaPoste').patchValue(null, { emitEvent: false });
    this.form.get('DateDeDebut').patchValue(null, { emitEvent: false });
    this.form.get('DateDeFin').patchValue(null, { emitEvent: false });
    this.form.get('DateEstimeeDistribution').patchValue(null, { emitEvent: false });

    // let dateRetourBAT = new Date();
    // dateRetourBAT.setDate(this.form.get('DateRemiseImprimeur').value.getDate() + 5);
    // let DateEstimeeDistribution = new Date();
    // if (campagne.FrequenceEnvoi == "Classique") {
    //   DateEstimeeDistribution.setDate(dateRetourBAT.getDate() + 12);
    // } else {
    //   DateEstimeeDistribution.setDate(dateRetourBAT.getDate() + 7);
    // }
    // this.form.get('DateEstimeeDistribution').patchValue(this._datePipe.transform(DateEstimeeDistribution, 'dd/MM/yyyy'), { emitEvent: false });

  }

  refreshPlanificationFrequenceEnvoi(campagne: Campagne) {
    this._GlobalDataService.Campagne.FrequenceEnvoi = campagne.FrequenceEnvoi;
    this._GlobalDataService.Campagne.Recurrence = campagne.Recurrence;
    this._GlobalDataService.Campagne.DetailRecurrence = campagne.DetailRecurrence;

    this.form.get('FrequenceEnvoi').patchValue(campagne.FrequenceEnvoi, { emitEvent: false });
    this.form.get('Recurrence').patchValue(campagne.Recurrence, { emitEvent: false });
    this.form.get('DetailRecurrence').patchValue(campagne.Recurrence, { emitEvent: false });
  }

  refreshPlanificationVitesse(campagne: Campagne) {
    if (this._GlobalDataService.CampagneExt.VitessesProduit.indexOf(campagne.CodeVitesse) > -1) {
      this._GlobalDataService.Campagne.CodeVitesse = campagne.CodeVitesse;
      this.form.get('CodeVitesse').patchValue(campagne.CodeVitesse, { emitEvent: false });
    }
    else if (this._GlobalDataService.CampagneExt.VitessesProduit.length > 0 ) {
      this._GlobalDataService.Campagne.CodeVitesse =this._GlobalDataService.CampagneExt.VitessesProduit[0];
      this.form.get('CodeVitesse').patchValue(this._GlobalDataService.CampagneExt.VitessesProduit[0], { emitEvent: false });
    }
  }

  reset() {
    this.form.get('CodeVitesse').patchValue(this._GlobalDataService.Campagne.CodeVitesse, { emitEvent: false });
    this.form.get('FrequenceEnvoi').patchValue(this._GlobalDataService.Campagne.FrequenceEnvoi, { emitEvent: false });
    this.form.get('Recurrence').patchValue(this._GlobalDataService.Campagne.Recurrence, { emitEvent: false });
    this.form.get('DetailRecurrence').patchValue(this._GlobalDataService.Campagne.DetailRecurrence, { emitEvent: false });


    this.form.get('DateRemiseImprimeur').patchValue(new Date(this._GlobalDataService.Campagne.DateRemiseImprimeur), { emitEvent: false });
    this.form.get('DateDeDebut').patchValue(new Date(this._GlobalDataService.Campagne.DateDeDebut), { emitEvent: false });
    this.form.get('DateDeFin').patchValue(new Date(this._GlobalDataService.Campagne.DateDeFin), { emitEvent: false });


    if (this._GlobalDataService.Campagne.DateRemiseImprimeur != 'null') {
      // update model
      let dateTheorique = new Date(this._GlobalDataService.Campagne.DateRemiseImprimeur);
      if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
        dateTheorique.setDate(dateTheorique.getDate() + 12);
      } else {
        dateTheorique.setDate(dateTheorique.getDate() + 7);
      }
      this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = this._datePipe.transform(dateTheorique, 'yyyy-MM-dd HH:mm:ss');
      this.form.get('DateTheoriqueDepotLaPoste').patchValue(this._datePipe.transform(dateTheorique, 'dd/MM/yyyy'), { emitEvent: false });
      this.form.get('DateEstimeeDistribution').patchValue(this._datePipe.transform(new Date(this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste), 'dd/MM/yyyy'));
    }


  }

}
