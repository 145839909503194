<!-- Votre sélection -->
<ng-container *ngIf="_CampagneTypologieService.dataSupports.length == 1">
  <app-typologie-card [EditMode]=true [showFormat]=true
    [showDimension]="_GlobalDataService.Campagne.CodeDimensionsSupport != ''"
    [showGrammage]="_GlobalDataService.Campagne.CodeGrammageSupport != ''"
    [showFinition]="_GlobalDataService.Campagne.CodeFinitionSupport != ''"
    [showEncre]="_GlobalDataService.Campagne.CodeEncreSupport != ''">
  </app-typologie-card>
</ng-container>

<!-- Votre sélection Lettre & Enveloppe-->
<ng-container *ngIf="_CampagneTypologieService.dataSupports.length > 1">
  <app-selection-lettre-enveloppe-card [EditMode]=true></app-selection-lettre-enveloppe-card>
</ng-container>

<ng-container *ngIf="!_GlobalDataService.isEmpty(_GlobalDataService.CampagneExt.Produit)">
  <p>
    Le volume requis pour cette commande est de <B> {{ _GlobalDataService.CampagneExt.Produit.MinFabrication}} PLIS
      MINIMUM </B> et <B>{{_GlobalDataService.CampagneExt.Produit.MaxFabrication === '0' ? 'PAS DE ' : 'de ' +
      _GlobalDataService.CampagneExt.Produit.MaxFabrication}} PLIS MAXIMUM </B>
  </p>
</ng-container>

<!-- Stepper navigation  -->
<app-campagne-stepper-nav
  [prevDisplay]="true"
  [nextDisplay]="true"
  (previous)="goToPrevious()"
  (next)="submit()"
></app-campagne-stepper-nav>
