import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-page-pli-card',
  templateUrl: './page-pli-card.component.html',
  styleUrls: ['./page-pli-card.component.scss']
})
export class PagePliCardComponent implements OnInit {

  navigationOption = {};

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {
   }

  ngOnInit(): void {
    this.navigationOption = { typologie: 'lettre' };
  }

  ngAfterViewInit() {
    this._CampagneTypologieService.SupportFormGroup.get('CodeSupport')
        .valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneTypologieService.SupportFormGroup.get('CodeSupport').valid) {

        console.log('CodeSupport value changed')
        console.log(this._CampagneTypologieService.SupportFormGroup.get('CodeSupport').value);
       
        setTimeout(() => {
          console.log(this._CampagneTypologieService.SupportFormGroup.value)
        })

        // update model
        this._GlobalDataService.Campagne.CodeSupport = value;
      }
    });
  }


  Valider() {
    this.supportChanged();
    this._CampagneTypologieService.navigate(navPaths.dimension, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  supportChanged(): void {
    // Dimension
    this._CampagneTypologieService.dataDimensionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeSupport: any; CodeTypologie: any; }) => element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataDimensionsSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataDimensionsSupports, 'CodeDimensionsSupport');

    if (this._CampagneTypologieService.dataDimensionsSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeDimensionsSupport = this._CampagneTypologieService.dataDimensionsSupports[0].CodeDimensionsSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeDimensionsSupport = "";
    } 

    this._CampagneTypologieService.SupportFormGroup.get('CodeDimensionsSupport').patchValue(this._GlobalDataService.Campagne.CodeDimensionsSupport, { emitEvent: false });


    // Grammage
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.produits
      .filter((element: { CodeDimensionsSupport: string | undefined; CodeSupport: string | undefined; CodeTypologie: any; }) => element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataGrammagesSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataGrammagesSupports, 'CodeGrammageSupport');

    if (this._CampagneTypologieService.dataGrammagesSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeGrammageSupport = this._CampagneTypologieService.dataGrammagesSupports[0].CodeGrammageSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeGrammageSupport = "";
    } 

    this._CampagneTypologieService.SupportFormGroup.get('CodeGrammageSupport').patchValue(this._GlobalDataService.Campagne.CodeGrammageSupport, { emitEvent: false });


    // Finition
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataFinitionsSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataFinitionsSupports, 'CodeFinitionSupport');

    if (this._CampagneTypologieService.dataFinitionsSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeFinitionSupport = this._CampagneTypologieService.dataFinitionsSupports[0].CodeFinitionSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeFinitionSupport = "";
    }

    this._CampagneTypologieService.SupportFormGroup.get('CodeFinitionSupport').patchValue(this._GlobalDataService.Campagne.CodeFinitionSupport, { emitEvent: false });


    // Encre
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.produits
      .filter((element: { CodeFinitionSupport: any; CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeFinitionSupport == this._GlobalDataService.Campagne.CodeFinitionSupport
        && element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEncresSupports, 'CodeFinitionSupport');

    if (this._CampagneTypologieService.dataEncresSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeEncreSupport = this._CampagneTypologieService.dataEncresSupports[0].CodeEncreSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeEncreSupport = "";
    }

    this._CampagneTypologieService.SupportFormGroup.get('CodeEncreSupport').patchValue(this._GlobalDataService.Campagne.CodeEncreSupport, { emitEvent: false });


    // Reset Enveloppe
    this._CampagneTypologieService.EnveloppeFormGroup.reset();
  }
}
