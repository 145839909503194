<mat-card class="gtmt-card-container">
    <mat-card-content>
        <mat-grid-list cols="1" rowHeight="6rem" [gutterSize]="'1rem'" style="width:100%;"
        >
            <!-- Format -->
            <mat-grid-tile class="gtmt-icon-container" rowspan="1">
                <mat-icon svgIcon="1 lettre"></mat-icon>
            </mat-grid-tile>

            <!-- Lettre -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <span>Lettre</span>
            </mat-grid-tile>

            <!-- Quanitié -->
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">Qté : 1</span>
                <button mat-raised-button color="blue" [disabled]="showFormat && showDimension && showGrammage && showFinition && showEncre"
                (click)="Configurer()">{{actiontitle}}</button>
            </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="1" rowHeight="4.4rem" [gutterSize]="'1.5rem'" style="width:100%;"
        *ngIf="showFormat && showDimension && showGrammage && showFinition && showEncre"
        >
            <!-- Page et pli -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <ng-container *ngIf="showDimension">
                    <span class="gtmt-label">Page et pli</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changePage()"></mat-icon>
                </ng-container>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{support.support}}</span>
            </mat-grid-tile>


            <!-- Dimension -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <ng-container *ngIf="showDimension">
                    <span class="gtmt-field-label">Dimension</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeDimension()"></mat-icon>
                </ng-container>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{support.dimension}}</span>
            </mat-grid-tile>

            <!-- Grammage -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <ng-container *ngIf="showGrammage">
                    <span class="gtmt-label">Grammage</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeGrammage()"></mat-icon>
                </ng-container>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{support.grammage}}</span>
            </mat-grid-tile>            

            <!-- Finition -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <ng-container *ngIf="showFinition">
                    <span class="gtmt-label">Finition</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeFinition()"></mat-icon>
                </ng-container>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{support.finition}}</span>
            </mat-grid-tile>     
            
            <!-- Encre -->
            <mat-grid-tile class="gtmt-label-container" rowspan="0.5">
                <ng-container *ngIf="showEncre">
                    <span class="gtmt-label">Encre</span>
                    <mat-icon svgIcon="Crayon" *ngIf="EditMode" (click)="changeEncre()"></mat-icon>
                </ng-container>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-value-container" rowspan="0.5">
                <span class="gtmt-label">{{support.Encre}}</span>
            </mat-grid-tile>              

        </mat-grid-list>


    </mat-card-content>
</mat-card>