<mat-card class="gtmt-card-container">
    <mat-card-content>

        <mat-card-title>
            <span class="gtmt-title"> Sélectionner le logo de votre organisation </span>

            <button mat-raised-button color="blue"
                (click)="hiddenfileinput.click()">
                Télécharger une image
            </button>
        </mat-card-title>
        <mat-divider></mat-divider>

        <mat-card-content>
            <mat-grid-list cols="2" rowHeight="4rem" gutterSize="2rem">
                <ng-container *ngFor="let fichier of _GlobalDataService.CampagneExt.FichiersCreation; let i=index">
                    <ng-container *ngIf="fichier.FichierCreation == '2'">
                        <mat-grid-tile colspan="1" rowspan="1">
                            {{fichier.NomFichier}}
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                        </mat-grid-tile>
                        <mat-grid-tile class="preview" colspan="1" rowspan="4">
                            
                            <img  [src]="FichiersCreationsURL[fichier.NomFichier]" 
                            style="width: 100%; height: 100%;"
                            >

                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="4" class="gtmt-flex-end">
                            <button mat-raised-button color="blue" (click)="deleteFile(i,fichier)">Supprimer le
                                fichier</button>
                        </mat-grid-tile>
                    </ng-container>
                </ng-container>
            </mat-grid-list>
        </mat-card-content>



        <!-- <mat-grid-list cols="2" rowHeight="10rem" style="width:100%">
            <mat-grid-tile class="gtmt-jc-center gtmt-ai-center gtmt-grid-tile" colspan="1" rowspan="1">
                <span>
                    Sélectionner le logo de votre organisation
                </span>
            </mat-grid-tile>

            <mat-grid-tile class="gtmt-jc-center gtmt-ai-center gtmt-grid-tile" colspan="1" rowspan="1">
                <button mat-raised-button color="blue" [disabled]="!_GlobalDataService.Campagne.IdCampagne"
                    (click)="hiddenfileinput.click()">
                    Télécharger une image
                </button>

            </mat-grid-tile>

            <mat-grid-tile colspan="2" rowspan="1">

            </mat-grid-tile>


            <ng-container *ngFor="let fichier of _GlobalDataService.Campagne.FichiersCreation; let i=index">
                <ng-container *ngIf="fichier.FichierCreation == 2">
                    <mat-grid-tile colspan="1" rowspan="1" class="gtmt-grid-tile">
                        {{fichier.NomFichier}}
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1" class="gtmt-grid-tile">
                    </mat-grid-tile>
                    <mat-grid-tile class="preview" colspan="1" rowspan="4" class="gtmt-grid-tile">

                        <img [src]="FichiersCreationsURL[fichier.NomFichier]" style="width: 100%; height: 100%;">

                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="4" class="gtmt-flex-end gtmt-grid-tile">
                        <button mat-raised-button color="blue" (click)="deleteFile(i,fichier)">Supprimer le
                            fichier</button>
                    </mat-grid-tile>
                </ng-container>
            </ng-container>
        </mat-grid-list> -->
    </mat-card-content>


</mat-card>

<input [(ngModel)]="fichiers_crea" #ctrl="ngModel" type="file" (change)="getFileDetailsV2($event)" #hiddenfileinput
    required>

<mat-spinner-overlay [overlay]=true *ngIf="showSpinner">
</mat-spinner-overlay>