<mat-card class="gtmt-card-container" [formGroup]="_CampagneTypologieService.SupportFormGroup">
    <mat-card-title>
        <h1> Choix du format</h1>
        <!-- <mat-icon svgIcon="Infos" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click"
            #popoverTrigger="mdePopoverTrigger" style="cursor: pointer;"> -->
        <mat-icon svgIcon="Aide" (click)="openHelp('new_campaign_step2')" style="cursor: pointer;">
        </mat-icon>
    </mat-card-title>
    <mat-card-content>
        <mat-grid-list cols="3" rowHeight="20rem" [gutterSize]="'4rem'" style="width:100%; margin-top: 3rem;">
            <mat-radio-group formControlName="CodeTypologie" aria-labelledby="dimension-radio-group-label"
                class="dimension-radio-group">

                <!-- First Row - checkboxs -->
                <mat-grid-tile class="gtmt-checkbox-container "  colspan="1"  rowspan="1"
                    *ngFor="let dataSupportTypologie of _CampagneTypologieService.dataSupportTypologies"
                    (click)="_CampagneTypologieService.SupportFormGroup.get('CodeTypologie').setValue(dataSupportTypologie.CodeTypologie)">
                    <mat-grid-list cols="1" rowHeight="2rem" style="width:100%;">
                        <mat-grid-tile class="gtmt-jc-end" rowspan="3">
                            <mat-radio-button [value]="dataSupportTypologie.CodeTypologie">
                            </mat-radio-button>
                        </mat-grid-tile>
                        <mat-grid-tile  class="gtmt-icon-container" rowspan="4">
                            <mat-icon svgIcon="{{dataSupportTypologie.Typologie}}"></mat-icon>
                        </mat-grid-tile>

                        <mat-grid-tile class="gtmt-label-container" rowspan="3">
                            <span>{{dataSupportTypologie.Typologie}}</span>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-grid-tile>

                <!-- Second Row - icons -->

                <!-- <mat-grid-tile class="gtmt-icon-container" rowspan="2"
                    *ngFor="let dataSupportTypologie of _CampagneTypologieService.dataSupportTypologies">
                    <mat-icon svgIcon="grammage"></mat-icon>
                </mat-grid-tile> -->

                <!-- Third Row - Lebels -->

                <!-- <mat-grid-tile class="gtmt-label-container" rowspan="0.5"
                    *ngFor="let dataSupportTypologie of _CampagneTypologieService.dataSupportTypologies">
                    <span>{{dataSupportTypologie.Typologie}}</span>
                </mat-grid-tile> -->
            </mat-radio-group>
        </mat-grid-list>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="Valider()">
            Valider la sélection
        </button>
    </mat-card-actions>
</mat-card>


<!-- <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="gtmt-popover-card">
        <mat-card-header> Point d’information
            <mat-icon svgIcon="Infos"></mat-icon>
        </mat-card-header>
        <mat-card-content>

        </mat-card-content>
    </mat-card>
</mde-popover> -->