import * as fromComponents from './components';

import { AccumulationAnnotationService, AccumulationChartModule, AccumulationDataLabelService, AccumulationLegendService, AccumulationTooltipService, ChartModule, LegendService, PieSeriesService } from '@syncfusion/ej2-angular-charts';

import { AdministrationRoutingModule } from './administration-routing.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { StylePaginatorDirective } from '@core/directives/style-paginator.directive';
import { paginationPersonnalise } from '@core/services/paginationPersonnalise.ts';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CompteClientComponent } from './components/compte-client/compte-client.component';
import { CreateAnnonceurComponent } from './components/create-annonceur/create-annonceur.component';

@NgModule({
  declarations: [
    fromComponents.components,
    StylePaginatorDirective,
    CompteClientComponent,
    CreateAnnonceurComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChartModule,
    AccumulationChartModule,
    AdministrationRoutingModule
  ],
  exports: [fromComponents.components],
  providers: [PieSeriesService, AccumulationLegendService, AccumulationTooltipService, AccumulationDataLabelService,StylePaginatorDirective,
    { provide: MatPaginatorIntl, useClass: paginationPersonnalise},
    AccumulationAnnotationService, LegendService],
})
export class AdministrationModule { }
