import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AdresseRetour } from '../models/adresseRetour.model';
import { GlobalDataService } from './global-data.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdresseService {


  _url = environment.url_api + '/adresse';
  _standaloneUrl = environment.url_api + '/standalone/adresse';
  _salesforceUrl = environment.url_api + '/salesforce/adresse';

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,
  ) { }

  /**
    * récupération une adresse par Id
    * @param id
    */
  get(id: string): any {
    let url;

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/' + id;
        break;
      }
      case "STANDALONE": {
        url = this._url + '/admin-adresse/' + id;
        break;
      }
      default: {
        url = this._url + '/admin-adresse/' + id;
        break;
      }
    }
    if (id !== undefined)
      return this._http.get<any>(url, {
        params: this._GlobalDataService.objectParams,
        headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
      });
  }

  getAdmin(id: string): any {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = environment.url_api + '/admin-adresse/' + id;
    if (id !== undefined)
      return this._http.get<any>(url, {
        params: this._GlobalDataService.objectParams,
        headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
      });
  }

  add(adresse: AdresseRetour) {
    let url = this._url + '/add';
    
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/add';
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/add';
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/add';
        break;
      }
    }
    return this._http.post<any>(url, { request: adresse }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }

  /**
   * retourne la liste des adresses d'un annonceur
   * @param idAnnonceur
   */
  list(idAnnonceur: string) {
    let url: string = "";

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/list' ;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/list?' + idAnnonceur;
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/list?' + idAnnonceur;
        break;
      }
    }
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });

  }

  /**
 * retourne la liste des adresses favorites d'un annonceur
 * @param idAnnonceur
 */
  listFav(idAnnonceur: string) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url = environment.url_api + '/address-favori/list/' + idAnnonceur;
    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` })
    });
  }




}
