import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagnePlanificationService, CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-support-encre-card',
  templateUrl: './support-encre-card.component.html',
  styleUrls: ['./support-encre-card.component.scss']
})
export class SupportEncreCardComponent implements OnInit {
  @Input() typologie?: string;

  navigationOption = {};

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
    private _CampagnePlanificationService: CampagnePlanificationService
  ) {
  }

  ngOnInit(): void {
    this.navigationOption = { typologie: this.typologie };
  }

  ngAfterViewInit() {
    this._CampagneTypologieService.SupportFormGroup.get('CodeEncreSupport')
      .valueChanges.subscribe((value: string) => {
        // if valid
        if (this._CampagneTypologieService.SupportFormGroup.get('CodeEncreSupport').valid) {

          console.log('CodeEncreSupport value changed')
          console.log(this._CampagneTypologieService.SupportFormGroup.get('CodeEncreSupport').value);

          setTimeout(() => {
            console.log(this._CampagneTypologieService.SupportFormGroup.value)
          })

          // update model
          this._GlobalDataService.Campagne.CodeEncreSupport = value;
        }
      });
  }


  Valider() {
    if (!this._GlobalDataService.Campagne.CodeEncreSupport) {
      this._GlobalDataService.alert("Veuillez sélectionner un Encre");
      return;
    }
    this.encreChanged();
    this._CampagneTypologieService.navigate(navPaths.selection, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  encreChanged(): void {

    this._GlobalDataService.Campagne.isDirty = true;

    let listeProduits = this._GlobalDataService.produits
      .filter((element: { CodeTypologie: string | undefined; CodeSupport: string | undefined; CodeDimensionsSupport: string | undefined; CodeGrammageSupport: string | undefined; CodeFinitionSupport: string | undefined; CodeEncreSupport: string | undefined; }) => element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeFinitionSupport == this._GlobalDataService.Campagne.CodeFinitionSupport
        && element.CodeEncreSupport == this._GlobalDataService.Campagne.CodeEncreSupport);

    this._GlobalDataService.CampagneExt.VitessesProduit = new Array<string>();

    if (this.typologie == 'autre' || this.typologie == 'enveloppe') {
      if (listeProduits.length === 0) {
        this._GlobalDataService.Campagne.IdProduit = "0";
        this._GlobalDataService.CampagneExt.Produit = {};
      }      
      else if (listeProduits.length === 1) {
        this._GlobalDataService.CampagneExt.Produit = listeProduits[0];
        this._GlobalDataService.Campagne.IdProduit = listeProduits[0].IdProduit;
        this._GlobalDataService.CampagneExt.VitessesProduit.push(this._GlobalDataService.CampagneExt.Produit.Vitesse)
        this._GlobalDataService.Campagne.CodeVitesse = this._GlobalDataService.CampagneExt.Produit.Vitesse;
      }
      else if (listeProduits.length > 1) {
        this._GlobalDataService.CampagneExt.Produit = listeProduits[0];
        this._GlobalDataService.Campagne.IdProduit = listeProduits[0].IdProduit;
        listeProduits.forEach((p: any) => {
          this._GlobalDataService.CampagneExt.VitessesProduit.push(p.Vitesse);
        })
      }
      this._CampagnePlanificationService.refreshPlanification(this._GlobalDataService.Campagne);
    }
    else if (this.typologie == 'lettre') {
      this._CampagneTypologieService.dataEnveloppes = this._GlobalDataService.distinct(listeProduits, 'CodeEnveloppe');
    }
  }
}
