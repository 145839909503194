<mat-card class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
  <ul class="carousel-inner" #carousel>
    <li *ngFor="let item of items" class="carousel-item">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
</mat-card>

<mat-toolbar>
  <!-- pagination -->
  <div class="blx-button-container">
    <button mat-mini-fab  color="primary" (click)="prev()" [disabled]="items.length <= 4">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>

  <div class="blx-button-container">
    <button mat-mini-fab color="primary" (click)="next()" [disabled]="items.length <= 4">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

</mat-toolbar>

