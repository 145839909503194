<div id="content">

    <mat-toolbar>
        <h1> Gestion des annonceurs</h1>
    </mat-toolbar>

	<button mat-raised-button color="primary" style="margin-top: 2rem"
		(click)="gotoCreateAnnonceur()"
		>Créer un annonceur Standalone</button>

    <table mat-table [dataSource]="dataSource" matTableExporter matSort multiTemplateDataRows
        #exporter="matTableExporter">

        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef> {{columnsLabels[column]}} </th>
            <td mat-cell *matCellDef="let annonceur"> {{annonceur[column]}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr (click)="gotoUpdateAnnonceur(rowData)" mat-row *matRowDef="let rowData; columns: columns"></tr>

    </table>

    <mat-paginator style-paginator (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="5"
        showFirstLastButtons>
    </mat-paginator>
</div>


<!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr title="Voir le détail de la campagne" (click)="gotoDetailCampagne(row)" mat-row
  *matRowDef="let row; columns: displayedColumns;" style="cursor:pointer"></tr>
<!-- Row shown when there is no matching data. -->
