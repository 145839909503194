<cdk-virtual-scroll-viewport appendOnly itemSize="44">
  <mat-list>
    <mat-list-item *ngFor="let fc of FichiersCreation" [mdePopoverTriggerFor]="_GlobalDataService.Campagne.isPurged?purgePopover:appPopover" (mouseenter)="$event.stopPropagation()" style="cursor:pointer">
      <span> {{ fc.NomFichier}} </span>
        <button mat-raised-button color="blue" (click)="downloadFile(fc.NomFichier)" [disabled]="_GlobalDataService.Campagne.isPurged">
        Ouvrir
        <mat-icon svgIcon="Ouvrir"></mat-icon>
      </button>
      <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapAutoCaptureEnabled]="false">
        <mat-card class="gtmt-popover-card">
          <mat-card-header>Nom de fichier</mat-card-header>
          <mat-card-content>
            <p>
              {{fc.NomFichier}}
            </p>
          </mat-card-content>
        </mat-card>
      </mde-popover>
      <mde-popover #purgePopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapAutoCaptureEnabled]="false" mdePopoverPositionY="above">
        <mat-card class="gtmt-popover-card">Le fichier a été purgé</mat-card>
      </mde-popover>
    </mat-list-item>
  </mat-list>
</cdk-virtual-scroll-viewport>
