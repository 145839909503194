import { Injectable } from '@angular/core';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root',
})
export class FileRequestLimitService {

  constructor(public _GlobalDataService: GlobalDataService) {}

  public checkFileSize(file: File, max_size: number): void {
    if (file.size > max_size) {
      this._GlobalDataService.alert(
        'Impossible de charger le document: fichier volumineux !'
      );
    }
  }
}
