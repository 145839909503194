import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalDataService } from '@core/services/global-data.service';
import { Router, ActivatedRoute, NavigationStart, Params } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AnnonceurService } from '@core/services/annonceur.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { Annonceur } from '@core/models/annonceur.model';

import { PopoutService } from '@core/services';
import { PopoutModalName, POPOUT_MODALS } from '@core/models/popout.tokens';
import { environment } from 'environments/environment';
import { AnyRecord } from 'dns';

declare const window: any;


@Component({
  selector: 'app-bootstrap',
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.scss']
})
export class BootstrapComponent implements OnInit {
  url: string = "";

  objectParams: any;

  constructor(public _GlobalDataService: GlobalDataService,
    router: Router,
    private activeRoute: ActivatedRoute,
    private _AuthenticationService: AuthenticationService,
    private popoutService: PopoutService,
    private _authenticationService: AuthenticationService
  ) {


    this._GlobalDataService.CONTEXT = "ELOQUA";

    this.activeRoute.queryParams.subscribe(params => {
      this._GlobalDataService.objectParams = params;
      console.log('activeRoute.queryParams - his._GlobalDataService.objectParams : ', this._GlobalDataService.objectParams);
    });

    console.log(window.location.url);
    if (window.location.hostname === 'localhost') {
      const port = window.location.port;
      this.url = 'http://localhost:' + port + router.url;
    }
    else {
      this.url = environment.url_entrypoint + router.url;
    }

    this.url = this.url.replace('bootstrap', 'start')

    console.log(this.url);


  }

  ngOnInit() {
    //get Annonceur
    // For  local dev only
    if (window.location.hostname === 'localhost') {
      // Dev
      this._GlobalDataService.refreshEloquaToken()
        .subscribe(async response => {
          if (response.id_token) {
            // this._GlobalDataService.APP_JWT = response.id_token;
            // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
            // localStorage.setItem("ELOQUA_APP_JWT", response.id_token);
            this._GlobalDataService.setJwtoken(response.id_token);
            console.log(`Stored ELOQUA_APP_JWT = ${response.id_token}`);
            await this._GlobalDataService.getStatutMapping();
          }
        }, () => {
          console.log("Error happened while getting JWT token");
        });
    }
    else {
     this._AuthenticationService.authenticateEloqua("eloqua/bootstrap", "GET")
        .subscribe(async response => {
          if (response.id_token) {
            // this._GlobalDataService.APP_JWT = response.id_token;
            // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
            // localStorage.setItem("ELOQUA_APP_JWT", response.id_token);
            this._GlobalDataService.setJwtoken(response.id_token);            
            console.log(`Stored ELOQUA_APP_JWT = ${response.id_token}`);

            await this._GlobalDataService.getStatutMapping();
          }
        }, () => {
          console.log("Error happened while getting JWT token");
        });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose() {
    this.popoutService.closePopoutModal();
  }

  openStartCompPopout(name: string) {
    if (!this.popoutService.isPopoutWindowOpen()) {
      this.popoutService.openPopoutModal(this.url,name);
    } else {
      this.popoutService.focusPopoutWindow();
    }
  }

  closePopOut() {
    this.popoutService.closePopoutModal();
  }

  openPopOut() {
    console.log('PopOut eloqua/CapMailing Plateform in new tab');
    // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
    // if (localStorage.getItem("ELOQUA_APP_JWT")) {

    this.openStartCompPopout('eloqua');

    // if (this._GlobalDataService.getJwtoken()) {      
    //   this.isAuthenticated().subscribe ( res => {
    //     if (!res) {
    //       this._GlobalDataService.alert("Votre session a expiré. Veuillez vous reconnecter à Eloqua pour continuer.");
    //       return;
    //     }
    //     else {
    //       this.openStartCompPopout('eloqua');
    //     }
    //   });
    // }
    // else {
    //   this._GlobalDataService.alert("Le jeton d'identification CapMailing Plafeform n'est pas valide. Veuillez vous reconnecter à Eloqua pour continuer.");
    //   return;
    // }
  }
  ReloadPopOut() {
      // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
      // if (localStorage.getItem("ELOQUA_APP_JWT")) {

      this.popoutService.closePopoutModal();
      this.openStartCompPopout('Eloqua');

      // if (this._GlobalDataService.getJwtoken()) {      
      //   this.isAuthenticated().subscribe ( res => {
      //     if (!res) {
      //       this._GlobalDataService.alert("Votre session a expiré. Veuillez vous reconnecter à Eloqua pour continuer.");
      //       return;
      //     }
      //     else {
      //       this.popoutService.closePopoutModal();
      //       this.openStartCompPopout('Eloqua');
      //     }
      //   });
      // }
      // else {
      //   this._GlobalDataService.alert("Le jeton d'identification CapMailing Plafeform n'est pas valide. Veuillez vous reconnecter à Eloqua pour continuer.");
      //   return;
      // }
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable<boolean>(sub => {
      this._authenticationService.isAuthenticated()
        .subscribe(
          (res) => {
            if (res) {
              sub.next(true);
              sub.complete();
            }
            else {
              sub.next(false);
              sub.complete();
            }
          },
          () => {
            sub.next(false);
            sub.complete();
          }
        )
    });
  }


}
