import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Campagne } from '@core/models/campagne.model';
import { Observable } from 'rxjs';
import { CampagneService } from './campagne.service';

@Injectable()
export class CampageResolverService implements Resolve<Observable<Campagne>> {
  constructor(private _CampagneService: CampagneService) {}


  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Campagne> {
    return this._CampagneService.campagne$;
  }
}
