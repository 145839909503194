import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  CampagneAdressesService,
  CampagnePlanificationService,
  CampagneTypologieService,
  GlobalDataService,
} from '@core/services';
import { Router } from '@angular/router';
import { CampagneService } from '@core/services/campagne.service';
import { CampagneIdentificationService } from '@core/services/campagne-identification.service';
import { HelpComponent } from 'app/campagne/components';

@Component({
  selector: 'app-bienvenue',
  templateUrl: './bienvenue.component.html',
  styleUrls: ['./bienvenue.component.scss'],
})
export class BienvenueComponent implements OnInit, AfterViewInit {
  @Input()
  public goToCampaignsState = false;

  public loaded = false;

  constructor(
    private _campagneService: CampagneService,
    private _globalDataService: GlobalDataService,
    private _router: Router,
    private _CampagneTypologieService: CampagneTypologieService,
    private _CampagneAdressesService: CampagneAdressesService,
    private _CampagnePlanificationService: CampagnePlanificationService,
    private _CampagneIdentificationService: CampagneIdentificationService,
    private _HelpComponent: HelpComponent
  ) {}

  public ngOnInit(): void {
    // Todo: get campagins count with api
    const count = 0;
    if (count > 0) {
      this.goToCampaignsState = true;
    }
  }

  public ngAfterViewInit(): void {
    this.loaded = true;
  }

  public goToNewCampaign() {
    this._campagneService.getCurrentCampagne().subscribe({
      next: (res) => this.loadNewCampaign(res.campagne),
    });
  }

  public goToCampaigns() {
    this._router
      .navigate(
        [
          '/standalone/home',
          {
            outlets: {
              navview: [
                'suivi-campagnes',
                this._globalDataService.Annonceur.IdAnnonceur,
              ],
            },
          },
        ],
        {
          queryParams: this._globalDataService.objectParams,
          queryParamsHandling: 'merge',
          skipLocationChange: true,
        }
      )
      .then((e) => {
        if (e) {
          console.log('Navigation is successful!');
        } else {
          console.log('Navigation has failed!');
        }
      });
  }

  public isBtnSuiviCampagneEnable() {
    return this._globalDataService.availableCamapgnes?.length === 0;
  }

  public openHelp() {
    this._globalDataService.referenceDocHelp = '';
    this._HelpComponent.OpenHelpFile();
  }

  private loadNewCampaign(campagne) {
    if (typeof campagne === 'object') {
      // Initialise
      this._globalDataService.initialise();
      this._globalDataService.Campagne = campagne;

      //  Reset Forms
      this._CampagneAdressesService.reset();
      this._CampagnePlanificationService.reset();
      this._CampagneTypologieService.reset();
      this._CampagneIdentificationService.reset();

      this._globalDataService.isLoaded = true;
      this._router
        .navigate(
          [
            '/standalone/home',
            {
              outlets: {
                navview: [
                  'campagne-details',
                  this._globalDataService.Campagne.IdCampagne,
                ],
              },
            },
          ],
          {
            queryParams: this._globalDataService.objectParams,
            queryParamsHandling: 'merge',
            skipLocationChange: true,
          }
        )
        .then((e) => {
          if (e) {
            console.log('Navigation is successful!');
          } else {
            console.log('Navigation has failed!');
          }
        });
    } else {
      this._globalDataService.isLoaded = true;
      this._router
        .navigate(
          ['/standalone/home', { outlets: { navview: ['nouvelle-campagne'] } }],
          {
            queryParams: this._globalDataService.objectParams,
            queryParamsHandling: 'merge',
            skipLocationChange: true,
          }
        )
        .then((e) => {
          if (e) {
            console.log('Navigation is successful!');
          } else {
            console.log('Navigation has failed!');
          }
        });
    }
    this._globalDataService.campagneBeforeChanges = Object.assign(
      {},
      this._globalDataService.Campagne
    );
  }
}
