import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MentionsLegalesComponent } from '@shared/components/mentions-legales/mentions-legales.component';
import { GestionFavorisComponent, SuiviCampagneRecurrenteComponent, SuiviCampagnesComponent, SuiviCampagneUneSeuleFoisComponent } from 'app/administration/components';
import { SuiviDetailCampagneComponent } from '../administration/components/suivi-detail-campagne/suivi-detail-campagne.component';
import { CampagneDetailsComponent, CampagneTypologieSelectionEnveloppeComponent, CampagneTypologieFormatComponent,  CampagneTypologiePageComponent, CampagneTypologieSelectionComponent, CampagneTypologieSelectionLettreComponent, HomeComponent, ParametrageComponent, CampagneValidationBatComponent, CampagneTypologieEnveloppeComponent, MessageConfirmationComponent } from './components';
import { CampagneTypologieDimensionComponent } from './components/campagne-typologie/campagne-typologie-dimension/campagne-typologie-dimension.component';
import { CampagneTypologieEncreComponent } from './components/campagne-typologie/campagne-typologie-encre/campagne-typologie-encre.component';
import { CampagneTypologieFinitionComponent } from './components/campagne-typologie/campagne-typologie-finition/campagne-typologie-finition.component';
import { CampagneTypologieGrammageComponent } from './components/campagne-typologie/campagne-typologie-grammage/campagne-typologie-grammage.component';


const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'prefix',
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'campagne-details',
        component: CampagneDetailsComponent,
        outlet: 'navview',
        children: [
          {
            path: 'typologie-selection',
            component: CampagneTypologieSelectionComponent,
            outlet: 'navtypologie',
          },
          {
            path: 'typologie-selection-lettre',
            component: CampagneTypologieSelectionLettreComponent,
            outlet: 'navtypologie',
          },
          {
            path: 'typologie-selection-enveloppe',
            component: CampagneTypologieSelectionEnveloppeComponent,
            outlet: 'navtypologie',
          },           
          {
            path: 'typologie-format',
            component: CampagneTypologieFormatComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-dimension',
            component: CampagneTypologieDimensionComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-grammage',
            component: CampagneTypologieGrammageComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-finition',
            component: CampagneTypologieFinitionComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-encre',
            component: CampagneTypologieEncreComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-page',
            component: CampagneTypologiePageComponent,
            outlet: 'navtypologie'
          },   
          {
            path: 'typologie-enveloppe',
            component: CampagneTypologieEnveloppeComponent,
            outlet: 'navtypologie'
          },                   
        ]
      },
      {
        path: 'campagne-details/:IdCampagne',
        component: CampagneDetailsComponent,
        outlet: 'navview',
        children: [
          {
            path: 'typologie-selection',
            component: CampagneTypologieSelectionComponent,
            outlet: 'navtypologie',
          },
          {
            path: 'typologie-selection-lettre',
            component: CampagneTypologieSelectionLettreComponent,
            outlet: 'navtypologie',
          },
          {
            path: 'typologie-selection-enveloppe',
            component: CampagneTypologieSelectionEnveloppeComponent,
            outlet: 'navtypologie',
          },                    
          {
            path: 'typologie-format',
            component: CampagneTypologieFormatComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-dimension',
            component: CampagneTypologieDimensionComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-grammage',
            component: CampagneTypologieGrammageComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-finition',
            component: CampagneTypologieFinitionComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-encre',
            component: CampagneTypologieEncreComponent,
            outlet: 'navtypologie'
          },
          {
            path: 'typologie-page',
            component: CampagneTypologiePageComponent,
            outlet: 'navtypologie'
          },    
          {
            path: 'typologie-enveloppe',
            component: CampagneTypologieEnveloppeComponent,
            outlet: 'navtypologie'
          },                 
        ]
      },
      {
        path: 'suivi-campagnes/:IdAnnonceur',
        component: SuiviCampagnesComponent,
        outlet: 'navview',
      },

      {
        path: 'suivi-detail-campagne/:IdCampagne',
        component: SuiviDetailCampagneComponent,
        outlet: 'navview',
      },
      {
        path: 'suivi-campagne-une-seule-fois/:IdCampagne',
        component: SuiviCampagneUneSeuleFoisComponent, 
        outlet: 'navview',
      },
      {
        path: 'suivi-campagne-recurrente/:IdCampagne',
        component: SuiviCampagneRecurrenteComponent, 
        outlet: 'navview',       
      }, 
      {
        path: 'parametrage',
        component: ParametrageComponent,
        outlet: 'navview',
      },
      {
        path: 'gestion-favoris',
        component: GestionFavorisComponent,
        outlet: 'navview',
      },      
      {
        path: 'parametrage/:IdAnnonceur',
        component: ParametrageComponent,
        outlet: 'navview',
      },
      {
        path: 'validation-bat',
        component: CampagneValidationBatComponent,
        outlet: 'navview',
        // resolve: { campagne: CampageResolverService },  
      },
      {
        path: 'mentions-legales',
        component: MentionsLegalesComponent,
        outlet: 'navview',
      },
      { 
        path: 'message-confirmation/:NomCampagne', 
        component: MessageConfirmationComponent, 
        outlet: 'navview',
      }, 
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampagneRoutingModule { }


