import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class CampagneAdressesService {

  nomAdresseRetour: string = "";


  // public form = this._formBuilder.group({
  //   OptionRetour: new FormControl(this._GlobalDataService.Campagne.OptionRetour),
  //   Alliage: new FormControl(this._GlobalDataService.Campagne.Alliage),
  //   AdresseRetour: new FormControl({ value: this._GlobalDataService.Campagne.IdAdresse > 0 ? true : false, disabled: false },
  //     [Validators.required]
  //   ),
  //   nomAdresseRetour: new FormControl(this.nomAdresseRetour),
  //   NomAdresse: new FormControl(this._GlobalDataService.CampagneExt.Adresse.NomAdresse, [
  //   ]),     
  // });

  public form = this._formBuilder.group({
    OptionRetour: new FormControl(),
    Alliage: new FormControl(),
    AdresseRetour: new FormControl([Validators.required]),
    nomAdresseRetour: new FormControl(this.nomAdresseRetour),
    NomAdresse: new FormControl()
  });

  constructor(
    private _formBuilder: FormBuilder,
    public _GlobalDataService: GlobalDataService) {

    if (typeof this._GlobalDataService.Campagne === 'object') {
      this.form.get('OptionRetour').patchValue(this._GlobalDataService.Campagne.OptionRetour, { emeitEvent: false });
      this.form.get('Alliage').patchValue(this._GlobalDataService.Campagne.Alliage, { emeitEvent: false });
      this.form.get('AdresseRetour').patchValue(this._GlobalDataService.Campagne.IdAdresse > 0 ? true : false, { emeitEvent: false });
      this.form.get('nomAdresseRetour').patchValue(this._GlobalDataService.CampagneExt.Adresse.NomAdresse, { emeitEvent: false });
    }
  }

  reset() {
    this.form.get('OptionRetour').patchValue(this._GlobalDataService.Campagne.OptionRetour, { emitEvent: false });
    this.form.get('Alliage').patchValue(this._GlobalDataService.Campagne.Alliage, { emitEvent: false });
    this.form.get('AdresseRetour').patchValue('0', { emitEvent: false });
    this.form.get('NomAdresse').patchValue('', { emitEvent: false });
  }

}
