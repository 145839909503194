import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Annonceur } from '@core/models/annonceur.model';
import { AccountService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-manage-annonceurs',
  templateUrl: './manage-annonceurs.component.html',
  styleUrls: ['./manage-annonceurs.component.scss']
})
export class ManageAnnonceursComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null | undefined;

  pageLength = 100;
  pageSize = 10;
  pageChangeEvent(event) { console.log(event) }

  dataSource = new MatTableDataSource<Annonceur>();


  public annonceurs = [];
//  columns = ["login", "email", "lastName", "firstName", "emailContact", "roleContact", "telephoneContact", "siret", "raisonSociale", "numeroClient", "numeroContrat", "numeroContratAlliage", "identifiantAlliageGE"];
  columns = ["annonceurName","login", "email", "lastName", "firstName", "numeroClient", "numeroContrat"];
  displayedColumns = this.columns.slice();
    columnsLabels = {
    "annonceurName": "Nom de l'annonceur",
    "login": "Login Administrateur",
    "email": "Email",
    "lastName": "Nom",
    "firstName": "Prénom",
    "emailContact": "Email",
    "roleContact": "Rôle",
    "telephoneContact": "Téléphone",
    "siret": "SIRET",
    "saisonSociale": "Raison sociale",
    "numeroClient": "Numéro Client",
    "numeroContrat": "Numéro Contrat",
    "numeroContratAlliage": "Numéro Contrat Alliage",
    "identifiantAlliageGE": "Identifiant Alliage GE"
  };

  constructor(
    private router: Router,
    private _AccountService: AccountService,
    private _GlobalDataService: GlobalDataService
  ) { }

  ngOnInit(): void {

    this._AccountService.listAnnonceurs()
      .subscribe((response) => {
        console.log("listAnnonceurs response", response);
        this.dataSource.data = response.utilisateur;
      });
  }

  gotoCreateAnnonceur() {
    console.log('gotoCreateAnnonceur');
    this._AccountService.userForm.reset();
    this._AccountService.EditMode = false;
    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['create-annonceur'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }
  gotoUpdateAnnonceur(row: any) {
    console.log('gotoCreateAnnonceur');
    console.log(row);
    this._AccountService.EditMode = true;
    this._AccountService.userForm.get('annonceurName').patchValue(row.annonceurName, { emeitEvent: false });
    this._AccountService.userForm.get('NumeroClient').patchValue(row.numeroClient, { emeitEvent: false });
    this._AccountService.userForm.get('NumeroContrat').patchValue(row.numeroContrat, { emeitEvent: false });
    this._AccountService.userForm.get('NumeroContratAlliage').patchValue(row.numeroContratAlliage, { emeitEvent: false });
    this._AccountService.userForm.get('IdentifiantAlliageGE').patchValue(row.identifiantAlliageGE, { emeitEvent: false });
    this._AccountService.userForm.get('login').patchValue(row.login, { emeitEvent: false });
    this._AccountService.userForm.get('LoginSFTP').patchValue(row.loginSFTP, { emeitEvent: false });
    this._AccountService.userForm.get('PasswordSFTP').patchValue(row.passwordSFTP, { emeitEvent: false });
    this._AccountService.userForm.get('email').patchValue(row.email, { emeitEvent: false });
    this._AccountService.userForm.get('firstname').patchValue(row.firstName, { emeitEvent: false });
    this._AccountService.userForm.get('lastname').patchValue(row.lastName, { emeitEvent: false });
    this._AccountService.userForm.get('TelephoneContact').patchValue(row.telephoneContact, { emeitEvent: false });
    this._AccountService.userForm.get('RoleContact').patchValue(row.roleContact, { emeitEvent: false });
    this._AccountService.userForm.get('Siret').patchValue(row.Siret, { emeitEvent: false });
    this._AccountService.userForm.get('RaisonSociale').patchValue(row.taisonSociale, { emeitEvent: false });

    this.router.navigate(["/standalone/home", { outlets: { 'navview': ['create-annonceur'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

}
