import { Component, OnInit } from '@angular/core';

import { CampagneAdressesService, CampagnePlanificationService, CampagneTypologieService, GlobalDataService } from '@core/services';
import { Router } from '@angular/router';
import { CampagneService } from '@core/services/campagne.service';
import { forkJoin, from, Subscription } from 'rxjs';
import { AdresseService } from '@core/services/adresse.service';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { environment } from 'environments/environment';
import { HelpComponent } from 'app/campagne/components';
import { ProduitService } from '@core/services/produit.service';
import { CampagneExt } from '@core/models/campagne-ext.model';
import { CampagneIdentificationService } from '@core/services/campagne-identification.service';

export interface sidenavItem {
  title: string;
  route?: string;
  icon?: string;
  id?: boolean;
  action(path?: string): void;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  sidenavWidth: string = "12rem;";
  logoWidth: string = "4rem;";

  logomargin: string = "2rem;";
  helpmargin: string = "2rem;";


  ngStyle: string = "";

  isLinear = true;

  public sidenavItems: sidenavItem[] = [];
  isExpanded: boolean = false;
  selectedItem = 0;

  version = environment.version;

  getAdminDetailsCampagneByIdSub: Subscription | undefined;

  constructor(
    public router: Router,
    public _GlobalDataService: GlobalDataService,
    private _CampagneService: CampagneService,
    private _AdresseService: AdresseService,
    public _HelpComponent: HelpComponent,
    private _FichierCreaService: FichierCreaService,
    private _ProduitService: ProduitService,
    private _CampagneTypologieService: CampagneTypologieService,
    private _CampagneAdressesService: CampagneAdressesService,
    private _CampagnePlanificationService: CampagnePlanificationService,
    private _CampagneIdentificationService: CampagneIdentificationService
  ) {

    console.log('Campagne home page');
  }

  ngOnInit(): void {

    if (this._GlobalDataService.isAdminLaposte()) {
      this.sidenavItems = [
        {
          title: 'Accueil       ',
          icon: 'EspaceClient',
          action: this.gotoGestionAnnonceurs.bind(this),
        },
        {
          title: 'Nouveau compte client standalone       ',
          icon: 'lettre1',
          action: this.gotoCreateAnnonceur.bind(this),
        },
        {
          title: 'Notification       ',
          icon: 'lettre1',
          action: this.gotoNotification.bind(this),
        },
      ];
    }
  }

  gotoGestionAnnonceurs() {
    console.log('gotoGestionAnnonceurs');
    this.router.navigate(["/administration/home", { outlets: { 'navview': ['liste-annonceurs'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  gotoCreateAnnonceur() {
    console.log('gotoCreateAnnonceur');
    this.router.navigate(["/administration/home", { outlets: { 'navview': ['create-annonceur'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  gotoNotification() {
    console.log('gotoNotification');
  }

  increase() {
    this.isExpanded = true;
  }
  decrease() {
    this.isExpanded = false;
  }

  openHelp() {
    this._GlobalDataService.referenceDocHelp = '';
    this._HelpComponent.OpenHelpFile();
  }

  ngOnDestroy() {
    if (this.getAdminDetailsCampagneByIdSub) this.getAdminDetailsCampagneByIdSub.unsubscribe();
  }

}
