<input [(ngModel)]="fichiers_crea" #ctrl="ngModel" type="file" (change)="getFileDetailsV2($event)" #hiddenfileinput
    required>
<mat-accordion>
    <mat-expansion-panel hideToggle expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span
                    matBadge="{{ _GlobalDataService.CampagneExt.NbFichiersCreation }}"
                    matBadgeOverlap="false"> Fichiers de créations </span>
                <span class="gtmt-fill-space"></span>
                <mat-icon svgIcon="Aide" (click)="openHelp('outil_guide_perso_enveloppe')" style="cursor: pointer;"></mat-icon>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card class="gtmt-card-container">

            <mat-card-title>
                <span> Fichiers de créations </span>

                <button mat-raised-button color="blue" [disabled]="!_GlobalDataService.Campagne.IdCampagne"
                    (click)="hiddenfileinput.click()">
                    Ajouter un fichier
                    <mat-icon>control_point</mat-icon>
                </button>
            </mat-card-title>
            <mat-divider></mat-divider>


            <mat-card-content>
                <!-- <cdk-virtual-scroll-viewport autosize itemSize="50"> -->
                <mat-grid-list cols="2" rowHeight="4rem" gutterSize="2rem">
                    <ng-container *ngFor="let fichier of _GlobalDataService.CampagneExt.FichiersCreation!; let i=index">
                        <ng-container *ngIf="fichier.FichierCreation == '1'">
                            <mat-grid-tile colspan="2" rowspan="1">                               
                            </mat-grid-tile>

                            <mat-grid-tile  class="preview" colspan="1" rowspan="4">
                                <!-- <pdf-viewer *ngIf="_GlobalDataService.CampagneExt.FichiersCreationsType![fichier.NomFichier] === 'application/pdf'" [src]="_GlobalDataService.CampagneExt.FichiersCreationsURL![fichier.NomFichier]" [rotation]="0"
                                    [original-size]="false" [show-all]="false" [fit-to-page]="true" [zoom]="1"
                                    [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="false"
                                    [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
                                    style="width: 100%; height: 100%;"></pdf-viewer>
                                
                                <img  target="_blank" *ngIf="_GlobalDataService.CampagneExt.FichiersCreationsType![fichier.NomFichier] === 'image'"  [src]="_GlobalDataService.CampagneExt.FichiersCreationsURL![fichier.NomFichier]" 
                                style="width: 100%; height: 100%;"
                                > -->
                                <mat-grid-list class="preview" cols="3" rowHeight="20rem">
                                    <mat-grid-tile colspan="1" rowspan="1">    
                                        <mat-icon  style="width: 12rem;height: 12rem" svgIcon="{{fichier.TypeFichier != 'null' ? fichier.TypeFichier : 'Autre' }}" ></mat-icon>                           
                                    </mat-grid-tile>
                                    <mat-grid-tile class="preview gtmt-jc-start" colspan="2" rowspan="1">  
                                        {{fichier.NomFichier}}                             
                                    </mat-grid-tile>
                                </mat-grid-list>

                            </mat-grid-tile>
                            <mat-grid-tile colspan="1" rowspan="4" class="gtmt-jc-end">
                                <button mat-raised-button color="blue" (click)="deleteFile(i,fichier)">Supprimer le
                                    fichier</button>
                            </mat-grid-tile>
                        </ng-container>
                    </ng-container>
                </mat-grid-list>

                <!-- </cdk-virtual-scroll-viewport> -->
            </mat-card-content>
        </mat-card>

    </mat-expansion-panel>
</mat-accordion>

<!-- <div class="overlay">
    <div class="gtmt-center">
        <mat-progress-spinner mode="indeterminate" *ngIf="showSpinner">
        </mat-progress-spinner>
    </div>
</div> -->

<mat-spinner-overlay [overlay]=true *ngIf="showSpinner">
</mat-spinner-overlay>
