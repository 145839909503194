<!-- Classic -->
<ng-container *ngIf="selected === 'classic'">
  <div class="classic" matTooltip="La creation chili est purgé !" [matTooltipDisabled]="!isPurged">
    <div class="thumbnail-container">
      <!-- Thumbnail-->
      <ng-container *ngIf="!isLoading && thumbnailSmall">
        <mat-icon
          class="open-large"
          svgIcon="enlarge"
          (click)="open(thumbnailLarge)"
        ></mat-icon>
        <div class="thumbnail">
          <img [src]="thumbnailSmall" />
        </div>
      </ng-container>

      <!-- Error Message-->
      <ng-container *ngIf="errorMessage">
        <div class="error-msg">
          {{ errorMessage }}
        </div>
      </ng-container>

      <!-- Loader -->
      <div *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="32"></mat-spinner>
      </div>
    </div>
  </div>
</ng-container>

<!-- Letter and envelop -->
<ng-container *ngIf="selected === 'letterEnvelop'">
  <div class="gtmt-thumbnail-chili-envelop" matTooltip="La creation chili est purgé !" [matTooltipDisabled]="!isPurged">
    <!-- Letter -->
    <div class="letter">
      <div class="text-container"><div class="text">Lettre :</div></div>
      <div class="thumbnail-container">
        <!-- Thumbnail-->
        <ng-container *ngIf="!isLoading && iframeLetterSmall">
          <mat-icon
            class="open-large"
            svgIcon="enlarge"
            (click)="open(iframeLetterLarge)"
          ></mat-icon>
          <div class="thumbnail">
            <img [src]="iframeLetterSmall" />
          </div>
        </ng-container>

        <!-- Error Message-->
        <ng-container *ngIf="errorMessage">
          <div class="error-msg">
            {{ errorMessage }}
          </div>
        </ng-container>

        <!-- Loader -->
        <div *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="32"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Envelop -->
    <div class="envelop">
      <div class="text-container"><div class="text">Enveloppe :</div></div>
      <div class="thumbnail-container">
        <!-- Thumbnail-->
        <ng-container *ngIf="!isLoading && iframeEnvelopeSmall">
          <mat-icon
            class="open-large"
            svgIcon="enlarge"
            (click)="open(iframeEnvelopeLarge)"
          ></mat-icon>
          <div class="thumbnail">
            <img [src]="iframeEnvelopeSmall" />
          </div>
        </ng-container>

        <!-- Error Message-->
        <ng-container *ngIf="errorMessage">
          <div class="error-msg">
            {{ errorMessage }}
          </div>
        </ng-container>

        <!-- Loader -->
        <div *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="32"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</ng-container>
