<mat-sidenav-container fullscreen>
  <mat-sidenav mode="side" #sidenav opened [fixedInViewport]="true" [fixedTopGap]="0" autosize opened="true">

    <div class="logo-container">
      <div class="logo"></div>
    </div>

    <div class="ml-container">
        <span (click)="gotoStep('mentions-legales')"> Mentions légales </span>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet name="loginview">
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
