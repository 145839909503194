<app-typologie-lettre-card>
        [EditMode]=true 
        [showFormat]=true 
        [showPage]=false
        [showDimension]=false 
        [showGrammage]=false 
        [showFinition]=false
        [showEncre]=false>
</app-typologie-lettre-card>

<mat-toolbar>
        <h2>Paramétrage de la lettre</h2>
        <button mat-raised-button color="blue" (click)="gotoTypologieSelection()">Terminer</button>
</mat-toolbar>

<app-page-pli-card></app-page-pli-card>
