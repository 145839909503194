import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService, GlobalDataService, PopoutService, SalesforceService } from '@core/services';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bootstrap',
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.scss']
})
export class BootstrapComponent implements OnInit {

  url: string = "";

  constructor(
    private _SalesforceService: SalesforceService,
    private popoutService: PopoutService,
    public _GlobalDataService: GlobalDataService,
    private _authenticationService: AuthenticationService
  ) {
    if (window.location.hostname === 'localhost') {
      const port = window.location.port;
      this.url = 'http://localhost:' + port + '/salesforce/home';
    }
    else {
      this.url = environment.url_entrypoint + '/salesforce/home';
    }
  }

  ngOnInit(): void {
    console.log("Salesforce bootstrap instanciated.");
    this._SalesforceService.registerListeners();;
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: Event) {
    this.popoutService.closePopoutModal();
  }

  openStartCompPopout(name: string) {
    if (!this.popoutService.isPopoutWindowOpen()) {
      this.popoutService.openPopoutModal(this.url + "?id=" + this._GlobalDataService.getinteractionIdSF(),name);
    } else {
      this.popoutService.focusPopoutWindow();
    }
  }

  closePopOut() {
    this.popoutService.closePopoutModal();
  }

  openPopOut() {
    console.log('PopOut salesforce/CapMailing Plateform in new tab');
    // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
    // if (localStorage.getItem("interactionIdSF") == 'null') {
     if ( this._GlobalDataService.getinteractionIdSF() == 'null') {
      this._GlobalDataService.alert("Attention, votre campagne n’est pas enregistrée côté Salesforce.<br>Merci de fermer cette fenêtre, d’enregistrer la campagne dans Salesforce puis de rouvrir l’étape CAP Mailing Plateforme pour pouvoir continuer.");
      return;
    }

    // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
    // if (localStorage.getItem("SALESFORCE_APP_JWT")) {

    // this.openStartCompPopout('salesforce');

    if (this._GlobalDataService.getJwtoken()) {        
      this.isAuthenticated().subscribe ( res => {
        if (!res) {
          this._GlobalDataService.alert("Votre session a expiré. Veuillez vous reconnecter à SalesCloud pour continuer.");
          return;
        }
        else {
          this.openStartCompPopout('salesforce');
        }
      });
    }
    else {
      this._GlobalDataService.alert("Le jeton d'identification CapMailing Plafeform n'est pas valide. Veuillez vous reconnecter à SalesCloud pour continuer.");
      return;
    }
  }
  ReloadPopOut() {
    // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
    // if (localStorage.getItem("interactionIdSF") == 'null') {
    if ( this._GlobalDataService.getinteractionIdSF() == 'null') {    
      this._GlobalDataService.alert("Attention, votre campagne n’est pas enregistrée côté Salesforce.<br>Merci de fermer cette fenêtre, d’enregistrer la campagne dans Salesforce puis de rouvrir l’étape CAP Mailing Plateforme pour pouvoir continuer.");
      return;
    }
    // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
    // if (localStorage.getItem("SALESFORCE_APP_JWT")) {

    // this.popoutService.closePopoutModal();
    // this.openStartCompPopout('salesforce');

    if (this._GlobalDataService.getJwtoken()) {  
      this.isAuthenticated().subscribe ( res => {
        if (!res) {
          this._GlobalDataService.alert("Votre session a expiré. Veuillez vous reconnecter à SalesCloud pour continuer.");
          return;
        }
        else {
          this.popoutService.closePopoutModal();
          this.openStartCompPopout('salesforce');
        }
      });
    }
    else {
      this._GlobalDataService.alert("Le jeton d'identification CapMailing Plafeform n'est pas valide. Veuillez vous reconnecter à SalesCloud pour continuer.");
      return;
    }
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable<boolean>(sub => {
      this._authenticationService.isAuthenticated()
        .subscribe(
          (res) => {
            if (res) {
              sub.next(true);
              sub.complete();
            }
            else {
              sub.next(false);
              sub.complete();
            }
          },
          () => {
            sub.next(false);
            sub.complete();
          }
        )
    });
  }
}
