import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '@core/services';
import { CampagneService } from '@core/services/campagne.service';

@Component({
  selector: 'app-campagne-alliage',
  templateUrl: './campagne-alliage.component.html',
  styleUrls: ['./campagne-alliage.component.scss']
})
export class CampagneAlliageComponent implements OnInit {

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _CampagneService: CampagneService,    
  ) { }

  ngOnInit(): void {
  }
  
  ValiderSelection() {
    this._GlobalDataService.Campagne.Alliage = true;
    this._CampagneService.put(this._GlobalDataService.Campagne)
      .subscribe(
        response => {
          console.log('Valider Selection est oK!', response);
        },
        error => {
          this._GlobalDataService.openSnackBarInfo(error.error.message);
        }
      );
  }
}
