import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Adresse } from '@core/models/adresse.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { Annonceur } from '@core/models/annonceur.model';
import { Campagne } from '@core/models/campagne.model';
import { AnnonceurService, AuthenticationService, CampagneTypologieService, GlobalDataService } from '@core/services';
import { AdresseService } from '@core/services/adresse.service';
import { CampagneService } from '@core/services/campagne.service';
import { ProduitService } from '@core/services/produit.service';
import { forkJoin, from } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public username?: string;
  public password?: string;
  public returnUrl?: string;

  form: FormGroup;

  hide: boolean = true;

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private _GlobalDataService: GlobalDataService,
    private _CampagneService: CampagneService,
    private _AnnonceurService: AnnonceurService,
    private _AdresseService: AdresseService,
    private _ProduitService: ProduitService,
    private _sanitizer: DomSanitizer,
    private _CampagneTypologieService: CampagneTypologieService
  ) { 
    this.form = this._formBuilder.group({
      loginName: new FormControl(),
      password: new FormControl(),
    });
  }

  ngOnInit(): void {
  }

  myFunction() {
    this.hide = !this.hide;
  }

  async login() {
    console.debug("login");

    if (this.form.get('loginName').invalid || this.form.get('password').invalid) {
      this._GlobalDataService.alert("Les champs Adresse mail et Mot de passe sont obligatoires."
        + "<br>Merci de compléter les champs concernés");
      return false;
    }


    this._GlobalDataService.CONTEXT = "ADMIN";

    return this._authenticationService.authenticateStandalone(this.form.get('loginName').value, this.form.get('password').value)
      .subscribe(
        async (result) => {
          console.debug("login sub result", result, "return url = ", this.returnUrl);
          if (result) {
            // ATA@09-03-23 - Correctif VLN07 : JWT stocké dans le localStorage
            //localStorage.setItem("ADMIN_APP_JWT", result.id_token);
            this._GlobalDataService.setJwtoken(result.id_token);
            await this._GlobalDataService.getStatutMapping();
            if (this._GlobalDataService.isAdminLaposte()) {
              // await this.router.navigateByUrl("/administration/liste-annonceurs");
              this.router.navigate(['/administration/home', { outlets: { 'navview': ['liste-annonceurs'] } }], {
                queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
                skipLocationChange: true
              }).then((e) => {
                if (e) {
                  console.log("Navigation is successful!");
                } else {
                  console.log("Navigation has failed!");
                }
              });


            }
            else {               
              this._GlobalDataService.alert("Vous n'êtes pas autorisé à accéder à l'espace Administration CapMailing Plateforme."
              + "<br>Merci de contactez votre administrateur pour demander les droits appropriés");
            }
          }
          else
            this._GlobalDataService.alert("Vos identifiants sont incorrects.");
        },
        (error) => {
          console.debug("login sub error", error);
          this._GlobalDataService.alert("Vos identifiants sont incorrects.");
        }
      );
  }

  init() {
    this._GlobalDataService.availableAdresses = new Array<Adresse>();
    this._GlobalDataService.availableCamapgnes = new Array<Campagne>();
    this._GlobalDataService.produits = new Array<any>();

    let availableAdresses$ = from(this._AdresseService.list(this._GlobalDataService.Annonceur.IdAnnonceur));
    let availableCamapgnes$ = from(this._CampagneService.getListAvailable(this._GlobalDataService.Annonceur.IdAnnonceur));
    let produits$ = from(this._ProduitService.getList());

    forkJoin({ availableAdresses: availableAdresses$, availableCamapgnes: availableCamapgnes$, produits: produits$ })
      .subscribe(
        (result: any) => {
          console.log('init data = ', result);

          this._GlobalDataService.availableAdresses = result.availableAdresses.adresse;

          if (result.availableCamapgnes.campagne != '[]' ) {
            this._GlobalDataService.availableCamapgnes = result.availableCamapgnes.campagne.filter((campagne: Campagne) => campagne.NomCampagne != '');
          }

          this._GlobalDataService.produits = result.produits.produit;

          this. _CampagneTypologieService.initDataSupportTypologies(this._GlobalDataService.Campagne);


          this.initAvailableCamapgnes();

        },
        error => { console.log(error); }
      );
  }


  initAvailableCamapgnes() {
    this._GlobalDataService.availableCamapgnes.forEach((campagne: Campagne) => {
      campagne.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
      let arr = this._GlobalDataService.availableAdresses.filter(adresse => adresse.IdAdresse == campagne.IdAdresse);
      if (arr.length > 0) campagne.Adresse = arr[0];
    })
  }


  successResponse(response: { annonceur: Annonceur; }) {

    console.log("Downloading logo");
    this._AnnonceurService.downloadLogo()
      .subscribe((response) => {
        console.log("Download logo response", response);
        if (response) {
          var urlCreator = window.URL;
          this._GlobalDataService.logo = this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response))


          this._authenticationService.getUserInfo().subscribe((response) => {
            console.log(response.body);
            if (response.body) {
              this._GlobalDataService.EmailNotification = response.body.email;
            }
          })

        }
        else {
          this._GlobalDataService.logo = null;
        }
      }, error => {
        console.log("Download logo error", error);
        this._GlobalDataService.logo = null;
      });

    console.log("Annonceur : ");
    console.log(response.annonceur);
    //l'annonceur n'existe pas dans la base
    this._GlobalDataService.Annonceur = response.annonceur;
    if (response.annonceur.IdAnnonceur === undefined) {
      this.router.navigate(['/standalone/home', { outlets: { 'navview': ['parametrage'] } }], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true
      }).then((e) => {
        if (e) {
          console.log("Navigation is successful!");
        } else {
          console.log("Navigation has failed!");
        }
      });
    }
    else {
      this.router.navigate(['/standalone/home'], {
        relativeTo: this.activatedRoute.parent,
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true
      }).then((e) => {
        if (e) {
          console.log("Navigation is successful!");
          this.init();

        } else {
          console.log("Navigation has failed!");
        }
      });
    }
  }


  errorResponse(error: any) {
    console.log('StartComponent', error);
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    });
  }

  ChangePassword() {
    const path='change-password-request';
    this.router.navigate([{ outlets: { 'loginview': path } }],
    {
      relativeTo: this.activatedRoute.parent,
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true,
    }).then((e) => {
      if (e) {
        console.log("Navigation to " + path + " is successful!");
      } else {
        console.log("Navigation to " + path + " has failed!");
      }
    });
  }

}
