import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationEnd, Router } from '@angular/router';
import { navPaths, Support } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';
import { ProduitService } from '@core/services/produit.service';

@Component({
  selector: 'app-selection-lettre-card',
  templateUrl: './selection-lettre-card.component.html',
  styleUrls: ['./selection-lettre-card.component.scss']
})
export class SelectionLettreCardComponent implements OnInit {

  @Input() EditMode?: boolean = true;
  @Input() showFormat?: boolean = true;
  @Input() showDimension?: boolean = true;
  @Input() showGrammage?: boolean = true;
  @Input() showFinition?: boolean = true;
  @Input() showEncre?: boolean = true;

  support: Support = new Support();

  navigationSubscription;

  navigationOption = {};

  actiontitle: string = "Configurer l’élément";

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    private _ProduitService: ProduitService,
    private _CampagneTypologieService: CampagneTypologieService,

  ) {
    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        console.log('Lettre NavigationEnd is successful');
        this.initialise();
      }
    });
  }

  initialise() {
    this.navigationOption = { typologie: 'lettre' };
    this.support.support = this._GlobalDataService.getLibelleProduit('CodeSupport', this._GlobalDataService.Campagne.CodeSupport, 'support')
    this.support.dimension = this._GlobalDataService.getLibelleProduit('CodeDimensionsSupport', this._GlobalDataService.Campagne.CodeDimensionsSupport, 'DimensionsSupport')
    this.support.grammage = this._GlobalDataService.getLibelleProduit('CodeGrammageSupport', this._GlobalDataService.Campagne.CodeGrammageSupport, 'GrammageSupport')
    this.support.finition = this._GlobalDataService.getLibelleProduit('CodeFinitionSupport', this._GlobalDataService.Campagne.CodeFinitionSupport, 'FinitionSupport')
    this.support.Encre = this._GlobalDataService.getLibelleProduit('CodeEncreSupport', this._GlobalDataService.Campagne.CodeEncreSupport, 'EncreSupport');

    this.showFormat = this.support.support ? true : false;
    this.showDimension = this.support.dimension ? true : false;;
    this.showGrammage = this.support.grammage ? true : false;
    this.showFinition = this.support.finition ? true : false;
    this.showEncre = this.support.Encre ? true : false;

    this.actiontitle ='Configurer l’élément'

    if (this.showFormat && this.showDimension && this.showGrammage && this.showFinition && this.showEncre ) {
      this.actiontitle ='Elément configuré'
    }    
  }


  ngOnInit(): void {
    this.initialise();
  }

  changePage(): void {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.page, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeDimension(): void {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.dimension, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeGrammage(): void {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.grammage, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeFinition(): void {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.finition, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeEncre() {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.encre, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  Configurer() {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.page, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }



  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }



}
