import { Component, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-campagne-typologie-selection-enveloppe',
  templateUrl: './campagne-typologie-selection-enveloppe.component.html',
  styleUrls: ['./campagne-typologie-selection-enveloppe.component.scss']
})
export class CampagneTypologieSelectionEnveloppeComponent implements OnInit {

  constructor(
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) { }

  submit() {
    //call API
    this._stepper.next();
  }


  ngOnInit(): void {
  }

}
