import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-support-finition-card',
  templateUrl: './support-finition-card.component.html',
  styleUrls: ['./support-finition-card.component.scss']
})
export class SupportFinitionCardComponent implements OnInit {
  @Input() typologie?: string;

  navigationOption = {};

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {
   }

  ngOnInit(): void {
    this.navigationOption = { typologie: this.typologie };
  }

  ngAfterViewInit() {
    this._CampagneTypologieService.SupportFormGroup.get('CodeFinitionSupport')
        .valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneTypologieService.SupportFormGroup.get('CodeFinitionSupport').valid) {

        console.log('CodeFinitionSupport value changed')
        console.log(this._CampagneTypologieService.SupportFormGroup.get('CodeFinitionSupport').value);
       
        setTimeout(() => {
          console.log(this._CampagneTypologieService.SupportFormGroup.value)
        })

        // update model
        this._GlobalDataService.Campagne.CodeFinitionSupport = value;
      }
    });
  }


  Valider() {
    if (!this._GlobalDataService.Campagne.CodeFinitionSupport) {
      this._GlobalDataService.alert("Veuillez sélectionner une Finition");
      return;
    }     
    this.finitionChanged();
    this._CampagneTypologieService.navigate(navPaths.encre, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  finitionChanged(): void {
    console.log('_GlobalDataService.Campagne.CodeFinitionSupport = ');
    console.log(this._GlobalDataService.Campagne.CodeFinitionSupport)

    this._GlobalDataService.Campagne.isDirty = true;

    // Encre
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.produits
      .filter((element: { CodeFinitionSupport: any; CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeFinitionSupport == this._GlobalDataService.Campagne.CodeFinitionSupport
        && element.CodeGrammageSupport == this._GlobalDataService.Campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == this._GlobalDataService.Campagne.CodeSupport
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataEncresSupports = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEncresSupports, 'CodeFinitionSupport');

    if (this._CampagneTypologieService.dataEncresSupports.length == 1) {
      this._GlobalDataService.Campagne.CodeEncreSupport = this._CampagneTypologieService.dataEncresSupports[0].CodeEncreSupport;
    }
    else {
      this._GlobalDataService.Campagne.CodeEncreSupport = "";
    }

    this._CampagneTypologieService.SupportFormGroup.get('CodeEncreSupport').patchValue(this._GlobalDataService.Campagne.CodeEncreSupport, { emitEvent: false });

  }
}
