import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { stepperStandaloneIndexes } from '@core/constants/stepperIndexes';
import { GlobalDataService } from '@core/services';
import { PaoService } from '@core/services/pao.service';
import { Subscription } from 'rxjs';
import { environment as env } from 'environments/environment';

@Component({
  selector: 'app-chili-iframe-personnalisation',
  templateUrl: './chili-iframe-personnalisation.component.html',
  styleUrls: ['./chili-iframe-personnalisation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChiliIframePersonnalisationComponent implements OnInit, OnDestroy {
  @Input()
  public idCampagne: string;

  public isLoadingChili: boolean = false;
  public iframeHasError: boolean = false;
  public iframeErrorMsg: string =
    "Veuillez configurer et sauvegarder votre création dans le Studio de création pour afficher l'aperçu de la Personnalisation.";
  public isVisible: boolean = true;

  private iframeUrl: SafeResourceUrl;
  private stepperSub: Subscription;

  public isCreationCreated: boolean;

  /**
   * Listener for postMessage
   */
  @HostListener('window:message', ['$event'])
  private onMessage(event: MessageEvent): void {
    // Read from Chili
    const origin = event.origin;
    if (!origin.startsWith(env.targetOriginChili)) {
      return;
    }

    // Read if iframe is visible
    if (this.isVisible) {
      console.log('Listener data', event);
      this.handleChiliPostMessage(event);
    }
  }

  constructor(
    public _sanitizer: DomSanitizer,
    private _paoService: PaoService,
    public _GlobalDataService: GlobalDataService,
    private _stepper: MatStepper,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    // Initial state
    this.checkCreationExistance();

    // Update on stepper change
    this.stepperSub = this._stepper.selectionChange.subscribe({
      next: (res: StepperSelectionEvent) => {
        if (res.selectedIndex !== stepperStandaloneIndexes.Personnalisation) {
          this.isVisible = false;
          return;
        }
        // Reset vars
        this.isLoadingChili = false;
        this.iframeHasError = false;
        this.isVisible = true;
        this.iframeUrl = undefined;
        this.cd.markForCheck();

        // Check creation
        this.checkCreationExistance();
      },
    });
  }

  public ngOnDestroy(): void {
    this.stepperSub?.unsubscribe();
  }

  public getIframeUrl(): SafeResourceUrl {
    return this.iframeUrl;
  }

  private loadChili(): void {
    this.isLoadingChili = true;
    this._paoService
      .checkCompletedStatus(
        this.idCampagne,
        "Veuillez configurer votre création dans l'étape 3 - Créations pour afficher l'aperçu de la personnalisation"
      )
      .subscribe({
        next: (_: boolean) => {
          this.loadIframe();
        },
        error: (_) => {
          this.isLoadingChili = false;
        },
      });
  }

  private loadIframe(): void {
    this.isLoadingChili = true;
    this._paoService.getIframePersonnalisation(this.idCampagne).subscribe({
      next: (response: { url: string }) => {
        this.iframeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
          response?.url
        );
        this.isLoadingChili = false;
        this.cd.markForCheck();
      },
      error: (_) => {
        this._GlobalDataService.alert(
          "Erreur lors du chargement de l'aperçu Chili."
        );
        this.isLoadingChili = false;
      },
    });
  }

  private checkCreationExistance() {
    this.isLoadingChili = true;
    const idCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this.cd.markForCheck();
    this._paoService.getStatus(idCampagne).subscribe({
      next: (res) => {
        console.log('Chili creation exists. Status is ' + res.status);
        this.isCreationCreated = true;
        this.loadChili();
      },
      error: (_) => {
        console.log('Chili creation does not exists.');
        this.isCreationCreated = false;
        this.isLoadingChili = false;
        this.cd.markForCheck();
      },
    });
  }

  /**
   * Handle Chili Iframe postMessages
   * @param event a MessageEvent
   */
  private handleChiliPostMessage(event: MessageEvent): void {
    // Loading postMessages
    if ('iframe_symfony' in event.data) {
      const data = event.data as {
        iframe_symfony: {
          hasError: boolean;
          message: string;
          chili: 'fullyRendered' | 'loading';
          templatesType?: 'GENERIC' | 'CUSTOM' | null;
        };
      };
      if (data.iframe_symfony.hasError === true) {
        console.log('Error on loading Chili: ', data.iframe_symfony.message);
        this.iframeHasError = true;
        this.isLoadingChili = false;
      }
      if (data.iframe_symfony.chili === 'fullyRendered') {
        this.isLoadingChili = false;
      }
    }

    // Fullpage mode
    if ('full_page' in event.data) {
      this._GlobalDataService.isChiliFullpageMode = event.data['full_page'];
    }
  }
}
