import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { AdressesDestinatairesService, GlobalDataService, PersonalisationService } from '@core/services';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';

@Component({
  selector: 'app-campagne-adresses-destinataires',
  templateUrl: './campagne-adresses-destinataires.component.html',
  styleUrls: ['./campagne-adresses-destinataires.component.scss']
})
export class CampagneAdressesDestinatairesComponent implements OnInit {

  @ViewChild('champFusionRef') private champFusionRef!: ElementRef;


  constructor(
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    public _ChampsFusionService: ChampsFusionService,
    public  adressesDestinatairesService: AdressesDestinatairesService,
    public personalisationService: PersonalisationService  ) {
  }

  ngOnInit() {
    /* if (!this._GlobalDataService.isEloquaContext())
      return; */
    this.adressesDestinatairesService.initialise();
    console.debug(this.adressesDestinatairesService.filteredAvailableChampsFusion);

  }

  ngAfterViewInit() {
    // streams
    const keyup2$ = fromEvent(this.champFusionRef.nativeElement, 'keyup');

    // wait .5s between keyups to emit current value
    keyup2$
      .pipe(
        map((event: any) => event.currentTarget.value),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filterChampsFusion(value)),
      )
      .subscribe((result) => {
        console.log(result);
        this.adressesDestinatairesService.filteredAvailableChampsFusion = result;
      });
  }

  private filterChampsFusion(champFusionName: string): any[] {
    return this.adressesDestinatairesService.availableChampsFusion
      .filter(cf => cf.ChampFusion.toLowerCase().includes(champFusionName))
      .filter(cf => this.adressesDestinatairesService.selectedChampsFusion.indexOf(cf) < 0);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }


  selectChampFusion(champsFusion: ChampsFusion) {
    transferArrayItem(
      this.adressesDestinatairesService.filteredAvailableChampsFusion,
      this.adressesDestinatairesService.selectedChampsFusion,
      this.adressesDestinatairesService.filteredAvailableChampsFusion.indexOf(champsFusion),
      this.adressesDestinatairesService.selectedChampsFusion.length - 1
    );
  }

  unselectChampFusion(champsFusion: ChampsFusion) {
    transferArrayItem(
      this.adressesDestinatairesService.selectedChampsFusion,
      this.adressesDestinatairesService.filteredAvailableChampsFusion,
      this.adressesDestinatairesService.selectedChampsFusion.indexOf(champsFusion),
      this.adressesDestinatairesService.filteredAvailableChampsFusion.length - 1
    );
  }

  ValiderSelection() {
    this.adressesDestinatairesService.setChampFusionType();
    // this.personalisationService.setChampFusionType();

    this._GlobalDataService.CampagneExt.ChampsFusion = this.adressesDestinatairesService.selectedChampsFusion.concat(this.personalisationService.selectedChampsFusion);
    this._ChampsFusionService.upsert({
      IdCampagne: this._GlobalDataService.Campagne.IdCampagne,
      ChampsFusion: this._GlobalDataService.CampagneExt.ChampsFusion 
    }).subscribe(() => {
      // this.adressesDestinatairesService.initialise();
      // this.personalisationService.initialise();      
    }, (error) => { console.error(error) });
  }

  Vider() {
    console.log('Vider');
    this.adressesDestinatairesService.filteredAvailableChampsFusion = this.adressesDestinatairesService.filteredAvailableChampsFusion.concat(this.adressesDestinatairesService.selectedChampsFusion);
    this.adressesDestinatairesService.selectedChampsFusion = [];  
    this.ValiderSelection();
  }

  submit() {
    //call API
    this._stepper.next();
  }

  ngOnDestroy() {
  }

}
