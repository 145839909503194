<div *ngIf="!waitingForServer; then thenBlock; else elseBlock"></div>
<ng-template #thenBlock>
  <!-- Is Campaign active -->
  <button
    *ngIf="!_GlobalDataService.Campagne.isPurged"
    mat-raised-button
    class="gtmt-btn-save"
    color="blue"
    (click)="save()"
    [disabled]="campaignName === '' || _GlobalDataService.Campagne.isPurged"
  >
    Enregistrer
  </button>

  <!-- Is Campaign Purged -->
  <button
    *ngIf="_GlobalDataService.Campagne.isPurged"
    mat-raised-button
    class="gtmt-btn-save"
    color="blue"
    [mdePopoverTriggerFor]="purgePopover"
    [disabled]="true"
  >
    Enregistrer
  </button>
</ng-template>
<ng-template #elseBlock>
  <!-- Loader -->
  <button mat-raised-button class="gtmt-btn-save" color="blue">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="20"
      style="margin: 0 auto"
    ></mat-progress-spinner>
  </button>
</ng-template>

<!-- Pop Over information -->
<mde-popover
  #purgePopover="mdePopover"
  [mdePopoverOverlapTrigger]="false"
  [mdeFocusTrapAutoCaptureEnabled]="false"
  mdePopoverPositionY="above"
>
  <mat-card class="gtmt-popover-card">
    Cette campagne est purgée. Tout enregistrement ou modification est
    impossible.
  </mat-card>
</mde-popover>
