<mat-list>
  <mat-list-item>
    <span> {{ adresse?.NomAdresse }} </span>
  </mat-list-item>
  <mat-list-item>
    <span> {{ adresse?.Ville }} </span>
    <span> {{ adresse?.CodePostal }} </span>
  </mat-list-item>
  <mat-list-item>
    <span> {{ adresse?.Numero }} </span>
  </mat-list-item>
  <mat-list-item>
    <span> {{ adresse?.RaisonSociale }} </span>
  </mat-list-item>
</mat-list>
