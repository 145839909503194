import { Component, Inject, OnInit } from '@angular/core';
import { DialogData } from '../dialog-message/dialog-message.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-shortcut',
  templateUrl: './dialog-shortcut.component.html',
  styleUrls: ['./dialog-shortcut.component.scss']
})
export class DialogShortcutComponent {

  onConfirm?;
  onCancel?;
  confirmMsg?: any;
  cancelMsg?: any;
  message?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _sanitizer: DomSanitizer
  ) {
    this.onConfirm = data.onConfirm || function () {};
    this.onCancel = data.onCancel || function () {};
    this.cancelMsg = data.cancelMsg;
    this.confirmMsg = data.confirmMsg;
    this.message = data.message;
    
   }

  trustHtml(htmlText: string) {
    return this._sanitizer.bypassSecurityTrustHtml(htmlText);
  }
}
