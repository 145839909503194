<div id="content">

    <mat-toolbar>
        <h1> Suivi des annonceurs</h1>
    </mat-toolbar>

    <button mat-raised-button color="primary" style="margin-top: 2rem"
        (click)="filterAnnonceurs('standalone')">Standalone</button>
    <button mat-raised-button color="primary" style="margin-top: 2rem"
        (click)="filterAnnonceurs('salesforce')">Salesforce</button>
    <button mat-raised-button color="primary" style="margin-top: 2rem"
        (click)="filterAnnonceurs('eloqua')">Eloqua</button>
    <button mat-raised-button color="primary" style="margin-top: 2rem" (click)="filterAnnonceurs('all')">Tous Les Canaux</button>

    <table mat-table [dataSource]="dataSource" matTableExporter matSort multiTemplateDataRows
        #exporter="matTableExporter">

        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef> {{columnsLabels[column]}} </th>
            <td mat-cell *matCellDef="let annonceur">
                <span class="gtmt-annonceur-field" (click)="gotoGestionAnnonceur(annonceur)">{{annonceur[column]}}</span> 
                <button mat-raised-button *ngIf="columnsLabels[column]==='Campagnes'" class="gtmt-suivi-button"
                    color="blue" (click)="gotoSuiviCampagnes(annonceur.IdAnnonceur)">
                    Suivi
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let rowData;  columns: columns"></tr>

    </table>

    <mat-paginator style-paginator (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="5"
        showFirstLastButtons>
    </mat-paginator>
</div>