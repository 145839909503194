
<mat-toolbar>
		<h1>Gestion des utilisateurs</h1>
</mat-toolbar>

	<!-- Content -->
<div class="content" role="main">
	<button mat-raised-button color="primary" style="margin-left: 20px"
		(click)="gotoCreateUser()"
		>Créer un utilisateur</button>

	<table mat-table [dataSource]="users">
		<ng-container [matColumnDef]="column" *ngFor="let column of columns">
			<th mat-header-cell *matHeaderCellDef> {{columnsLabels[column]}} </th>
			<td class="gtmt-overflow-ellipsis" mat-cell *matCellDef="let user"> {{user[column]}} </td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columns"></tr>
		<tr mat-row *matRowDef="let rowData; columns: columns"></tr>
	</table>

    <mat-paginator style-paginator (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="5"
        showFirstLastButtons>
    </mat-paginator>	
</div>