import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { NavigationEnd, Router } from '@angular/router';
import { Adresse } from '@core/models/adresse.model';
import { isteps, isteps_standalone } from '@core/models/campagne.model';
import {
  Criteria,
  EstimationRequest,
} from '@core/models/estimationRequest.model';
import { Support } from '@core/models/support.model';
import {
  AdressesDestinatairesService,
  AdressesDestinatairesSfService,
  GlobalDataService,
  PersonalisationService,
  PersonalisationSfService,
} from '@core/services';
import { AuditLogService } from '@core/services/audit-log.service';
import { CampagneService } from '@core/services/campagne.service';
import { EstimationService } from '@core/services/estimation.service';
import { ProduitService } from '@core/services/produit.service';
import { map, Observable, of, startWith, Subject, takeUntil } from 'rxjs';
import { DialogConfirmCampagneComponent, HelpComponent } from '..';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PaoService } from '@core/services/pao.service';
import { StatusChiliResponse } from '@core/models/status-chili-response.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { ChiliReturnAddress } from '@core/models/chili-get-creation-details.model';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { stepperStandaloneIndexes } from '@core/constants/stepperIndexes';

@Component({
  selector: 'app-campagne-recapitulatif',
  templateUrl: './campagne-recapitulatif.component.html',
  styleUrls: ['./campagne-recapitulatif.component.scss'],
})
export class CampagneRecapitulatifComponent implements OnInit, OnDestroy {
  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;

  @Input() stepper!: MatStepper;

  title: string = 'Récapitulatif';

  isteps = isteps;

  isteps_standalone = isteps_standalone;

  navigationSubscription;

  support: Support = new Support();
  adresse: Adresse = null;

  ConfirmationForm!: FormGroup;

  campagne_confirmation!: boolean;

  public EmailNotificationForm!: FormGroup;
  public controls: any;
  public activationLoading = false;

  filteredEmails: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailtCtrl = new FormControl("", [Validators.email]);
  addressNotifCtrl = new FormControl([this._GlobalDataService.EmailNotification]);

  public adresseRetour = new AdresseRetour();

  // PAO : Chili
  public chiliContactFields: ChampsFusion[] = [];
  public chiliAddressesFields: ChampsFusion[] = [];
  public chiliStatus: 'BROUILLON' | 'VALIDEE' = undefined;
  public isLoadingChiliDetails = false;

  private destroy$ = new Subject<void>();

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private _CampagneService: CampagneService,
    public _GlobalDataService: GlobalDataService,
    private _AuditLogService: AuditLogService,
    public _HelpComponent: HelpComponent,
    public _ProduitService: ProduitService,
    public adressesDestinatairesSfService: AdressesDestinatairesSfService,
    public personalisationSfService: PersonalisationSfService,
    public adressesDestinatairesService: AdressesDestinatairesService,
    public personalisationService: PersonalisationService,
    private _EstimationService: EstimationService,
    private _paoService: PaoService,
    public _stepper: MatStepper
  ) {
    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {
          this.initialiseSupport();
        }
      });

    this.filteredEmails = this.emailtCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this._GlobalDataService.emails.slice()
      )
    );
  }

  initialiseSupport() {
    this.support.support = this._GlobalDataService.getLibelleProduit(
      'CodeSupport',
      this._GlobalDataService.Campagne.CodeSupport,
      'support'
    );
    this.support.dimension = this._GlobalDataService.getLibelleProduit(
      'CodeDimensionsSupport',
      this._GlobalDataService.Campagne.CodeDimensionsSupport,
      'DimensionsSupport'
    );
    this.support.grammage = this._GlobalDataService.getLibelleProduit(
      'CodeGrammageSupport',
      this._GlobalDataService.Campagne.CodeGrammageSupport,
      'GrammageSupport'
    );
    this.support.finition = this._GlobalDataService.getLibelleProduit(
      'CodeFinitionSupport',
      this._GlobalDataService.Campagne.CodeFinitionSupport,
      'FinitionSupport'
    );
    this.support.Encre = this._GlobalDataService.getLibelleProduit(
      'CodeEncreSupport',
      this._GlobalDataService.Campagne.CodeEncreSupport,
      'EncreSupport'
    );
  }

  ngOnInit(): void {
    this.ConfirmationForm = this._formBuilder.group({
      campagne_confirmation: new FormControl(this.campagne_confirmation, [
        Validators.required,
      ]),
    });

    this.controls = {
      EmailNotification: new FormControl(
        this._GlobalDataService.Campagne.EmailNotification,
        [Validators.required, Validators.email]
      ),
    };

    this.EmailNotificationForm = this._formBuilder.group(this.controls);

    if (this._GlobalDataService.Campagne.EmailNotification != '') {
      this._GlobalDataService.emails =
        this._GlobalDataService.Campagne.EmailNotification.split(',');
    }

    this.filteredEmails = of(this._GlobalDataService.emails);

    this.initialiseSupport();

    this._stepper.selectionChange.pipe(takeUntil(this.destroy$)).subscribe({
      next: (event: StepperSelectionEvent) => {
        if (this._GlobalDataService.Campagne.creationType === 'chili') {
          if (event.selectedIndex === stepperStandaloneIndexes.Recapitulatif) {
            this.loadChiliData();
          }
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  ngAfterViewInit() {
    const idCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this.controls.EmailNotification.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        // if valid
        if (this.controls.EmailNotification.valid) {
          // update model
          this._GlobalDataService.Campagne.EmailNotification = value;
          this.saveCampagne();
        }
      });

    this.ConfirmationForm.get('campagne_confirmation')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        // if valid
        if (this.ConfirmationForm.get('campagne_confirmation').valid) {
          // Check Chili creation status
          if (
            value &&
            this._GlobalDataService.Campagne.creationType === 'chili'
          ) {
            this._paoService
              .getStatus(idCampagne)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (response: { status: String }) => {
                  if (
                    response.status !== StatusChiliResponse.BROUILLON && // Todo: Dislay message if not VALIDEE
                    response.status !== StatusChiliResponse.VALIDEE
                  ) {
                    this._GlobalDataService.alert(
                      'Option studio choisie mais non complété.'
                    );
                    this.campagne_confirmation = false;
                    this.ConfirmationForm.get('campagne_confirmation').setValue(
                      false
                    );
                  } else {
                    this.campagne_confirmation =
                      value && !this._GlobalDataService.Campagne.isPurged;
                  }
                },
                error: (error) => {
                  console.log(
                    'Error while fetching Chili creation status',
                    error
                  );
                },
              });
          } else {
            this.campagne_confirmation =
              value && !this._GlobalDataService.Campagne.isPurged;
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Check if valid email
    if(value.length > 0 && !this.emailtCtrl.valid){
      return;
    }

    // Add new email
    if (value) {
      this._GlobalDataService.emails.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.emailtCtrl.setValue(null);

    this.filteredEmails = of(this._GlobalDataService.emails);

    this._GlobalDataService.Campagne.EmailNotification = this._GlobalDataService.emails.join(',');
    this._GlobalDataService.Campagne.isDirty = true;
  }

  remove(email: string): void {
    const index = this._GlobalDataService.emails.indexOf(email);

    if (index >= 0) {
      this._GlobalDataService.emails.splice(index, 1);
    }

    this._GlobalDataService.Campagne.EmailNotification = this._GlobalDataService.emails.join(',');
    this._GlobalDataService.Campagne.isDirty = true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this._GlobalDataService.emails.push(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.emailtCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this._GlobalDataService.emails.filter((email) =>
      email.toLowerCase().includes(filterValue)
    );
  }

  saveCampagne() {
    //call API
    this._CampagneService
      .put(this._GlobalDataService.Campagne)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => console.log(res),
        error: (err) => console.error(err),
      });
  }

  submit() {
    //call API
    this.stepper.next();
  }

  gotoStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  onConfirmClick(): any {
    let request: EstimationRequest = new EstimationRequest();

    request.custAccNumber = this._GlobalDataService.Annonceur.NumeroClient;
    request.contractNumber = this._GlobalDataService.Annonceur.NumeroContrat;
    request.quantity = '1';

    request.criteria = new Criteria();

    request.criteria.item_type = 'ITE002';
    request.criteria.folding = 'FOL016';
    request.criteria.printFormatValue = 'PFV006';
    request.criteria.paperGrammageCode = '350';
    request.criteria.glossLevel = 'GLO001';
    request.criteria.printingInkType = 'INK001';
    request.criteria.windowEnvelope = 'WIN005';
    request.criteria.envelopType = 'NC001';
    request.criteria.envelope_paperGrammage = 'NC';
    request.criteria.envelope_glossLevel = 'GLO003';
    request.criteria.envelope_printingInkType = 'INK002';
    request.criteria.deliveryPriority = 'NUR001';
    request.criteria.campaignProductionMode = 'CPM004';

    console.log(request);

    this._EstimationService
      .put(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => this.responseHandler(res),
        error: (err) => this.responseHandler(err),
      });
  }

  goToPrevious() {
    this._stepper.previous();
  }

  responseHandler(response: any) {
    console.log(response);

    if (
      typeof response.response === 'object' &&
      response.response.status === 'SUCCESS'
    ) {
      this.validate();
    } else if (response.status === 500) {
      this._GlobalDataService.alert(
        'Le serveur de vérification de La Poste est indisponible pour le moment. Veuillez réessayer ultérieurement.'
      );
    } else if (response.response.errorCode === 'INVALID_CONTRACT') {
      this._GlobalDataService.alert(
        'Le numéro de contrat saisi  ' +
          this._GlobalDataService.Annonceur.NumeroContrat +
          '  est invalide'
      );
    } else {
      this._GlobalDataService.alert(
        'Le serveur de vérification de La Poste est indisponible La Poste pour le moment. Veuillez réessayer ultérieurement.'
      );
    }
  }
  errorHandler(error: any) {
    console.log('errorHandler :' + error);
    return false;
  }

  validate(): any {
    if (
      this._GlobalDataService.didCampagneProductChange(
        this._GlobalDataService.Campagne
      )
    ) {
      this._GlobalDataService.alert(
        'Vous avez opté pour un autre produit, merci de remplacer les fichiers à envoyer en production.'
      );
      this._GlobalDataService.campagneBeforeChanges = Object.assign(
        {},
        this._GlobalDataService.Campagne
      );
      return false;
    }

    /**
     * 05/10/2020 desactiver le contrôle en attendant la validation finale!
    if(this._GlobalDataService.Campagne.AssetId == ""){
      this._GlobalDataService.alert("Merci de sauvegarder la campagne Eloqua avant de confirmer la campagne Cap Mailing");
      return false;
    }
   */

    // Check Enregistrement Eloqua
    if (this._GlobalDataService.isEloquaContext()) {
      if (
        !this._GlobalDataService.objectParams.AssetId ||
        this._GlobalDataService.objectParams.AssetId == 'null' ||
        this._GlobalDataService.objectParams.AssetId == '0000'
      ) {
        this._GlobalDataService.alert(
          'Attention, votre canvas de campagne n’est pas enregistré côté Eloqua.<br>Merci de fermer cette fenêtre, d’enregistrer la campagne dans Eloqua puis de rouvrir l’étape CAP Mailing Plateforme pour pouvoir Envoyer la campagne.'
        );
        return false;
      }
    }

    // Check Enregistrement Salesforce
    if (this._GlobalDataService.isSalesforceContext()) {
      if (!this._GlobalDataService.getinteractionIdSF()) {
        this._GlobalDataService.alert(
          'Attention, votre campagne n’est pas enregistrée côté Salesforce.<br>Merci de fermer cette fenêtre, d’enregistrer la campagne dans Salesforce puis de rouvrir l’étape CAP Mailing Plateforme pour pouvoir Envoyer la campagne.'
        );
        return false;
      }
    }

    // Check Nom de Campagne
    if (this._GlobalDataService.Campagne.NomCampagne == '') {
      this._GlobalDataService.alert(
        'Veuillez renseigner le nom de la campagne'
      );
      return false;
    }
    console.log(
      'this._GlobalDataService.nomCampagneIsValid',
      this._GlobalDataService.nomCampagneIsValid
    );
    //contrôle de la campagne
    if (!this._GlobalDataService.nomCampagneIsValid) {
      // this._GlobalDataService.alert("Le nom de la campagne '" + this._GlobalDataService.Campagne.NomCampagne + "' existe déjà.");
      // CTBEB-410 (2020-10-21 CFZ)
      this._GlobalDataService.alert(
        'Le nom de la campagne est obligatoire et doit-être unique. Merci de renseigner un nouveau nom de campagne'
      );

      return false;
    }

    // Check Déscription et Référence
    if (!this._GlobalDataService.descriptionCampagneIsValid) {
      this._GlobalDataService.alert(
        'La description de la campagne contient des caractères invalides (")'
      );
      return false;
    }
    if (!this._GlobalDataService.refCampagneIsValid) {
      this._GlobalDataService.alert(
        'La référence de la campagne contient des caractères invalides (")'
      );
      return false;
    }

    // Check Adresse de Notification
    if (this._GlobalDataService.Campagne.EmailNotification == '') {
      this._GlobalDataService.alert(
        'Veuillez renseigner une adresse de notification'
      );
      return false;
    }

    // Contrôle du produit
    if (
      this._GlobalDataService.Campagne.IdProduit === undefined ||
      this._GlobalDataService.Campagne.IdProduit == '' ||
      this._GlobalDataService.Campagne.IdProduit == '0'
    ) {
      this._GlobalDataService.alert(
        'Veuillez sélectionner un Support/Enveloppe'
      );
      return false;
    }

    // Check Chili Creation
    console.log(this.chiliStatus);
    if (
      this._GlobalDataService.Campagne.creationType === "chili"
      && 
      this.chiliStatus !== 'VALIDEE'
    ) {
      this._GlobalDataService.alert(
        'Attention vous n’avez pas finalisé votre création Chili ! Vous ne pouvez pas activer votre campagne. '
      );
      return false;
    } 

    // Check Alliage
    if (
      this._GlobalDataService.Campagne.Alliage &&
      !this._GlobalDataService.logo
    ) {
      this._GlobalDataService.alert(
        'Veuillez fournir le logo (obligatoire) dans votre page de paramétrage à côté de votre numéro de contrat Alliage.'
      );
      return false;
    }

    // Controle de l'adresse de retour - Classic Path
    if (
      this._GlobalDataService.Campagne.creationType !== 'chili' &&
      !this._GlobalDataService.Campagne.Alliage &&
      (!this._GlobalDataService.Campagne.IdAdresse ||
        this._GlobalDataService.Campagne.IdAdresse === '0')
    ) {
      this._GlobalDataService.alert(
        'Veuillez sélectionner une adresse de retour.'
      );
      return false;
    }

    // Controle de l'adresse de retour - Chili path
    // Alliage ou adresse de retour requis
    console.log(this._GlobalDataService.Campagne.creationType);
    console.log(this.adresseRetour);
    if (
      this._GlobalDataService.Campagne.creationType === 'chili' &&
      !this._GlobalDataService.Campagne.Alliage &&
      (!this.adresseRetour.IdAdresse ||
        this.adresseRetour.IdAdresse === '0')
    ) {
      this._GlobalDataService.alert(
        'Attention vous n’avez pas finalisé votre création Chili ! Vous ne pouvez pas activer votre campagne. Veuillez sélectionner une adresse de retour.'
      );
      return false;
    }

    //controle de logo
    // if (this._GlobalDataService.Campagne.Alliage && this._GlobalDataService.CampagneExt.NbFichiersLogo == 0) {
    //   this._GlobalDataService.alert("Veuillez sélectionner un logo de votre organisationr.");
    //   return false;
    // }

    // Contrôle des fichiers créa
    if (
      this._GlobalDataService.Campagne.creationType !== 'chili' &&
      this._GlobalDataService.CampagneExt.NbFichiersCreation == 0
    ) {
      this._GlobalDataService.alert(
        'Veuillez sélectionner au moins un fichier Création.'
      );
      return false;
    }

    // Contrôle des champs de fusion
    let isChampsFusionAvailableContext = !(
      this._GlobalDataService.isStandaloneContext() &&
      this._GlobalDataService.Campagne.importOption === 'sftp'
    );
    if (
      this._GlobalDataService.Campagne.creationType !== 'chili' &&
      isChampsFusionAvailableContext &&
      this._GlobalDataService.CampagneExt.ChampsFusion!.length == 0
    ) {
      this._GlobalDataService.alert(
        'Veuillez sélectionner au moins un champ de personnalisation'
      );
      return false;
    }

    // Contrôle de la fréquence d'envoi
    if (this._GlobalDataService.Campagne.FrequenceEnvoi == 'Récurrente') {
      if (
        !this._GlobalDataService.Campagne.DateDeDebut ||
        this._GlobalDataService.Campagne.DateDeDebut == 'null' ||
        !this._GlobalDataService.Campagne.DateDeFin ||
        this._GlobalDataService.Campagne.DateDeFin == 'null'
      ) {
        this._GlobalDataService.alert(
          "Merci de renseigner l'ensemble des champs de l'écran Planification"
        );
        return false;
      }
    } else if (
      this._GlobalDataService.Campagne.FrequenceEnvoi == 'Une seule fois'
    ) {
      if (
        !this._GlobalDataService.Campagne.DateRemiseImprimeur ||
        this._GlobalDataService.Campagne.DateRemiseImprimeur === 'null'
      ) {
        this._GlobalDataService.alert(
          "Merci de renseigner l'ensemble des champs de l'écran Planification"
        );
        return false;
      }
    } else {
      this._GlobalDataService.alert(
        "Merci de renseigner l'ensemble des champs de l'écran Planification"
      );
      return false;
    }

    // Check Confirmation selected 
    if (this.ConfirmationForm.value.campagne_confirmation !== true) {
      this._GlobalDataService.alert(
        "Merci de confirmer que la campagne de courrier postal est de nature 'MARKETING PUBLICITAIRE'"
      );
      return false;
    } else {
      const dialogRef = this.dialog.open(DialogConfirmCampagneComponent);
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          console.log(`Dialog result: ${result}`);

          if (result === true) {
            this.activationLoading = true;
            
            // Save campaign
            this._CampagneService
              .put(this._GlobalDataService.Campagne)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (_) => {
                  // Activate campaign
                  this._CampagneService
                  .activate(this._GlobalDataService.Campagne.IdCampagne)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe({
                    next: (res) => {
                      this.activationLoading = false;
                      this.successResponse(res);
                    },
                    error: (err) => {
                      this.activationLoading = false;
                      this.errorResonse(err);
                    }
                  });
                },
                error: (err) => {
                  this.activationLoading = false;
                  this.errorResonse(err);
                }
              });
            console.info(this._GlobalDataService.Campagne);
          }
        });
    }
  }

  successResponse(response: any) {
    console.log('Success!', response);
    this._GlobalDataService.Campagne.isDirty = false;
    const path = this._GlobalDataService.isStandaloneContext()
      ? '/standalone/home'
      : '/campagne/home';
    this.router
      .navigate(
        [
          path,
          {
            outlets: {
              navview: [
                'message-confirmation',
                this._GlobalDataService.Campagne.NomCampagne,
              ],
            },
          },
        ],
        {
          queryParams: this._GlobalDataService.objectParams,
          queryParamsHandling: 'merge',
          skipLocationChange: true,
        }
      )
      .then((e) => {
        if (e) {
          console.log('Navigation is successful!');
        } else {
          console.log('Navigation has failed!');
        }
      });
  }

  errorResonse(HttpErrorResponse: { error: { message: string } }) {
    console.error('Error!', HttpErrorResponse);
    if (
      HttpErrorResponse.error.message &&
      HttpErrorResponse.error.message != ''
    ) {
      this._GlobalDataService.openSnackBarInfo(
        "une erreur c'est produite : " + HttpErrorResponse.error.message
      );
    }
  }

  openHelpMarketing() {
    this._GlobalDataService.referenceDocHelp = '#advertising_marketing';
    this._HelpComponent.OpenHelpFile();
    return false;
  }

  private loadChiliData() {
    this.isLoadingChiliDetails = true;
    const idCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._paoService.getStatus(idCampagne).subscribe({
      next: (res) => {
        this.chiliStatus = res.status;
        if (res.status != 'VALIDEE') {
          this.isLoadingChiliDetails = false;
          return;
        }
        this.getChiliCreationDetails(idCampagne);
      },
      error: (err) => {
        this.isLoadingChiliDetails = false;
        this.chiliStatus = undefined;
        console.log('(Error) adresseRetour Error::', err);
      },
    });
  }

  private getChiliCreationDetails(idCampagne) {
    this._paoService
      .getDetails(idCampagne)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.isLoadingChiliDetails = false;
          if (res.champsFusions) {
            this.chiliContactFields = res.champsFusions.map((v) => {
              return {
                Id: '',
                ChampFusion: v.ChampFusion,
                internalName: v.internalName,
                IsPaveAdresse: '' + v.IsPaveAdresse,
              } as ChampsFusion;
            });
          }
          if (res.champsAdresses) {
            this.chiliAddressesFields = res.champsAdresses.map((v) => {
              return {
                Id: '',
                ChampFusion: v.ChampFusion,
                internalName: v.internalName,
                IsPaveAdresse: '' + v.IsPaveAdresse,
              } as ChampsFusion;
            });
          }
          if (res.return_address) {
            this.adresseRetour = this.mapChiliReturnAddressToAddressRetour(
              res.return_address
            );
          }
          this._GlobalDataService.Campagne.Alliage = res.alliage;
        },
        error: (err) => {
          this.isLoadingChiliDetails = false;
          console.log('(Error) Status Error::', err);
        },
      });
  }

  private mapChiliReturnAddressToAddressRetour(
    returnAdress: ChiliReturnAddress
  ) {
    const adresseRetour = new AdresseRetour();
    adresseRetour.IdAdresse = '' + returnAdress.id.toString();
    adresseRetour.NomAdresse = returnAdress.nom;
    adresseRetour.RaisonSociale = returnAdress.raisonSociale;
    adresseRetour.Service = returnAdress.service;
    adresseRetour.EntreeBatimentZI = returnAdress.entreeBatimentZI;
    adresseRetour.Numero = returnAdress.numero;
    adresseRetour.MentionSpeciale = returnAdress.mentionSpeciale;
    adresseRetour.Ville = returnAdress.ville;
    adresseRetour.CodePostal = returnAdress.codePostal;
    adresseRetour.Favori = returnAdress.favori ? 'true' : 'false';
    return adresseRetour;
  }
}
