<ul class="timeline"
    id="timeline">

  <li class="li complete"
      *ngFor="let element of ListeStatutCommande">
    <div class="timestamp">
      <span class="date">{{element.dateCreation | date:'dd/MM/yyyy'}}</span>
    </div>
    <div class="timestamp status">
      <h4> {{ element["StatutLibelle"] || element["statut"]}} </h4>
    </div>
  </li>

</ul>
