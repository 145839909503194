import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '@core/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public _GlobalDataService: GlobalDataService) { }

  ngOnInit(): void {
    console.log("footer, _GlobalDataService.context = ", this._GlobalDataService.CONTEXT, this._GlobalDataService.isEloquaContext());
  }
}
