import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Adresse } from '@core/models/adresse.model';

@Component({
  selector: 'app-adresse-retour-card',
  templateUrl: './adresse-retour-card.component.html',
  styleUrls: ['./adresse-retour-card.component.scss']
})
export class AdresseRetourCardComponent implements OnInit {

  @Input() adresse!: Adresse;

  @Output() edit: EventEmitter<Event>;
  @Output() remove: EventEmitter<Event>;


  constructor() {
    this.edit = new EventEmitter();
    this.remove = new EventEmitter();

   }

  ngOnInit(): void {
  }

  onEdit(e): void {
    this.edit.emit(e);
  }
  
  onRemove(e): void {
    this.remove.emit(e);
  }

}
