import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Annonceur } from '@core/models/annonceur.model';
import { AccountService, GlobalDataService } from '@core/services';
import { HelpComponent } from 'app/campagne/components';
import { StandaloneService } from '@core/services/standalone.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-compte-client',
  templateUrl: './compte-client.component.html',
  styleUrls: ['./compte-client.component.scss'],
})
export class CompteClientComponent implements OnInit, OnDestroy {
  public hidePasswordSFTP: boolean = true;
  public form: FormGroup;

  // Generate password
  public generatePasswordLoading = false;
  private subGeneratePassword: Subscription;

  constructor(
    public router: Router,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public _GlobalDataService: GlobalDataService,
    public _HelpComponent: HelpComponent,
    private _AccountService: AccountService,
    private _standaloneService: StandaloneService
  ) {
    _GlobalDataService.referenceDocHelp = '#ID_annonceur';

    this.form = this._formBuilder.group({
      login: new FormControl(this._GlobalDataService.Annonceur.LoginContact),
      annonceurName: new FormControl(
        this._GlobalDataService.Annonceur.annonceurName,
        [Validators.required, Validators.maxLength(50)]
      ),
      NumeroClient: new FormControl(
        this._GlobalDataService.Annonceur.NumeroClient,
        [Validators.required, Validators.maxLength(50)]
      ),
      NumeroContrat: new FormControl(
        this._GlobalDataService.Annonceur.NumeroContrat,
        [Validators.required, Validators.maxLength(50)]
      ),
      NumeroContratAlliage: new FormControl(
        this._GlobalDataService.Annonceur.NumeroContratAlliage,
        [Validators.minLength(2), Validators.maxLength(50)]
      ),
      IdentifiantAlliageGE: new FormControl(
        this._GlobalDataService.Annonceur.IdentifiantAlliageGE,
        [Validators.maxLength(10)]
      ),
      NomContact: new FormControl(
        this._GlobalDataService.Annonceur.NomContact,
        [Validators.maxLength(50)]
      ),
      PrenomContact: new FormControl(
        this._GlobalDataService.Annonceur.PrenomContact,
        [Validators.maxLength(50)]
      ),
      EmailContact: new FormControl(
        this._GlobalDataService.Annonceur.EmailContact,
        [Validators.required, Validators.email]
      ),
      TelephoneContact: new FormControl(
        this._GlobalDataService.Annonceur.TelephoneContact,
        [Validators.maxLength(20)]
      ),
      RoleContact: new FormControl(
        this._GlobalDataService.Annonceur.RoleContact,
        []
      ),
      Siret: new FormControl(this._GlobalDataService.Annonceur.Siret, []),
      RaisonSociale: new FormControl(
        this._GlobalDataService.Annonceur.RaisonSociale,
        [Validators.maxLength(100)]
      ),
      LoginSFTP: new FormControl(
        this._GlobalDataService.Annonceur.loginSFTP,
        []
      ),
      PasswordSFTP: new FormControl(
        this._GlobalDataService.Annonceur.passwordSFTP,
        []
      ),
    });
  }

  ngOnInit(): void {
    Object.keys(this.form.controls).forEach((c) => {
      if (
        this.form.get(c).hasValidator(Validators.required) &&
        !this.form.get(c).value
      ) {
        this.form.get(c).markAsTouched({ onlySelf: true });
        this.form.get(c).updateValueAndValidity({ onlySelf: true });
      }
    });
  }

  ngOnDestroy(): void {
    this.subGeneratePassword?.unsubscribe();
  }

  saveAnnonceur() {
    console.log('saveAnnonceur');

    if (
      this.form.get('NumeroClient').invalid ||
      this.form.get('NumeroContrat').invalid
    ) {
      this._GlobalDataService.alert(
        'Les champs numéro client et numéro de contrat sont obligatoires.' +
          '<br>Le champ numéro client doit être numérique.' +
          '<br>Merci de compléter les champs concernés'
      );
      return;
    }

    console.debug(this._GlobalDataService.Annonceur);
    if (this._GlobalDataService.Annonceur.IdAnnonceur === undefined) {
      // création
      this._GlobalDataService.Annonceur = new Annonceur();
    }


    this._GlobalDataService.Annonceur.annonceurName =
      this.form.value.annonceurName;

    this._GlobalDataService.Annonceur.NumeroClient =
      this.form.value.NumeroClient;
    this._GlobalDataService.Annonceur.NumeroContrat =
      this.form.value.NumeroContrat;

    this._GlobalDataService.Annonceur.NumeroContratAlliage =
      this.form.value.NumeroContratAlliage;
    this._GlobalDataService.Annonceur.IdentifiantAlliageGE =
      this.form.value.IdentifiantAlliageGE;

    this._GlobalDataService.Annonceur.Siret = this.form.value.Siret;
    this._GlobalDataService.Annonceur.RaisonSociale =
      this.form.value.RaisonSociale;

    this._GlobalDataService.Annonceur.NomContact = this.form.value.NomContact;
    this._GlobalDataService.Annonceur.PrenomContact =
      this.form.value.PrenomContact;

    // TODO: à voir 'email' correspond à quel champ dans quel model
    this._GlobalDataService.Annonceur.email = this.form.value.EmailContact;
    this._GlobalDataService.Annonceur.EmailContact =
      this.form.value.EmailContact;

    this._GlobalDataService.Annonceur.TelephoneContact =
      this.form.value.TelephoneContact;

    this._AccountService
      .saveAnnonceurDetails(this._GlobalDataService.Annonceur)
      .subscribe(
        (resul) => {
          this._GlobalDataService.alert(
            "La mise à jour du client s'est passée avec succès"
          );
        },
        (err) => {
          let msg = 'Une erreur est survenue lors de la mise à jour de vos informations personnelles';
          if(err.error?.message) {
            msg = `${msg} :<br /> ${err.error.message}`;
          }
          this._GlobalDataService.alert(msg);
        }
      );
  }

  public generatePassword() {
    const IdAnnonceur = this._GlobalDataService.Annonceur.IdAnnonceur;
    this.generatePasswordLoading = true;
    this.subGeneratePassword = this._standaloneService
      .generatePassword(IdAnnonceur)
      .subscribe({
        next: () => {
          this.generatePasswordLoading = false;
          this._GlobalDataService.alert(
            'Un nouveau mot de passe vous a été envoyé par email.'
          );
        },
        error: () => {
          this.generatePasswordLoading = false;
          this._GlobalDataService.alert('Une erreur est survenu.');
        },
      });
  }

  public isGeneratePasswordDisable() {
    return (
      !this.form.valid ||
      this.generatePasswordLoading ||
      !this.form.controls['LoginSFTP'].value
    );
  }
}
