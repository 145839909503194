import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalesforceRoutingModule } from './salesforce-routing.module';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from '@shared/shared.module';
import { BootstrapComponent } from './components/bootstrap/bootstrap.component';

@NgModule({
  declarations: [
    HomeComponent,
    BootstrapComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SalesforceRoutingModule
  ]
})
export class SalesforceModule { }
