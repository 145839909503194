<div class="gtmt-field-label" matTooltip="Le fichier est purgé !" [matTooltipDisabled]="!isPurged">
  <span class="gtmt-field-text">
    Fichier de ciblage: {{ campagne["ImportFile"] }}
  </span>
  <button
    mat-raised-button
    color="blue"
    (click)="downloadFile(campagne['ImportFile'])"
    [disabled]="isPurged"
  >
    Ouvrir
    <mat-icon svgIcon="Ouvrir"></mat-icon>
  </button>
</div>
