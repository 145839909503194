import { AccountComponent } from './account/account.component';
import { AdminConfirmationComponent } from './account/admin-confirmation/admin-confirmation.component';
import { ResetComponent } from './account/reset/reset.component';
import { SuiviCampagnesComponent } from './suivi-campagnes/suivi-campagnes.component';
import { SuiviDetailCampagneComponent } from './suivi-detail-campagne/suivi-detail-campagne.component';
import { ChartsComponent } from './charts/charts.component';
import { ListeAnnonceursComponent } from './liste-annonceurs/liste-annonceurs.component';
import { ParametrageComponent } from './parametrage/parametrage.component';
import { SuiviCampagneUneSeuleFoisComponent } from './suivi-campagne-une-seule-fois/suivi-campagne-une-seule-fois.component';
import { SuiviCampagneRecurrenteComponent } from './suivi-campagne-recurrente/suivi-campagne-recurrente.component';
import { SuiviLcampagneListCommandesComponent } from './suivi-campagne-list-commandes/suivi-campagne-list-commandes.component';
import { SuiviCampagneProgressionComponent } from './suivi-campagne-progression/suivi-campagne-progression.component';
import { SuiviCampagneCarousselComponent } from './suivi-campagne-caroussel/suivi-campagne-caroussel.component';
import { SuiviCampagneTelechargementComponent } from './suivi-campagne-telechargement/suivi-campagne-telechargement.component';
import { SuiviCampagneRecapilatifComponent } from './suivi-campagne-recapilatif/suivi-campagne-recapilatif.component';
import { SuiviCampagneCommandeComponent } from './suivi-campagne-commande/suivi-campagne-commande.component';
import { TimelineCommandeAdminComponent } from './timeline-commande-admin/timeline-commande-admin.component';
import { SuiviCampagneBatComponent } from './suivi-campagne-bat/suivi-campagne-bat.component';
import { SuiviCampagneInfoComponent } from './suivi-campagne-info/suivi-campagne-info.component';
import { CarouselItemDirective } from './carousel/carousel-item.directive';
import { CarouselComponent, CarouselItemElement } from './carousel/carousel.component';
import { StatutCommandeCardComponent } from './statut-commande-card/statut-commande-card.component';
import { GestionFavorisComponent } from './gestion-favoris/gestion-favoris.component';
import { AdresseRetourFavoriComponent } from './gestion-favoris/adresse-retour-favori/adresse-retour-favori.component';
import { LogoAlliageFavoriComponent } from './gestion-favoris/logo-alliage-favori/logo-alliage-favori.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ChangePasswordRequestComponent } from './login-page/change-password-request/change-password-request.component';
import { ChangePasswordComponent } from './login-page/change-password/change-password.component';
import { ChangePasswordCheckComponent } from './login-page/change-password-check/change-password-check.component';
import { LoginComponent } from './login-page/login/login.component';
import { HomeComponent } from './home/home.component';

export const components: any[] = [
  AccountComponent,
  SuiviCampagnesComponent,
  SuiviDetailCampagneComponent,
  ChartsComponent,
  ParametrageComponent,
  ListeAnnonceursComponent,
  AdminConfirmationComponent,
  ResetComponent,
  SuiviCampagneUneSeuleFoisComponent,
  SuiviCampagneRecurrenteComponent,
  SuiviCampagneTelechargementComponent,
  TimelineCommandeAdminComponent,
  SuiviLcampagneListCommandesComponent,
  SuiviCampagneProgressionComponent,
  SuiviCampagneCarousselComponent,
  SuiviCampagneRecapilatifComponent,
  SuiviCampagneCommandeComponent,
  SuiviCampagneBatComponent,
  SuiviCampagneInfoComponent  ,
  CarouselComponent,
  CarouselItemDirective,
  CarouselItemElement,
  StatutCommandeCardComponent,
  GestionFavorisComponent,
  AdresseRetourFavoriComponent,
  LogoAlliageFavoriComponent,
  HomeComponent,
  LoginPageComponent,
  LoginComponent,
  ChangePasswordRequestComponent,
  ChangePasswordComponent,
  ChangePasswordCheckComponent
];

export * from './suivi-campagnes/suivi-campagnes.component';
export * from './charts/charts.component';
export * from './parametrage/parametrage.component';
export * from './liste-annonceurs/liste-annonceurs.component';
export * from './account/account.component';
export * from './suivi-detail-campagne/suivi-detail-campagne.component';
export * from './account/admin-confirmation/admin-confirmation.component';
export * from './account/reset/reset.component';
export * from './suivi-campagne-une-seule-fois/suivi-campagne-une-seule-fois.component';
export * from './suivi-campagne-recurrente/suivi-campagne-recurrente.component';
export * from './suivi-campagne-list-commandes/suivi-campagne-list-commandes.component';
export * from './suivi-campagne-progression/suivi-campagne-progression.component';
export * from './suivi-campagne-caroussel/suivi-campagne-caroussel.component';
export * from './suivi-campagne-telechargement/suivi-campagne-telechargement.component';
export * from './suivi-campagne-recapilatif/suivi-campagne-recapilatif.component';
export * from './suivi-campagne-commande/suivi-campagne-commande.component';
export * from './timeline-commande-admin/timeline-commande-admin.component';
export * from './suivi-campagne-bat/suivi-campagne-bat.component';
export * from './suivi-campagne-info/suivi-campagne-info.component';
export * from './carousel/carousel-item.directive';
export * from './carousel/carousel.component';
export * from './statut-commande-card/statut-commande-card.component';
export * from './gestion-favoris/gestion-favoris.component';
export * from './gestion-favoris/adresse-retour-favori/adresse-retour-favori.component';
export * from './gestion-favoris/logo-alliage-favori/logo-alliage-favori.component';

export * from './home/home.component';
export * from './login-page/login/login.component';
export * from './login-page/login-page.component';
export * from './login-page/change-password-request/change-password-request.component';
export * from './login-page/change-password/change-password.component';
export * from './login-page/change-password-check/change-password-check.component';


