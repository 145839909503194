<mat-card class="gtmt-card-container" [formGroup]="personalisationService.form">
  <mat-card-title style="display: flex; padding: 0 24px">Champs de personnalisation</mat-card-title>
  <mat-card-content>
    <mat-grid-list cols="2" rowHeight="2rem" [gutterSize]="'0.5rem'" style="width:100%">

      <!-- Champs de personnalisation -->

      <mat-grid-tile colspan="1" rowspan="5">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Champs de personnalisation</mat-label>
          <input #champFusionRef ma matInput formControlName="champFusion" placeholder="Champs de personnalisation"
            type="text">
          <button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon svgIcon="Loupe"></mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile colspan="1" rowspan="5">
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="16">
          <div cdkDropList #filteredAvailableChampsFusionRef="cdkDropList" [cdkDropListData]="personalisationService.filteredAvailableChampsFusion"
            class="gtmt-drop-list" [cdkDropListConnectedTo]="[selectedChampsFusionRef]"
            (cdkDropListDropped)="drop($event)">
            <cdk-virtual-scroll-viewport appendOnly itemSize="60">
              <div class="gtmt-drop-box" *ngFor="let champFusion of personalisationService.filteredAvailableChampsFusion" cdkDrag>
                <li>
                  {{champFusion.ChampFusion}}
                </li>
                <button mat-raised-button color="blue" (click)="selectChampFusion(champFusion)">Ajouter</button>
                <div class="gtmt-drop-box" *cdkDragPreview [src]="champFusion.ChampFusion"
                  [alt]="champFusion.ChampFusion">
                  <li>
                    {{champFusion.ChampFusion}}
                  </li>
                </div>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
      </mat-grid-tile>


      <mat-grid-tile colspan="1" rowspan="16">
          <div cdkDropList #selectedChampsFusionRef="cdkDropList" [cdkDropListData]="personalisationService.selectedChampsFusion"
            class="gtmt-drop-list" [cdkDropListConnectedTo]="[filteredAvailableChampsFusionRef]"
            (cdkDropListDropped)="drop($event)">
            <cdk-virtual-scroll-viewport appendOnly itemSize="60">
              <div class="gtmt-drop-box gtmt-champs-selected" *ngFor="let champFusion of personalisationService.selectedChampsFusion" cdkDrag>
                <li>
                  {{champFusion.ChampFusion}}
                </li>
                <mat-icon  (click)="unselectChampFusion(champFusion)">close</mat-icon>
              </div>
              <div class="gtmt-drop-box gtmt-champs-glisser">
                <li>
                  <mat-icon svgIcon="Updown"></mat-icon>
                  Glisser - Déposer pour trier la sélection
                </li>
              </div>              
            </cdk-virtual-scroll-viewport>
          </div>
      </mat-grid-tile>

    </mat-grid-list>
  </mat-card-content>


  <mat-card-actions align="end">
    <mat-grid-list cols="2" rowHeight="4rem" [gutterSize]="'2.5rem'" style="width:100%">

      <mat-grid-tile colspan="1" rowspan="1">
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-raised-button color="blue" (click)="Vider()">
          Vider
        </button>
        <!-- <button mat-raised-button color="blue" (click)="ValiderSelection()">
          Valider la sélection
        </button> -->
      </mat-grid-tile>
      
    </mat-grid-list>
  </mat-card-actions>

</mat-card>

<!-- iframe Chili -->
<mat-card>
  <mat-card-content id="iframe-chili">
    <app-chili-iframe-personnalisation 
      id="app-chili-iframe-personnalisation" 
      *ngIf="_GlobalDataService.Campagne.creationType === 'chili'"
    ></app-chili-iframe-personnalisation>
    <app-purge-message 
      *ngIf="_GlobalDataService.Campagne.isPurged"
      id="app-purge-message" 
      [message]= "messagePurge"
    ></app-purge-message>
  </mat-card-content>
</mat-card>

<!-- Stepper navigation  -->
<app-campagne-stepper-nav
  [prevDisplay]="true"
  [nextDisplay]="true"
  (previous)="goToPrevious()"
  (next)="submit()"
></app-campagne-stepper-nav>
