import { AccumulationChartComponent, IPointEventArgs } from '@syncfusion/ej2-angular-charts';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CampagneService } from '@core/services/campagne.service';
import { ChartsService } from '@core/services/charts.service';
import { clearScreenDown } from 'readline';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, OnDestroy {

  //documentation:
  //https://ej2.syncfusion.com/angular/documentation/accumulation-chart/data-label/

  @ViewChild('chartStatus')
  pieStatus: AccumulationChartComponent | undefined;
  // @ViewChild('chartCourrier')
  // pieCourrier: AccumulationChartComponent | undefined;

  Statutpiedata: any[] = [];
  StatutpiedataRaw: any[] = [];
  modifiedStatusData: any[] = [];
  _pieStatusDataSub: any;
  StatutlegendSettings!: Object;
  statusAnimation!: Object;
  Statuttitle!: string;
  statusDatalabel!: Object;
  border!: Object;
  statusEnableSmartLabels: boolean = true;
  explodeAll: boolean = true;
  statusEnableBorderOnMouseMove: boolean = true;
  centerStatusTitle!: String;
  paletteStatus: string[] = [];
  paletteStatusRaw: string[] = [];
  statusLegend: any[] = [];
  firstInitPieStatus: boolean = false;
  isPaletteCompeted: boolean = true;
  paletteStatusInit: any[] = [];
  pattern: string[] = ["#E94649", "#F6B53F", "#6FAAB0", "#FF33F3", "#228B22", "#3399FF"];


  // Courrierpiedata: any[] = [];
  // CourrierpiedataRaw: any[] = [];
  // _pieCourrierDataSub: any;
  // CourrierlegendSettings!: Object;
  // courrierAnimation!: Object;
  // Courriertitle!: string;
  // Courrierdatalabel!: Object;
  // CourrierenableSmartLabels: boolean = true;
  // courrierEnableBorderOnMouseMove: boolean = true;
  // centerCourrierTitle!: String;
  // paletteCourrier: string[] = [];
  // paletteCourrierRaw: string[] = [];
  // courrierLegend: any[] = [];
  // courrierSelected: boolean = false;
  // firstInitPieCourrier: boolean = false;

  height: string = '90%';
  width: string = '50%';
  disabledColor: string = "lightgrey";

  @Input() IdAnnonceur: string = "";

  campagneDataIsLoaded = false;
  // courrierDataIsLoaded = false;

  constructor(private _ChartsService: ChartsService, private _CampagneService: CampagneService,) {

    this._pieStatusDataSub = _ChartsService.pieStatusdataSub.subscribe((value) => {
      if (this.StatutpiedataRaw.length === 0) {
        this.StatutpiedataRaw = value;
        this.Statutpiedata = value;
        this.initStatusPiePalette();
      }
      this.modifiedStatusData = value;
      this.buildStatusLegend();
      this.checkStatusVisibility();
      this.campagneDataIsLoaded = true;
      this.pieStatus?.refresh();
    });
    // this._pieCourrierDataSub = _ChartsService.pieCourrierdataSub.subscribe((value) => {
    //   if (this.CourrierpiedataRaw.length === 0) {
    //     this.CourrierpiedataRaw = value;
    //     this.Courrierpiedata = value;
    //     this.initCourrierPiePalette();
    //   }
    //   this.courrierDataIsLoaded = true;
    //   this.pieCourrier?.refresh();
    // });
  }

  ngOnInit(): void {
    this.statusPieSettings();
    this._ChartsService.getChartCampagneByIdAnnonceur(this.IdAnnonceur);

    // this.courrierPieSettings();
    // this._ChartsService.getChartCourrierByIdAnnonceur(this.IdAnnonceur);
  }

  statusPieSettings() {
    this.StatutlegendSettings = { visible: false };
    this.Statuttitle = 'Campagnes par statut';
    this.centerStatusTitle = this.Statuttitle;
    this.statusAnimation = { enable: false };
    this.statusDatalabel = { visible: true, position: 'Outside', name: 'text' };
    this.border = { color: 'white', width: '2px' };
  }

  initStatusPiePalette() {
    const len = this.Statutpiedata.length;
    this.paletteStatusRaw = Array(len).fill('');
    for (let i = 0; i < len; i++) { this.paletteStatusRaw[i] = this.pattern[i % this.pattern.length]; }
    this.paletteStatus = [...this.paletteStatusRaw];
    this.paletteStatusInit = [...this.paletteStatusRaw];
  }

  buildStatusLegend() {
    if (this.StatutpiedataRaw.length > 0) {
      if (!this.firstInitPieStatus) {
        this.statusLegend = [];
        this.StatutpiedataRaw.forEach((status, index) => {
          let color = this.disabledColor;
          if (this.paletteStatusRaw && this.paletteStatusRaw[index]) color = this.paletteStatusRaw[index];
          this.statusLegend.push({ name: status.statut, visibility: true, color:"#E94649"});
        });
        this.firstInitPieStatus = true;
      }
    }
  }

  pieStatusLoaded(args: any): void {
    if (args.accumulation) {
      let centerStatusTitle: HTMLDivElement = document.getElementById('center-status-title') as HTMLDivElement;
      centerStatusTitle.style.fontSize = "15px";
      let rectStatus: ClientRect = centerStatusTitle.getBoundingClientRect();
      centerStatusTitle.style.top = (args.accumulation.origin.y + args.accumulation.element.offsetTop - (rectStatus.height / 2)) + 'px';
      centerStatusTitle.style.left = (args.accumulation.origin.x + args.accumulation.element.offsetLeft - (rectStatus.width / 2)) + 'px';
      centerStatusTitle.style.visibility = 'visible';
    }
  }

  // courrierPieSettings() {
  //   this.CourrierlegendSettings = { visible: false };
  //   this.Courriertitle = 'Courrier';
  //   this.centerCourrierTitle = this.Courriertitle;
  //   this.courrierAnimation = { enable: false };
  //   this.Courrierdatalabel = { visible: true, position: 'Outside', name: 'text' };
  // }

  // initCourrierPiePalette() {
  //   const len = this.Courrierpiedata.length;
  //   this.paletteCourrierRaw = Array(len).fill('');
  //   const pattern = ["#800000", "#ff8000", "#0080ff", "#8000ff", "#004000", "#0000a0"];
  //   for (let i = 0; i < len; i++) { this.paletteCourrierRaw[i] = pattern[i % pattern.length]; }
  //   this.paletteCourrier = [...this.paletteCourrierRaw];
  // }

  // buildCourrierLegend() {
  //   if (this.CourrierpiedataRaw.length > 0) {
  //     if (!this.courrierSelected) {
  //       if (!this.firstInitPieCourrier) {
  //         this.courrierLegend = [];
  //         this.CourrierpiedataRaw.forEach((courrier, index) => {
  //           let color = this.disabledColor;
  //           if (this.paletteCourrierRaw && this.paletteCourrierRaw[index]) color = this.paletteCourrierRaw[index];
  //           this.courrierLegend.push({ name: courrier.statut, visibility: true, color: color });
  //         });
  //         this.firstInitPieCourrier = true;
  //       }
  //       else {
  //         this.CourrierpiedataRaw.forEach((_, index) => {
  //           let color = this.disabledColor;
  //           if (this.courrierLegend[index].visibility) {
  //             if (this.paletteCourrierRaw && this.paletteCourrierRaw[index]) color = this.paletteCourrierRaw[index];
  //           }
  //           this.courrierLegend[index].color = color;
  //         });
  //       }
  //     }
  //   }
  // }

  // pieCourrierLoaded(args: any): void {
  //   if (args.accumulation) {
  //     let centerCourrierTitle: HTMLDivElement = document.getElementById('center-courrier-title') as HTMLDivElement;
  //     centerCourrierTitle.style.fontSize = "15px";
  //     let rectCourrier: ClientRect = centerCourrierTitle.getBoundingClientRect();
  //     centerCourrierTitle.style.top = (args.accumulation.origin.y + args.accumulation.element.offsetTop - (rectCourrier.height / 2)) + 'px';
  //     centerCourrierTitle.style.left = (args.accumulation.origin.x + args.accumulation.element.offsetLeft - (rectCourrier.width / 2)) + 'px';
  //     centerCourrierTitle.style.visibility = 'visible';
  //   }

  //   this.buildCourrierLegend();
  // }

  // afficher les campagnes par status en cliquant charte
  pointClick(args: IPointEventArgs, pieType: string): void {
    this.vider();
    const index = args.point.index
    this.paletteStatus[index] = this.pattern[index];

    const selectedStatus = this.StatutpiedataRaw[index].statut;
    console.log(selectedStatus);
    this._CampagneService.getCampagnesByStatus(selectedStatus);
    this.pieStatus?.refresh();

    // console.log('pointClick');
    // if (pieType == "statusPie") {
    //   this.filterStatusByStatus(args.point.index);
    // }

    // if (pieType == "courrierPie") {
    //   this.filterCourrierByStatus(args.point.index);
    // }
  }
  

  filterStatusByStatus(index: any) {
    // const selectedStatus = this.StatutpiedataRaw[index].statut;
    // this._CampagneService.getCampagneByStatus(selectedStatus);
    // this.pieStatus?.refresh();

    // if (!this.statusLegend[index].visibility) {
    //   this._ChartsService.addCampagneDataStatus(selectedStatus);
    //   this._CampagneService.addCampagneByStatus(selectedStatus);
    // } else {
    //   this._ChartsService.filterCampagneDataByStatus(selectedStatus);
    //   this._CampagneService.filterCampagneByStatus(selectedStatus);
    // }

    // this.pieStatus?.refresh();
  }

  checkStatusVisibility() {
    this.statusLegend.forEach((legend, index) => {
      let color = this.disabledColor;
      if (this.modifiedStatusData.filter(e => e.statut === legend.name).length > 0) {
        this.statusLegend[index].visibility = true;
        if (this.paletteStatus && this.paletteStatusRaw && this.paletteStatus[index] && this.paletteStatusRaw[index]) {
          color = this.paletteStatusRaw[index];
        }
      }
      else {
        this.statusLegend[index].visibility = false;
      }
      this.paletteStatus[index] = color;
      this.statusLegend[index].color = color;
    });
  }

  // filterCourrierByStatus(index: any) {
  //   const selectedStatus = this.CourrierpiedataRaw[index].statut;
  //   this.courrierLegend[index].visibility = !this.courrierLegend[index].visibility;

  //   if (this.courrierLegend[index].visibility) {
  //     if (this.paletteCourrier && this.paletteCourrierRaw && this.paletteCourrier[index] && this.paletteCourrierRaw[index]) {
  //       this.paletteCourrier[index] = this.paletteCourrierRaw[index];
  //     }
  //     this._ChartsService.addCourrierDataStatus(selectedStatus);
  //   } else {
  //     if (this.paletteCourrier && this.paletteCourrier[index]) {
  //       this.paletteCourrier[index] = this.disabledColor;
  //     }
  //     this._ChartsService.filterCourrierDataByStatus(selectedStatus);
  //   }

  //   this.pieCourrier?.refresh();
  // }

  ngOnDestroy() {
    this._ChartsService.onComponentDestroy();
    this._pieStatusDataSub.unsubscribe();
  }


  vider(){
    for (let i = 0; i < this.paletteStatus.length; i++) { this.paletteStatus[i] = this.disabledColor; }
    this.pieStatus?.refresh();
    this._CampagneService.clearCampagnes();
  }

  remplir(){
    for (let i = 0; i < this.paletteStatus.length; i++) { this.paletteStatus[i] = this.paletteStatusRaw[i]; }
    this.pieStatus?.refresh();
    this._CampagneService.selectAllCampagnes();
  }
  
}
