<mat-dialog-title class="creation-dialog-title">
    <mat-icon>report_problem</mat-icon>
    <strong>Attention !</strong>
</mat-dialog-title>

<mat-dialog-content class="creation-dialog-content">
    <p>Si vous changez d’option de créations, toutes vos modifications seront perdues.</p>
    <p>Etes vous sure de vouloir changer d’option de créations ?</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button *ngIf="data.BouttonConfirmation" mat-raised-button mat-dialog-close (click)="onCancel()">Annuler</button>
    <button *ngIf="data.BouttonConfirmation" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial (click)="onConfirm()">Changer d'option de créations</button>
</mat-dialog-actions>