export const stepperStandaloneIndexes = {
  Identification: 0,
  Typologie: 1,
  Creation: 2,
  Personnalisation: 3,
  Plannification: 4,
  Adresse: 5,
  Recapitulatif: 6
} as const;
export const stepperEloquaIndexes = {
  Identification: 0,
  Typologie: 1,
  Creation: 2,
  Personnalisation: 3,
  Plannification: 4,
  Adresse: 5,
  Recapitulatif: 6
} as const;