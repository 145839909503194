<mat-dialog-title>
    <div id="close">
        <button mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    <div id="title">
        <mat-icon svgIcon="idee"></mat-icon>
    </div>
    
</mat-dialog-title>

<mat-dialog-content>
    <p>{{ message }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close (click)="onCancel()">{{ cancelMsg }}</button>
    <button mat-raised-button mat-dialog-close (click)="onConfirm()">{{ confirmMsg || "Visualiser mes
        données"}}</button>
</mat-dialog-actions>