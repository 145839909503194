import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AdresseService } from '@core/services/adresse.service';
import { AuditLogService } from '@core/services/audit-log.service';
import { Campagne } from '@core/models/campagne.model';
import { CampagneService } from '@core/services/campagne.service';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { Commande } from '@core/models/commande.model';
import { CommandeService } from '@core/services/commande.service';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { AdressesDestinatairesService, AdressesDestinatairesSfService, GlobalDataService, PersonalisationService, PersonalisationSfService } from '@core/services';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { StatutCommandeService } from '@core/services/statut-commande.service';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-suivi-detail-campagne',
  templateUrl: './suivi-detail-campagne.component.html',
  styleUrls: ['./suivi-detail-campagne.component.scss']
})
export class SuiviDetailCampagneComponent implements OnInit, OnDestroy {
  titre = "Détail d’une campagne courrier";
  percentage: any = '0';
  recurrence: boolean = false;
  toggleResume: boolean = false;
  campagne!: Campagne;
  isLoaded = false;
  displayedColumns: string[] =
    ['CodeGTMT',
      'Statut',
      'DateDeTransmission',
      'DateDEnvoi',
      'DateDepositDone',
      'NombreDePlis',
      'Annulation'];
  dataSource: any = new MatTableDataSource<Commande>();
  listeStatutCommande: any = [];
  statutConfig: any;
  statusBefore: any;
  statusCurrent: any;
  statusCurrentIndex = 0;
  statusNext: any;
  currentStatutConfig: any;

  DateValidationBAT: string = "";

  currentcommande: any;

  currentStatutCommande: any

  statutsAutoriseAnnulationCommande = [
    'CANCEL_CAMPAIGNACCEPTED',
    'ANNULATIONDEMANDE', 'ANNULATIONOK', 'ANNULATIONKO',
    'COMPLIANCE_CHECKKO', 'BAT_REJECTED', 'DEPOSITIN_PROGRESS',
    'DEPOSITDONE', 'DISTRIBTERMINE'
  ];

  statutsAutoriseAnnulationCampagne = ['TERMINEE', 'ANNULATION_DEMANDE', 'ANNULEE', 'EN_CREATION'];

  _queryParamsSub: Subscription;
  _paramMapSub: Subscription;
  _getAdminCampagneByIdSub: Subscription | undefined;
  _getAdminListByIdCampagneSub: Subscription | undefined;
  _getStatutsByIdCommandeAdminSub: Subscription | undefined;
  _getListByIdCampagneSub: Subscription | undefined;
  _afterClosedSub: Subscription | undefined;
  _demandeAnnulationCommandeSub: Subscription | undefined;
  _statutConfigListSub: Subscription | undefined;

  showButtonRetour = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null | undefined;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private _CampagneService: CampagneService,
    public _GlobalDataService: GlobalDataService,
    public _AdresseService: AdresseService,
    public _ChampsFusionService: ChampsFusionService,
    public _FichierCreaService: FichierCreaService,
    public _CommandeService: CommandeService,
    private _StatutCommandeService: StatutCommandeService,
    public _AuditLogService: AuditLogService,
    public adressesDestinatairesSfService: AdressesDestinatairesSfService,
    public personalisationSfService: PersonalisationSfService,
    public adressesDestinatairesService: AdressesDestinatairesService,
    public personalisationService: PersonalisationService,
  ) {


    this._queryParamsSub = this.activeRoute.queryParams.subscribe((params: any) => {
      // this._GlobalDataService.objectParams = params;
      // console.log(this._GlobalDataService.objectParams);
      // console.log('CONTEXT ', this._GlobalDataService.CONTEXT);  
      let queryParams: Params = {
        AppId:params['AppId'],
        AssetId: params['AssetId'],
        InstallId: params['InstallId'], //environment.InstallIdv10,
        InstanceId: params['InstanceId'],
        SiteId: params['SiteId'],
        SiteName: params['SiteName'],
        UserId: params['UserId'],
        UserName: params['UserName'],
        oauth_consumer_key: params['oauth_consumer_key'],
        oauth_nonce: params['oauth_nonce'],
        oauth_signature: params['oauth_signature'],
        oauth_signature_method: params['oauth_signature_method'],
        oauth_timestamp: params['oauth_timestamp'],
        oauth_version: params['oauth_version'],
      };

      this._GlobalDataService.objectParams = queryParams; // Print the parameter to the console.
      console.log(this._GlobalDataService.objectParams);
      console.log('globaldataService ', this._GlobalDataService.CONTEXT);


      console.log('this._GlobalDataService.objectParams!', this._GlobalDataService.objectParams);
      

      
    });
    this._paramMapSub = this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      console.log(params);
      if (params.get('showButtonRetour') === "true") {
        this.showButtonRetour = true;
      } else {
        this.showButtonRetour = false;
      }
      if (params.get('IdCampagne') != "") {
        this._getAdminCampagneByIdSub = this._CampagneService.getAdminCampagneById(params.get('IdCampagne'))
          .subscribe(
            response => this.getDetailCampagne(response),
          );
      }
    });
  }

  getStatutsResponse_old(response: any) {
    this._statutConfigListSub = this._CampagneService.getStatutConfigList().subscribe(
      res => {
        if (res.statutConfig) {
          this.statutConfig = res.statutConfig;
          if (Array.isArray(response.StatutCommande)) {
            this.listeStatutCommande = response.StatutCommande;
            this.statusCurrentIndex = this.listeStatutCommande.length - 1;
            this.statusCurrent = this.listeStatutCommande.at(this.statusCurrentIndex);
            this.statusCurrent.vignetteTitle = "En cours";
            this.currentStatutConfig = this.statutConfig.filter((s: any) => s.codeStatut === this.statusCurrent.codeStatut)[0];
            if ( this.currentStatutConfig.percentage === '0' ) {

              for (var i = this.listeStatutCommande.length - 2; i >0; i--) {
                let statusPrev = this.listeStatutCommande.at(i);
                let prevStatutConfig = this.statutConfig.filter((s: any) => s.codeStatut === statusPrev.codeStatut)[0];
                if (prevStatutConfig.percentage != '0') {
                  this.percentage = prevStatutConfig.percentage; 
                  break;
                }                  
              }
            }
            else this.percentage = this.currentStatutConfig.percentage;
            if (this.listeStatutCommande.length > 1) {
              this.listeStatutCommande.at(this.statusCurrentIndex - 1).vignetteTitle = "Terminé";
              this.statusBefore = this.listeStatutCommande.at(this.statusCurrentIndex - 1);
            }

            if (this.currentStatutConfig.prochainCodeStatut && this.currentStatutConfig.prochainCodeStatut !== "null") {
              let statutLibelle = this.statutConfig.filter((s: any) => s.codeStatut === this.currentStatutConfig.prochainCodeStatut)[0].libelle;
              this.statusNext = { statut: statutLibelle, vignetteTitle: "À venir" };
              this.listeStatutCommande.push(this.statusNext);
              this.statusCurrentIndex += 1;
            }
          }
          else if (response.StatutCommande && typeof response.StatutCommande === "object") {
            console.log("fix wrong server response");
            this.listeStatutCommande = [response.StatutCommande];
          }
        }
        this.isLoaded = true;
      });
  }

  getStatutsResponse(response: any) {
    this._statutConfigListSub = this._CampagneService.getStatutConfigList().subscribe(
      res => {
        if (res.statutConfig) {
          this.statutConfig = res.statutConfig;
          if (Array.isArray(response.StatutCommande)) {
            this.listeStatutCommande = response.StatutCommande;
            this.statusCurrentIndex = this.listeStatutCommande.length - 1;
            this.statusCurrent = this.listeStatutCommande.at(this.statusCurrentIndex);
            this.statusCurrent.vignetteTitle = "En cours";
            this.currentStatutConfig = this.statutConfig.filter((s: any) => s.codeStatut === this.statusCurrent.codeStatut)[0];
            if ( this.currentStatutConfig.percentage === '0' ) {
              for (var i = this.listeStatutCommande.length - 2; i >0; i--) {
                let statusPrev = this.listeStatutCommande.at(i);
                let prevStatutConfig = this.statutConfig.filter((s: any) => s.codeStatut === statusPrev.codeStatut)[0];
                if (prevStatutConfig.percentage != '0') {
                  this.percentage = prevStatutConfig.percentage; 
                  break;
                }                  
              }
            }
            else this.percentage = this.currentStatutConfig.percentage;
            // if (this.currentStatutConfig.percentage) this.percentage = this.currentStatutConfig.percentage;
            if (this.listeStatutCommande.length > 1) {
              this.listeStatutCommande.at(this.statusCurrentIndex - 1).vignetteTitle = "Terminé";
              this.statusBefore = this.listeStatutCommande.at(this.statusCurrentIndex - 1);
            }

            if (this.currentStatutConfig.prochainCodeStatut && this.currentStatutConfig.prochainCodeStatut !== "null") {
              let statutLibelle = this.statutConfig.filter((s: any) => s.codeStatut === this.currentStatutConfig.prochainCodeStatut)[0].libelle;
              this.statusNext = { statut: statutLibelle, vignetteTitle: "À venir" };
              this.listeStatutCommande.push(this.statusNext);
              this.statusCurrentIndex += 1;
            }
          }
          else if (response.StatutCommande && typeof response.StatutCommande === "object") {
            console.log("fix wrong server response");
            this.listeStatutCommande = [response.StatutCommande];
          }
        }
        this.isLoaded = true;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() {
    this._GlobalDataService.referenceDocHelp = "#campaign_detail";

    this.dataSource.paginator = this.paginator;
  }

  getStatutsRecurrenceResponse(response: any) {
    if (Array.isArray(response.StatutCommande)) {
      console.log(response.StatutCommande);
      let currentStatutCommandeArray = response.StatutCommande.filter((s: any) => ['CONFORMITEOK', 'BAT_AVAILABLEOK', 'BAT_ACCEPTEDOK', 'BAT_REJECTEDOK'].indexOf(s.codeStatut) > -1 )
      
      let currentStatutCommandeBATOKArray =  currentStatutCommandeArray.filter((s: any) => ['BAT_ACCEPTEDOK'].indexOf(s.codeStatut) > -1 );

      if ( currentStatutCommandeBATOKArray.length >=1 ) 
        this.DateValidationBAT = currentStatutCommandeBATOKArray[0].dateModification;

      console.log(currentStatutCommandeArray);    
      if (currentStatutCommandeArray.length >= 1 )  {
        this.currentStatutCommande = currentStatutCommandeArray[currentStatutCommandeArray.length-1];

        this.currentStatutCommande.StatutLibelle = this._GlobalDataService.mappingStatut[this.currentStatutCommande.codeStatut];

        console.log(this.currentStatutCommande);
      }
    }
    else if (response.StatutCommande && typeof response.StatutCommande === "object") {
      console.log("fix wrong server response");
    }
  }

  getDetailCampagne(response: any) {
    this.campagne = response.campagne;
    console.log(this.campagne);
    if (this.campagne?.Recurrence) this.recurrence = true;
    this._getAdminListByIdCampagneSub = this._CommandeService.getAdminListByIdCampagne(<string>this.campagne?.IdCampagne)
      .subscribe(
        response => {
          if (response.commande && response.commande.length > 0) {
            if (typeof response.commande === 'object') {
              this.currentcommande = response.commande[0];
              this._GlobalDataService.Campagne.GTMTCommande = this.currentcommande.NCommandeLaPoste;
              console.log(this.currentcommande);
              if (this.recurrence) {
                this.dataSource.data = response.commande;
                this._StatutCommandeService.getStatutsByIdCommandeAdmin(this.currentcommande.IdCommande)
                  .subscribe(
                    response => this.getStatutsRecurrenceResponse(response),
                    error => { }
                  );
              } else {
                this.dataSource.data = response.commande.slice(-1);
              }
              this._getStatutsByIdCommandeAdminSub = this._StatutCommandeService.getStatutsByIdCommandeAdmin(response.commande.at(-1).IdCommande)
                .subscribe(
                  response => this.getStatutsResponse(response),
                  error => { }
                );
            }
            this.isLoaded = true;
          }
        },
        error => {
          this.isLoaded = true;
        }

      );
  }

  getListCommandes(IdCampagne: any) {
    this._getListByIdCampagneSub = this._CommandeService.getListByIdCampagne(IdCampagne)
      .subscribe(
        response => {
          if (typeof response.commande === 'object') {
            this.dataSource.data = response.commande;
            console.log(this.dataSource.data);
            if (this.campagne.FrequenceEnvoi != 'Récurrente') {
              this.currentcommande = response.commande[0];
              this._getStatutsByIdCommandeAdminSub = this._StatutCommandeService.getStatutsByIdCommandeAdmin(this.currentcommande.IdCommande)
                .subscribe(
                  response => this.getStatutsResponse(response),
                  error => { }
                );
            }
          }
          this.isLoaded = true;
        },
        error => {
          this.isLoaded = true;
        }
      );
  }

  demandeAnnulationCommande(commande: any) {
    let data = {
      message: "Etes-vous sur de vouloir annuler cette commande ?",
      titre: 'Confirmation',
      BouttonConfirmation: true,
    }
    const dialogRef = this._GlobalDataService.confirm(data);
    this._afterClosedSub = dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log("Commande", commande)
      if (result === true) {
        //call API
        this._demandeAnnulationCommandeSub = this._CommandeService.demandeAnnulationCommande(commande.IdCommande)
          .subscribe(
            (response: any) => {
              this.getListCommandes(commande.IdCampagne);
            }
          );
        this._AuditLogService.add(commande.IdCampagne, commande.IdCommande, `Annulation commande`);
      }
    });
  }

  autoriserAnnulationCommande(commande: any): boolean {
    return this.statutsAutoriseAnnulationCommande.indexOf(commande.CodeStatut) > -1;
  }

  onChevronLeft() {
    this.statusCurrentIndex -= 1;
    this.slideStatus();
  }

  onChevronRight() {
    this.statusCurrentIndex += 1;
    this.slideStatus();
  }

  slideStatus() {
    if (this.listeStatutCommande[this.statusCurrentIndex + 1]) this.statusNext = this.listeStatutCommande[this.statusCurrentIndex + 1];
    else this.statusNext = {};
    if (this.listeStatutCommande[this.statusCurrentIndex]) this.statusCurrent = this.listeStatutCommande[this.statusCurrentIndex];
    else this.statusCurrent = {};
    if (this.listeStatutCommande[this.statusCurrentIndex - 1]) {
      this.listeStatutCommande[this.statusCurrentIndex - 1].vignetteTitle = "Terminé";
      this.statusBefore = this.listeStatutCommande[this.statusCurrentIndex - 1];
    }
    else this.statusBefore = {};
  }

  downloadFile(fileName?: string) {
    // console.log("download file " + fileName);
    return this._FichierCreaService.download(<string>fileName)
      .subscribe((response: any) => {
        console.log(response);
        var blob = new Blob([response], { type: response.type });
        //Create a link element, hide it, direct
        //it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style.display = "none";
        a.rel = "noreferrer noopener"
        a.target = "_blank";
        document.body.appendChild(a);
        //Create a DOMString representing the blob
        //and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = <string>fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);
      });
    // return this._sanitizer.bypassSecurityTrustUrl(base64File);
  }

  returnToList() {

    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }

    this.router.navigate([path, { outlets: { 'navview': ['suivi-campagnes', this._GlobalDataService.Annonceur.IdAnnonceur] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });

  }

  VisualiserBAT() {

    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }
    this.router.navigate([path, { outlets: { 'navview': ['validation-bat'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  demandeAnnulation() {
    let data = {
      message: "Etes-vous sur de vouloir annuler cette campagne ?\n"
        + "Nous vous rappelons que des frais d'annulation sont susceptibles de vous être facturés selon les modalités décrites à l'article \"Annulation\" de votre contrat.",
      titre: 'Confirmation',
      BouttonConfirmation: true,
      confirmMsg: "Annuler",
      cancelMsg: "Ne pas annuler",
      campagne: this.campagne as Campagne
    }
    const dialogRef = this._GlobalDataService.dialogAnnulation(data);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === true) {
        //traitement
        console.log("Campagne", this.campagne);
        var oldStatut = this.campagne.StatutCampagne;
        var oldCodeStatut = this.campagne.CodeStatut;
        this.campagne.StatutCampagne = "Annulation demandée";
        this.campagne.CodeStatut = "ANNULATION_DEMANDE";


        this._AuditLogService.add(this.campagne.IdCampagne, '0', `Annulation campagne (${this.campagne.IdCampagne})`).subscribe(
          result => {
            this._CampagneService.put(this.campagne)
              .subscribe(
                response => {
                  this._GlobalDataService.alert("Demande d'annulation envoyée ");
                  // this.router.navigate(['/detail-campagne', this.campagne.IdCampagne, "false"], {
                  //   queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
                  // });
                },
                error => {
                  this._GlobalDataService.openSnackBarInfo("Une erreur s'est produite lors de la demande d'annulation");

                  this.campagne.StatutCampagne = oldStatut;
                  this.campagne.CodeStatut = oldCodeStatut;

                }
              );
          });
      } else {
        this.campagne.CommentaireAnnulation = null;
      }
    });
  }
  /**
 * Si statut Campagne existe dans la liste => bouton actif
 * @param Campagne 
   * @param Campagne 
 * @param Campagne 
 * return true  : bouton disabled
 * return false : bouton enabled
 */
  autoriserAnnulationCampagne(): boolean {
    console.log(this.campagne.CodeStatut);
    if (['TERMINEE', 'ANNULATION_DEMANDE', 'ANNULEE', 'EN_CREATION'].indexOf(this.campagne.CodeStatut) > -1) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this._queryParamsSub) this._queryParamsSub.unsubscribe();
    if (this._paramMapSub) this._paramMapSub.unsubscribe();
    if (this._getAdminCampagneByIdSub) this._getAdminCampagneByIdSub.unsubscribe();
    if (this._getAdminListByIdCampagneSub) this._getAdminListByIdCampagneSub.unsubscribe();
    if (this._getStatutsByIdCommandeAdminSub) this._getStatutsByIdCommandeAdminSub.unsubscribe();
    if (this._getListByIdCampagneSub) this._getListByIdCampagneSub.unsubscribe();
    if (this._afterClosedSub) this._afterClosedSub.unsubscribe();
    if (this._demandeAnnulationCommandeSub) this._demandeAnnulationCommandeSub.unsubscribe();
    if (this._statutConfigListSub) this._statutConfigListSub.unsubscribe();
  }
}

