<carousel
  #blxcarousel
  [slidesize]="slidesize"
  (fetchRecordSet)="fetch($event)"
  *ngIf="items?.length"
>
  <ng-container *ngFor="let item of items" style="cursor: pointer">
    <ng-container *carouselItem>
      <div class="gtmt-item-container">
        <div class="state">{{ item.Title }}</div>
        <app-statut-commande-card
          [statutCommande]="item"
        ></app-statut-commande-card>
      </div>
    </ng-container>
  </ng-container>
</carousel>
