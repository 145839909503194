import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { GlobalDataService } from './global-data.service';
import { Annonceur } from '@core/models/annonceur.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  EditMode: boolean = false;

  userForm = new FormGroup({
    annonceurName: new FormControl("", [
      Validators.minLength(1),
      Validators.maxLength(50),
      Validators.pattern(/^[a-zA-Z][a-zA-Z0-9_-]{0,31}$/),
    ]),
    NumeroClient: new FormControl("", [Validators.maxLength(50)]),
    NumeroContrat: new FormControl("", [Validators.maxLength(50)]),
    NumeroContratAlliage: new FormControl("", [Validators.maxLength(50)]),
    IdentifiantAlliageGE: new FormControl("", [Validators.maxLength(10)]),
    login: new FormControl("", [
      Validators.minLength(1),
      Validators.maxLength(50),
      Validators.pattern(/^[a-zA-Z][a-zA-Z0-9_-]{0,31}$/),
    ]),
    LoginSFTP: new FormControl("", [Validators.minLength(100)]),
    PasswordSFTP: new FormControl(""),    
    email: new FormControl("", [Validators.required, Validators.email]),
    firstname: new FormControl("", [Validators.maxLength(50)]),
    lastname: new FormControl("", [Validators.maxLength(50)]),
    TelephoneContact: new FormControl("", [Validators.maxLength(20)]),
    RoleContact: new FormControl("", [Validators.maxLength(50)]),
    Siret: new FormControl("", [Validators.maxLength(14)]),
    RaisonSociale: new FormControl("", [Validators.maxLength(100)]),
  });


  _url = environment.url_api;
  _urlGateway = environment.url_gateway;

  constructor(private _http: HttpClient, public _GlobalDataService: GlobalDataService) { }

  /**
   * 
   * @param user 
   * @returns 
   */
  standaloneCreateUser(user: any) { 
    console.debug("standaloneCreateUser", user);
    return this._http.post<any>(this._url + "/standalone/user", {
        login: user.login,
        firstName: user.firstname,
        lastName: user.lastname,
        email: user.email
    }, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  standaloneCreateAnnonceur(user: any) {
    console.debug("standaloneCreateAnnonceur", user);
    
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }

    return this._http.post<any>(this._url + "/standalone/user/admin-annonceur", {
      "annonceurName": user.annonceurName,
      "NumeroClient": user.NumeroClient,
      "NumeroContrat": user.NumeroContrat,
      "NumeroContratAlliage": user.NumeroContratAlliage,
      "IdentifiantAlliageGE": user.IdentifiantAlliageGE,
      "login": user.login,
      "LoginSFTP": user.LoginSFTP,
      "firstName": user.firstname,
      "lastName": user.lastname,
      "email": user.email,
      "TelephoneContact": user.TelephoneContact,
      "RoleContact": user.RoleContact,
      "Siret": user.Siret,
      "RaisonSociale": user.RaisonSociale
    }, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  standaloneResetPasswordInit(email: string) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }
    return this._http.post<any>(this._urlGateway + "/account/reset-password/init",  email);
  }

  standaloneResetPasswordFinish(password: any, key: any) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    return this._http.post<any>(this._urlGateway + "/account/reset-password/finish", {
      key: key,
      newPassword: password
    }, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  standaloneResetPassword(password: any, key: any) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    return this._http.post<any>(this._urlGateway + "/account/reset-password/finish", {
      key: key,
      newPassword: password
    }, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  expiredPasswordChange(login: string, currentPassword: string, newPassword: string) {
    return this._http.post<any>(this._urlGateway + "/account/expired-password-change", {
      login,
      currentPassword,
      newPassword
    }, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  changePassword(currentPassword: any, newPassword: any) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    return this._http.post<any>(this._urlGateway + "/account/change-password", {
      currentPassword,
      newPassword
    }, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  getUserDetails(login: string) {
    console.debug("getUserDetails");
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    

    return this._http.get<any>(this._url + `/standalone/user/${login}`,
      { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  saveUserDetails(userDetails: any) {
    console.debug("saveUserDetails", userDetails);
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    

    return this._http.put<any>(this._url + `/standalone/user/${userDetails.login}`, { request: userDetails}, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  // ajout nom du client
  saveAnnonceurDetails(annonceurDetails: Annonceur) {
    console.log("saveAnnonceurDetails", annonceurDetails);
    return this._http.put<any>(this._url + `/standalone/user/admin-annonceur/${annonceurDetails.IdAnnonceur}`, annonceurDetails, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  listUsers() {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    return this._http.get<any>(this._url + `/standalone/admin-annonceur/user/list`, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

  listAnnonceurs() {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    return this._http.get<any>(this._url + `/standalone/admin-annonceur/list`, { headers: new HttpHeaders({ "Authorization": `Bearer ${this._GlobalDataService.getJwtoken()}` }) });
  }

}
