import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, GlobalDataService } from '@core/services';
import { PasswordControlData } from '@shared/components/password-control/password-control.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit, OnDestroy {

  private resetKey = null;

  public isSendingPassword: boolean = false;

  public errorSubject: Subject<string> = new Subject<string>();

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private _AccountService: AccountService,
    private _GlobalDataService: GlobalDataService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.resetKey = params['key'];
    });
  }

  ngOnDestroy() {
    this.errorSubject?.unsubscribe();
  }

  gotoLoginPage(path: string) {
    this.router
      .navigate([path], {
        skipLocationChange: false,
      })
      .then((e) => {
        if (e) {
          console.log('Navigation is successful!');
        } else {
          console.log('Navigation has failed!');
        }
      });
  }

  public changePassword(passwordForm: PasswordControlData): void {
    // Call API Reset password
    this.isSendingPassword = true;
    this._AccountService
      .standaloneResetPassword(passwordForm.newPassword, this.resetKey)
      .subscribe({
        next: (_) => {
          if (this._GlobalDataService.isAdminLaposte()) {
            this.gotoLoginPage('/administration');
          } else {
            this.gotoLoginPage('/standalone');
          }
          this.isSendingPassword = false;
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          if(error.status >=400 && error.status <=499 && error.error) {
            const body : {title?: string} = error.error;
            if(body.title === 'Vous ne pouvez pas créer de mot de passe contenant votre prénom.') {
              this.errorSubject.next('Vous ne pouvez pas créer de mot de passe contenant votre prénom.');
            } else if(body.title === 'Vous ne pouvez pas créer de mot de passe contenant votre nom.') {
              this.errorSubject.next('Vous ne pouvez pas créer de mot de passe contenant votre nom.');
            } else if(body.title === 'Vous ne pouvez pas utilisez un ancien mot de passe.') {
              this.errorSubject.next('Entrez un nouveau mot de passe. Il doit être différent de vos 9 derniers mot de passe.');
            } else {
              this.errorSubject.next('Le changement de mot de passe a echoué, veuillez réessayer.');
            }
          } else if(error.status >=500 && error.status <=599) {
            this._GlobalDataService.alert(
              'Une erreur est survenue.'
            );
          } else {
            this._GlobalDataService.alert(
              'Le changement de mot de passe a echoué, veuillez réessayer.'
            );
          }
          this.isSendingPassword = false;
        },
      });
  }
}
