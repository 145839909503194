import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { GlobalDataService } from './global-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StandaloneService {
  private _url: string = environment.url_standalone;

  constructor(
    private _http: HttpClient,
    private _globalDataService: GlobalDataService
  ) {}

  public generatePassword(idAnnonceur: number | string): Observable<any> {
    let url = this._url + '/generate-password';
    return this._http.post<any>(
      url,
      {
        idAnnonceur: idAnnonceur,
      },
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this._globalDataService.getJwtoken()}`,
        }),
      }
    );
  }
}
