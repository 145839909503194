export class Adresse {
    CodePostal: string; 
    EntreeBatimentZI: string; 
    IdAdresse: string; 
    IdAnnonceur: string; 
    MentionSpeciale: string; 
    NomAdresse: string; 
    Numero: string; 
    RaisonSociale: string; 
    Service: string; 
    Ville: string; 

    constructor() {
        this.CodePostal="";
        this.EntreeBatimentZI = "";
        this.IdAdresse = "";
        this.IdAnnonceur = "";
        this.MentionSpeciale = "";
        this.NomAdresse = "";
        this.Numero = "";
        this.RaisonSociale = "";
        this.Service = "";
        this.Ville = "";
     }  
}