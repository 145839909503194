<div id="content">

  <!-- <div class="title">
    <span class="title-text">Suivi de toutes vos campagnes courriers</span>
  </div> -->

  <mat-toolbar>
    <h1> Suivi de toutes vos campagnes courriers</h1>
  </mat-toolbar>

  <app-charts [IdAnnonceur]="IdAnnonceur"></app-charts>

  <mat-grid-list cols="12" rowHeight="8rem" class="mt-3">

    <!-- Trier par -->
    <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
      <button mat-button [mat-menu-trigger-for]="menuTrier">Trier par <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #menuTrier="matMenu" yPosition="above">
        <button class="resetBtn" mat-menu-item (click)="resetSortByOrder();$event.stopPropagation()">
          <mat-icon svgIcon="Refresh-bleu"></mat-icon>Réinitialiser le filtre
        </button>

        <button class="resetBtn" mat-menu-item *ngFor="let order of campagneOrders"        
        (click)="sortByOrder(order);$event.stopPropagation()">
          <mat-icon svgIcon="{{order.sort === 'croisant' ? 'FlecheBas' : 'FlecheHaut' }}" ></mat-icon>{{order.name}}
        </button>

        <!-- <mat-radio-group>
          <mat-radio-button disableRipple="true" class="gtmt-radio-button" *ngFor="let order of campagneOrders"
            [value]="order.sort" [checked]="order.checked" (change)="sortByOrder(order)">
            <mat-icon svgIcon="Vector"></mat-icon> {{order.name}}
          </mat-radio-button>
        </mat-radio-group> -->
      </mat-menu>
    </mat-grid-tile>

    <!-- Filtrer par date -->
    <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
      <button mat-button [matMenuTriggerFor]="menuYear">Filtrer par date <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #menuYear="matMenu">
        <button class="resetBtn" mat-menu-item (click)="resetFilterByYears();$event.stopPropagation()">
          <mat-icon svgIcon="Refresh-bleu"></mat-icon> Réinitialiser le filtre
        </button>
        <mat-checkbox *ngFor="let year of campagneYears" [checked]="year.filtered" (click)="$event.stopPropagation()"
          (change)="filterByYears(year)">{{year.value}}</mat-checkbox>
      </mat-menu>
    </mat-grid-tile>

    <mat-grid-tile class="gtmt-jc-start" colspan="2" rowspan="1">
      <button mat-button [matMenuTriggerFor]="menuUser">Filtrer par utilisateur <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #menuUser="matMenu">
        <button class="resetBtn" mat-menu-item (click)="resetFilterByUsers();$event.stopPropagation()">
          <mat-icon svgIcon="Refresh-bleu"></mat-icon> Réinitialiser le filtre
        </button>
        <mat-checkbox *ngFor="let user of campagneUsers" [checked]="user.filtered" (click)="$event.stopPropagation()"
          (change)="filterByUsers(user)">{{user.value}}</mat-checkbox>
      </mat-menu>
    </mat-grid-tile>
    <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
    </mat-grid-tile>

    <mat-grid-tile class="gtmt-jc-start" colspan="3" rowspan="1">
      <mat-form-field appearance="outline" id="searchInput">
        <mat-label>Recherche</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Recherche" #input>
        <mat-icon svgIcon="Loupe" matSuffix></mat-icon>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile class="gtmt-jc-end" colspan="2" rowspan="1">
      <button mat-raised-button color="blue" (click)="rafraichir()">Rafraichir
        <mat-icon svgIcon="Refresh"></mat-icon>
      </button>
    </mat-grid-tile>

    <mat-grid-tile class="gtmt-jc-end" colspan="2" rowspan="1">
      <button mat-raised-button color="blue"
        (click)="exporter.exportTable('csv', {fileName:'gtmt-export-campagnes', delimiter: ';'})" type="button">Exporter
        le tableau <mat-icon svgIcon="Telecharger"></mat-icon>
      </button>
    </mat-grid-tile>

  </mat-grid-list>


  <table mat-table [dataSource]="dataSource" matTableExporter matSort multiTemplateDataRows
    #exporter="matTableExporter">
    <!-- NomCampagne Column -->
    <ng-container matColumnDef="NomCampagne">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
      <td mat-cell class="gtmt-overflow-ellipsis" style="padding-left:1rem;padding-right: 1rem; cursor:pointer"
        [mdePopoverTriggerFor]="NomCampagnePopover" (mouseenter)="$event.stopPropagation()" *matCellDef="let element">
        {{element.NomCampagne}}
        <mde-popover #NomCampagnePopover="mdePopover" [mdePopoverOverlapTrigger]="false"
          [mdeFocusTrapAutoCaptureEnabled]="false">
          <mat-card class="gtmt-popover-card">
            <mat-card-header> Nom de la campagne courrier
            </mat-card-header>
            <mat-card-content>
              <p>
                {{element.NomCampagne}}
              </p>
            </mat-card-content>
          </mat-card>
        </mde-popover>
      </td>
    </ng-container>

    <!-- NomCampagne Column -->
    <ng-container matColumnDef="GTMTCommande">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> GTMT </th>
      <td mat-cell *matCellDef="let element"> {{element.GTMTCommande}}
      </td>
    </ng-container>


    <!-- EmailNotification Column -->
    <ng-container matColumnDef="CreeParLogin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Utilisateur </th>
      <td mat-cell class="gtmt-overflow-ellipsis" style="padding-left:1rem;padding-right: 1rem;"
        [mdePopoverTriggerFor]="CreeParLoginPopover" (mouseenter)="$event.stopPropagation()" *matCellDef="let element">
        {{element.CreeParLogin}}
        <mde-popover #CreeParLoginPopover="mdePopover" [mdePopoverOverlapTrigger]="false"
          [mdeFocusTrapAutoCaptureEnabled]="false">
          <mat-card class="gtmt-popover-card">
            <mat-card-header>ID Utilisateur
            </mat-card-header>
            <mat-card-content>
              <p>
                {{element.CreeParLogin}}
              </p>
            </mat-card-content>
          </mat-card>
        </mde-popover>

      </td>
    </ng-container>

    <!-- CodeVitesse Column -->
    <!-- <ng-container matColumnDef="CodeVitesse">
          <th mat-header-cell *matHeaderCellDef> Vitesse </th>
          <td mat-cell *matCellDef="let element"> {{element.CodeVitesse}} </td>
        </ng-container> -->

    <!-- FrequenceEnvoi Column -->
    <!-- <ng-container matColumnDef="FrequenceEnvoi">
          <th mat-header-cell *matHeaderCellDef> Fréquence </th>
          <td mat-cell *matCellDef="let element"> {{element.FrequenceEnvoi}} </td>
        </ng-container> -->


    <!-- DateRemiseImprimeur Column -->
    <ng-container matColumnDef="DateRemiseImprimeur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header title="Date de remise imprimeur"> D.R.I. </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.DateRemiseImprimeur != 'null'; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          {{element.DateRemiseImprimeur | date :'dd/MM/yyyy'}}
        </ng-template>
        <ng-template #elseBlock>
          {{element.DateRemiseImprimeur}}
        </ng-template>
      </td>
    </ng-container>


    <!-- DateTheoriqueDepotLaPoste Column -->
    <ng-container matColumnDef="DateTheoriqueDepotLaPoste">
      <th mat-header-cell *matHeaderCellDef mat-sort-header title="Date théorique de dépôt La Poste"> D.T.D. </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.DateTheoriqueDepotLaPoste != 'null'; then thenBlock else elseBlock">
        </div>
        <ng-template #thenBlock>
          {{element.DateTheoriqueDepotLaPoste | date :'dd/MM/yyyy'}}
        </ng-template>
        <ng-template #elseBlock>
          {{element.DateTheoriqueDepotLaPoste}}
        </ng-template>
      </td>
    </ng-container>



    <!-- Recurrence Column -->
    <ng-container matColumnDef="Recurrence">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Récurrence </th>
      <td mat-cell *matCellDef="let element">
        {{element.Recurrence ? element.Recurrence : 'Envoi simple'}}
      </td>
    </ng-container>




    <!-- DetailRecurrence Column -->
    <ng-container matColumnDef="DetailRecurrence">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Détail récurrence </th>
      <td mat-cell *matCellDef="let element"> {{element.DetailRecurrence}} </td>
    </ng-container>


    <!-- DateDeDebut Column -->
    <ng-container matColumnDef="DateDeDebut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> DT début </th>

      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.DateDeDebut != 'null'; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          {{element.DateDeDebut | date :'dd/MM/yyyy'}}
        </ng-template>
        <ng-template #elseBlock>
          {{element.DateDeDebut}}
        </ng-template>
      </td>


    </ng-container>


    <!-- DateDeFin Column -->
    <ng-container matColumnDef="DateDeFin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> DT fin </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.DateDeFin != 'null'; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          {{element.DateDeFin | date :'dd/MM/yyyy'}}
        </ng-template>
        <ng-template #elseBlock>
          {{element.DateDeFin}}
        </ng-template>
      </td>
    </ng-container>

    <!-- Alliage Column -->
    <ng-container matColumnDef="Alliage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Alliage </th>
      <td mat-cell *matCellDef="let element">
        <div class="gtmt-chips">
          {{element.Alliage ? 'Oui' : 'Non'}}
        </div>
      </td>
    </ng-container>

    <!-- StatutCampagne Column -->
    <ng-container matColumnDef="StatutCampagne">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
      <td mat-cell *matCellDef="let element"> {{element.StatutCampagneLibelle || element.StatutCampagne}} </td>
    </ng-container>

    <!-- Nbre_commandes  Column -->
    <ng-container matColumnDef="NumberDeCommande">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nbre de commandes </th>
      <td mat-cell *matCellDef="let element"> {{element.NumberDeCommande}} </td>
    </ng-container>

    <!-- Nbre de courriers  Column -->
    <ng-container matColumnDef="NombrePli">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nbre de courriers </th>
      <td mat-cell *matCellDef="let element"> {{element.NombrePli}} </td>
    </ng-container>

    <!-- Nbre PND Column -->
    <ng-container matColumnDef="Nbre_PND">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nbre PND </th>
      <td mat-cell *matCellDef="let element"> {{element.Nbre_PND}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr title="Voir le détail de la campagne" (click)="gotoDetailCampagne(row)" mat-row
      *matRowDef="let row; columns: displayedColumns;" style="cursor:pointer"></tr>
    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow style="cursor:pointer">
      <td colspan="13">Aucune donnée correspondant au filtre "{{input.value}}"</td>
    </tr>
  </table>

  <!-- <mat-paginator pageSize="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->


  <mat-paginator style-paginator (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="5"
    showFirstLastButtons>
  </mat-paginator>

</div>

<mat-spinner-overlay [overlay]=true *ngIf="showSpinner">
</mat-spinner-overlay>