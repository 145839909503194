<ng-container *ngIf="typologie === 'autre'">
        <app-typologie-card 
                [EditMode]=true 
                [showFormat]=true 
                [showDimension]=true 
                [showGrammage]=true
                [showFinition]=false 
                [showEncre]=false>
        </app-typologie-card>

        <app-support-finition-card [typologie]="'autre'"></app-support-finition-card>
</ng-container>


<ng-container *ngIf="typologie === 'lettre'">
        <app-typologie-lettre-card 
                [EditMode]=true 
                [showFormat]=true 
                [showPage]=true 
                [showDimension]=true 
                [showGrammage]=true
                [showFinition]=false 
                [showEncre]=false>
        </app-typologie-lettre-card>

        <mat-toolbar>
                <h2>Paramétrage de la lettre</h2>
                <button mat-raised-button color="blue" (click)="gotoTypologieSelection()">Terminer</button>
        </mat-toolbar>           

        <app-support-finition-card [typologie]="'lettre'"></app-support-finition-card>
</ng-container>

<ng-container *ngIf="typologie === 'enveloppe'">
        <app-typologie-enveloppe-card 
                [EditMode]=true 
                [showFormat]=true 
                [showEnveloppe]=true 
                [showDimension]=true 
                [showGrammage]=true
                [showFinition]=false 
                [showEncre]=false>
        </app-typologie-enveloppe-card>

        <mat-toolbar>
                <h2>Paramétrage de l'enveloppe</h2>
                <button mat-raised-button color="blue" (click)="gotoTypologieSelection()">Terminer</button>
        </mat-toolbar>   

        <app-enveloppe-finition-card [typologie]="'enveloppe'"></app-enveloppe-finition-card>        
</ng-container>
