import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Commande } from '@core/models/commande.model';
import { GlobalDataService } from '@core/services';
import { AuditLogService } from '@core/services/audit-log.service';
import { CommandeService } from '@core/services/commande.service';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
  selector: 'app-suivi-campagne-list-commandes',
  templateUrl: './suivi-campagne-list-commandes.component.html',
  styleUrls: ['./suivi-campagne-list-commandes.component.scss']
})
export class SuiviLcampagneListCommandesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: any = new MatTableDataSource<Commande>();


  statutsAutoriseAnnulationCommande = [
    'CANCEL_CAMPAIGNACCEPTED',
    'ANNULATIONDEMANDE', 'ANNULATIONOK', 'ANNULATIONKO',
    'COMPLIANCE_CHECKKO', 'BAT_REJECTED', 'DEPOSITIN_PROGRESS',
    'DEPOSITDONE', 'DISTRIBTERMINE'
  ];

  displayedColumns: string[] =
    ['CodeGTMT',
      'Statut',
      'DateDeTransmission',
      'DateEnvoi',
      'DateDepositDone',
      'NombreDePlis',
      'Annulation'];

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _CommandeService: CommandeService,
    private _AuditLogService: AuditLogService
  ) { }

  ngOnInit(): void {
    this.dataSource.data = this._GlobalDataService.CampagneExt.Commandes;
    this.dataSource.paginator = this.paginator;
  }

  demandeAnnulationCommande(commande: any) {
    let data = {
      message: "Etes-vous sur de vouloir annuler cette commande ?",
      titre: 'Confirmation',
      BouttonConfirmation: true,
    }
    const dialogRef = this._GlobalDataService.confirm(data);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log("Commande", commande)
      if (result === true) {
        //call API
        this._CommandeService.demandeAnnulationCommande(commande.IdCommande)
          .subscribe(
            (response: any) => {
              //this.getListCommandes(commande.IdCampagne);
              commande.CodeStatut = 'ANNULATIONDEMANDE'
              console.log(response);
            }
          );
        this._AuditLogService.add(commande.IdCampagne, commande.IdCommande, `Annulation commande`);
      }
    });
  }

  autoriserAnnulationCommande(commande: any): boolean {
    return this.statutsAutoriseAnnulationCommande.indexOf(commande.CodeStatut) > -1;
  }



}
