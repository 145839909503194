import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountService,
  AnnonceurService,
  GlobalDataService,
} from '@core/services';

@Component({
  selector: 'app-create-annonceur',
  templateUrl: './create-annonceur.component.html',
  styleUrls: ['./create-annonceur.component.scss'],
})
export class CreateAnnonceurComponent {
  constructor(
    private router: Router,
    public _AccountService: AccountService,
    private _GlobalDataService: GlobalDataService,
    private _AnnonceurService: AnnonceurService
  ) {}

  public onSubmit() {
    console.log('Submit : ', this._AccountService.userForm);

    if (this._AccountService.EditMode) {
      this._AccountService
        .saveAnnonceurDetails(this._AccountService.userForm.value)
        .subscribe(
          (response) => {
            this._GlobalDataService.alert(
              `L'annonceur ${this._AccountService.userForm.value.annonceurName} a été mis à jour avec succès`
            );
            this._AccountService.userForm.reset();
          },
          (error) => {
            this._GlobalDataService.alert(
              `Erreur technique lors de la mise à jour de l'annonceur ${this._AccountService.userForm.value.annonceurName}`
            );
            console.log(error);
          }
        );
    } else {
      this._AccountService
        .standaloneCreateAnnonceur(this._AccountService.userForm.value)
        .subscribe({
          next: (response) => {
            const idAnnonceur = parseInt(response.IdAnnonceur);
            this._GlobalDataService.alert(
              `L'annonceur ${this._AccountService.userForm.value.annonceurName} a été créé avec succès`
            );
            this.getCreatedAnnonceur(idAnnonceur);
          },
          error: (error) => {
            const message = `Erreur technique lors de la création de l'annonceur ${this._AccountService.userForm.value.annonceurName}`;
            if (error.status === 400 && error.error?.message) {
              this._GlobalDataService.alert(
                `${message} :<br />${error.error.message}`
              );
            } else {
              this._GlobalDataService.alert(message);
            }
            console.log(error);
          },
        });
    }
  }

  public gotoGestionAnnonceurs() {
    this.router
      .navigate(
        ['/administration/home', { outlets: { navview: ['compte-client'] } }],
        {
          queryParams: this._GlobalDataService.objectParams,
          queryParamsHandling: 'merge',
          skipLocationChange: true,
        }
      )
      .then((e) => {
        if (e) {
          console.log('Navigation is successful!');
        } else {
          console.log('Navigation has failed!');
        }
      });
  }

  public getCreatedAnnonceur(idAnnonceur: number) {
    this._AnnonceurService.AdminList().subscribe({
      next: (data) => {
        console.log('List of annonceurs: ', data.annonceur);
        const annonceur = data.annonceur.find(
          (a) => a.IdAnnonceur === idAnnonceur
        );
        console.log(
          'this._GlobalDataService.Annonceur: ',
          this._GlobalDataService.Annonceur
        );
        if(annonceur) {
          this._GlobalDataService.Annonceur = annonceur;
          this._AccountService.userForm.reset();
          this.gotoGestionAnnonceurs();
        }
      },
      error: (error) => {
        console.log('Failed on retrieving created annonceur', error);
        this._GlobalDataService.alert(
          "Une erreur technique sur récupération de l'annonceur créé"
        );
      },
    });
  }
}
