<mat-card class="gtmt-card-container" [formGroup]="_CampagneTypologieService.EnveloppeFormGroup">
    <mat-card-title>Encre</mat-card-title>
    <mat-card-content>
        <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'">
            <mat-radio-group formControlName="CodeEncreEnveloppe" (change)="encreChanged()"
                aria-labelledby="dimension-radio-group-label" class="dimension-radio-group"
                [(ngModel)]="_GlobalDataService.Campagne.CodeEncreEnveloppe">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="0.5"
                    (click)="_CampagneTypologieService.EnveloppeFormGroup.get('CodeEncreEnveloppe').setValue(dataEncresEnveloppe.CodeEncreEnveloppe)"
                    *ngFor="let dataEncresEnveloppe of _CampagneTypologieService.dataEncresEnveloppes">
                    <span>  {{dataEncresEnveloppe.EncreEnveloppe}}</span>
                    <mat-radio-button [value]="dataEncresEnveloppe.CodeEncreEnveloppe">                       
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="Valider()">
            Valider la sélection
        </button>
    </mat-card-actions>

</mat-card>