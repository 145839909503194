import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { SafePipe } from './components/help/safePipe.comonent';
import { CampagneRoutingModule } from './campagne-routing.module';
import { HelpComponent } from './components';
import { DynamicFilterPipe } from '@shared/pipes/dynamic-filter.pipe';
import { CampagneSaveComponent } from './components/campagne-save/campagne-save.component';
import { CampagnePersonnalisationStandaloneComponent } from './components/campagne-personnalisation-standalone/campagne-personnalisation-standalone.component';
import { CampagneStandaloneCiblageComponent } from './components/campagne-personnalisation-standalone/campagne-standalone-ciblage/campagne-standalone-ciblage.component';
import { PurgeMessageComponent } from './components/purge-message/purge-message.component';

@NgModule({
  declarations: [fromComponents.components, SafePipe, CampagneSaveComponent, CampagnePersonnalisationStandaloneComponent, CampagneStandaloneCiblageComponent, PurgeMessageComponent],
  imports: [
    CommonModule,
    SharedModule,
    CampagneRoutingModule
  ],
  exports:  [fromComponents.components],
  schemas: [ NO_ERRORS_SCHEMA],
  providers: [
    HelpComponent, DynamicFilterPipe
  ],  
})
export class CampagneModule { }
