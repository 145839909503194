import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataService } from '@core/services';
import { HelpComponent } from 'app/campagne/components';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() titre: any;
  @Input() showButtonRetour: any;
  @Input() showButtonSetting: any;
  
  public environment: any;

  constructor(private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private domSanitizer: DomSanitizer,
    public _GlobalDataService: GlobalDataService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    public _HelpComponent: HelpComponent,
  ) {

    this.environment = environment;

    this.activeRoute.queryParams.subscribe(params => {
      this._GlobalDataService.objectParams = params; // Print the parameter to the console. 
    });

    iconRegistry.addSvgIcon(
      'logolp_part',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/logolp_part.svg'));
  }

  ngOnInit(): void {
  }

  goToParametre() {
    switch (this._GlobalDataService.CONTEXT) {
      case 'SALESFORCE': {
        this.router.navigate(['/salesforce/parametrage'], {
          queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
        });
        break;
      }
      default: {
        this.router.navigate(['/parametrage'], {
          queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
        });
        break;
      }
    }
  }

  OpenHelpFile() {
    this._HelpComponent.OpenHelpFile();
  }

  showInfo() {
    this._GlobalDataService.alert("Information application :<br>" +
      "Cap Mailing est une application créée par La Poste.<br>" +
      "Version de l’application : " + this._GlobalDataService.version + "<br>" +
      "Un problème avec cette application ? <br>" +
      "N’hésitez pas à contacter le SAV ou à consulter la <a href=\"" + environment.url_entrypoint + "/assets/Cap_Mailing_OnlineHelp.htm" + "\" target='_blank' rel='noopener noreferrer'>FAQ</a>");
  }
}
