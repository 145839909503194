<div mat-dialog-title>
  <h1>{{ title }}</h1>
  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<mat-dialog-content [formGroup]="filters" class="mat-typography">
  <mat-grid-list cols="3" rowHeight="5rem" [gutterSize]="'2rem'">
    <!-- Placeholder -->
    <mat-grid-tile colspan="2"> </mat-grid-tile>

    <!-- Tout sélectionner -->
    <mat-grid-tile colspan="1" style="background-color: #ffffff; cursor: pointer" (click)="
              filters.get('allFilters').setValue(!filters.get('allFilters').value)
            ">
      <div class="gtmt-small-card-header" style="background: #ffcb05; border-radius: 0.3rem; height: 3.4rem">
        <span class="gtmt-checkbox-label" style="color: #ffffff">
          Tout sélectionner
        </span>
        <mat-checkbox formControlName="allFilters" (click)="$event.stopPropagation()"></mat-checkbox>
      </div>
    </mat-grid-tile>

    <!-- Campagne classique -->
    <div *ngIf="data.campagne.creationType !== 'chili'">
      <!-- Support -->
      <mat-grid-tile [colspan]="campagne.Enveloppe.support ? 2 : 1" rowspan="4" class="gtmt-grid-tile"
        (click)="onCheckSupport()">
        <div class="gtmt-card-header" style="cursor: pointer" [mdePopoverTriggerFor]="appPopover"
          (mouseenter)="$event.stopPropagation()">
          <span class="gtmt-checkbox-label"> Support </span>
          <mat-checkbox formControlName="Support" (click)="$event.stopPropagation()">
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <!-- Support -->
          <div class="gtmt-dupli-produit">
            <app-support-info-card [support]="campagne.Support"></app-support-info-card>
          </div>
          <!-- Enveloppe -->
          <div class="gtmt-dupli-produit" *ngIf="campagne.Enveloppe.support">
            <app-support-info-card [support]="campagne.Enveloppe"></app-support-info-card>
          </div>
        </div>
      </mat-grid-tile>

      <!-- FrequenceEnvoi -->
      <mat-grid-tile colspan="1" rowspan="2" class="gtmt-grid-tile" (click)="
        filters
          .get('FrequenceEnvoi')
          .setValue(!filters.get('FrequenceEnvoi').value)
      ">
        <div class="gtmt-small-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Fréquence </span>
          <mat-checkbox formControlName="FrequenceEnvoi" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-small-card-content">
          <span class="gtmt-field-label"> {{ campagne["FrequenceEnvoi"] }} </span>
        </div>
      </mat-grid-tile>

      <!-- CodeVitesse -->
      <mat-grid-tile colspan="1" rowspan="2" class="gtmt-grid-tile" (click)="
        filters.get('CodeVitesse').setValue(!filters.get('CodeVitesse').value)
      ">
        <div class="gtmt-small-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Vitesse </span>
          <mat-checkbox formControlName="CodeVitesse" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-small-card-content">
          <span class="gtmt-field-label"> {{ campagne["CodeVitesse"] }} </span>
        </div>
      </mat-grid-tile>

      <!-- Fichier de ciblage -->
      <mat-grid-tile colspan="2" rowspan="2" *ngIf="
        _GlobalDataService.isStandaloneContext() &&
        !isEmpty(campagne) &&
        contactFilePresent &&
        !campagne.isPurged
      " class="gtmt-grid-tile">
        <div class="gtmt-small-card-header" style="cursor: pointer" (click)="
          filters
            .get('ImportCiblage')
            .setValue(!filters.get('ImportCiblage').value)
        ">
          <span class="gtmt-checkbox-label"> Fichiers de ciblage </span>
          <mat-checkbox formControlName="ImportCiblage" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-small-card-content">
          <app-fichier-ciblage-card [campagne]="campagne"></app-fichier-ciblage-card>
        </div>
      </mat-grid-tile>

      <!-- Fichier de ciblage purgé -->
      <mat-grid-tile colspan="2" rowspan="2" *ngIf="
        _GlobalDataService.isStandaloneContext() &&
        !isEmpty(campagne) &&
        contactFilePresent &&
        campagne.isPurged
      " class="gtmt-grid-tile-disabled">
        <div class="gtmt-small-card-header">
          <span class="gtmt-checkbox-label"> Fichiers de ciblage </span>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-small-card-content">
          <app-fichier-ciblage-card [campagne]="campagne" [isPurged]="true"></app-fichier-ciblage-card>
        </div>
      </mat-grid-tile>

      <!-- Alliage -->
      <mat-grid-tile colspan="1" rowspan="2" *ngIf="campagne.Alliage" class="gtmt-grid-tile"
        (click)="filters.get('Alliage').setValue(!filters.get('Alliage').value)">
        <ng-container *ngIf="_GlobalDataService.Annonceur.NumeroContratAlliage">
          <div class="gtmt-small-card-header" style="cursor: pointer">
            <span class="gtmt-checkbox-label"> Alliage </span>
            <mat-checkbox formControlName="Alliage" (click)="$event.stopPropagation()"></mat-checkbox>
          </div>
          <mat-divider></mat-divider>
          <div class="gtmt-small-card-content">
            <span class="gtmt-field-label">
              {{ campagne["Alliage"] ? "Oui" : "Non" }}
            </span>
          </div>
        </ng-container>
      </mat-grid-tile>

      <!-- Placeholder Alliage -->
      <mat-grid-tile colspan="1" rowspan="2" *ngIf="!_GlobalDataService.Annonceur.NumeroContratAlliage">
      </mat-grid-tile>

      <!-- Champ de personnalisation -->
      <mat-grid-tile colspan="1" rowspan="4" class="gtmt-grid-tile" *ngIf="
        showFusionFields() &&
        champsPersoPresent &&
        !this.campagne.isPurged
      " (click)="
        filters.get('ChampFusion').setValue(!filters.get('ChampFusion').value)
      ">
        <div class="gtmt-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Champ de personnalisation </span>
          <mat-checkbox formControlName="ChampFusion" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <app-champ-fusion-card [IdCampagne]="campagne.IdCampagne"
            (ChampsPersonnalisation)="getChampsPersonnalisation($event)"></app-champ-fusion-card>
        </div>
      </mat-grid-tile>

      <!-- Champ de personnalisation purgé -->
      <mat-grid-tile colspan="1" rowspan="4" class="gtmt-grid-tile-disabled"
        *ngIf="showFusionFields() && champsPersoPresent && this.campagne.isPurged">
        <div class="gtmt-card-header">
          <span class="gtmt-checkbox-label"> Champ de personnalisation </span>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <app-champ-fusion-card [IdCampagne]="campagne.IdCampagne"
            (ChampsPersonnalisation)="getChampsPersonnalisation($event)"></app-champ-fusion-card>
        </div>
      </mat-grid-tile>

      <!-- Adresse de retour -->
      <mat-grid-tile colspan="1" rowspan="4" class="gtmt-grid-tile" *ngIf="!campagne.Alliage"
        (click)="filters.get('Adresse').setValue(!filters.get('Adresse').value)">
        <div class="gtmt-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Adresse de retour </span>
          <mat-checkbox formControlName="Adresse" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <app-adresse-retour *ngIf="campagne.Adresse" [adresse]="campagne.Adresse"
            (click)="$event.stopPropagation()"></app-adresse-retour>
        </div>
      </mat-grid-tile>

      <!-- Pavé adresse -->
      <mat-grid-tile colspan="1" rowspan="4" class="gtmt-grid-tile" *ngIf="
        showFusionFields() &&
        champsAddressPresent &&
        !campagne.isPurged
      " (click)="
        filters.get('PaveAdresse').setValue(!filters.get('PaveAdresse').value)
      ">
        <div class="gtmt-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Pavé adresse </span>
          <mat-checkbox formControlName="PaveAdresse" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <app-pave-adresse-card [IdCampagne]="campagne.IdCampagne" (ChampsAdresse)="getChampsAdresse($event)">
          </app-pave-adresse-card>
        </div>
      </mat-grid-tile>

      <!-- Pavé adresse purgé -->
      <mat-grid-tile colspan="1" rowspan="4" class="gtmt-grid-tile-disabled"
        *ngIf="showFusionFields() && champsAddressPresent && campagne.isPurged">
        <div class="gtmt-card-header">
          <span class="gtmt-checkbox-label"> Pavé adresse </span>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <app-pave-adresse-card [IdCampagne]="campagne.IdCampagne" (ChampsAdresse)="getChampsAdresse($event)">
          </app-pave-adresse-card>
        </div>
      </mat-grid-tile>

      <!-- Fichiers de créations -->
      <mat-grid-tile *ngIf="
        creationFilePresent &&
        !campagne.isPurged
      " rowspan="4" class="gtmt-grid-tile" (click)="
        filters
          .get('FichierCreation')
          .setValue(!filters.get('FichierCreation').value)
      ">
        <div class="gtmt-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Fichiers de créations </span>
          <mat-checkbox formControlName="FichierCreation" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <app-fichiers-crea-card [IdCampagne]="campagne.IdCampagne" (FichiersCreation)="getFichiersCreation($event)">
          </app-fichiers-crea-card>
        </div>
      </mat-grid-tile>

      <!-- Fichiers de créations purgé -->
      <mat-grid-tile rowspan="4" class="gtmt-grid-tile-disabled" *ngIf="creationFilePresent && campagne.isPurged">
        <div class="gtmt-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Fichiers de créations </span>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <app-fichiers-crea-card [IdCampagne]="campagne.IdCampagne" (FichiersCreation)="getFichiersCreation($event)">
          </app-fichiers-crea-card>
        </div>
      </mat-grid-tile>
    </div>

    <!-- Campagne PAO -->
    <div *ngIf="data.campagne.creationType === 'chili'" class="gtmt-dupli-pao">
      <!-- Support -->
      <mat-grid-tile [colspan]="campagne.Enveloppe.support ? 2 : 1" rowspan="4" class="gtmt-grid-tile"
        (click)="onCheckSupport()">
        <div class="gtmt-card-header" style="cursor: pointer" [mdePopoverTriggerFor]="appPopover"
          (mouseenter)="$event.stopPropagation()">
          <span class="gtmt-checkbox-label"> Support </span>
          <mat-checkbox formControlName="Support" (click)="$event.stopPropagation()">
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-card-content">
          <!-- Support -->
          <div class="gtmt-dupli-produit">
            <app-support-info-card [support]="campagne.Support"></app-support-info-card>
          </div>

          <!-- Enveloppe -->
          <div class="gtmt-dupli-produit" *ngIf="campagne.Enveloppe.support">
            <app-support-info-card [support]="campagne.Enveloppe"></app-support-info-card>
          </div>
        </div>
      </mat-grid-tile>

      <!-- FrequenceEnvoi -->
      <mat-grid-tile colspan="1" rowspan="2" class="gtmt-grid-tile" (click)="
          filters
            .get('FrequenceEnvoi')
            .setValue(!filters.get('FrequenceEnvoi').value)
        ">
        <div class="gtmt-small-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Fréquence </span>
          <mat-checkbox formControlName="FrequenceEnvoi" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-small-card-content">
          <span class="gtmt-field-label"> {{ campagne["FrequenceEnvoi"] }} </span>
        </div>
      </mat-grid-tile>

      <!-- Placeholder -->
      <mat-grid-tile colspan="1" rowspan="4" *ngIf="!campagne.Enveloppe.support" style="border: none">
      </mat-grid-tile>

      <!-- CodeVitesse -->
      <mat-grid-tile colspan="1" rowspan="2" class="gtmt-grid-tile" (click)="
          filters.get('CodeVitesse').setValue(!filters.get('CodeVitesse').value)
        ">
        <div class="gtmt-small-card-header" style="cursor: pointer">
          <span class="gtmt-checkbox-label"> Vitesse </span>
          <mat-checkbox formControlName="CodeVitesse" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-small-card-content">
          <span class="gtmt-field-label"> {{ campagne["CodeVitesse"] }} </span>
        </div>
      </mat-grid-tile>

      <!-- Fichier de ciblage -->
      <mat-grid-tile colspan="1" rowspan="4" *ngIf="
      _GlobalDataService.isStandaloneContext() &&
      campagne.importFile.length > 4 &&
      !campagne.isPurged
      " class="gtmt-grid-tile">
        <div class="gtmt-big-card-header" style="cursor: pointer" (click)="
        filters
          .get('ImportCiblage')
          .setValue(!filters.get('ImportCiblage').value)
      ">
          <span class="gtmt-checkbox-label"> Fichiers de ciblage </span>
          <mat-checkbox formControlName="ImportCiblage" (click)="$event.stopPropagation()"></mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-big-card-content">
          <app-fichier-ciblage-card [campagne]="campagne" [isPurged]="campagne.isPurged"></app-fichier-ciblage-card>
        </div>
      </mat-grid-tile>

      <!-- Fichier de ciblage purgé -->
      <mat-grid-tile colspan="1" rowspan="4" *ngIf="
          _GlobalDataService.isStandaloneContext() &&
          !isEmpty(campagne) &&
          contactFilePresent &&
          campagne.isPurged
        " class="gtmt-grid-tile-disabled">
        <div class="gtmt-big-card-header">
          <span class="gtmt-checkbox-label"> Fichiers de ciblage </span>
        </div>
        <mat-divider></mat-divider>
        <div class="gtmt-big-card-content">
          <app-fichier-ciblage-card [campagne]="campagne" [isPurged]="true"></app-fichier-ciblage-card>
        </div>
      </mat-grid-tile>

      <!-- Studio Création -->
      <mat-grid-tile
        [colspan]="campagne.importFile.length > 4 ? 2 : 3"
        rowspan="4"
        class="tile gtmt-grid-tile"
        [class.gtmt-grid-tile-disabled]="campagne.isPurged"
      >
        <div
          class="header"
          style="cursor: pointer"
          (click)="
            !campagne.isPurged &&
              isChiliValid &&
              filters
                .get('ChiliCreation')
                .setValue(!filters.get('ChiliCreation').value)
          "
        >
          <span class="gtmt-checkbox-label">
            Studio de création, personnalisation & adresses
          </span>
          <mat-checkbox
            *ngIf="!campagne.isPurged"
            formControlName="ChiliCreation"
            (click)="$event.stopPropagation()"
            [disabled]="!isChiliValid"
          >
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div 
          class="content" 
          [matTooltip]="
            (!campagne.isPurged && !loadingChiliStatus && !isChiliValid) 
            ? 'La création n\'est plus disponible pour cette campagne.' 
            : undefined"
        >
          <div *ngIf="loadingChiliStatus" class="spinner">
            <mat-spinner diameter="32"></mat-spinner>
          </div>
          <app-thumbnail-chili-recap
            *ngIf="!_GlobalDataService.Campagne.isPurged"
            [hidden]="loadingChiliStatus"
            [class.half]="campagne.CodeTypologie !== 'ITE001'"
            [idCampaign]="campagne.IdCampagne"
            [selected]="
              campagne.CodeTypologie !== 'ITE001' ? 'classic' : 'letterEnvelop'
            "
            [isPurged]="campagne.isPurged"
          ></app-thumbnail-chili-recap>
        </div>
      </mat-grid-tile>
    </div>
  </mat-grid-list>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button color="white" mat-dialog-close>Retour</button>
  <button mat-raised-button color="yellow" (click)="duplicateCampagne(campagne!)" [disabled]="!canDuplicate">
    Valider la sélection
  </button>
</mat-dialog-actions>

<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapAutoCaptureEnabled]="false">
  <mat-card class="gtmt-popover-card" *ngIf="!canDuplicateProduit">
    <mat-card-header> Choix du produit indisponible </mat-card-header>
    <mat-card-content>
      <p>
        Le produit utilisé dans cette campagne n'existe plus, vous ne pouvez pas
        le dupliquer.
      </p>
    </mat-card-content>
  </mat-card>
</mde-popover>