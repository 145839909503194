import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Annonceur } from '@core/models/annonceur.model';
import { CampagneExt } from '@core/models/campagne-ext.model';
import { AnnonceurService, CampagneDetailsService, GlobalDataService } from '@core/services';
import { CampagneService } from '@core/services/campagne.service';



@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  url: string = "";

  IdCampagne: string = "";

  constructor(public _GlobalDataService: GlobalDataService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private activeRoute: ActivatedRoute,
    private _CampagneService: CampagneService,
    private _AnnonceurService: AnnonceurService,
    private _CampagneDetailsService: CampagneDetailsService
  ) {
    this._GlobalDataService.CONTEXT = "ELOQUA";

    this.activeRoute.queryParams.subscribe(params => {
      let queryParams: Params = {
        AppId:  params['AppId'],
        AssetId: params['AssetId'],
        InstallId:  params['InstallId'], //environment.InstallIdv10,s
        InstanceId: params['InstanceId'],
        SiteId: params['SiteId'],
        SiteName: params['SiteName'],
        UserId: params['UserId'],
        UserName: params['UserName'],
        oauth_consumer_key: params['oauth_consumer_key'],
        oauth_nonce: params['oauth_nonce'],
        oauth_signature: params['oauth_signature'],
        oauth_signature_method: params['oauth_signature_method'],
        oauth_timestamp: params['oauth_timestamp'],
        oauth_version: params['oauth_version'],
      };
      this._GlobalDataService.objectParams = queryParams; // Print the parameter to the console.
      console.log('this._GlobalDataService.objectParams!', this._GlobalDataService.objectParams);
    });
  }

  async ngOnInit() {
    if (Object.keys(this._GlobalDataService.objectParams).length !== 0) {
        this._GlobalDataService.refreshEloquaToken()
        .subscribe(async (result) => {
          console.log("result is ", result);
          if (result) {
            console.log('id token ', result.id_token);
            this._GlobalDataService.setJwtoken(result.id_token);
          //get Annonceur
          this._AnnonceurService.get()
            .subscribe(
              response => this.successResponse(response),
              error => this.errorResponse(error)
            );

          } else {
            this.router.navigate(['/erreur'], {
              queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
            });
          }
        },
          (error) => {
            console.debug("isAuth fail", error);
          }
        );
    } else {
      this.router.navigate(['/erreur'], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
      });
    }
  }

  successResponse(response: { annonceur: Annonceur; }) {
    console.log("Annonceur : ");
    console.log(response.annonceur);
    //l'annonceur n'existe pas dans la base
    this._GlobalDataService.Annonceur = response.annonceur;
    if (response.annonceur.IdAnnonceur === undefined) {

      this.router.navigate(['/campagne/home', { outlets: { 'navview': ['parametrage'] } }], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true
      }).then((e) => {
        if (e) {
          console.log("Navigation is successful!");
        } else {
          console.log("Navigation has failed!");
        }
      });
    } else {
      //récupérer la campagne en cours ou bien créer une (côté front) et la retourner
      this._CampagneService.getCurrentCampagne()
        .subscribe(
          response => this.SuccessCurrentCampagne(response.campagne),
          error => this.ErrorCurrentCampagne(error)
        );
    }
  }

  errorResponse(error: any) {
    console.log('StartComponent', error);
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge"
    });
  }

  navigate(view: string, IdCampagne: string) {
    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }

    this.router.navigate([path, { outlets: { 'navview': [view, IdCampagne] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }


  SuccessCurrentCampagne(campagne) {
    // this._AuditLogService.add(campagne.IdCampagne, 0, `Accès annonceur (${campagne.IdCampagne})`).subscribe();
    if (typeof campagne === 'object') {

      console.log('current Annonceur : ', this._GlobalDataService.Annonceur);

      this._GlobalDataService.CampagneExt = new CampagneExt();

      this._GlobalDataService.Campagne = campagne;

      console.log('current Campagne : ', this._GlobalDataService.Campagne);

      console.log('current CampagneExt : ', this._GlobalDataService.CampagneExt);


      console.log('- Id Campagne : ', this._GlobalDataService.Campagne.IdCampagne);

      console.log("- Statut Campagne", campagne.StatutCampagne);

      console.log("- Code Statut Campagne", campagne.CodeStatut);

      this._CampagneDetailsService.gotoDetailCampagne();

    } else {
      //une erreur est survenue => redirection page erreur
      this._CampagneDetailsService.navigate('campagne-details', this._GlobalDataService.Campagne.IdCampagne);
    }
    this._GlobalDataService.campagneBeforeChanges = Object.assign({}, this._GlobalDataService.Campagne);

    console.log("Downloading logo");
    this._AnnonceurService.downloadLogo()
      .subscribe((response) => {
        console.log("Download logo response", response);
        if (response) {
          var urlCreator = window.URL;
          this._GlobalDataService.logo = this.domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response))
        }
        else {
          this._GlobalDataService.logo = null;
        }
      }, error => {
        console.log("Download logo error", error);
        this._GlobalDataService.logo = null;
      });
  }

  ErrorCurrentCampagne(error: string) {
    //une erreur est survenue => redirection page erreur
    console.log('StartComponent : ErrorCurrentCampagne', error)
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    });
    // this.isLoaded = true;
  }
}

