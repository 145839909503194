import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-campagne-stepper-nav',
  templateUrl: './campagne-stepper-nav.component.html',
  styleUrls: ['./campagne-stepper-nav.component.scss'],
})
export class CampagneStepperNavComponent {
  @Input()
  public prevDisplay = false;

  @Input()
  public nextDisplay = false;

  @Input()
  public validateDisplay = false;

  @Input()
  public prevDisable = false;

  @Input()
  public nextDisable = false;

  @Input()
  public validateDisable = false;

  @Input()
  public validateLoading = false;

  @Output()
  public previous = new EventEmitter<boolean>();

  @Output()
  public next = new EventEmitter<boolean>();

  @Output()
  public validate = new EventEmitter<boolean>();

  public previousEvent() {
    this.previous.emit(true);
  }

  public nextEvent() {
    this.next.emit(true);
  }

  public validateEvent() {
    this.validate.emit(true);
  }
}
