import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

export interface PasswordControlData {
  oldPassword?: string;
  newPassword: string;
}

@Component({
  selector: 'app-password-control',
  templateUrl: './password-control.component.html',
  styleUrls: ['./password-control.component.scss'],
})
export class PasswordControlComponent implements OnInit, OnDestroy {
  @Input()
  public type: 'change' | 'reset' = 'change';

  @Input()
  public firstName: string = null;

  @Input()
  public lastName: string = null;

  @Input()
  public successEvent: Observable<string>;

  @Input()
  public errorEvent: Observable<string>;

  @Input()
  public isSending: boolean;

  @Output()
  public passwordSend: EventEmitter<PasswordControlData> =
    new EventEmitter<PasswordControlData>();

  public hideOldPassword: boolean = true;
  public hideNewPassword: boolean = true;
  public hideConfirm: boolean = true;
  public disableButton: boolean = true;
  public passwordStrenght = 0;

  public minLength = 8;
  public maxLength = 50;
  private forbiddenWords = [
    'capmailing',
    'cap mailing',
    'eloqua',
    'salesforce',
    'la poste',
    'laposte',
    'courrier',
    'publicité',
    'publicite',
  ];

  public formGroup: FormGroup;

  public successMessage: string = undefined;
  public errorMessage: string = undefined;
  private successEventSubscription: Subscription;
  private errorEventSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.formGroup = this.getFormGroup();
    if (this.successEvent) {
      this.successEventSubscription = this.successEvent.subscribe((message) => {
        this.errorMessage = undefined;
        this.successMessage = message;
        this.init();
      });
    }
    if (this.errorEvent) {
      this.errorEventSubscription = this.errorEvent.subscribe((message) => {
        this.errorMessage = message;
        this.successMessage = undefined;
      });
    }
  }

  ngOnDestroy() {
    this.successEventSubscription?.unsubscribe();
    this.errorEventSubscription?.unsubscribe();
  }

  public init() {
    this.hideOldPassword = true;
    this.hideNewPassword = true;
    this.hideConfirm = true;
    this.formGroup = this.getFormGroup();
  }

  public validate(): void {
    // Reset form errors
    this.formGroup.controls['passwordConfirm'].setErrors(null);

    // Send signal with passwords
    this.passwordSend.emit({
      oldPassword: this.formGroup.value.oldPassword,
      newPassword: this.formGroup.value.password,
    });
  }

  public isFormInvalid(withError?: boolean): boolean {
    if (withError) {
      this.errorMessage = undefined;
    }
    const oldPassword: string = this.formGroup.value.oldPassword;
    const password: string = this.formGroup.value.password;

    // Vérifie la robustesse du mot de passe
    if (this.passwordStrenght !== 100) {
      return true;
    }

    // Vérifie la présence de l'espace
      if (password.indexOf(' ') != -1) {
        this.errorMessage =
          'Vous ne pouvez pas mettre d\'espace dans votre mot de passe.';
        return true;
      }

    // Vérifie que l'ancien mot de passe et le nouveau ne correspondent pas
    if (oldPassword.length > 4 && password === oldPassword) {
      if (withError) {
        this.errorMessage =
          "Le mot de passe ne peut pas être similaire à l'ancien";
      }
      return true;
    }

    // Vérifie que le nom d'utilisateur n'est pas présent
    if (
      this.lastName &&
      this.formGroup.value.password
        .toLowerCase()
        .indexOf(this.lastName.toLowerCase()) !== -1
    ) {
      if (withError) {
        this.errorMessage =
          'Vous ne pouvez pas créer de mot de passe contenant votre nom.';
      }
      return true;
    }

    if (
      this.firstName &&
      this.formGroup.value.password
        .toLowerCase()
        .indexOf(this.firstName.toLowerCase()) !== -1
    ) {
      if (withError) {
        this.errorMessage =
          'Vous ne pouvez pas créer de mot de passe contenant votre prénom.';
      }
      return true;
    }

    // vérifier si ancien MPD est vide
    if (
      this.type === 'change' &&
      (!this.formGroup.value.oldPassword ||
        this.formGroup.value.oldPassword === '')
    ) {
      return true;
    }

    // Vérifie la présence de mots interdits
    for (const word of this.forbiddenWords) {
      if (password.toLowerCase().indexOf(word) != -1) {
        if (withError) {
          this.errorMessage =
            'Vous ne pouvez pas créer de mot de passe contenant le terme [' +
            word +
            '].';
        }
        return true;
      }
    }

    // vérifier si confirmation MDP est vide
    if (
      !this.formGroup.value.passwordConfirm ||
      this.formGroup.value.passwordConfirm === ''
    ) {
      return true;
    }

    // Check confirm passwords are identical
    if (
      !(this.formGroup.value.password === this.formGroup.value.passwordConfirm)
    ) {
      if (withError) {
        this.errorMessage = 'Les mots de passe ne sont pas identiques';
      }
      return true;
    }

    if (withError) {
      this.formGroup.controls['passwordConfirm'].setErrors(null);
      this.errorMessage = undefined;
    }
    return false;
  }

  private getFormGroup(): FormGroup {
    return new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(this.minLength),
        Validators.maxLength(this.maxLength),
      ]),
      passwordConfirm: new FormControl('', [
        Validators.required,
        Validators.minLength(this.minLength),
        Validators.maxLength(this.maxLength),
      ]),
    });
  }

  public keyup() {
    this.successMessage = undefined;
    this.isFormInvalid(true);
  }
}
