import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationEnd, Router } from '@angular/router';
import { Enveloppe } from '@core/models/enveloppe.model';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';
import { ProduitService } from '@core/services/produit.service';

@Component({
  selector: 'app-selection-enveloppe-card',
  templateUrl: './selection-enveloppe-card.component.html',
  styleUrls: ['./selection-enveloppe-card.component.scss']
})
export class SelectionEnveloppeCardComponent implements OnInit {

  @Input() EditMode?: boolean = true;
  @Input() showFormat?: boolean = true;
  @Input() showEnveloppe?: boolean = true;
  @Input() showDimension?: boolean = true;
  @Input() showGrammage?: boolean = true;
  @Input() showFinition?: boolean = true;
  @Input() showEncre?: boolean = true;

  enveloppe: Enveloppe = new Enveloppe();

  supportSelected: boolean = false;

  navigationSubscription;

  navigationOption = {};

  actiontitle: string = "Configurer l’élément";


  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    private _ProduitService: ProduitService,
    private _CampagneTypologieService: CampagneTypologieService,

  ) {
    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        console.log('Enveloppe NavigationEnd is successful');

        this.initialise();
      }
    });
  }

  initialise() {
    this.navigationOption = { typologie: 'enveloppe' };
    this.enveloppe.enveloppe = this._GlobalDataService.getLibelleProduit('CodeEnveloppe', this._GlobalDataService.Campagne.CodeEnveloppe, 'enveloppe')
    this.enveloppe.dimension = this._GlobalDataService.getLibelleProduit('CodeDimensionsEnveloppe', this._GlobalDataService.Campagne.CodeDimensionsEnveloppe, 'DimensionsEnveloppe')
    this.enveloppe.grammage = this._GlobalDataService.getLibelleProduit('CodeGrammageEnveloppe', this._GlobalDataService.Campagne.CodeGrammageEnveloppe, 'GrammageEnveloppe')
    this.enveloppe.finition = this._GlobalDataService.getLibelleProduit('CodeFinitionEnveloppe', this._GlobalDataService.Campagne.CodeFinitionEnveloppe, 'FinitionEnveloppe')
    this.enveloppe.Encre = this._GlobalDataService.getLibelleProduit('CodeEncreEnveloppe', this._GlobalDataService.Campagne.CodeEncreEnveloppe, 'EncreEnveloppe');

    this.showFormat = this.enveloppe.enveloppe ? true : false;
    this.showDimension = this.enveloppe.dimension ? true : false;;
    this.showGrammage = this.enveloppe.grammage ? true : false;
    this.showFinition = this.enveloppe.finition ? true : false;
    this.showEncre = this.enveloppe.Encre ? true : false;
    this.supportSelected =  this._GlobalDataService.Campagne.CodeEncreSupport ? true : false;


    this.actiontitle ='Configurer l’élément'

    if (this.showFormat && this.showDimension && this.showGrammage && this.showFinition && this.showEncre ) {
      this.actiontitle ='Elément configuré'
    }

  }


  ngOnInit(): void {
    this.initialise();
  }

  changeEnveloppe(): void {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.enveloppe, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeDimension(): void {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.dimension, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeGrammage(): void {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.grammage, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeFinition(): void {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.finition, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeEncre() {
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.encre, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  Configurer() {
    if (!this.supportSelected ) {
      this._GlobalDataService.alert("Veuillez configurer la lettre d'abord.");
      return;
    }
    this._GlobalDataService.Campagne.isDirty = true;
    this._CampagneTypologieService.navigate(navPaths.enveloppe, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }



}
