import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Adresse } from '@core/models/adresse.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { Annonceur } from '@core/models/annonceur.model';
import { Campagne } from '@core/models/campagne.model';
import { AnnonceurService, AuthenticationService, GlobalDataService } from '@core/services';
import { AdresseService } from '@core/services/adresse.service';
import { CampagneService } from '@core/services/campagne.service';
import { ProduitService } from '@core/services/produit.service';
import { forkJoin, from } from 'rxjs';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {




  constructor(
    public router: Router,
    private _GlobalDataService: GlobalDataService,
  ) { }

  ngOnInit(): void {

    // this.router.navigate(["/standalone/home", { outlets: { 'navview': ['suivi-campagnes', this._GlobalDataService.Annonceur.IdAnnonceur] } }], {

    this.router.navigate(["/standalone/loginpage", { outlets: { 'loginview': ['login'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }


  gotoStep(view: string){
    let path = "/standalone/loginpage";
    this.router.navigate([path, {outlets : {'loginview' : view}}]);
  }


}
