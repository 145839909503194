<mat-sidenav-container fullscreen>
  <!-- Left menu -->
  <mat-sidenav
    mode="side"
    #sidenav
    opened
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    autosize
    opened="true"
  >
    <div class="logo-container">
      <div class="logo"></div>
    </div>

    <div class="ml-container">
      <span> Mentions légales </span>
    </div>
  </mat-sidenav>

  <!-- Content -->
  <mat-sidenav-content cols="2">
    <app-password-control
      [type]="'reset'"
      [errorEvent]="errorSubject.asObservable()"
      [isSending]="isSendingPassword"
      (passwordSend)="changePassword($event)"
    >
      <div class="header" header>
        <p class="title">
          Créer votre nouveau mot de passe<br />Cap&nbsp;Mailing&nbsp;Plateforme
        </p>
        <p class="text">
          Entrez votre nouveau mot de passe. Il doit être différent de vos
          précédents mot de passe.
        </p>
      </div>
    </app-password-control>
  </mat-sidenav-content>
</mat-sidenav-container>
