<!-- Classique creation type -->
<mat-accordion
  *ngIf="this._GlobalDataService.Campagne.creationType !== 'chili'"
  multi="true"
>

  <mat-expansion-panel expanded="true" *ngIf="_GlobalDataService.Campagne.importOption != 'sftp'">
      <!-- Pavé adresse Eloqua and Standalone -->
    <div *ngIf="
      _GlobalDataService.isEloquaContext() ||
      (_GlobalDataService.isStandaloneContext() &&
        _GlobalDataService.Campagne.importOption === 'upload')
    ">
      <mat-expansion-panel-header>
        <mat-panel-title> Pavé adresse destinataires </mat-panel-title>
      </mat-expansion-panel-header>
      <app-campagne-adresses-destinataires></app-campagne-adresses-destinataires>
    </div>
  
    <!-- Pavé adresse Salesforce -->
    <div *ngIf="_GlobalDataService.isSalesforceContext()">
      <mat-expansion-panel-header>
        <mat-panel-title> Pavé adresse destinataires </mat-panel-title>
      </mat-expansion-panel-header>
      <app-campagne-adresses-destinataires-sf></app-campagne-adresses-destinataires-sf>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    expanded="true"
    *ngIf="
      PaveAdresseSelect() ||
      (_GlobalDataService.isStandaloneContext() &&
        _GlobalDataService.Campagne.importOption != 'upload')
    "
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Options de retour </mat-panel-title>
    </mat-expansion-panel-header>

    <app-campagne-adresses-options-retour></app-campagne-adresses-options-retour>

    <app-campagne-adresse-retour
      *ngIf="_GlobalDataService.Campagne.OptionRetour == 'AdresseRetour'"
    ></app-campagne-adresse-retour>

    <app-campagne-alliage
      *ngIf="_GlobalDataService.Campagne.OptionRetour == 'Alliage'"
    ></app-campagne-alliage>
  </mat-expansion-panel>

  <mat-expansion-panel
    expanded="true"
    *ngIf="_GlobalDataService.Campagne.Alliage"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Gestion du logo </mat-panel-title>
    </mat-expansion-panel-header>

    <app-campagne-gestion-logo></app-campagne-gestion-logo>
  </mat-expansion-panel>
</mat-accordion>

<!-- iframe Chili -->
<div>
  <app-chili-iframe-address
    *ngIf="this._GlobalDataService.Campagne.creationType === 'chili'"
    [idCampagne]="this._GlobalDataService.Campagne.IdCampagne"
  ></app-chili-iframe-address>
  <app-purge-message
    *ngIf="_GlobalDataService.Campagne.isPurged"
    class="app-purge-message"
    [message]="messagePurge"
  ></app-purge-message>
</div>

<!-- Stepper navigation  -->
<app-campagne-stepper-nav
  [prevDisplay]="true"
  [nextDisplay]="true"
  (previous)="goToPrevious()"
  (next)="submit()"
></app-campagne-stepper-nav>
