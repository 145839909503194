export class ThumbnailChiliResponse {
  urls: Array<ThumbnailChili>;
  name: ThumbnailName;
  pageNum: number;
}

export enum ThumbnailName {
  Lettre = 'Lettre',
  Enveloppe = 'Enveloppe',
}

export enum ThumbnailType {
  Small = 'thumb',
  Large = 'full',
}

export class ThumbnailChili {
  type: ThumbnailType;
  url: string;
}
