import { Component, OnInit, ViewChild } from '@angular/core';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { ChiliReturnAddress } from '@core/models/chili-get-creation-details.model';
import { GlobalDataService, PaoService } from '@core/services';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
  selector: 'app-suivi-campagne-recapilatif',
  templateUrl: './suivi-campagne-recapilatif.component.html',
  styleUrls: ['./suivi-campagne-recapilatif.component.scss'],
})
export class SuiviCampagneRecapilatifComponent implements OnInit {
  @ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger;

  public personalisationChampsFusion: ChampsFusion[] = [];
  public adressesDestinatairesChampsFusion: ChampsFusion[] = [];

  // PAO : Chili
  public isLoadingChiliDetails = false;
  public adresseRetour = new AdresseRetour();

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _paoService: PaoService
  ) {
    console.log(this.personalisationChampsFusion);
    console.log(this.adressesDestinatairesChampsFusion);
  }

  ngOnInit(): void {
    this.personalisationChampsFusion =
      this._GlobalDataService.CampagneExt.ChampsFusion.filter(
        (cf) => cf.IsPaveAdresse == '0' || cf.IsPaveAdresse == 'false'
      );
    this.adressesDestinatairesChampsFusion =
      this._GlobalDataService.CampagneExt.ChampsFusion.filter(
        (cf) => cf.IsPaveAdresse == '1' || cf.IsPaveAdresse == 'true'
      );

    if (this._GlobalDataService.Campagne.creationType === 'chili') {
      this.getChiliCreationDetails();
    }
  }

  public getChiliCreationDetails() {
    this.isLoadingChiliDetails = true;
    const idCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._paoService.getDetails(idCampagne).subscribe({
      next: (res) => {
        this.isLoadingChiliDetails = false;
        if (res.champsFusions) {
          this.personalisationChampsFusion = res.champsFusions.map(
            (v) => {
              return {
                Id: '',
                ChampFusion: v.ChampFusion,
                internalName: v.internalName,
                IsPaveAdresse: '' + v.IsPaveAdresse,
              } as ChampsFusion;
            }
          );
        }
        if (res.champsAdresses) {
          this.adressesDestinatairesChampsFusion = res.champsAdresses.map(
            (v) => {
              return {
                Id: '',
                ChampFusion: v.ChampFusion,
                internalName: v.internalName,
                IsPaveAdresse: '' + v.IsPaveAdresse,
              } as ChampsFusion;
            }
          );
        }
        if (res.return_address) {
          this.adresseRetour = this.mapChiliReturnAddressToAddressRetour(
            res.return_address
          );
        }
        this._GlobalDataService.Campagne.Alliage = res.alliage;
      },
      error: (err) => {
        this.isLoadingChiliDetails = false;
        console.log('(Error) adresseRetour Error::', err);
      },
    });
  }

  private mapChiliReturnAddressToAddressRetour(returnAdress: ChiliReturnAddress) {
    const adresseRetour = new AdresseRetour();
    adresseRetour.IdAdresse = '' + returnAdress.id.toString();
    adresseRetour.NomAdresse = returnAdress.nom;
    adresseRetour.RaisonSociale = returnAdress.raisonSociale;
    adresseRetour.Service = returnAdress.service;
    adresseRetour.EntreeBatimentZI = returnAdress.entreeBatimentZI;
    adresseRetour.Numero = returnAdress.numero;
    adresseRetour.MentionSpeciale = returnAdress.mentionSpeciale;
    adresseRetour.Ville = returnAdress.ville;
    adresseRetour.CodePostal = returnAdress.codePostal;
    adresseRetour.Favori = returnAdress.favori ? "true" : "false";
    return adresseRetour;
  }
}
