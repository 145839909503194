<mat-card class="mat-card-suivi-telech">
  <mat-card-content>
    <mat-grid-list [cols]="2" rowHeight="5rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-jc-space-between" colspan="1">
        <span style="font-weight: bold;">Fichier de ciblage : {{_GlobalDataService.Campagne.importFile}}</span>
      </mat-grid-tile>

      <mat-grid-tile class="gtmt-ai-end gtmt-jc-end" colspan="1">
        <span *ngIf="(!_GlobalDataService.Campagne.isPurged)">
          <button mat-raised-button color="blue" (click)="downloadFile()">
            Télécharger
          </button>
        </span>

        <span *ngIf="(_GlobalDataService.Campagne.isPurged)" [mdePopoverTriggerFor]="purgePopover" (mouseenter)="$event.stopPropagation()" style="cursor:pointer">
          <button mat-raised-button color="blue" [disabled]="true">
            Télécharger
          </button>
        </span>

      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>

  <mde-popover #purgePopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapAutoCaptureEnabled]="false" mdePopoverPositionY="above">
    <mat-card class="gtmt-popover-card">
      Le fichier de ciblage a été purgé
    </mat-card>
  </mde-popover>
</mat-card>
