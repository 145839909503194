import { Component, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalDataService } from '@core/services/global-data.service';

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

export class HelpComponent implements OnInit {
  srcHelp;// = this.urlBase+this._GlobalDataService.referenceDocHelp
  urlBase = 'assets/Cap_Mailing_OnlineHelp.htm';
  

  constructor(private dialog: MatDialog,
    public _GlobalDataService: GlobalDataService,

  ) {
    this.srcHelp = this.urlBase+this._GlobalDataService.referenceDocHelp;

  }

  ngOnInit(): void {
  }

  OpenHelpFile() {
    let config = {
      width: '940px',
      height: '440px',
      panelClass: 'gtmt-panel'
    };
    this.openDialog(HelpComponent, config);
  }

  async openDialog(componentType: Type<any>, config: any) {

    const dialogRef = this.dialog.open(componentType, config);

    dialogRef.afterOpened().subscribe(result => {
    });

    dialogRef.beforeClosed().subscribe(async result => {
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
