import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@core/services';
import { ListCampagneComponent, ParametrageComponent } from 'app/campagne/components';
import { BootstrapComponent } from './components/bootstrap/bootstrap.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [

  { path: '', 
    component: BootstrapComponent, 
    data: { context: 'SALESFORCE' }
  },
  { path: 'home', 
    component: HomeComponent, 
    data: { context: 'SALESFORCE',  },
    // canActivate: [AuthGuardService],
  },

  // { path: 'creation-campagne', component: CreationCampagneComponent, canActivate: [StdUserRouteAccessService]},

  { path: 'dashboard',
    component: ListCampagneComponent, 
    data: { context: 'SALESFORCE' } ,
    canActivate: [AuthGuardService],    
  },
  { path: 'parametrage', 
    component: ParametrageComponent, 
    canActivate: [AuthGuardService],
    data:  { context: 'SALESFORCE' } 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesforceRoutingModule { }
