import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './api/start/start.component';
import { AppComponent } from './app.component';
import { MessageConfirmationComponent,  ParametrageComponent } from './campagne/components';
import { ErreurComponent } from './erreur/erreur.component';



const routes: Routes = [
  {
    path: 'standalone',
    // loadChildren: () => import('./standalone/standalone-routing.module').then(m => m.StandaloneRoutingModule),
    loadChildren: () => import('./standalone/standalone.module').then(m => m.StandaloneModule),

  },
  {
    path: 'salesforce',
    loadChildren: () => import('./salesforce/salesforce-routing.module').then(m => m.SalesforceRoutingModule),
  },

  {
    path: 'administration',
    // loadChildren: () => import('./administration/administration-routing.module').then(m => m.AdministrationRoutingModule),
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
  },

  {
    path: 'eloqua',
    loadChildren: () => import('./eloqua/eloqua-routing.module').then(m => m.EloquaRoutingModule),
  },

  // Only For testing purpose
  {
    path: 'campagne',
    loadChildren: () => import('./campagne/campagne-routing.module').then(m => m.CampagneRoutingModule),
  },

  // A réorganiser
  { path: 'api/start', component: StartComponent },

  { path: 'erreur', component: ErreurComponent },
  { path: '', redirectTo: '/salesforce', pathMatch: 'full' },
  { path: '**', component: AppComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, initialNavigation: 'enabled', onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
