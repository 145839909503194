import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataService } from '@core/services';

@Component({
  selector: 'app-change-password-check',
  templateUrl: './change-password-check.component.html',
  styleUrls: ['./change-password-check.component.scss']
})
export class ChangePasswordCheckComponent implements OnInit {

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private _GlobalDataService: GlobalDataService,
  ) { }

  ngOnInit(): void {
  }
  gotorequestReset() {
    const path='change-password-request';
    this.router.navigate([{ outlets: { 'loginview': path } }],
    {
      relativeTo: this.activatedRoute.parent,
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true,
    }).then((e) => {
      if (e) {
        console.log("Navigation to " + path + " is successful!");
      } else {
        console.log("Navigation to " + path + " has failed!");
      }
    });
  }
}
