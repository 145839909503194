<cdk-virtual-scroll-viewport appendOnly itemSize="44">   
    <mat-list>
        <mat-list-item *cdkVirtualFor="let cf of cfs">
            <span> {{ "Champ "+ cf.Sequence}} </span>
            <span [mdePopoverTriggerFor]="appPopover" (mouseenter)="$event.stopPropagation()" style="cursor:pointer"> {{
                cf.ChampFusion}} </span>
            <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false"
                [mdeFocusTrapAutoCaptureEnabled]="false">
                <mat-card class="gtmt-popover-card">
                    <mat-card-header> {{ "Champ "+ cf.Sequence}}
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            {{ cf.ChampFusion}}
                        </p>
                    </mat-card-content>
                </mat-card>
            </mde-popover>
        </mat-list-item>        
    </mat-list>
</cdk-virtual-scroll-viewport>