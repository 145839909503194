import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  activatedRouteSub: Subscription;
  constructor(private router: Router,
    private _authenticationService: AuthenticationService,
    private _GlobalDataService: GlobalDataService,
    ) {
     }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  {

      //console.log(route);

      if ( route.data['context'])  this._GlobalDataService.CONTEXT = route.data['context'];

      return new Observable<boolean>(sub => {
        this._authenticationService.isAuthenticated()
          .subscribe(
            (res) => {
              console.log("isAuthenticated ? ", res);
              if (res) {
                sub.next(true);
                sub.complete();
              }
              else {
                // this.router.navigate(["/standalone/login"], {
                //   queryParams: {
                //     return: state.url
                //   }
                // });
                sub.next(false);
                sub.complete();
              }
            },
            () => {
              // this.router.navigate(["/standalone/login"], {
              //   queryParams: {
              //     return: state.url
              //   }
              // });
              sub.next(false);
              sub.complete();
            }
          )
      });


    // if (!this.authService.isUserLoggedIn()) {
    //   alert('You are not allowed to view this page. You are redirected to login Page');

    //   this.router.navigate(["login"], { queryParams: { retUrl: route.url } });
    //   return false;

    //   //var urlTree = this.router.createUrlTree(['login']);
    //   //return urlTree;
    // }

  }

}
