import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,  FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { CampagneAdressesService, GlobalDataService } from '@core/services';
import { AdresseService } from '@core/services/adresse.service';
import { CampagneService } from '@core/services/campagne.service';
import { debounceTime, distinctUntilChanged, forkJoin, from, fromEvent, map } from 'rxjs';

@Component({
  selector: 'app-adresse-retour-favori',
  templateUrl: './adresse-retour-favori.component.html',
  styleUrls: ['./adresse-retour-favori.component.scss']
})
export class AdresseRetourFavoriComponent implements OnInit {


  @ViewChild('adresseRetourRef') private adresseRetourRef!: ElementRef;


  availableRecords: Array<AdresseRetour> = [];
  filteredAvailableRecords: Array<AdresseRetour> = [];

  public controls: any;

  public form!: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    public _GlobalDataService: GlobalDataService,
    private _AdresseService: AdresseService,
    private _CampagneService: CampagneService,
    public _campagneAdressesService: CampagneAdressesService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    let availableAdresses$ = from(this._AdresseService.list(this._GlobalDataService.Annonceur.IdAnnonceur));
    let joined$ = forkJoin({availableAdresses: availableAdresses$});
    joined$.subscribe(
      (result: any) => {
        // availableAdresses
        this._GlobalDataService.availableAdresses = result.availableAdresses.adresse;
        this.availableRecords = this._GlobalDataService.availableAdresses;
        this.filteredAvailableRecords =  this.availableRecords;
      },
      error => { console.log(error); }
    );
  }



  ngAfterViewInit() {
    // streams
    const keyup2$ = fromEvent(this.adresseRetourRef.nativeElement, 'keyup');

    // wait .5s between keyups to emit current value
    keyup2$
      .pipe(
        map((event: any) => event.currentTarget.value),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filter(value)),
      )
      .subscribe((result) => {
        console.log(result);
        this.filteredAvailableRecords = result;
      });
  }

  private filter(adresseRetour: string): any[] {
    return this.availableRecords
      .filter(adresse => adresse.NomAdresse.toLowerCase().includes(adresseRetour));
  }

  drop(event: CdkDragDrop<any[]>) {
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Drap and Drop left to Right
      const currItem = event.previousContainer.data[event.previousIndex];
      if (event.previousContainer.id < event.container.id) {
        this.select(currItem);
      }
      else {
        this.unselect(currItem);
      }
    }
  }


  select(adresse: AdresseRetour) {
    transferArrayItem(
      this._GlobalDataService.CampagneExt.selectedAdresses,
      this.filteredAvailableRecords,
      0,
      this.filteredAvailableRecords.indexOf(adresse)
    );

    transferArrayItem(
      this.filteredAvailableRecords,
      this._GlobalDataService.CampagneExt.selectedAdresses,
      this.filteredAvailableRecords.indexOf(adresse),
      0
    );
    this._campagneAdressesService.form.get('NomAdresse').setValue(adresse.NomAdresse);
    this._GlobalDataService.Campagne.IdAdresse = (this._GlobalDataService.CampagneExt.selectedAdresses.length > 0 && this._GlobalDataService.CampagneExt.selectedAdresses[0].IdAdresse) || '0';
    this._GlobalDataService.CampagneExt.Adresse = (this._GlobalDataService.CampagneExt.selectedAdresses.length > 0 && this._GlobalDataService.CampagneExt.selectedAdresses[0]) || null;
    this._GlobalDataService.Campagne.isDirty = true;

  }

  unselect(adresse: AdresseRetour) {
    transferArrayItem(
      this._GlobalDataService.CampagneExt.selectedAdresses,
      this.filteredAvailableRecords,
      this._GlobalDataService.CampagneExt.selectedAdresses.indexOf(adresse),
      this.filteredAvailableRecords.length - 1
    );
    this._campagneAdressesService.form.get('NomAdresse').setValue(null);
    this._GlobalDataService.Campagne.IdAdresse = '0';
    this._GlobalDataService.CampagneExt.Adresse = null;    
    this._GlobalDataService.Campagne.isDirty = true;
  }

  updateAdresse(adresse: AdresseRetour) {
    console.log(adresse);
    adresse.Favori =  adresse.Favori === '0' ? '1' : '0';
    this._AdresseService.add(adresse)
      .subscribe(
          response => console.log(response),
          error =>  this._GlobalDataService.alert("Une erreur s'est produite lors de la mise à jour")
      );
  }


  ngOnDestroy() {
  }

}
