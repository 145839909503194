<p>
  L’application Cap Mailing Plateforme est uniquement dédiée à l’émission de
  campagnes de courriers publicitaires.
  <tr>
    Pour en savoir plus sur la définition de ce types de campagnes,
    <strong (click)="openHelpMarketing()"
      >cliquez ici pour accèder à la trousse à outils.</strong
    >
  </tr>
</p>

<form
  [formGroup]="_CampagneIdentificationService.form"
  #formDirective="ngForm"
  class="mat-form"
  novalidate
>
  <mat-grid-list cols="25" rowHeight="1.2rem">
    <mat-grid-tile
      colspan="6"
      rowspan="38"
      *ngIf="_GlobalDataService.Campagne.IdCampagneCopie != '0'"
    >
    </mat-grid-tile>

    <mat-grid-tile colspan="12" rowspan="38" class="gtmt-ai-start">
      <mat-grid-list cols="12" rowHeight="1rem" style="width: 100%">
        <!-- Nom de la campagne courrier -->
        <mat-grid-tile colspan="12" rowspan="10">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Nom de la campagne courrier</mat-label>
            <input
              matInput
              placeholder="Nom de la campagne courrier"
              formControlName="NomCampagne"
              (change)="verifIsUnique(); verifForbiddenCharactersNom($event); emitCampaignName($event)"
              required
              maxlength="100"
              type="string"
            />
          </mat-form-field>
        </mat-grid-tile>

        <!-- Référence de bon de commande interne -->
        <mat-grid-tile colspan="12" rowspan="10">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Référence de bon de commande interne</mat-label>
            <input
              matInput
              placeholder="Référence de bon de commande interne"
              formControlName="BonDeCommande"
            />
          </mat-form-field>
        </mat-grid-tile>

        <!-- Description -->
        <mat-grid-tile colspan="12" rowspan="10">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              formControlName="Description"
              placeholder="Description"
              placeholder="Description"
              cdkTextareaAutosize
              cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="3"
              (blur)="dumpValue('Description')"
            >
            </textarea>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Nom de campagne Parente -->
        <mat-grid-tile
          colspan="12"
          rowspan="10"
          *ngIf="_GlobalDataService.Campagne.IdCampagneCopie != '0'"
        >
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Duplication</mat-label>
            <input matInput formControlName="NomCampagneParente" readonly />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-grid-tile>

    <mat-grid-tile
      colspan="1"
      rowspan="38"
      *ngIf="_GlobalDataService.Campagne.IdCampagneCopie === '0'"
    >
    </mat-grid-tile>

    <mat-grid-tile
      *ngIf="_GlobalDataService.Campagne.IdCampagneCopie === '0'"
      colspan="12"
      rowspan="38"
      class="gtmt-ai-start"
    >
      <!-- <mat-grid-tile *ngIf="_GlobalDataService.Campagne.IdCampagneCopie != '0'" colspan="12" rowspan="42"> -->
      <mat-grid-list cols="12" rowHeight="1rem" style="width: 100%">
        <!-- Dupliquer une campagne courrier existante -->
        <mat-grid-tile colspan="12" rowspan="10">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Dupliquer une campagne courrier existante</mat-label>
            <input
              matInput
              #IdCampagneCopie
              placeholder="Utiliser les paramètres d’une campagne existante"
              formControlName="NomCampagneParente"
            />
            <button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon svgIcon="Loupe"></mat-icon>
            </button>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Liste campagnes -->
        <mat-grid-tile colspan="12" rowspan="32">
          <cdk-virtual-scroll-viewport appendOnly itemSize="56">
            <mat-list>
              <mat-list-item *cdkVirtualFor="let campagne of filteredcampagnes">
                <mat-icon
                  mat-list-icon
                  styles="width:4px; height:4px"
                  svgIcon="Ellipse2"
                ></mat-icon>
                <span
                  style="width: 80%"
                  class="gtmt-overflow-ellipsis"
                  matLine
                  >{{ campagne.NomCampagne }}</span
                >
                <div style="width: 15%" class="gtmt-raised-button">
                  <button
                    mat-raised-button
                    color="blue"
                    (click)="openCampagneDuplicationDialog(campagne)"
                  >
                    Afficher
                  </button>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
          </cdk-virtual-scroll-viewport>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-grid-tile>

    <mat-grid-tile
      colspan="6"
      rowspan="38"
      *ngIf="_GlobalDataService.Campagne.IdCampagneCopie != '0'"
    >
    </mat-grid-tile>
  </mat-grid-list>
</form>

<!-- Stepper navigation  -->
<app-campagne-stepper-nav
  [prevDisplay]="_GlobalDataService.Campagne.IdCampagneCopie !== '0'" 
  [prevDisabled]="true" 
  [nextDisplay]="true" 
  (previous)="goToPrevious()"
  (next)="submit()">
></app-campagne-stepper-nav>
