import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '@core/services';

@Component({
  selector: 'app-rythme-envoi',
  templateUrl: './rythme-envoi.component.html',
  styleUrls: ['./rythme-envoi.component.scss']
})
export class RythmeEnvoiComponent implements OnInit {

  constructor(
    public _GlobalDataService: GlobalDataService,
  ) { }

  ngOnInit(): void {
   
  }

}
