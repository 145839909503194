<mat-card class="gtmt-card-container" [formGroup]="_CampagneTypologieService.SupportFormGroup">
    <mat-card-title>Nombre de feuilles et plis</mat-card-title>
    <mat-card-content>

        <mat-grid-list cols="2" rowHeight="3.5rem" [gutterSize]="'1.2rem'">
            <mat-radio-group formControlName="CodeSupport" aria-labelledby="dimension-radio-group-label"
                class="dimension-radio-group">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="0.5"
                    *ngFor="let dataSupport of _CampagneTypologieService.dataSupports"
                    (click)="_CampagneTypologieService.SupportFormGroup.get('CodeSupport').setValue(dataSupport.CodeSupport)"
                    >
                    <span> {{dataSupport.support}}</span>
                    <mat-radio-button [value]="dataSupport.CodeSupport">
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list>


    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="Valider()">
            Valider la sélection
        </button>
    </mat-card-actions>

</mat-card>