<!-- <h2 mat-dialog-title>Install Angular</h2> -->

<div mat-dialog-title>
    <h1> {{ title }}</h1>
    <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<mat-dialog-content [formGroup]="form" class="mat-typography">

    <mat-grid-list cols="2" rowHeight="4rem" [gutterSize]="'1.5rem'">

        <!-- Nom Adresse -->
        <mat-grid-tile colspan="1" rowspan="2">
            <mat-form-field appearance="fill"  floatLabel="always">
                <mat-label>Nom de l’adresse</mat-label>
                <input matInput type="text" formControlName="NomAdresse" required>
                <mat-error *ngIf="form.get('NomAdresse').hasError('required')">
                    Nom de l’adresse est requis.
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>

        <!-- Raison Sociale -->
        <mat-grid-tile colspan="1" rowspan="2">
            <mat-form-field appearance="fill"  floatLabel="always">
                <mat-label>Raison Sociale</mat-label>
                <input matInput type="text" formControlName="RaisonSociale" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="form.get('RaisonSociale').hasError('required')">
                    Raison Sociale est requise.
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>


        <!-- Service -->
        <mat-grid-tile colspan="1" rowspan="2">
            <mat-form-field appearance="fill"  floatLabel="always">
                <mat-label>Service</mat-label>
                <input matInput type="text" formControlName="Service" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="form.get('Service').hasError('required')">
                    Service est requis.
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>


        <!-- Entrée / Bâtiment  -->
        <mat-grid-tile colspan="1" rowspan="2">
            <mat-form-field appearance="fill"  floatLabel="always">
                <mat-label>Entrée / Bâtiment </mat-label>
                <input matInput type="text" formControlName="EntreeBatimentZI" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="form.get('EntreeBatimentZI').hasError('required')">
                    Entrée / Bâtiment  est requis.
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>        

        <!-- Numéro / libellé de la voie  -->
        <mat-grid-tile colspan="2" rowspan="2">
            <mat-form-field appearance="fill"  floatLabel="always">
                <mat-label>Numéro / libellé de la voie </mat-label>
                <input matInput type="text" formControlName="Numero" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="form.get('Numero').hasError('required')">
                    Numéro / libellé de la voie est requis.
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>  

        <!-- Mention de distribution  -->
        <mat-grid-tile colspan="2" rowspan="2">
            <mat-form-field appearance="fill"  floatLabel="always">
                <mat-label>Mention de distribution </mat-label>
                <input matInput type="text" formControlName="MentionSpeciale" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="form.get('MentionSpeciale').hasError('required')">
                    Mention de distribution  est requis.
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>  

        <!-- Ville -->
        <mat-grid-tile colspan="1" rowspan="2">
            <mat-form-field appearance="fill"  floatLabel="always">
                <mat-label>Ville</mat-label>
                <input matInput type="text" formControlName="Ville" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="form.get('Ville').hasError('required')">
                    Ville est requise.
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>

        <!-- Code postal -->
        <mat-grid-tile colspan="1" rowspan="2">
            <mat-form-field appearance="fill"  floatLabel="always">
                <mat-label>Code postal</mat-label>
                <input matInput type="text" formControlName="CodePostal" [errorStateMatcher]="errorMatcher">
                <mat-error *ngIf="form.get('CodePostal').hasError('required')">
                    Code postal est requis.
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>

    </mat-grid-list>

</mat-dialog-content>


<mat-dialog-actions align="center">
    <button mat-raised-button (click)="valider()" 
    [disabled]="form.invalid"
    cdkFocusInitial>Valider</button>
</mat-dialog-actions>