<mat-card>
    <mat-card-header>
        <mat-card-title>Mentions légales</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h2>Vos données personnelles</h2>
        <div>
            <p>Vos données personnelles sont traitées par La Poste afin permettre la réalisation de statistiques
                anonymes concernant le service
            </p>
        </div>
        <div>
            <p>Les destinataires de vos données sont La Poste et ses prestataires (Efrontech SA et Dupliprint)
            </p>
        </div>
        <div>
            <p>Vos données sont conservées pendant 90 jours
            </p>
        </div>
        <div>
            <p>Vous disposez d’un droit d’accès, de rectification, d’opposition, de la limitation du traitement, de
                portabilité pour demander le transfert de vos données lorsque cela est possible et d’effacement de vos
                données en adressant un courriel à :
                <a
                    href="mailto: mesdonneespersonnelles.laposte@laposte.fr">mesdonneespersonnelles.laposte@laposte.fr</a><br>
                Dans le cadre de la politique de protection des données personnelles de La Poste, vous pouvez contacter
                Madame la Déléguée à la Protection des Données, CP C703, 9 rue du Colonel Pierre Avia 75015 PARIS.<br>
                En cas de difficulté dans la gestion de vos données personnelles, vous pouvez introduire une réclamation
                auprès de la CNIL.
            </p>
        </div>
        <h2>Conditions générales</h2>
        <div id='cgv'>
            <p>Retrouvez nos conditions générales de vente :
                <a href="https://www.laposte.fr/conditions-generales-de-vente" target="_blank">CGV</a>
            </p>
        </div>
        <div id='csv'>
            <p>Retrouvez nos conditions spécifiques de vente :
                <a href="https://lastation.laposte.fr/sites/p8_u1/files/2022-12/CSV_V3_20221116.pdf" target="_blank">CSV</a>
            </p>
        </div>
    </mat-card-content>
</mat-card>

