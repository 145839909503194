<mat-card class="gtmt-card-container" [formGroup]="_CampagneTypologieService.EnveloppeFormGroup">
    
    <mat-card-title>Dimension</mat-card-title>

    <mat-card-content>
        <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'">
            <mat-radio-group formControlName="CodeDimensionsEnveloppe" (change)="dimensionChanged()"
                aria-labelledby="dimension-radio-group-label" class="dimension-radio-group"
                [(ngModel)]="_GlobalDataService.Campagne.CodeDimensionsEnveloppe">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="0.5"
                    (click)="_CampagneTypologieService.EnveloppeFormGroup.get('CodeDimensionsEnveloppe').setValue(dataDimensionsEnveloppe.CodeDimensionsEnveloppe)"
                    *ngFor="let dataDimensionsEnveloppe of _CampagneTypologieService.dataDimensionsEnveloppes">
                    <span>  {{dataDimensionsEnveloppe.DimensionsEnveloppe}}</span>
                    <mat-radio-button [value]="dataDimensionsEnveloppe.CodeDimensionsEnveloppe">                       
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="Valider()">
            Valider la sélection
        </button>
    </mat-card-actions>

</mat-card>