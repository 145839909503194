import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalDataService } from './global-data.service';
import { FichiersCreation } from '../models/FichiersCreation.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FichierCreaService {

  _url = environment.url_api + '/fichier-creation';
  _standaloneUrl = environment.url_api + '/standalone/fichier-creation';
  _salesforceUrl = environment.url_api + '/salesforce/fichier-creation';

  constructor(private _http: HttpClient,
    public _GlobalDataService: GlobalDataService,
  ) { }

  add(FichiersCreation: FichiersCreation) {
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url!: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + '/add';
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + '/add';
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + '/add';
        break;
      }
    }
    let request = {
      NomFichier : FichiersCreation.NomFichier,
      TypeFichier : FichiersCreation.TypeFichier,
      IdCampagne: FichiersCreation.IdCampagne,
      FichierPersonnalisation: FichiersCreation.FichierPersonnalisation,
      FichierLogo: FichiersCreation.FichierLogo
    }
    return this._http.post<any>(url, { request: request }, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${ this._GlobalDataService.getJwtoken() }` })
    });
  }

  uploadFile(file: File|Blob, idCampagne?: string) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url!: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = environment.url_scheduler + '/uploadFile';
        break;
      }
      case "STANDALONE": {
        url = environment.url_scheduler + '/standalone/uploadFile';
        break;
      }
      case "SALESFORCE": {
        url = environment.url_scheduler + '/salesforce/uploadFile';
        break;
      }
    }
    let formData = new FormData();
    formData.append("file", file);
    formData.append("idCampagne", <string>idCampagne);

    return this._http.post<any>(url, formData, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${ this._GlobalDataService.getJwtoken() }` })
    });
  }

  download(fileName: string, idCampagne?: string) {

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url!: string;

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = environment.url_scheduler + `/downloadFile/${fileName}`
        break;
      }
      case "STANDALONE": {
        url = environment.url_scheduler + `/standalone/downloadFile/${idCampagne}/${fileName}`
        break;
      }
      case "SALESFORCE": {
        url = environment.url_scheduler + `/salesforce/downloadFile/${idCampagne}/${fileName}`
        break;
      }
    }
    return this._http.get(url, {
      params: this._GlobalDataService.objectParams,
      responseType: 'blob',
      headers: new HttpHeaders({ "Authorization": `Bearer ${ this._GlobalDataService.getJwtoken() }` })
    });
  }

/**
 * permet de récupérer les fichiers créa d'une campagne suivant l'AssetId et InstanceId dans l'URL
 */
  list(IdCampagne: string){

    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url: string = "";

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + "/list/" + IdCampagne;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + "/list/" + IdCampagne
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + "/list/" + IdCampagne
        break;
      }
    }

    return this._http.get<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${ this._GlobalDataService.getJwtoken() }` })
    });
  }

  // delete(idFichier: string){
  //   let url = this._url + '/' + idFichier
  //   return this._http.delete<any>(url, {
  //     params: this._GlobalDataService.objectParams,
  //     headers: new HttpHeaders({ "Authorization": `Bearer ${ this._GlobalDataService.getJwtoken() }` })
  //   });
  // }

  delete(idFichier: string){
    
    // ATA@20230403 - Security enhancements
    // if (this._GlobalDataService.isTokenExpired(this._GlobalDataService.getJwtoken())) {
    //   this._GlobalDataService.refreshToken();
    // }    
    let url: string = "";

    switch (this._GlobalDataService.CONTEXT) {
      case "ELOQUA": {
        url = this._url + "/" + idFichier;
        break;
      }
      case "STANDALONE": {
        url = this._standaloneUrl + "/" + idFichier
        break;
      }
      case "SALESFORCE": {
        url = this._salesforceUrl + "/" + idFichier
        break;
      }
    }

    return this._http.delete<any>(url, {
      params: this._GlobalDataService.objectParams,
      headers: new HttpHeaders({ "Authorization": `Bearer ${ this._GlobalDataService.getJwtoken() }` })
    });
  }

}
