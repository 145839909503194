import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalDataService } from '@core/services';
import { AccountService } from '@core/services/account.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent {
  public userForm = new FormGroup({
    login: new FormControl('', [
      Validators.minLength(1),
      Validators.maxLength(50),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    firstname: new FormControl('', [Validators.maxLength(50)]),
    lastname: new FormControl('', [Validators.maxLength(50)]),
  });

  public isLoadingCreateUser = false;

  constructor(
    private router: Router,
    private _AccountService: AccountService,
    private _GlobalDataService: GlobalDataService
  ) {}

  public onSubmit() {
    this.isLoadingCreateUser = true;
    this._AccountService.standaloneCreateUser(this.userForm.value).subscribe({
      next: (_) => {
        this.isLoadingCreateUser = false;
        this._GlobalDataService.alert(
          `L'utilisateur ${this.userForm.value.login} a été créé avec succès.`
        );
        this.userForm.reset();
        this.gotoManageUsers();
      },
      error: (err) => {
        this.isLoadingCreateUser = false;
        console.log(
          `Erreur technique lors de création de l'utilisateur ${this.userForm.value.login}!`,
          err.error
        );
        if (err.error?.message) {
          this._GlobalDataService.alert(err.error.message);
        } else {
          this._GlobalDataService.alert('Une erreur est survenue.');
        }
      },
    });
  }

  private gotoManageUsers() {
    console.log('gotoManageUsers');
    this.router
      .navigate(
        ['/standalone/home', { outlets: { navview: ['manage-users'] } }],
        {
          queryParams: this._GlobalDataService.objectParams,
          queryParamsHandling: 'merge',
          skipLocationChange: true,
        }
      )
      .then((e) => {
        if (e) {
          console.log('Navigation is successful!');
        } else {
          console.log('Navigation has failed!');
        }
      });
  }
}
