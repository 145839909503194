<mat-grid-list cols="1" rowHeight="250rem">
  <mat-grid-tile>
    <div id="content">

      <div class="title">
        <span class="title-text">Suivi de campagne courrier</span>
        <span class="fill-space"></span>
        <button mat-button class="btn-grey" (click)="returnToList()">Toutes vos campagnes courrier</button>
      </div>

      <!-- <div *ngIf="isLoaded"> -->

      <div *ngIf="!recurrence">
        <div class="flex font-bold">
          <span>Statut de la campagne courrier</span>
          <span class="fill-space"></span>
          <span>{{percentage}}%</span>
        </div>
        <p class="secondary">{{campagne?.StatutCampagneLibelle || campagne?.StatutCampagne}}</p>
        <mat-progress-bar class="custom-progress" mode="determinate" [value]="percentage"></mat-progress-bar>
        <div class="secondary">● Progression de la campagne courrier</div>

      </div>

      <div>
        <div class="subtitle">évolution de la campagne courrier</div>
        <div *ngIf="!recurrence">
          <div class="grid-evo-vignettes center">
            <div class="secondary">{{statusBefore?.vignetteTitle || "Terminé"}}</div>
            <div></div>
            <div class="secondary">{{statusCurrent?.vignetteTitle || "En cours"}}</div>
            <div></div>
            <div class="secondary">{{statusNext?.vignetteTitle || "À venir"}}</div>
            <div class="vignette" [class.evo-before]="statusBefore?.vignetteTitle === 'Terminé'"
              [class.evo-now]="statusBefore?.vignetteTitle === 'En cours'"
              [class.evo-after]="statusBefore?.vignetteTitle === 'À venir'">
              <div *ngIf="statusBefore && statusBefore.codeStatut">
                <p class="grid-title">{{ _GlobalDataService.mappingStatut[statusBefore.codeStatut]}}</p>
                <hr>
                <p>Date : {{statusBefore.dateModification}}</p>
                <button mat-raised-button color="blue" *ngIf="statusBefore.statut ==='BAT_AVAILABLE,OK'"
                  style="float: right;" (click)="VisualiserBAT()">
                  Voir
                </button>
              </div>
            </div>
            <div class="table">
              <mat-icon class="table-cell" *ngIf="statusBefore && statusBefore.statut" (click)="onChevronLeft()">
                chevron_left</mat-icon>
            </div>
            <div class="vignette" [class.evo-before]="statusCurrent?.vignetteTitle === 'Terminé'"
              [class.evo-now]="statusCurrent?.vignetteTitle === 'En cours'"
              [class.evo-after]="statusCurrent?.vignetteTitle === 'À venir'">
              <div *ngIf="statusCurrent">
                <p class="grid-title">{{_GlobalDataService.mappingStatut[statusCurrent.codeStatut]}}</p>
                <hr>
                <p>Date : {{statusCurrent.dateModification}}</p>
                <button mat-raised-button color="blue" *ngIf="statusCurrent.statut ==='BAT_AVAILABLE,OK'"
                  style="float: right;" (click)="VisualiserBAT()">
                  Voir
                </button>
              </div>
            </div>
            <div class="table">
              <mat-icon class="table-cell" *ngIf="statusNext && statusNext.statut" (click)="onChevronRight()">
                chevron_right
              </mat-icon>
            </div>
            <div class="vignette" [class.evo-before]="statusNext?.vignetteTitle === 'Terminé'"
              [class.evo-now]="statusNext?.vignetteTitle === 'En cours'"
              [class.evo-after]="statusNext?.vignetteTitle === 'À venir'">
              <div *ngIf="statusNext && statusNext.codeStatut">
                <p class="grid-title">{{_GlobalDataService.mappingStatut[statusNext.codeStatut]}}</p>
                <hr>
                <p>Date : {{statusNext?.dateModification || "À venir"}}</p>
                <button mat-raised-button color="blue" *ngIf="statusNext.statut ==='BAT_AVAILABLE,OK'"
                  style="float: right;" (click)="VisualiserBAT()">
                  Voir
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="recurrence">
          <div class="grid-evo-vignettes">
            <div>
              <p class="grid-title">Date de début de récurrence</p>
              <p>{{campagne.DateDeDebut}}</p>
            </div>
            <div></div>
            <div>
              <p class="grid-title">Date de fin de récurrence</p>
              <p>{{campagne.DateDeFin}}</p>
            </div>
            <div></div>
            <div>
              <p class="grid-title">Date de validation du BAT</p>
              <p>{{DateValidationBAT}}</p>
            </div>
          </div>
        </div>
      </div>




      <!-- <mat-grid-list cols="1" rowHeight="6rem">

    <mat-grid-tile colspan="1" rowspan="1">
      <button [disabled]="autoriserAnnulationCampagne(Campagne)" mat-raised-button color="accent"
        (click)="demandeAnnulation(Campagne)">
        <mat-icon>cancel</mat-icon>Annuler la campagne
      </button>
    </mat-grid-tile>
  </mat-grid-list> -->


      <mat-grid-list *ngIf="!recurrence" [cols]="3" rowHeight="8rem" [gutterSize]="'2rem'">

        <mat-grid-tile colspan="1" rowspan="1">
          <h2>Commande envoyée le </h2>
          <span>{{currentcommande?.DateCreation}}</span>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <h2>Numéro de commande</h2>
          <span>{{currentcommande?.IdCommande}}</span>

        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <h2>Nombre de plis</h2>
          <span>{{currentcommande?.NombrePli}}</span>

        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <h2>Référence La Poste</h2>
          <span>{{currentcommande?.NCommandeLaPoste}}</span>

        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <h2>Commande transmise le</h2>
          <span>{{currentcommande?.DateCreation}}</span>

        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <h2>Nombre de plis non distribués</h2>
          <span>{{currentcommande?.NombrePliPND}}</span>

        </mat-grid-tile>
      </mat-grid-list>


      <mat-grid-list [cols]="1" rowHeight="8rem" [gutterSize]="'2rem'">

        <mat-grid-tile class="gtmt-flex-end" colspan="1" rowspan="1">
          <button *ngIf="campagne?.FrequenceEnvoi == 'Récurrente'" mat-button class="btn-grey"
            [disabled]="statutsAutoriseAnnulationCampagne.indexOf(campagne.CodeStatut) > -1"
            (click)="demandeAnnulation()">
            Annuler la campagne récurrente
          </button>

          <button *ngIf="campagne?.FrequenceEnvoi != 'Récurrente'" mat-button class="btn-grey"
            [disabled]="statutsAutoriseAnnulationCommande.indexOf(currentcommande?.CodeStatut) > -1"
            (click)="demandeAnnulationCommande(currentcommande)">
            Annuler cette campagne courrier
          </button>

        </mat-grid-tile>
      </mat-grid-list>

      <div *ngIf="recurrence">
        <div class="subtitle">BON à TIRER</div>
        <mat-grid-list [cols]="2" rowHeight="5rem" [gutterSize]="'2rem'">

          <mat-grid-tile class="gtmt-ai-start gtmt-jc-space-between" colspan="1" rowspan="2">

            <span style="font-weight: bold;"> Bon à tirer</span>
            <span> {{currentStatutCommande?.StatutLibelle }}</span>
            <span> Date
              {{currentStatutCommande?.dateModification }}
            </span>
          </mat-grid-tile>


          <mat-grid-tile class="gtmt-ai-end gtmt-jc-end" colspan="1" rowspan="2">

            <button mat-button class="btn-grey" (click)="VisualiserBAT()" [disabled]="['', undefined].indexOf(_GlobalDataService.CampagneExt.CurrentCommande?.UrlBat) != -1">
              Visualiser mon Bon à Tirer
            </button>

          </mat-grid-tile>
        </mat-grid-list>
      </div>


      <div>
        <div class="subtitle" (click)="toggleResume=!toggleResume">récapitulatif de la campagne courrier<mat-icon
            class="pull-right">
            {{toggleResume?'expand_more':'chevron_right'}}</mat-icon>
        </div>
        <div *ngIf="toggleResume">
          <div *ngIf="campagne">

            <p>
              Le volume requis pour cette commande est de <B> {{ campagne.Produit.MinFabrication}} PLIS MINIMUM </B> et <B>{{campagne.Produit.MaxFabrication === '0' ? 'PAS DE ' : 'de ' + campagne.Produit.MaxFabrication}} PLIS MAXIMUM </B>
            </p>

<!--             
            <p>Le volume requis pour cette commande est de <span class="highlight">{{campagne.Produit.MinFabrication ||
                '-'}}
                PLIS MINIMUM</span><span *ngIf="campagne.Produit.MaxFabrication && campagne.Produit.MaxFabrication > 0">
                et
                de <span class="highlight">{{campagne.Produit.MaxFabrication}} PLIS
                  MAXIMUM</span></span></p> -->
            <div class="wrapper">
              <div>
                <p class="grid-title">Statut de la campagne courrier</p>
                <p>{{campagne?.StatutCampagneLibelle || campagne?.StatutCampagne}}</p>
              </div>
              <div>
                <p class="grid-title">Date de remise imprimeur</p>
                <p *ngIf="campagne.DateRemiseImprimeur && campagne?.DateRemiseImprimeur != 'null'">
                  {{campagne.DateRemiseImprimeur | date :'dd/MM/yyyy'}}</p>
              </div>
              <div>
                <p class="grid-title">Nom de la campagne courrier</p>
                <p>{{campagne.NomCampagne}}</p>
              </div>
              <div>
                <p class="grid-title">Bon de commande</p>
                <p>{{campagne.BonDeCommande}}</p>
              </div>
              <div>
                <p class="grid-title">Description</p>
                <p>{{campagne.Description}}</p>
              </div>
              <div>
                <p class="grid-title">Adresse de notification</p>
                <p><span class="highlight">{{campagne.EmailNotification}}</span></p>
              </div>
              <div>
                <p class="grid-title">Fréquence</p>
                <p *ngIf="campagne.FrequenceEnvoi">{{campagne.FrequenceEnvoi}}</p>
              </div>
              <div>
                <p class="grid-title">Vitesse</p>
                <p>{{campagne.CodeVitesse}}</p>
              </div>
              <div>
                <p class="grid-title">Alliage</p>
                <p>{{campagne.Alliage ? 'Oui' : 'Non'}}</p>
              </div>
              <div>
                <p class="grid-title">Support</p>
                <hr />
                <div class="grid-div" *ngIf="campagne.Produit.Typologie">
                  <div>Format</div>
                  <div class="highlight center">{{campagne.Produit.support}}</div>
                </div>
                <div class="grid-div" *ngIf="campagne.Produit.Typologie">
                  <div>Dimension</div>
                  <div class="highlight center">{{campagne.Produit.DimensionsSupport}}</div>
                </div>
                <div class="grid-div" *ngIf="campagne.Produit.Typologie">
                  <div>Grammage</div>
                  <div class="highlight center">{{campagne.Produit.GrammageSupport}}</div>
                </div>
                <div class="grid-div" *ngIf="campagne.Produit.Typologie">
                  <div>Finition</div>
                  <div class="highlight center">{{campagne.Produit.FinitionSupport}}</div>
                </div>
              </div>
              <div *ngIf="_GlobalDataService.isSalesforceContext()">
                <p class="grid-title">Champ de personnalisation</p>
                <hr />
                <div class="grid-div"
                  *ngFor="let champ of personalisationSfService.selectedChampsFusion;let index = index">
                  <div>Champ {{index+1}}</div>
                  <div class="highlight center">{{champ.ChampFusion}}</div>
                </div>

              </div>

              <div *ngIf="_GlobalDataService.isEloquaContext()">
                <p class="grid-title">Champ de personnalisation</p>
                <hr />
                <div class="grid-div"
                  *ngFor="let champ of personalisationService.selectedChampsFusion;let index = index">
                  <div>Champ {{index+1}}</div>
                  <div class="highlight center">{{champ.ChampFusion}}</div>
                </div>

              </div>
              <!-- <mat-card>
              <mat-card-title class="grid-title">
                Champ de Personnalisation
              </mat-card-title>
              <mat-divider></mat-divider>
              <mat-card-content>
                <app-champ-fusion-card *ngIf="_GlobalDataService.isSalesforceContext()"
                  [cfs]="personalisationSfService.selectedChampsFusion"></app-champ-fusion-card>
                <app-champ-fusion-card *ngIf="_GlobalDataService.isEloquaContext()"
                  [cfs]="personalisationService.selectedChampsFusion"></app-champ-fusion-card>
              </mat-card-content>
            </mat-card> -->

              <div>
                <p class="grid-title">Fichiers de créations</p>
                <hr />
                <div class="grid-div-file" *ngFor="let file of campagne.FichiersCreation">
                  <div>{{file.NomFichier}}</div>
                  <div class="highlight center">
                    <a (click)="downloadFile(file.NomFichier)" download="{{file.NomFichier}}">Ouvrir <mat-icon>
                        open_in_new
                      </mat-icon>
                    </a>
                  </div>
                </div>
              </div>
              <div>


                <div *ngIf="_GlobalDataService.isSalesforceContext()">
                  <p class="grid-title">Pavé adresse</p>
                  <hr />
                  <div class="grid-div"
                    *ngFor="let champ of adressesDestinatairesSfService.selectedChampsFusion;let index = index">
                    <div>Champ {{index+1}}</div>
                    <div class="highlight center">{{champ.ChampFusion}}</div>
                  </div>

                </div>

                <div *ngIf="_GlobalDataService.isEloquaContext()">
                  <p class="grid-title">Pavé adresse</p>
                  <hr />
                  <div class="grid-div"
                    *ngFor="let champ of adressesDestinatairesService.selectedChampsFusion;let index = index">
                    <div>Champ {{index+1}}</div>
                    <div class="highlight center">{{champ.ChampFusion}}</div>
                  </div>

                </div>

                <!-- <p class="grid-title">Pavé adresse</p>
            <hr />
            <div class="grid-div" *ngIf="campagne.Adresse">
              <div>Nom</div>
              <div class="highlight center">{{campagne.Adresse.Service}}</div>
            </div>
            <div class="grid-div" *ngIf="campagne.Adresse">
              <div>Raison sociale</div>
              <div class="highlight center">{{campagne.Adresse.RaisonSociale}}</div>
            </div>
            <div class="grid-div" *ngIf="campagne.Adresse">
              <div>Libellé de la voie</div>
              <div class="highlight center">{{campagne.Adresse.NomAdresse}}</div>
            </div>
            <div class="grid-div" *ngIf="campagne.Adresse">
              <div>Ville</div>
              <div class="highlight center">{{campagne.Adresse.Ville}}</div>
            </div>
            <div class="grid-div" *ngIf="campagne.Adresse">
              <div>Code postal</div>
              <div class="highlight center">{{campagne.Adresse.CodePostal}}</div>
            </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="recurrence">
        <div class="subtitle">occurences précédentes de la campagne courrier</div>
        <div>
          <table mat-table [dataSource]="dataSource" matTableExporter matSort multiTemplateDataRows
            #exporter="matTableExporter">
            <ng-container matColumnDef="CodeGTMT">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Code GTMT </th>
              <td mat-cell *matCellDef="let element"> {{element.NCommandeLaPoste}} </td>
            </ng-container>
            <ng-container matColumnDef="Statut">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
              <td mat-cell *matCellDef="let element"> {{ _GlobalDataService.mappingStatut[element.CodeStatut]}} </td>
            </ng-container>
            <ng-container matColumnDef="DateDeTransmission">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de transmission </th>
              <td mat-cell *matCellDef="let element"> {{element.DateCreation}} </td>
            </ng-container>
            <ng-container matColumnDef="DateDEnvoi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date d'envoi </th>
              <td mat-cell *matCellDef="let element"> {{element.dateModification}} </td>
            </ng-container>
            <ng-container matColumnDef="DateDepositDone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date deposite done </th>
              <td mat-cell *matCellDef="let element"> {{element.dateModification}} </td>
            </ng-container>
            <ng-container matColumnDef="NombreDePlis">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de plis </th>
              <td mat-cell *matCellDef="let element"> {{element.NombrePli}} </td>
            </ng-container>
            <ng-container matColumnDef="Annulation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Annulation </th>
              <td mat-cell *matCellDef="let element" class="action-link">
                <button mat-button class="btn-grey" [disabled]="autoriserAnnulationCommande(element)"
                  (click)="demandeAnnulationCommande(element)">
                  Annuler
                </button>
              </td>
              <!-- <td mat-cell
              *matCellDef="let element"> {{element.NCommandeLaPoste}} </td> -->
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr title="Commande" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <!-- Row shown when there is no matching data. -->
            <tr *matNoDataRow>
              <td colspan="13">Aucune donnée</td>
            </tr>
          </table>
        </div>
      </div>

    </div>

    <!-- </div> -->
    <!-- <mat-form-field>
  <mat-label>Filtrer:</mat-label>
  <input matInput
         (keyup)="applyFilter($event)"
         placeholder="Ex. 123"
         #input>
</mat-form-field> -->

    <!-- <div style="overflow-x: scroll;">
  <table mat-table
         class="deail-commande Cofirmation"
         [dataSource]="dataSource"
         matSort
         multiTemplateDataRows>

    <ng-container matColumnDef="IdCommande">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> N° commande </th>
      <td mat-cell
          *matCellDef="let row"> {{row.IdCommande}} </td>
    </ng-container>

    <ng-container matColumnDef="DateCreation">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header>Commande envoyée le</th>
      <td mat-cell
          *matCellDef="let row">

        <div *ngIf="row.DateCreation != 'null'; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          {{row.DateCreation | date :'dd/MM/yyyy'}}
        </ng-template>
        <ng-template #elseBlock>
          {{row.DateCreation}}
        </ng-template>

      </td>
    </ng-container>

    <ng-container matColumnDef="NombrePli">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Nbre de plis </th>
      <td mat-cell
          *matCellDef="let row"> {{row.NombrePli}} </td>
    </ng-container>

    <ng-container matColumnDef="NombrePliPND">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Nbre PND </th>
      <td mat-cell
          *matCellDef="let row"> {{row.NombrePliPND}} </td>
    </ng-container>

    <ng-container matColumnDef="StatutCommande"
                  style="min-width: 100px">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Statut </th>
      <td mat-cell
          *matCellDef="let row">
        <div style=" max-height: 6rem; min-width: 25rem; overflow-y: scroll;">
          {{row.StatutCommandeLibelle || row.StatutCommande}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="RaisonDuStatut">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Raison du statut </th>
      <td mat-cell
          *matCellDef="let row"
          title="Cliquez pour voir plus de détails">
        <div style="overflow-y: scroll;
                                max-height: 6rem;
                                min-width: 42rem;
                                max-width: 42rem;">
          {{row.RaisonDuStatut}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="RaisonDuStatutSocrate">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Raison du statut Socrate</th>
      <td mat-cell
          *matCellDef="let row">{{row.RaisonDuStatutSocrate}}</td>
    </ng-container>

    <ng-container matColumnDef="UrlBat">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> URL BAT </th>
      <td mat-cell
          *matCellDef="let row"> <a [href]="row.UrlBat"
           target="_blank"
           rel="noopener noreferrer">{{row.UrlBat}}</a> </td>
    </ng-container>
    <ng-container matColumnDef="LoginBAT">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Login BAT </th>
      <td mat-cell
          *matCellDef="let row"> {{row.LoginBAT}} </td>
    </ng-container>
    <ng-container matColumnDef="MotDePasseBAT">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header>Mot de passe BAT</th>
      <td mat-cell
          *matCellDef="let row"> {{row.MotDePasseBAT}} </td>
    </ng-container>

    <ng-container matColumnDef="NCommandeLaPoste">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Référence La Poste </th>
      <td mat-cell
          *matCellDef="let row"> {{row.NCommandeLaPoste}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                            <td mat-cell *matCellDef="let row" class="action-link">
                                <button mat-raised-button color="accent">
                                    <mat-icon>cancel</mat-icon>Annuler la commande
                                </button>
                            </td>
                        </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail">
          <app-timeline-commande-admin [IdCommande]="element.IdCommande">
          </app-timeline-commande-admin>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        [class.example-expanded-row]="expandedElement === row"></tr>
    <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"></tr>

    <tr class="mat-row"
        *matNoDataRow>
      <td class="mat-cell"
          colspan="9">Aucune donnée correspondant au filtre "{{input.value}}"
      </td>
    </tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 20]"
               showFirstLastButtons></mat-paginator>


<div class="title">
  <h2>Détail de la campagne</h2>
</div>-->
    <!-- <app-admin-detail-campagne-table *ngIf="isLoaded"
                                 [Campagne]="Campagne">
</app-admin-detail-campagne-table> -->



  </mat-grid-tile>
</mat-grid-list>